<script setup>
const props = defineProps({
  direction_arrow: {
    type: String,
    default: 'left'
  }
})
</script>
<template>
  <div class="tooltip inline-block relative w-auto">
    <slot class="tooltip" name="title"></slot>
    <ul :class="`arrow-${props.direction_arrow}`"
      class="z-50 absolute w-fit w-full bg-white rounded-md border-2 border-[#8FA0BC] text-xs leading-4 text-grey font-normal">
      <slot></slot>
    </ul>
  </div>
</template>

<style scoped>
.w-fit {
  min-width: fit-content;
}
.arrow-left {
  display: none;
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 15px), -50%);
  @apply py-3 px-2;
}

.arrow-left::before {
  content: '';
  position: absolute;
  top: 40%;
  left: 98%;
  transform: translateX(-50%);
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  transform: rotate(136deg);
  @apply border-t-2 border-l-2 border-[#8FA0BC]
}

.arrow-right {
  display: none;
  max-width: 250px;
  top: 0;
  right: calc(-32%);
  transform: translate(100%, calc(-60% + 11px));
  @apply py-3 px-6;
}

.arrow-right::before {
  content: '';
  position: absolute;
  top: calc(52%);
  right: 98%;
  transform: translateX(-50%);
  display: block;
  width: 13px;
  height: 13px;
  background-color: white;
  transform: rotate(314deg);
  @apply border-t-2 border-l-2 border-primary-light
}

.arrow-top {
  display: none;
  max-width: 150px;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 12px));
  @apply py-2 px-2;
  text-align: center;
}

.arrow-top::before {
  content: '';
  position: absolute;
  bottom: -17px;
  left: 50%;
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  transform: rotate(45deg) translateX(-50%);
  @apply border-b-2 border-r-2 border-primary-light
}

.arrow-down {
  display: none;
  max-width: 300px;
  top: 10px;
  left: 49%;
  transform: translate(-50%, calc(107% - 12px));
  @apply py-2 px-2;
  text-align: center;
}

.arrow-down::before {
  content: '';
  position: absolute;
  bottom: 12px;
  left: 55%;
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  transform: rotate(222deg) translateX(100%);
  @apply border-b-2 border-r-2 border-primary-light
}

.icon {
  top: 0px;
  left: 0px;
}

.tooltip {
  /* padding: 10px; */
}

.tooltip:hover:not(.no-tooltip) .arrow-left {
  display: block;
}

.tooltip:hover:not(.no-tooltip) .arrow-top {
  display: block;
}

.tooltip:hover:not(.no-tooltip) .arrow-right {
  display: block;
}

.tooltip:hover:not(.no-tooltip) .arrow-down {
  display: block;
}</style>
