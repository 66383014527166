<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold">Editar ruta</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <form @submit.prevent="editItinerary()" id="create-edit-user">
      <section class="pt-4">
        <nav class="px-12 border-b-2 border-grey-lighter">
          <ul class="flex">
            <li class="flex-none text-xl text-primary font-semibold border-b-2 border-primary pb-4 pr-1">Datos de la ruta</li>
          </ul>
        </nav>
        <article class="px-12 py-3">
            <fieldset>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-full mr-2" for="name">
                  <strong class="font-medium">Nombre de la ruta*</strong>
                  <input id="name" required v-model="itinerary.name"
                    class="p-3 mt-2 rounded-lg bg-grey-lighter-2" placeholder="Introduce un nombre para tu ruta..." type="text">
                </label>
              </div>
      <div class="mt-5 flex w-full">
        <label class="flex flex-col w-full" for="description">
          <strong class="font-medium text-grey-dark">Descripción de la ruta</strong>
          <textarea id="description" v-model="itinerary.description" cols="30" rows="10" placeholder="Escriba una descripción de la ruta..." class="bg-grey-lighter-2 resize-none rounded mt-2 p-3 h-24"></textarea>
        </label>
      </div>
            </fieldset>
        </article>
      </section>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <UiButton @click="changeState(false)" class="mr-3" :type="'outline-default'">Cancelar</UiButton>
        <UiButton :type="'secondary'" @click="validateForm">Guardar cambios</UiButton>
      </footer>
      </form>
    </div>
  </div>
  </transition>
</template>
<script>
import UiButton from '../Layout/UI/UiButton.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'AddUserModal',
  components: {
    UiButton
  },
  prop: ['id'],
  emits: ['modal:show', 'modal:hide', 'success'],
  data () {
    return {
      open: false,
      itinerary: {},
      itinerary_id: this.id
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getItinerary (itinerary_id) {
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${itinerary_id}/`)
        .then(({ data }) => {
          this.itinerary = data
        })
        .catch(() => {
          console.error('Ha habido un problema para cargar la ruta')
        })
    },
    async validateForm () {
      api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/`,
        {
          name: this.itinerary.name,
          description: this.itinerary.description
        })
        .then(() => {
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'La ruta se ha actualizado correctamente.'
          })
          this.$emit('success')
          this.changeState(false)
        })
        .catch(() => {
          console.error('Ha habido un problema al actualizar la ruta')
        })
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }

  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white
  }

</style>
