<template>
  <UiHeader :slotCol="true" :title="'Organizaciones'" :padding="'pt-8'">
    <div class="pl-12 pb-4 text-grey">Selecciona una organización para gestionarla <strong>({{count}} organizaciones)</strong></div>
  </UiHeader>
  <UiPageBody>
    <div class="flex justify-between mt-10">
      <SearchField @change="getBusiness" v-model="search" />
      <UiCustomSelect @change="getBusiness" :filter="false" v-model="business_kind">
        <option value="">Tipo empresa</option>
        <option value="lead">Oportunidad de venta</option>
        <option value="free">FREE</option>
        <option value="customer">Cliente</option>
        <option value="closed">Sin acceso</option>
        <option value="old">Antiguo Cliente</option>
        <option value="other">Usos varios</option>
      </UiCustomSelect>
    </div>
<!--     <div class="mt-5">
      <h2 class="text-xl mb-2">Por orden alfabético</h2>
      <ul class="flex flex-wrap">
        <li class="text-grey-light" v-for="letter in letters" :key="letter">
          <button class="w-10 text-lg uppercase" @click="addOrRemoveLetter(letter)" :class="{'font-bold text-primary': selected_letter == letter}">{{letter}}</button>
        </li>
      </ul>
    </div> -->
    <section class="mt-5">
      <div class="flex flex-wrap">
        <div class="w-1/4 flex pl-2 pr-6 py-4" v-for="company in all_business" :key="company.business_id">
        <UiBox class="flex flex-col justify-between">
          <div class="h-32">
          <figure class="w-16 h-16 rounded-full flex justify-center items-center bg-white shadow-lg absolute -top-5 -right-5 p-2">
            <img class="w-14 h-auto" v-if="company.logo" @error="company.logo = false" :src="company.logo" :alt="company.name">
            <svg v-else width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.66663 7.20833H21.3333M9.66663 7.20833H3.83329C2.22246 7.20833 0.916626 8.51417 0.916626 10.125V24.7083C0.916626 26.3192 2.22246 27.625 3.83329 27.625H27.1666C28.7775 27.625 30.0833 26.3192 30.0833 24.7083V10.125C30.0833 8.51417 28.7775 7.20833 27.1666 7.20833H21.3333H9.66663ZM9.66663 7.20833V2.25C9.66663 1.76675 10.0584 1.375 10.5416 1.375H20.4583C20.9416 1.375 21.3333 1.76675 21.3333 2.25V7.20833H9.66663Z" stroke="#E3E5EC" stroke-width="1.5"/>
            </svg>
          </figure>
          <h3 class="font-semibold ">{{company.name}}</h3>
          <p>{{company.stage}}</p>
          </div>
          <UiButton @click="successUpdateBusiness(company)" class="w-full text-center" :size="'btn-xs'" :type="'outline-secondary'">Seleccionar</UiButton>
        </UiBox>
        </div>
      </div>
    </section>
  </UiPageBody>
  <AccessDenied :permission="'can_change_organization'" />
</template>

<script>
import UiHeader from '../components/Layout/UI/UiHeader.vue'
import UiPageBody from '../components/Layout/UI/UiPageBody.vue'
import SearchField from '../components/SearchField.vue'
import UiCustomSelect from '../components/Layout/UI/UiCustomSelect.vue'
import { api } from '../api/instances'
import UiBox from '../components/Layout/UI/UiBox.vue'
import UiButton from '../components/Layout/UI/UiButton.vue'
import { mapActions, mapState } from 'vuex'
import AccessDenied from '../components/AccessDenied.vue'
export default {
  components: { UiHeader, UiPageBody, SearchField, UiCustomSelect, UiBox, UiButton, AccessDenied },
  name: 'Organizations',
  data () {
    return {
      search: '',
      business_kind: '',
      selected_letter: '',
      letters: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      all_business: [],
      activeFilter: { filter: 'name', direction: false },
      all_data: null,
      count: null
    }
  },
  computed: {
    ...mapState(['business'])
  },
  methods: {
    ...mapActions(['successUpdateBusiness']),
    addOrRemoveLetter (letter) {
      if (this.selected_letter === letter) {
        this.selected_letter = ''
      } else {
        this.selected_letter = letter
      }
    },
    getBusiness () {
      api.get('/jarvis/business/business/',
        {
          params: {
            search: this.search,
            stage: this.business_kind,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          this.all_data = data
          this.count = data.count
          this.all_business = data.results
        })
    }
  },
  created () {
    this.getBusiness()
  }
}
</script>

<style>

</style>
