<template>
 <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
    <div v-if="open" class="overlay">
      <div class="dialog animate__animated animate__slideInUp">
        <slot>
          <figure class="flex justify-center mb-6">
            <svg width="96" height="85" viewBox="0 0 96 85" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42.8546 2.99999C45.164 -1.00001 50.9375 -1 53.2469 3L94.86 75.0761C97.1694 79.0761 94.2826 84.0761 89.6638 84.0761H6.43756C1.81876 84.0761 -1.06798 79.0761 1.24142 75.0761L42.8546 2.99999Z" fill="#F9C84C"/>
              <path d="M42.5118 25.9196C42.4026 22.9566 44.7752 20.4951 47.7402 20.4951C50.7086 20.4951 53.0825 22.9621 52.9683 25.9283L51.8666 54.5456C51.7808 56.7757 49.948 58.5393 47.7162 58.5393C45.4818 58.5393 43.6478 56.7716 43.5656 54.5387L42.5118 25.9196Z" fill="#494949"/>
              <circle cx="48" cy="66.0747" r="4" fill="#494949"/>
            </svg>
          </figure>
        </slot>
        <h2 class="text-xl font-bold text-grey-dark">{{title}}</h2>
        <p>{{text}}</p>
        <div class=" flex justify-center mt-5">
          <button @click.prevent="closeMainDialogBox" type="button" class="btn-outline-default mr-1">Cancelar</button>
          <button @click.prevent="action.func()" :class="`btn-${typeButton} ml-1`" >{{action.text}}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogBox',
  props: {
    typeButton: {
      type: String,
      default: 'danger'
    }
  },
  data () {
    return {
      open: false,
      title: '¿Estás seguro de desvincular a este usuario de su equipo?',
      text: 'Esta acción no se puede revertir.',
      action: {
        text: 'Desvincular',
        func: () => { console.error('Error...') }
      }
    }
  },
  computed: {
    ...mapState(['dialogBox'])
  },
  watch: {
    dialogBox () {
      this.open = this.dialogBox.open
      if (this.dialogBox.open) {
        this.title = this.dialogBox.dialog.title
        this.text = this.dialogBox.dialog.text
        this.action = { text: this.dialogBox.dialog.btn_text, func: this.dialogBox.dialog.func }
      }
    }
  },
  methods: {
    ...mapActions(['closeMainDialogBox']),
    dialog (state, obj = null) {
      if (state === 'show') {
        this.open = true
        this.title = obj.title
        this.text = obj.text
        this.action = { text: obj.btn_text, func: obj.func }
      }
      if (state === 'hide') {
        this.open = false
      }
    },
    btnAction () {
      this.action()
    }
  }
}
</script>

<style scoped>
  .overlay {
    animation-duration: .5s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .dialog{
    animation-duration: .5s;
    @apply relative lg:w-5of12 2xl:w-4of12 rounded-lg bg-white mb-24 text-center p-10
  }
</style>
