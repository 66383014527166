import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Reset from '../views/Reset.vue'

import Users from '../views/Users.vue'
import UserDetail from '../views/User/UserDetail.vue'
import UserProgress from '../views/User/UserProgress.vue'

import Teams from '../views/Teams.vue'
import Assistant from '../views/Assistant/Main.vue'
import AssistantIndex from '../views/Assistant/AssistantIndex.vue'
import AssistantConfig from '../views/Assistant/AssistantConfig.vue'

import Itinerary from '../views/Itinerary/Itinerary.vue'
import ItineraryDetail from '../views/Itinerary/ItineraryDetail.vue'
import ItineraryList from '../views/Itinerary/ItineraryList.vue'
import ItineraryArchived from '../views/Itinerary/ItineraryArchived.vue'
import ItineraryDrafts from '../views/Itinerary/ItineraryDrafts.vue'
import SelectTypePath from '../views/Itinerary/SelectTypePath.vue'

import Requests from '../views/Requests/Requests.vue'
import Licenses from '../views/Requests/ReleaseLicense.vue'
import RequestList from '../views/Requests/RequestList.vue'

import StudentsDetail from '../views/Analytics/StudentsDetail.vue'

import AnalyticsGlobal from '../views/Analytics/AnalyticsGlobal.vue'

import PlannerIndex from '../views/PlannerV1/PlannerIndex.vue'

import Organizations from '../views/Organizations.vue'

import CorporativeCampus from '../views/CorporativeCampus/FormationsList.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: Home
  },
  {
    path: '/reset',
    name: 'reset',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: Reset
  },
  {
    path: '/organizaciones',
    name: 'organizations',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: Organizations
  },
  {
    path: '/gestion/usuarios',
    name: 'users',
    component: Users,
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    children: [
      {
        path: 'detalle/:employee_id',
        name: 'user-detail',
        component: UserDetail,
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        children: [
          {
            name: 'user-progress',
            path: '',
            component: UserProgress
          },
          {
            name: 'user-paths',
            path: 'paths',
            component: () => import('../views/User/UserPaths.vue')
          },
          {
            name: 'user-careers',
            path: 'careers',
            component: () => import('../views/User/UserCareers.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/gestion/equipos',
    name: 'teams',
    component: Teams,
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    children: [
      {
        path: ':id_team',
        name: 'team_id',
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        component: () => import('../views/Teams/Team.vue')
      },
      {
        path: ':id_team/log-equipo',
        name: 'team-log',
        component: () => import('../views/Teams/TeamLog.vue')
      }
    ]
  },
  {
    path: '/gestion/solicitudes',
    name: 'requests',
    component: Requests,
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    children: [
      {
        path: '',
        name: 'request-list',
        component: RequestList
      },
      {
        path: '/solicitudes/satisfaccion',
        name: 'satisfyer-list',
        component: () => import('../views/Requests/SatisfyerList.vue')
      },
      {
        path: '/solicitudes/liberar-licencias',
        name: 'licenses',
        component: Licenses
      }
    ]
  },
  {
    path: '/gestion/asistente',
    name: 'assistant-index',
    component: AssistantIndex,
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    children: [
      {
        path: '',
        name: 'assistant',
        component: Assistant
      },
      {
        path: 'configuracion',
        name: 'assistant-config',
        component: AssistantConfig
      }]
  },
  {
    path: '/gestion/rutas',
    name: 'itinerary',
    component: Itinerary,
    children: [
      {
        path: '',
        name: 'itinerary-list',
        component: ItineraryList
      },
      {
        path: 'borradores',
        name: 'itinerary-drafts',
        component: ItineraryDrafts
      },
      {
        path: 'archivados',
        name: 'itinerary-archived',
        component: ItineraryArchived
      },
      {
        path: 'tipo',
        name: 'itinerary-type',
        component: SelectTypePath
      },
      {
        path: 'detalles-de-rutas/:id_itinerary',
        name: 'itinerary-detail',
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        component: ItineraryDetail
      },
      {
        path: 'nueva-ruta',
        name: 'new-itinerary',
        component: () => import('../views/Itinerary/New/NewItinerary.vue'),
        children: [
          {
            path: ':id_itinerary?/formulario-creacion',
            name: 'step-1',
            component: () => import('../views/Itinerary/New/Step-1.vue')
          },
          {
            path: ':id_itinerary?/step-2',
            name: 'step-2',
            component: () => import('../views/Itinerary/New/Step-2.vue')
          },
          {
            path: ':id_itinerary?/step-3',
            name: 'step-3',
            component: () => import('../views/Itinerary/New/Step-3.vue')
          },
          {
            path: ':id_itinerary?/step-4',
            name: 'step-4',
            component: () => import('../views/Itinerary/New/Step-4.vue')
          },
          {
            path: ':id_itinerary?/step-5',
            name: 'step-5',
            component: () => import('../views/Itinerary/New/Step-5.vue')
          }
        ]
      },
      {
        path: 'nueva-ruta-ia',
        name: 'new-ia-itinerary',
        component: () => import('../views/Itinerary/New/NewAIItinerary.vue'),
        children: [
          {
            path: ':id_itinerary?/formulario-creacion',
            name: 'ai-step-1',
            component: () => import('../views/Itinerary/New/AI-Step-1.vue')
          },
          {
            path: ':id_itinerary?/step-2',
            name: 'ai-step-2',
            component: () => import('../views/Itinerary/New/AI-Step-2.vue')
          },
          {
            path: ':id_itinerary?/step-3',
            name: 'ai-step-3',
            component: () => import('../views/Itinerary/New/AI-Step-3.vue')
          },
          {
            path: ':id_itinerary?/step-4',
            name: 'ai-step-4',
            component: () => import('../views/Itinerary/New/Step-4.vue')
          },
          {
            path: ':id_itinerary?/step-5',
            name: 'step-5',
            component: () => import('../views/Itinerary/New/Step-5.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/analitica/global',
    name: 'analytics-global',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: AnalyticsGlobal
  },
  {
    path: '/analitica/valoraciones',
    name: 'ratings',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Analytics/Ratings.vue'),
    children: []
  },
  {
    path: '/catalogo',
    name: 'catalog',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/TrainingCatalog.vue'),
    children: []
  },
  {
    path: '/analitica/usuarios',
    name: 'students',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Analytics/StudentList/StudentsListView.vue'),
    children: [{
      path: '',
      name: 'stats-students-general',
      component: () => import('../views/Analytics/StudentList/GeneralView.vue')
    },
    {
      path: '/matriculas',
      name: 'stats-students-enrollments',
      component: () => import('../views/Analytics/StudentList/EnrollmentsView.vue')
    },
    {
      path: '/certificados',
      name: 'stats-students-certificates',
      component: () => import('../views/Analytics/StudentList/CertificatesView.vue')
    },
    {
      path: '/dedicacion',
      name: 'stats-students-dedication',
      component: () => import('../views/Analytics/StudentList/DedicationView.vue')
    },
    {
      path: ':employee_id',
      name: 'detail-student',
      component: StudentsDetail,
      beforeEnter: (to, from, next) => {
        next()
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
      },
      children: [
        {
          name: 'detail-student-progress',
          path: '',
          component: UserProgress
        },
        {
          name: 'detail-student-paths',
          path: 'paths',
          component: () => import('../views/User/UserPaths.vue')
        },
        {
          name: 'detail-student-careers',
          path: 'careers',
          component: () => import('../views/User/UserCareers.vue')
        }
      ]
    }]
  },
  {
    path: '/analitica/formaciones',
    name: 'formations',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Formations/Formations.vue'),
    children: [
      {
        path: '',
        name: 'formations-by-students',
        component: () => import('../views/Formations/FormationsByStudents.vue')
      },
      {
        path: 'cursos-y-talleres',
        name: 'formations-courses',
        component: () => import('../views/Formations/FormationsCourses.vue')
      },
      {
        path: 'rutas-ow',
        name: 'formations-careers',
        component: () => import('../views/Formations/FormationsCareers.vue')
      },
      {
        path: 'rutas-corporativas',
        name: 'formations-itineraries',
        component: () => import('../views/Formations/FormationsItinerary.vue')
      },
      {
        path: 'detalle/ruta-ow/:career_id',
        name: 'detail-careers',
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        component: () => import('../views/Formations/DetailCareers.vue')
      },
      {
        path: 'detalle/cursos-y-talleres/:course_id',
        name: 'detail-course',
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        component: () => import('../views/Formations/DetailCourse.vue')
      },
      {
        path: 'detalle/ruta-corporativa/:itinerary_id',
        name: 'detail-itinerary-formation',
        beforeEnter: (to, from, next) => {
          next()
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        },
        component: () => import('../views/Formations/DetailItinerary.vue')
      }]
  },
  {
    path: '/reportes-personalizados',
    name: 'custom-reports',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Reports/CreateReports.vue'),
    children: [
      {
        path: '',
        name: 'report-list',
        component: () => import('../views/Reports/ReportList.vue')
      },
      {
        path: 'borradores',
        name: 'reports-drafts',
        component: () => import('../views/Reports/DraftReports.vue')
      },
      {
        path: 'archivados',
        name: 'reports-archived',
        component: () => import('../views/Reports/ArchivedReports.vue')
      }
    ]
  },
  {
    path: '/nuevo-reporte',
    name: 'new-report',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Reports/New/NewReport.vue'),
    children: [
      {
        path: ':report_id?/paso-1',
        name: 'report-step-one',
        component: () => import('../views/Reports/New/Step-1.vue')
      },
      {
        path: ':report_id?/paso-2',
        name: 'report-step-two',
        component: () => import('../views/Reports/New/Step-2.vue')
      },
      {
        path: ':report_id?/paso-3',
        name: 'report-step-three',
        component: () => import('../views/Reports/New/Step-3.vue')
      }
    ]
  },
  {
    path: '/planificador',
    name: 'planner-index',
    props: {
      team_id: null
    },
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: PlannerIndex
  },
  {
    path: '/detalle-de-sprint/',
    name: 'detail-sprint',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/PlannerV1/DetailSprint.vue')
  },
  {
    path: '/configuracion',
    name: 'settings',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: () => import('../views/Setup/Settings.vue'),
    children: [
      {
        path: '',
        name: 'profile-organization',
        component: () => import('../views/Setup/Profile.vue')
      },
      {
        path: '/contratos',
        name: 'contracts-organization',
        component: () => import('../views/Setup/Contracts.vue')
      },
      {
        path: '/listado-de-etiquetas',
        name: 'tags-list',
        component: () => import('../views/Setup/Tags/TagsList.vue')
      }
    ]
  },
  {
    path: '/detalle-de-etiqueta/:tag_id',
    name: 'tag-detail',
    component: () => import('../views/Setup/Tags/TagDetail.vue')
  },
  {
    path: '/campus-corporativo',
    name: 'corporative-campus',
    beforeEnter: (to, from, next) => {
      next()
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    component: CorporativeCampus
  },
  {
    path: '/landings',
    name: 'landings',
    children: [
      {
        path: 'campus-corporativo',
        name: 'landing-campus',
        component: () => import('../views/Landings/CorporativeCampus.vue')
      },
      {
        path: 'planificador',
        name: 'landing-planner',
        component: () => import('../views/Landings/Planner.vue')
      },
      {
        path: 'reportes-personalizados',
        name: 'landing-reports',
        component: () => import('../views/Landings/Reports.vue')
      }
    ]
  },
  {
    path: '/analitica/graficas',
    name: 'stats',
    component: () => import('../views/Stats/Stats.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
