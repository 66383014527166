<template>
  <div id="c3-activity-chart-2"></div>
</template>

<script>
import * as c3 from 'c3'
import { mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'ChartHoursByDay',
  props: {
    days: {
      type: String,
      default: '7'
    }
  },
  data () {
    return {
      hourlyActivity: null
    }
  },
  watch: {
    days () {
      this.getHoursByDay()
    }
  },
  computed: {
    ...mapState(['active_business']),
    hours_by_day () {
      const hours = []
      for (const day in this.hourlyActivity) {
        for (let i = 0; i < this.hourlyActivity[day].length; i++) {
          if (hours[i]) {
            hours[i].push(this.hourlyActivity[day][i])
          } else {
            hours[i] = []
            hours[i].push(this.hourlyActivity[day][i])
          }
        }
      }
      return hours
    }
  },
  methods: {
    getHoursByDay () {
      api.get(`/jarvis/business/${this.active_business}/analitics/students/${this.$route.params.employee_id}/hours/`, { params: { days: this.days } })
        .then(({ data }) => {
          this.hourlyActivity = data
          this.getChart(data)
        }).catch((error) => { console.error('ERROR:', error) })
    },
    parseTime (value) {
      const my_data = []
      for (const k in value) {
        const p = [...k, ...value[k]]
        my_data.push(p)
      }
      return my_data
    },
    getChart (val) {
      var names = {
        0: 'LUNES',
        1: 'MARTES',
        2: 'MIÉRCOLES',
        3: 'JUEVES',
        4: 'VIERNES',
        5: 'SÁBADO',
        6: 'DOMINGO'
      }

      var data = this.parseTime(val)
      c3.generate({
        bindto: '#c3-activity-chart-2',
        color: {
          pattern: ['#304178', '#C1C9E6', '#131A30', '#2D3960', '#9DAAD8', '#222E55', '#304178']
        },
        data: {
          columns: data,
          type: 'bar',
          groups: [
            ['0', '1', '2', '3', '4', '5', '6']
          ],
          order: null,
          names: names
        },
        bar: {
          width: {
            ratio: 0.4
          }
        },
        zoom: {
          enabled: false
        },
        legend: {
          show: false
        },
        axis: {
          x: {
            label: 'Hora del día',
            tick: {
              values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              format: function (x) { return (x < 10) ? '0' + x : x }
            }
          },
          y: {
            label: 'Horas dedicación',
            tick: {

            }
          }
        },
        tooltip: {
          format: {
            title: function (d) { return 'Hora ' + d },
            value: (value, ratio, id) => {
              return this.$filters.hoursAndMinutes(value * 60 * 60) // moment.duration(value, 'hours').humanize();
            }
          }
        }
      })
    }
  },
  created () {
    this.getHoursByDay()
  }
}
</script>

<style>

</style>
