<template>
  <svg :id="props.id" :width="props.size.width" :height="props.size.height" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9333 13.8333H6.4C6.29391 13.8333 6.19217 13.7912 6.11716 13.7162C6.04214 13.6412 6 13.5394 6 13.4333V6.9C6 6.79391 6.04214 6.69217 6.11716 6.61716C6.19217 6.54214 6.29391 6.5 6.4 6.5H12.9333C13.0394 6.5 13.1412 6.54214 13.2162 6.61716C13.2912 6.69217 13.3333 6.79391 13.3333 6.9V13.4333C13.3333 13.5394 13.2912 13.6412 13.2162 13.7162C13.1412 13.7912 13.0394 13.8333 12.9333 13.8333Z" :stroke="props.color" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.99996 6.50008V3.56675C9.99996 3.46066 9.95782 3.35892 9.8828 3.28391C9.80779 3.20889 9.70605 3.16675 9.59996 3.16675H3.06663C2.96054 3.16675 2.8588 3.20889 2.78378 3.28391C2.70877 3.35892 2.66663 3.46066 2.66663 3.56675V10.1001C2.66663 10.2062 2.70877 10.3079 2.78378 10.3829C2.8588 10.4579 2.96054 10.5001 3.06663 10.5001H5.99996" :stroke="props.color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-trash'
  },
  color: {
    type: String,
    default: '#414141'
  },
  size: {
    type: Object,
    default: () => ({
      width: 16,
      height: 17
    })
  }
})
</script>
