<template>
  <ul class="flex 2xl:flex-nowrap xl:flex-wrap items-center w-full">
      <li class="2xl:max-w-1/3 xl:w-auto mb-1 mr-1" v-for="{id, name, bg_color, text_color} in list_tags" :key="id">
        <Tag :tooltip="true" :color="{bg_color, text_color}" :name="name"/>
      </li>
      <li class="has-tooltip max-w-1/3 mb-1" v-if="list.length > is_screen_xl">
          <div class="tooltip">
            <div class="bg-primary text-white rounded py-1 px-4 right-0">
              <ul class="flex">
                <li class="tag-item text-sm" v-for="{id, name} in tool_tip_tags" :key="id">
                  {{name}}
                </li>
              </ul>
            </div>
            <svg class="absolute h-5 right-4 ml-3 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve">
              <polygon style="fill:rgb(48, 65, 120)" points="0,0 127.5,127.5 255,0"/>
            </svg>
          </div>
          <Tag class="cursor-pointer" :limit="true" :name="`+${list.length - is_screen_xl}`"/>
      </li>
  </ul>
</template>

<script>
import Tag from './Tag.vue'

export default {
  name: 'TagsList',
  components: {
    Tag
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 3
    }
  },
  computed: {
    list_tags () {
      return this.list.slice(0, this.is_screen_xl)
    },
    tool_tip_tags () {
      return this.list.slice(this.is_screen_xl, this.list.length)
    },
    is_screen_xl () {
      let limit
      if (!this.limit) {
        (window.screen.width <= 1280) ? limit = 2 : limit = this.limit
        return limit
      } else {
        limit = this.limit
        return limit
      }
    }

  }
}
</script>
<style scoped>
.has-tooltip {
  position: relative;
}
.tooltip {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, calc(-100% - 10px));
  display: none;
}
.has-tooltip:hover .tooltip {
  display: block;
  @apply z-50;
}
.max-w-1\/3 {
  max-width: 33.33%;
}
.tag-item{
  margin-right: 2px;
  white-space: nowrap;
}
.tag-item:not(:last-child):not(:first-child)::before{
  content: ',';
  display: inline-block;
  margin-right: 5px;
}
.tag-item:last-child::before{
  content: 'y';
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

</style>
