<template>
<transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeInUp"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <button v-if="visible" @click="visible = false" class="toast-message">
    <figure class="mr-5">
      <svg v-if="status == 'success'" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#34C759"/>
        <path d="M12.3528 17.6698L10.8798 16.317L8.17413 19.2631L9.64719 20.6159L12.3528 17.6698ZM26.3528 15.4731L27.8259 14.1202L25.1202 11.1741L23.6472 12.5269L26.3528 15.4731ZM9.64719 20.6159L11.1416 21.9883L13.8472 19.0422L12.3528 17.6698L9.64719 20.6159ZM19.2584 21.9883L26.3528 15.4731L23.6472 12.5269L16.5528 19.0422L19.2584 21.9883ZM11.1416 21.9883C13.4367 24.0961 16.9633 24.0961 19.2584 21.9883L16.5528 19.0422C15.7878 19.7448 14.6122 19.7448 13.8472 19.0422L11.1416 21.9883Z" fill="white"/>
      </svg>
      <svg v-if="status == 'danger'" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#ED5858"/>
        <path d="M12 24L18 18M18 18L24 12M18 18L12 12M18 18L24 24" stroke="white" stroke-width="4" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
      <svg v-if="status == 'info'" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#2A61CC"/>
        <path d="M17.25 24.5C21.2541 24.5 24.5 21.2541 24.5 17.25C24.5 13.2459 21.2541 10 17.25 10C13.2459 10 10 13.2459 10 17.25C10 21.2541 13.2459 24.5 17.25 24.5Z" fill="white"/>
        <path d="M17.25 17.25V20.25" stroke="#2A61CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.25 15.25C17.8023 15.25 18.25 14.8023 18.25 14.25C18.25 13.6977 17.8023 13.25 17.25 13.25C16.6977 13.25 16.25 13.6977 16.25 14.25C16.25 14.8023 16.6977 15.25 17.25 15.25Z" fill="#2A61CC"/>
      </svg>
      <svg v-if="status == 'warning'" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#EFC250"/>
      </svg>
      <svg v-if="status == 'generic'" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#DDDDDD"/>
      </svg>
    </figure>
    <div class="mr-4 flex-1">
      <h2 class="leading-6 font-bold">{{title}}</h2>
      <p class="text-sm" v-html="message"></p>
    </div>
    <div>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 10L6.00001 6.00002M6.00001 6.00002L10 2M6.00001 6.00002L2 2M6.00001 6.00002L10 10" stroke="#5C5C5C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </div>
  </button>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'ToastMessage',
  props: {
    index: {
      type: Number
    },
    status: {
      type: String,
      default: 'generic'
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    ...mapMutations(['removeToastMessage'])
  },
  created () {
    setTimeout(() => {
      this.visible = true
    }, 300)

    setTimeout(() => {
      this.visible = false
    }, (status === 'danger') ? 8000 : 4000)

    setTimeout(() => {
      this.removeToastMessage(this.index)
    }, 5500)
  }
}
</script>

<style>
  .toast-message{
    animation-duration: 0.2s;
    @apply flex items-center text-left bg-white shadow-lg py-3 px-5 rounded-lg mb-4 w-full justify-between z-50
  }
</style>
