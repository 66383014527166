<template>
    <button @click="downloadXLS">
      <slot></slot>
    </button>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { api } from '../api/instances'
export default {
  name: 'DownloadXLS',
  props: ['file', 'path', 'params', 'formatted', 'noid'],
  emits: ['complete'],
  data () {
    return {
      downloading_xlsx: false
    }
  },
  computed: {
    ...mapState(['active_business', 'files_downloading', 'business']),
    file_date () {
      const date = new Date()
      return `${date.getFullYear()}-${
      new Intl.DateTimeFormat('es-ES', { month: '2-digit' }).format(date)}-${
      new Intl.DateTimeFormat('es-ES', { day: '2-digit' }).format(date)}`
    }
  },
  methods: {
    ...mapMutations(['addToastMessage', 'progressWait']),
    ...mapActions(['updateProgressWait']),
    async downloadXLS () {
      let jsonFormatted = this.formatted
      if (this.business[0].has_ids) {
        jsonFormatted = JSON.stringify([{ field: 'cid', visible: 'ID' }, ...JSON.parse(this.formatted)])
      } else {
        jsonFormatted = this.formatted
      }
      this.downloading_xlsx = true
      const id = this.files_downloading.length
      this.updateProgressWait({ id, name_file: this.file })
      const idx = this.files_downloading.findIndex(file => file.id === id)
      await api.get(this.path, {
        params: {
          format: 'xlsx',
          ...this.params,
          json: jsonFormatted
        },
        responseType: 'blob'
      })
        .then((response) => {
          this.downloading_xlsx = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('target', '_blank')
          fileLink.setAttribute('download', `${this.file_date}-${this.file}`)
          document.body.appendChild(fileLink)
          fileLink.click()
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          // toastr.success('La descarga se ha completado.', '¡COMPLETADO!')
          this.$emit('complete')
        })
        .catch((error) => {
          this.downloading_xlsx = false
          console.error('ERROR:', error)
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.addToastMessage({
            status: 'danger',
            title: '¡Error!',
            message: 'Ha ocurrido un error al descargar el archivo.'
          })
          // this.progress_wait.is_downloading = false
          // toastr.error('La descarga no se realizó.', '¡ERROR!')
        })
      setTimeout(() => {
        // this.files_downloading[idx].is_downloading = false
        this.files_downloading.splice(idx, 1)
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
