<template>
  <div class="bg-[#D9D9D980] w-full h-full flex">
    <div class="loader h-full">
      <span class="absolute position-loading">Cargando...</span>
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
</template>
<style scoped>
.loader {
  position: relative;
  margin: 0px auto;
  width: 200px;
}

.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.7;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {

  100%,
  30%,
  0% {
    stroke: #475467;
  }

  40%,
  66% {
    stroke: #101828;
  }

  80%,
  90% {
    stroke: #f63d68;
  }

}

@keyframes color {

  100%,
  30%,
  0% {
    stroke: #475467;
  }

  40%,
  66% {
    stroke: #101828;
  }

  80%,
  90% {
    stroke: #f63d68;
  }
}

.position-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #475467;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 0px 0px 5px #fff;
}
</style>
