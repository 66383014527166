<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <slot></slot>
  </div>
  </transition>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
 .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
</style>
