<template>
  <svg width="225" height="236" viewBox="0 0 225 236" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M221.996 89.6201H147.766L145.364 145.751L178.063 144.774L207.144 143.904L221.996 143.46V89.6201Z" fill="#FEFEFF"/>
  <path d="M205.777 176.751L209.158 185.309L220.971 179.627L213.374 190.593L223.153 195.792L211.868 197.181L212.539 206.358L205.777 200.113L196.436 208.66L199.684 197.181L190.582 195.792L198.179 190.593L195.025 183.263L202.395 185.309L205.777 176.751Z" fill="black"/>
  <path d="M65.7557 112.238C64.6254 106.483 59.4396 95.8388 57.8777 99.1463C55.9253 103.28 56.6789 115.116 59.3163 115.805C61.9537 116.494 58.1175 99.2273 54.8292 100.443C52.1987 101.416 53.7331 106.469 54.8292 108.874C54.441 105.631 53.9423 101.091 48.561 98.0518" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M37.8143 66.845V2.77832H123.292V17.4517H156.441V64.3237" stroke="#B7B7B7" stroke-width="2"/>
  <path d="M85.6784 148.05H98.5099L121.886 147.218V46.1787H5.04841L1.26666 151.517L40.997 150.101" stroke="#4A4A4A" stroke-width="2"/>
  <path d="M5.04836 46.1787H121.886V67.0959H4.29303L5.04836 46.1787Z" fill="#818181"/>
  <path d="M14.2427 61.9806C16.9887 61.9806 19.2148 59.5883 19.2148 56.6373C19.2148 53.6862 16.9887 51.2939 14.2427 51.2939C11.4968 51.2939 9.27069 53.6862 9.27069 56.6373C9.27069 59.5883 11.4968 61.9806 14.2427 61.9806Z" fill="white"/>
  <path d="M27.1701 61.9806C29.9161 61.9806 32.1422 59.5883 32.1422 56.6373C32.1422 53.6862 29.9161 51.2939 27.1701 51.2939C24.4241 51.2939 22.1981 53.6862 22.1981 56.6373C22.1981 59.5883 24.4241 61.9806 27.1701 61.9806Z" fill="white"/>
  <path d="M40.0972 61.9806C42.8431 61.9806 45.0692 59.5883 45.0692 56.6373C45.0692 53.6862 42.8431 51.2939 40.0972 51.2939C37.3512 51.2939 35.1251 53.6862 35.1251 56.6373C35.1251 59.5883 37.3512 61.9806 40.0972 61.9806Z" fill="white"/>
  <path d="M120.579 101.309L75.6758 102.619L78.975 27.3721H180.91V99.5483L160.516 100.144" stroke="#4A4A4A" stroke-width="2"/>
  <path d="M117.045 135.337H150.304M117.045 131.692H137.952H117.045Z" stroke="black" stroke-width="2" stroke-linecap="round"/>
  <path d="M195.947 35.927C195.947 37.5242 195.586 40.6405 193.988 40.6405C192.39 40.6405 190.16 37.5242 190.16 35.927C190.16 34.3299 191.455 33.0352 193.053 33.0352C194.651 33.0352 195.947 34.3299 195.947 35.927Z" fill="#4A4A4A"/>
  <path d="M207.851 44.4416C207.851 46.0388 207.496 49.1551 205.92 49.1551C204.346 49.1551 202.147 46.0388 202.147 44.4416C202.147 42.8445 203.424 41.5498 204.999 41.5498C206.573 41.5498 207.851 42.8445 207.851 44.4416Z" fill="#4A4A4A"/>
  <path d="M212.315 55.7668C212.315 57.364 211.96 60.4803 210.384 60.4803C208.81 60.4803 206.611 57.364 206.611 55.7668C206.611 54.1697 207.888 52.875 209.463 52.875C211.037 52.875 212.315 54.1697 212.315 55.7668Z" fill="#4A4A4A"/>
  <path d="M215.869 44.4416C215.869 46.0388 215.515 49.1551 213.939 49.1551C212.364 49.1551 210.165 46.0388 210.165 44.4416C210.165 42.8445 211.442 41.5498 213.017 41.5498C214.592 41.5498 215.869 42.8445 215.869 44.4416Z" fill="#4A4A4A"/>
  <path d="M69.3821 33.0352L75.4957 49.3485L86.0752 41.2053L78.3227 54.2935L100.963 60.6093L76.1684 58.3375L84.4335 77.6751L73.369 61.8456L62.9507 76.6001L65.9658 60.6093L48.3867 63.4043L66.4504 54.9655L53.0439 34.7014L68.604 48.4616L69.3821 33.0352Z" fill="#DEDEDE"/>
  <path d="M110.345 21.108C112.732 24.0828 115.264 30.3253 119.439 27.308L111.057 38.0133C97.3757 18.132 107.363 17.3895 110.345 21.108Z" fill="#DEDEDE"/>
  <path d="M138.852 5.2102C139.766 4.79336 140.445 4.32074 140.37 4.15459C140.294 3.98844 139.491 4.19167 138.578 4.60851C137.664 5.02536 136.984 5.49797 137.06 5.66413C137.136 5.83028 137.938 5.62705 138.852 5.2102Z" fill="white"/>
  <path d="M137.501 7.83345C144.313 9.85051 146.016 19.0624 146.016 23.4161L132.831 44.5788C129.869 44.8819 121.894 44.3969 113.693 40.0321C105.493 35.6673 112.564 30.1397 117.124 27.9215C118.461 26.9433 120.571 23.2094 118.323 16.1001C116.074 8.99078 109.505 8.50859 106.501 9.15611C104.628 9.41792 101.574 9.01558 104.352 5.31211C109.973 -1.79719 121.96 1.19258 127.251 3.57614C130.028 2.51801 133.919 5.97345 135.517 7.83345H137.501Z" fill="#DEDEDE"/>
  <path d="M51.9413 78.915C48.6346 82.015 39.7576 96.9364 40.492 106.484C42.352 130.664 64.3413 128.143 64.3413 149.76C64.3413 173.196 29.704 171.378 29.704 196.798C29.704 219.696 62.316 228.073 64.3413 230.65" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M148.083 23.6227C131.02 23.0275 130.805 41.148 132.831 50.2827C133.829 54.788 175.115 76.1574 175.528 78.8444C175.859 80.9937 170.568 91.9189 167.881 97.1137C162.935 99.9103 147.934 110.001 127.499 127.99C107.064 145.978 105.247 170.735 107.039 179.622C106.529 177.706 101.243 173.746 99.4334 170.28C97.6246 166.815 91.704 166.843 82.1974 174.076C74.5921 179.863 76.2454 182.06 76.7414 185.367C84.6749 199.606 96.5888 228.045 111.503 233.886C118.364 236.572 129.028 234.63 132.831 231.199C135.873 228.455 142.337 203.795 143.743 196.562L154.696 221.74C176.644 228.467 218.435 214.229 224.673 191.23C222.125 181.709 211.86 149.457 207.892 141.223C202.932 130.931 224.673 85.1866 210.537 64.7494C207.793 60.7814 194.927 49.3734 188.837 44.1654C183.919 40.7347 173.37 38.6267 167.881 34.6587C161.02 29.6987 155.688 25.896 148.083 23.6227Z" fill="#B7B7B7"/>
  <path d="M118.447 159.184C120.935 159.184 122.952 157.167 122.952 154.679C122.952 152.191 120.935 150.174 118.447 150.174C115.958 150.174 113.941 152.191 113.941 154.679C113.941 157.167 115.958 159.184 118.447 159.184Z" fill="#818181"/>
  <path d="M126.3 181.422C128.788 181.422 130.805 179.405 130.805 176.916C130.805 174.428 128.788 172.411 126.3 172.411C123.812 172.411 121.795 174.428 121.795 176.916C121.795 179.405 123.812 181.422 126.3 181.422Z" fill="#818181"/>
  <path d="M149.033 146.453C151.522 146.453 153.539 144.436 153.539 141.948C153.539 139.459 151.522 137.442 149.033 137.442C146.545 137.442 144.528 139.459 144.528 141.948C144.528 144.436 146.545 146.453 149.033 146.453Z" fill="#818181"/>
  <path d="M154.985 195.144C157.474 195.144 159.491 193.127 159.491 190.639C159.491 188.151 157.474 186.134 154.985 186.134C152.497 186.134 150.48 188.151 150.48 190.639C150.48 193.127 152.497 195.144 154.985 195.144Z" fill="#818181"/>
  <path d="M184.084 171.171C186.572 171.171 188.589 169.154 188.589 166.665C188.589 164.177 186.572 162.16 184.084 162.16C181.596 162.16 179.579 164.177 179.579 166.665C179.579 169.154 181.596 171.171 184.084 171.171Z" fill="#818181"/>
  <path d="M217.399 194.565C219.887 194.565 221.904 192.548 221.904 190.06C221.904 187.572 219.887 185.555 217.399 185.555C214.91 185.555 212.893 187.572 212.893 190.06C212.893 192.548 214.91 194.565 217.399 194.565Z" fill="#818181"/>
  <path d="M170.692 123.059C173.18 123.059 175.197 121.041 175.197 118.553C175.197 116.065 173.18 114.048 170.692 114.048C168.204 114.048 166.187 116.065 166.187 118.553C166.187 121.041 168.204 123.059 170.692 123.059Z" fill="#818181"/>
  <path d="M159.532 168.194C162.02 168.194 164.037 166.177 164.037 163.689C164.037 161.201 162.02 159.184 159.532 159.184C157.044 159.184 155.027 161.201 155.027 163.689C155.027 166.177 157.044 168.194 159.532 168.194Z" fill="#818181"/>
  <path d="M180.86 209.61C183.348 209.61 185.365 207.593 185.365 205.105C185.365 202.617 183.348 200.6 180.86 200.6C178.372 200.6 176.355 202.617 176.355 205.105C176.355 207.593 178.372 209.61 180.86 209.61Z" fill="#818181"/>
  <path d="M199.46 143.229C201.948 143.229 203.965 141.212 203.965 138.724C203.965 136.236 201.948 134.219 199.46 134.219C196.972 134.219 194.955 136.236 194.955 138.724C194.955 141.212 196.972 143.229 199.46 143.229Z" fill="#818181"/>
  <path d="M188.548 95.8612C191.036 95.8612 193.053 93.8441 193.053 91.3559C193.053 88.8677 191.036 86.8506 188.548 86.8506C186.06 86.8506 184.043 88.8677 184.043 91.3559C184.043 93.8441 186.06 95.8612 188.548 95.8612Z" fill="#818181"/>
  <path d="M206.652 77.6747C209.14 77.6747 211.157 75.6576 211.157 73.1694C211.157 70.6812 209.14 68.6641 206.652 68.6641C204.164 68.6641 202.147 70.6812 202.147 73.1694C202.147 75.6576 204.164 77.6747 206.652 77.6747Z" fill="#818181"/>
  <path d="M171.849 67.0937C174.338 67.0937 176.355 65.0766 176.355 62.5883C176.355 60.1001 174.338 58.083 171.849 58.083C169.361 58.083 167.344 60.1001 167.344 62.5883C167.344 65.0766 169.361 67.0937 171.849 67.0937Z" fill="#818181"/>
  <path d="M155.068 40.9706C157.556 40.9706 159.573 38.9535 159.573 36.4653C159.573 33.9771 157.556 31.96 155.068 31.96C152.58 31.96 150.563 33.9771 150.563 36.4653C150.563 38.9535 152.58 40.9706 155.068 40.9706Z" fill="#818181"/>
  <path d="M130.764 224.656C133.252 224.656 135.269 222.639 135.269 220.151C135.269 217.663 133.252 215.646 130.764 215.646C128.276 215.646 126.259 217.663 126.259 220.151C126.259 222.639 128.276 224.656 130.764 224.656Z" fill="#818181"/>
  <path d="M109.436 216.224C111.924 216.224 113.941 214.206 113.941 211.718C113.941 209.23 111.924 207.213 109.436 207.213C106.948 207.213 104.931 209.23 104.931 211.718C104.931 214.206 106.948 216.224 109.436 216.224Z" fill="#818181"/>
  <path d="M90.588 199.112C93.0762 199.112 95.0933 197.095 95.0933 194.607C95.0933 192.119 93.0762 190.102 90.588 190.102C88.0997 190.102 86.0826 192.119 86.0826 194.607C86.0826 197.095 88.0997 199.112 90.588 199.112Z" fill="#818181"/>
  <path d="M95.1347 180.182C97.6229 180.182 99.64 178.164 99.64 175.676C99.64 173.188 97.6229 171.171 95.1347 171.171C92.6464 171.171 90.6293 173.188 90.6293 175.676C90.6293 178.164 92.6464 180.182 95.1347 180.182Z" fill="#818181"/>
  <path d="M107.493 178.582C106.694 185.291 105.575 199.323 107.493 201.77C109.411 204.217 110.331 209.375 110.552 211.649" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M178.669 76.6405C179.951 72.6862 185.63 67.6133 188.507 67.3818C192.103 67.0925 201.857 64.5298 203.965 65.7285" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M180.405 78.358C182.598 77.02 187.289 76.9223 188.722 77.968C190.511 79.275 196.086 81.9881 196.582 83.3951" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M104.937 186.104C102.844 187.593 100.907 191.867 101.305 193.595C101.802 195.755 102.101 201.947 103.199 202.958" stroke="#818181" stroke-width="2" stroke-linecap="round"/>
  <path d="M106.005 98.8089C106.005 100.803 105.471 102.585 104.635 103.855C103.795 105.128 102.691 105.836 101.541 105.836C100.391 105.836 99.2878 105.128 98.4479 103.855C97.6114 102.585 97.0773 100.803 97.0773 98.8089C97.0773 96.8141 97.6114 95.0318 98.4479 93.7621C99.2878 92.489 100.391 91.7822 101.541 91.7822C102.691 91.7822 103.795 92.489 104.635 93.7621C105.471 95.0318 106.005 96.8141 106.005 98.8089Z" stroke="#404040" stroke-width="4"/>
  <path d="M118.488 117.161C127.847 117.161 135.435 109.574 135.435 100.214C135.435 90.8549 127.847 83.2676 118.488 83.2676C109.129 83.2676 101.541 90.8549 101.541 100.214C101.541 109.574 109.129 117.161 118.488 117.161Z" fill="#DEDEDE"/>
  <path d="M117.992 100.221C111.048 97.0465 103.677 94.2772 102.409 90.7779C99.2771 73.2689 128.611 64.4339 137.005 76.9086C138.011 78.4026 139.551 82.4641 146.264 83.3569C154.655 84.4729 153.373 86.6636 157.259 93.5662C160.367 99.0884 173.048 100.785 176.313 100.221L134.856 128.579L130.971 126.84L129.483 132.254L117.992 140.314C117.303 136.911 116.339 129.452 117.992 126.84C120.059 123.574 117.992 122.086 117.992 115.845C117.992 110.852 121.216 108.335 122.828 107.702C127.664 104.974 129.193 100.221 125.887 97.5342C123.241 95.3849 119.521 98.4295 117.992 100.221Z" fill="#4A4A4A"/>
  <path d="M123.655 107.985C126.965 107.985 129.648 105.301 129.648 101.991C129.648 98.6814 126.965 95.998 123.655 95.998C120.345 95.998 117.661 98.6814 117.661 101.991C117.661 105.301 120.345 107.985 123.655 107.985Z" fill="#B7B7B7"/>
  <path d="M105.055 105.091C105.991 105.091 106.749 104.239 106.749 103.189C106.749 102.139 105.991 101.288 105.055 101.288C104.119 101.288 103.36 102.139 103.36 103.189C103.36 104.239 104.119 105.091 105.055 105.091Z" fill="#B7B7B7"/>
  <path d="M119.563 101.288C120.513 100.337 122.621 99.3867 124.068 99.3867" stroke="black" stroke-width="2" stroke-linecap="round"/>
  <path d="M122.373 99.7051C122.687 100.188 123.346 101.776 123.448 102.859" stroke="black" stroke-width="2" stroke-linecap="round"/>
  <path d="M97.0773 98.6431L118.943 96.5352" stroke="#404040" stroke-width="4"/>
  <path d="M55.6384 170.337L55.8307 172.312C58.7622 179.546 70.4383 178.18 76.7413 181.647C79.4421 177.569 90.8525 168.9 97.2013 168.669C94.2609 160.094 90.6773 153.185 85.5685 145.493C80.4291 137.755 75.6133 145.329 73.8478 150.083C73.0038 151.507 69.4923 153.97 62.1984 152.422C54.9045 150.874 53.7874 144.382 54.1407 141.33C54.2195 139.439 53.523 136.439 50.1064 139.563C43.5757 145.848 47.7139 157.488 50.5993 162.522C49.8156 165.389 53.6321 168.927 55.6384 170.337Z" fill="#DEDEDE"/>
  <path d="M66.4092 143.996C69.6014 146.083 76.0602 147.997 73.4619 152.445L83.0654 142.835C61.951 131.146 62.4189 141.388 66.4092 143.996Z" fill="#DEDEDE"/>
  <path d="M34.912 131.521C34.912 133.135 35.2721 136.286 36.8701 136.286C38.468 136.286 40.6987 133.135 40.6987 131.521C40.6987 129.906 39.4033 128.598 37.8054 128.598C36.2074 128.598 34.912 129.906 34.912 131.521Z" fill="#404040"/>
  <path d="M26.8107 131.521C26.8107 133.135 27.1708 136.286 28.7687 136.286C30.3667 136.286 32.5973 133.135 32.5973 131.521C32.5973 129.906 31.3019 128.598 29.704 128.598C28.1061 128.598 26.8107 129.906 26.8107 131.521Z" fill="#404040"/>
  <path d="M18.792 131.521C18.792 133.135 19.1521 136.286 20.75 136.286C22.348 136.286 24.5787 133.135 24.5787 131.521C24.5787 129.906 23.2833 128.598 21.6853 128.598C20.0875 128.598 18.792 129.906 18.792 131.521Z" fill="#404040"/>
  </svg>
</template>
