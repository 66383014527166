<script setup>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const files_downloading = computed(() => store.state.files_downloading)
const num_files_downloading = computed(() => store.state.files_downloading.length)
const progress_download = computed(() => files_downloading.value[0].progress)
const progress_message = computed(() => files_downloading.value[0].message)
const is_download = computed(() => files_downloading.value.some(el => el.is_downloading === true))
const show_bar = ref(false)
const show_downloads = ref(false)
let rest = 100
let diviser = 2
const updateProgressDownload = () => {
  show_bar.value = is_download
  const loading = setInterval(() => {
    if (files_downloading.value.length === 1) {
      if (progress_download.value < 100) {
        const progress = progress_download.value + (rest / diviser)
        rest = rest - rest / diviser
        files_downloading.value[0].progress = progress
      }
    } else {
      files_downloading.value.forEach(el => {
        if (el.progress < 100) {
          const progress = el.progress + (rest / diviser)
          rest = rest - rest / diviser
          el.progress = progress
        }
      }
      )
    }
    if (!is_download.value) {
      clearInterval(loading)
    }
  }, 200)
}
watch(is_download, () => {
  if (is_download.value) {
    updateProgressDownload()
    show_bar.value = is_download.value
  } else {
    show_bar.value = false
  }
})
watch(num_files_downloading, () => {
  rest = num_files_downloading.value * 100
  diviser = rest - 8
  show_bar.value = true
})

</script>

<template>
<div v-if="(show_bar && files_downloading.length === 1)"
  class="flex fixed bottom-16 right-48 items-center justify-center rounded-xl px-3 p-2 z-50 bg-primary text-white w-2/3">
  <span class="flex items-center font-semibold">
    <div class="w-4 h-4 border-2 border-blue-400 border-dotted border-t-0 rounded-full animate-spin mr-5"></div>
    {{progress_message}}
  </span>
  <div class="w-3/6 flex items-center rounded  h-2 mx-20 bg-primary-light">
    <div class="h-2 rounded-full border-dashed bg-white loading-bar" :style="`width: ${progress_download}%`">
    </div>
  </div>
  <button @click="show_bar = false" class="border-2 border-white p-2 rounded">
    Ocultar
  </button>
</div>
<div v-if="(show_bar && files_downloading.length > 1)" class="fixed flex justify-between rounded-xl bottom-10 right-12 z-50 bg-primary"
  :class="(show_downloads) ? 'default' : 'collapse'">
  <div v-if="!show_downloads" class="flex items-center">
    <span class="text-white ml-4">
      Descargando archivos...
    </span>
    <div class="w-4 h-4 border-2 border-white border-dotted border-t-0 rounded-full animate-spin ml-5"></div>
  </div>
  <div class="flex-col w-full mt-1" v-else>
    <div
    class="pr-3 pl-1 py-1 my-1 rounded-lg bg-grey-lightest border-2 border-grey"
    v-for="({ name_file, progress, is_downloading }, index) in files_downloading.values()" :key="index">
      <div v-if="is_downloading" class="flex items-center justify-between">
        <div class="truncate w-8/12 ml-2">
          <span class="text-primary-darker truncate flex items-center" :title="name_file">
            {{(name_file) ? name_file : `Archivo ${index}`}}
          </span>
        </div>
        <div class="w-3/12 mx-2 flex items-center rounded  h-2  bg-primary-light">
          <div class="h-2 rounded-full border-dashed bg-primary loading-bar" :style="`width: ${progress}%`"></div>
        </div>
        <span v-if="progress.toFixed(0) < 100" class="ml-2 px-1 text-xs w-1/12">
          {{progress.toFixed(1)+'%'}}
        </span>
        <span v-else class="text-xs  ml-2 px-1 w-1/12">
          <div class="w-4 h-4 border-2 border-primary border-dotted border-t-0 rounded-full animate-spin"></div>
        </span>
      </div>
      </div>
    </div>
  <div class="flex ml-5"
  >
    <button @click="(show_downloads = !show_downloads)"
    class="relative">
      <svg class="top-1.5 right-3 absolute" v-if="!show_downloads" width="15" height="9" viewBox="0 0 15 9" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.6727 2.73994L14.4126 2.06727L13.0673 0.587387L12.3273 1.26006L13.6727 2.73994ZM7.5 7L6.82733 7.73994L7.5 8.35146L8.17267 7.73994L7.5 7ZM2.67267 1.26006L1.93273 0.587387L0.587387 2.06727L1.32733 2.73994L2.67267 1.26006ZM12.3273 1.26006L6.82733 6.26006L8.17267 7.73994L13.6727 2.73994L12.3273 1.26006ZM8.17267 6.26006L2.67267 1.26006L1.32733 2.73994L6.82733 7.73994L8.17267 6.26006Z"
          fill="#ffff" />
      </svg>
      <svg class="top-1.5 -right-1 absolute" v-if="show_downloads" transform="rotate(180)" width="15" height="9"
        viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.6727 2.73994L14.4126 2.06727L13.0673 0.587387L12.3273 1.26006L13.6727 2.73994ZM7.5 7L6.82733 7.73994L7.5 8.35146L8.17267 7.73994L7.5 7ZM2.67267 1.26006L1.93273 0.587387L0.587387 2.06727L1.32733 2.73994L2.67267 1.26006ZM12.3273 1.26006L6.82733 6.26006L8.17267 7.73994L13.6727 2.73994L12.3273 1.26006ZM8.17267 6.26006L2.67267 1.26006L1.32733 2.73994L6.82733 7.73994L8.17267 6.26006Z"
          fill="#ffff" />
      </svg>
    </button>
  </div>
</div>
</template>
<style scoped>
.loading-bar {
  transition-timing-function: ease-out;
  transition: 0.2s
}
.default {
  @apply px-4 p-2 w-1/4
}

.collapse {
  @apply px-4 p-2 h-10 w-1/6
}
</style>
