<template>
<div class="tooltip inline-block relative w-auto" :class="{'no-tooltip' : disabled}">
  <slot name="icon">
    <SVGIcons class="icon svg-16 cursor-pointer relative z-20" :icon="'info'"/>
  </slot>
  <ul  class="z-30 absolute w-80 bg-white rounded-md border-2 border-primary-light py-3 px-6 text-xs leading-4 text-grey font-normal" :class="position">
    <slot></slot>
  </ul>
</div>
</template>

<script>
import SVGIcons from './Layout/SVGIcons.vue'

export default {
  name: 'Tooltip',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'arrow-top'
    }
  },
  components: { SVGIcons }
}
</script>

<style scoped>
  .arrow-top {
    display: none;
    max-width: 250px;
    bottom: 0;
    left: calc(50% + 10px);
    transform: translate(-50%, calc( 100% + 12px ) );
  }
  .arrow-top::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 50%;
    transform: translateX(-50%);
    display: block;
    width: 18px;
    height: 18px;
    background-color: white;
    transform: rotate(45deg);
    @apply border-t-2 border-l-2 border-primary-light
  }
  .arrow-right {
    display: none;
    max-width: 250px;
    bottom: 0;
    right: calc(50% + 10px);
    transform: translate(10%, calc( 100% + 12px ) );
  }
  .arrow-right::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 90%;
    transform: translateX(90%);
    display: block;
    width: 18px;
    height: 18px;
    background-color: white;
    transform: rotate(45deg);
    @apply border-t-2 border-l-2 border-primary-light
  }
  .icon {
    top: 0px;
    left: 0px;
  }
  .tooltip {
    /* padding: 10px; */
  }
  .tooltip:hover:not(.no-tooltip) .arrow-top{
    display: block;
  }
  .tooltip:hover:not(.no-tooltip) .arrow-right{
    display: block;
  }
</style>
