<template>
  <div class="w-full h-auto px-2">
    <div class="rounded-lg overflow-hidden">
        <div class="md:flex">
            <div class="w-full">
                <div class="relative h-16 rounded-lg border-grey-lighter  border-2 flex items-center">
                    <div v-if="error" class="absolute px-2">
                        <div class="flex items-center pl-3">
                          <svg id="icon-danger" width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17.5" r="17.5" fill="#ED5858"/>
                            <path d="M12 24L18 18M18 18L24 12M18 18L12 12M18 18L24 24" stroke="white" stroke-width="4" stroke-linecap="square" stroke-linejoin="round"/>
                          </svg>
                          <p class="text-xs text-grey-light font-semibold ml-2">
                            <span class="text-danger-dark font-bold">ERROR</span>
                            formato de archivo no permitido.</p>
                            </div>
                    </div>
                    <div v-else class="absolute px-2">
                        <div class="flex items-center">
                          <SVGIcons v-if="file === ''" :icon="'upload'" class="svg-50 mr-2 mt-3 z-50"/>
                          <SVGIcons v-else :icon="'check-green'" class="svg-38 z-50 mt-3 ml-2"/>
                          <p v-if="file === ''" class="text-xs text-grey-light font-semibold ml-2">Haz
                            <span class="text-primary font-bold">click aquí</span>
                            o arrastra tu archivo XLSX para subirlo</p>
                            <p class="text-center">{{file.name}}</p>
                            </div>
                    </div>
                    <input type="file" accept=".xlsx"  ref="myFiles" class="h-full w-full opacity-0 " @change="checkFile" name="">
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import SVGIcons from '../../Layout/SVGIcons.vue'
export default {
  components: { SVGIcons },
  props: {
    size: {
      type: Object
    },
    modelValue: {
      type: Object
    }
  },
  emits: ['change', 'update:modelValue', 'success'],
  data () {
    return {
      file: '',
      error: false
    }
  },
  methods: {
    checkFile (event) {
      if (event.target.files[0].name.split('.').pop() !== 'xlsx') {
        this.$emit('error')
        this.error = true
        event.target.value = ''
        this.file = ''
        this.$emit('update:modelValue', this.file)
        return
      } else {
        this.error = false
      }
      this.file = ''
      this.file = this.$refs.myFiles.files[0]
      this.$emit('success')
      this.$emit('update:modelValue', this.file)
    }
  }
}
</script>
