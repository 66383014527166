<template>
<nav :class="`flex mt-2 mb-3 ${bgClass} rounded py-3 font-medium text-grey`">
  <slot></slot>
</nav>
</template>

<script>
export default {
  name: 'UiTableHeader',
  props: {
    bgClass: {
      type: String,
      default: 'bg-grey-lightest'
    }
  }
}
</script>
