<template>
  <svg :id="props.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5V19M5 12H19" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-times'
  },
  color: {
    type: String,
    default: '#626262'
  }
})
</script>
