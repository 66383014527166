<template>
  <footer class="px-10 pb-10 flex justify-end">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'ModalFooter'
}
</script>

<style>

</style>
