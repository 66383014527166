<template>
    <UiHeader  :title="'Rutas corporativas'"/>
    <UiPageBody>
      <section class="container mb-5 mt-14 pl-12 mx-12 pb-8 w-auto bg-grey-lightest flex flex-col rounded">
        <nav class="flex  px-6 pt-6 pl-0 mr-4">
          <ul class="flex justify-between border-b-2 border-grey-lighter w-full ">
            <li class="flex items-center text-xl pr-1 font-semibold border-b-2 pb-5 text-primary">
              {{itinerary.name}}
            </li>
            <li class="flex items-end pr-1 mr-1 mb-2">
              <button
                v-if="(itinerary.can_edit)"
                class="text-primary font-semibold text-sm"
                @click="$refs['itinerary-edit'].modal('show'),$refs['itinerary-edit'].getItinerary(itinerary.id)">
                Editar
              </button>
            </li>
          </ul>
        </nav>
        <article class="mt-5 text-grey-dark w-11/12">
          {{itinerary.description}}
        </article>
        <nav class="flex justify-between px-6 pt-6 pl-0 mr-4">
          <ul class="flex justify-between border-b-2 border-grey-lighter w-full ">
            <li class="flex items-center text-xl pr-1 border-b-2 pb-5 font-semibold text-primary">
              Dedicación
            </li>
            <li class="flex items-end pr-1 mr-1 mb-2">
              <button class="text-primary font-semibold text-sm"
                v-if="(itinerary.can_edit)"
                @click="$refs['itinerary-dedication'].modal('show'),$refs['itinerary-dedication'].getItinerary(itinerary.id)">
                Editar
              </button>
            </li>
          </ul>
        </nav>
        <article class="mt-5 flex justify-center text-grey-dark w-auto mr-10 bg-grey-lighter-2 p-5 rounded">
          <p class="w-1/3 text-grey mr-10">Duración del contenido: <strong class="2xl:inline sm:block text-grey-dark ">{{itinerary.duration_in_hours}} horas</strong></p>
          <p class="w-1/3 text-greyt">Dedicación semanal recomendada: <strong class="text-grey-dark ">{{time}}</strong></p>
          <p class="w-1/3 text-right text-grey">Duración de la ruta: <strong class="2xl:inline sm:block text-grey-dark ">{{itinerary.dedicated_weeks}} semana{{(itinerary.dedicated_weeks > 1)? 's' : ''}}</strong></p>
        </article>
      </section>
      <section class="container my-10 rounded mx-12 p-12 w-auto  bg-grey-lightest">
        <nav class="flex justify-between w-full border-b-2 border-grey-lighter">
            <ul class="flex w-full">
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex items-center bg-transparent pb-4" :class="(hide === true) ? 'active-link' : 'desactive'" @click="hide = true">
                Formaciones
                <Balloon class="ml-3" :type="'secondary'">{{courses.length}}</Balloon>
              </button>
            </li>
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex items-center bg-transparent pb-4" :class="(hide === false) ? 'active-link' : 'desactive'" @click="hide = false">
                Usuarios
                <Balloon class="ml-3" :type="'secondary'">{{pages.count}}</Balloon>
              </button>
            </li>
            </ul>
        </nav>
        <article class="2xl:text-base xl:text-sm" v-if="hide">
          <div class="flex container justify-between my-5">
            <div class="flex-none w-full flex justify-end">
              <div class="">
                <UiButton
                  v-if="(itinerary.can_edit)"
                  @click="$refs['add-courses'].modal('show')" :type="'secondary'">
                  Añadir formación
                  <SVGIcons class="svg-14 ml-2" icon="plus"/>
                </UiButton>
              </div>
            </div>
          </div>
          <nav class="custom-table-head-rank text-sm justify-between">
            <UiTableCell class="w-10"></UiTableCell>
            <UiTableCell class="w-10 flex justify-center">Orden</UiTableCell>
            <UiTableCell class="w-1/12 flex justify-center">Tipo</UiTableCell>
            <UiTableCell class="w-6/12">
              Nombre
            </UiTableCell>
            <UiTableCell class="w-2/12 justify-center">
              Categoría
            </UiTableCell>
            <UiTableCell class="w-2/12">
            Duración
            </UiTableCell>
            <UiTableCell class="w-[34px]"></UiTableCell>
          </nav>
          <div v-if="first_loading && courses.length == 0" class="skeleton-pulse">
            <div v-for="num in 10" :key="num" class=" custom-table-body-rank flex flex-no">
              <ul class="custom-table-row-rank">
                <li class="flex-auto w-full py-4"></li>
              </ul>
            </div>
          </div>
          <div class="listCourses overflow-auto custom-scroll">
            <VueDraggableNext @change="updateItinerary" v-model="courses">
              <transition-group>
                <div class="custom-table-body-rank " :class="{ 'loading' : loading }" v-for="({id,business, name,kind,scope_name,academy_link,duration}, index) in courses" :key="id">
                  <UiTableRow
                  class="justify-between"
                  >
                    <li class="w-10 flex justify-center items-center"><SVGIcons class="svg-14 ml-2" icon="drag-and-drop"/></li>
                    <UiTableCell class="w-10 flex justify-center">{{index+1}}</UiTableCell>
                    <UiTableCell class=" w-1/12 flex justify-center ">{{kind_rename[kind]}}</UiTableCell>
                    <UiTableCell class="flex w-6/12 items-center relative">
                      <div class='has-tooltip-itinerary' :title="name">
                        {{name}}
                      <span v-if="business" title="Formación corporativa" class="text-2xs bg-corporative absolute rounded-full right-8 px-2 py-1">C</span>
                      </div>
                    </UiTableCell>
                    <UiTableCell class="w-2/12 flex items-center justify-center">
                      <div class='has-tooltip-itinerary truncate w-10/12' :title="scope_name">
                        {{scope_name}}
                      </div>
                    </UiTableCell>
                    <UiTableCell class="w-2/12 text-sm flex items-center justify-center">{{$filters.hoursAndMinutes(duration)}}</UiTableCell>
                    <UiTableCell class="w-[34px] flex justify-start items-center">
                      <MenuEllipsis
                        class="relative -top-1"
                        :items="[
                          {type: 'default', permission: 'all', icon: 'view', text: 'Ver contenido', border: false, func: () => {
                            openContain(academy_link)
                            }
                          },
                          {type: 'danger', permission: (itinerary.can_edit) ? 'all' : '', icon: 'trash', text: 'Eliminar', border: true,  func: () => {
                            openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                              title: '¿Quieres eliminar este curso de la ruta?',
                              text: 'A los usuarios se les eliminará este curso de dentro de la ruta.',
                              btn_text: 'Eliminar',
                              func: () => {
                                removeCourseFromList(id)
                                  }
                                }
                              })
                            }
                          }
                        ]"
                      />
                    </UiTableCell>
                  </UiTableRow>
                </div>
              </transition-group>
            </VueDraggableNext>
          </div>
        </article>
        <article v-else class="2xl:text-base xl:text-sm">
          <div class="flex container justify-between my-5">
            <div class="flex-none w-1/3 flex">
              <div class="col-auto px-1 justify-center xl:w-50 lg:w-42">
                <SearchField :border="'border-none'" :bgClass="'bg-grey-lighter-2'"  :placeholder="'Buscar por nombre'" v-model="search" @change="getUser" />
              </div>
            </div>
            <div class="flex-none w-2/3 flex justify-end">
              <div class="flex">
                <UiButton @click="filterItineraryToUsers" class="mr-2" :type="'outline-secondary'" :size="'btn-sm'">
                  <svg class="mr-2" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.5 7H9.55C9.79855 7 10 7.20145 10 7.45V12.55C10 12.7985 9.79855 13 9.55 13H1.45C1.20147 13 1 12.7985 1 12.55V7.45C1 7.20145 1.20147 7 1.45 7H2.5M8.5 7V4C8.5 3 7.9 1 5.5 1C3.1 1 2.5 3 2.5 4V7M8.5 7H2.5"
                      stroke="#304178" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{all_users_filtered ? 'Habilitar catálogo a todos': 'Limitar catálogo a todos'}}
                </UiButton>
                <DownloadXLS
                  class="btn btn-outline-secondary"
                  :file="`usuarios-${itinerary.name}.xlsx`"
                  :path="`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/students/stats/`"
                  formatted='[{"field":"first_name","visible":"Nombre"},
                        {"field":"last_name","visible":"Apellidos"},
                        {"field":"email","visible":"Email"},
                        {"field":"team","visible":"Equipo"},
                        {"field":"progress","visible":"Progreso"},
                        {"field":"active_time","visible":"Dedicación", "output": "tiempo"},
                        {"field":"active_time_in_hour","visible":"Dedicación en horas", "output": "hours"},
                        {"field":"updated_at","visible":"Última actividad","output":"date"}]'
                  :params="{limit:1000000000, search:this.search, ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter}"
                  >
                  Descargar reporte
                </DownloadXLS>
                <div class="flex items-center">
                  <Tooltip direction_arrow="down" >
                    <template v-slot:title>
                    <button @click="$refs['AddUserByEmailRef'].modal('show', { context: context, route_id: this.$route.params.id_itinerary })" class="ml-3 flex items-center py-2.5 px-2 rounded text-secondary-darker font-semibold border-2 border-secondary-darker">
                      <EmailAddIcon/>
                    </button>
                    </template>
                    <template v-slot:default>
                      <li class=" text-primary-800 w-[160px] text-xs font-normal">
                        <p class="w-full">Añadir usuarios por email</p>
                      </li>
                    </template>
                    </Tooltip>
                </div>

                <UiButton class="ml-2" @click="$refs['add-users'].modal('show'), $refs['add-users'].getUsers(), $refs['add-users'].getStudents()" :type="'secondary'">
                  Añadir usuario
                  <SVGIcons class="svg-14 ml-2" icon="plus"/>
                </UiButton>
              </div>
            </div>
          </div>
          <UiTableHeader class="custom-table-head-rank text-sm">
            <UiTableCell first class="w-5/12">
                Nombre
            </UiTableCell>
            <UiTableCell class="w-3/12 justify-center">
                Progreso
            </UiTableCell>
            <UiTableCell class="w-3/12 justify-center">
                Equipo
            </UiTableCell>
            <UiTableCell class="w-2/12 justify-center">
                Última actividad
            </UiTableCell>
            <UiTableCell class="w-2/12 justify-center">
                Dedicación total
            </UiTableCell>
            <UiTableCell class="w-3/12 justify-center">
                Catálogo
            </UiTableCell>
            <UiTableCell last class="w-1/12"></UiTableCell>
          </UiTableHeader>
          <UiTableRow v-for="{first_name, team, email, last_name, avatar_url, user_id, employee_id, progress, updated_at, active_time, is_filtered} in users" :key="user_id">
              <UiTableCell first class="w-5/12 truncate">
                <UserIdentity :user="{first_name, email, last_name, avatar_url}" dark />
              </UiTableCell>
              <UiTableCell class="w-3/12 justify-center">
                <ProgressBar :progress="progress"/>
              </UiTableCell>
              <UiTableCell class="w-3/12 justify-center">
                {{team}}
              </UiTableCell>
              <UiTableCell class="w-2/12  2xl:justify-start xl:justify-center text-sm flex items-center"> {{$filters.shortDate(updated_at)}}</UiTableCell>
              <UiTableCell class="w-2/12  2xl:justify-start xl:justify-center text-sm flex items-center"> {{$filters.hoursAndMinutes(active_time)}}</UiTableCell>
              <UiTableCell class="w-3/12 text-sm flex items-center 2xl:ml-2 xl:ml-0">
                <ToggleFilteredState @change="getUser" class="mr-3" :user="{employee_id: employee_id, is_filtered: is_filtered}" />
                {{ (!is_filtered) ? 'Catálogo sin limitaciones' : 'Limitado a ruta'}}
              </UiTableCell>
              <UiTableCell last class="w-1/12 flex items-center justify-end pr-6">
                <MenuEllipsis
                    class="relative -top-1"
                      :items="[
                          {type: 'default', permission: 'all', icon: 'view', text: 'Ver usuario', border: false, func: () => {
                          this.$router.push({ name: 'user-detail', params: { employee_id: employee_id} })
                          }
                        },
                        {type: 'danger', permission: 'all', icon: 'trash', text: 'Eliminar', border: true,  func: () => {
                          openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                              title: '¿Estás seguro de eliminar este usuario de la ruta?',
                              text: 'A los usuarios se les eliminará de su ruta de cursos de dentro de la ruta.',
                              btn_text: 'Eliminar',
                              func: () => {
                                removeUser(user_id)
                                }
                              }
                            })
                          }
                        }
                      ]"
                />
              </UiTableCell>
            </UiTableRow>
          <div class="flex justify-end">
            <Paginator :pages="pages" v-model="paginator.page" @change="getUser(this.paginator.page)"/>
          </div>
        </article>
      </section>
    </UiPageBody>
  <ItineraryEditModal ref="itinerary-edit" @success="getItinerary" />
  <ItineraryDedicationModal ref="itinerary-dedication" @success="getItinerary" />
  <AddCoursesModal ref="add-courses" @success="getCourses"/>
  <AddUserItineraryModal ref="add-users" @success="getUser"/>
  <AddUserByEmail  @change="getUser" ref="AddUserByEmailRef"/>
  <DialogBox ref="user-dialog" />
</template>
<script>
import AddCoursesModal from '../../components/Modals/AddCoursesModal.vue'
import AddUserItineraryModal from '../../components/Modals/AddUserItineraryModal.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import DialogBox from '../../components/DialogBox.vue'
import DownloadXLS from '../../components/DownloadXLS.vue'
import Balloon from '../../components/Balloon.vue'
import MenuEllipsis from '../../components/MenuEllipsis/Menu.vue'
import SearchField from '../../components/SearchField.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import ItineraryEditModal from '../../components/Modals/ItineraryEditModal.vue'
import ItineraryDedicationModal from '../../components/Modals/ItineraryDedicationModal.vue'
import UiButton from '../../components/Layout/UI/UiButton.vue'
import UserIdentity from '../../components/UserIdentity.vue'
import Paginator from '../../components/Paginator.vue'
import ProgressBar from '../../components/ProgressBar.vue'
import { api } from '../../api/instances'
import { mapState, mapActions, mapMutations } from 'vuex'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'
import ToggleFilteredState from '../../components/ToggleFilteredState.vue'
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import AddUserByEmail from './New/components/AddUserByEmail.vue'
import EmailAddIcon from '@/views/PlannerV1/icons/EmailAddIcon.vue'
import Tooltip from '@/views/PlannerV1/components/Tooltip.vue'
export default {
  name: 'ItineraryDetail',
  components: {
    AddCoursesModal,
    AddUserItineraryModal,
    Balloon,
    DialogBox,
    DownloadXLS,
    MenuEllipsis,
    SearchField,
    SVGIcons,
    UiButton,
    ItineraryEditModal,
    ItineraryDedicationModal,
    UserIdentity,
    Paginator,
    ProgressBar,
    VueDraggableNext,
    UiTableHeader,
    UiTableCell,
    UiTableRow,
    ToggleFilteredState,
    UiHeader,
    UiPageBody,
    AddUserByEmail,
    Tooltip,
    EmailAddIcon
  },
  data () {
    return {
      activeFilter: { filter: '', direction: false },
      itinerary: {},
      hide: true,
      kind: [],
      kind_rename: { curso: 'Curso', taller: 'Taller', recurso: 'Recurso', premium: 'Empresas', webinar: 'Webinars', lab: 'Laboratorio' },
      courses: [],
      loading: false,
      first_loading: true,
      search: '',
      users: [],
      pages: {},
      paginator: {
        limit: 10,
        page: 1
      },
      all_users_filtered: null
    }
  },
  computed: {
    ...mapState(['active_business', 'user']),
    courses_ids () {
      return this.courses.map((el) => el.id)
    },
    time () {
      const hours = parseInt(this.itinerary.weekly_dedication)
      const minutes = this.itinerary.weekly_dedication % 1
      return `${(hours > 0) ? hours + 'h' : ''} ${(minutes === 0.5) ? '30min' : ''}`
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    ...mapActions(['openDialogBox']),
    async getItinerary () {
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/`)
        .then(({ data }) => {
          this.itinerary = data
        })
        .catch(() => {
          console.error('Ha habido un problema al cargar la ruta')
        })
    },
    async getCourses () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/courses/`, {
        params: {
          pagination: 'False'
        }
      })
        .then(({ data }) => {
          this.courses = data
          this.loading = false
        })
        .catch(() => {
          console.error('Ha habido un problema al cargar los cursos')
          this.loading = false
        })
      this.first_loading = false
    },
    openContain (url) {
      window.open('http://www.openwebinars.net' + url, '_blank')
    },
    removeCourseFromList (id) {
      this.courses.splice(this.courses.findIndex((el) => el.id === id), 1)
      this.$refs['user-dialog'].dialog('hide')
      this.updateItinerary()
    },
    async updateItinerary () {
      await api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/courses/`, {
        courses: this.courses_ids
      })
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.getItinerary()
        })
        .catch(() => {
          console.error('Ha habido un problema al actualizar la ruta')
        })
    },
    async getUser (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/students/stats/`,
        {
          params: {
            limit: this.paginator.limit,
            search: this.search,
            page: pag,
            student__department_id: this.team_id,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          this.users = data.results
          this.pages = data.paginator
          this.all_users_filtered = data.results.some(el => el.is_filtered)
          this.loading = false
        })
        .catch(() => {
          console.error('Ha habido un problema para obtener a los usuarios')
          this.loading = false
        })
    },
    async removeUser (user_id) {
      await api.delete(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/students/${user_id}`)
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.$refs['user-dialog'].dialog('hide')
          this.getUser()
        })
        .catch(() => {
          console.error('Ha habido un problema al eliminar a los usuarios')
        })
    },
    async filterItineraryToUsers () {
      await api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/filtered-users/`, {
        action: (this.all_users_filtered) ? 'unlock' : 'lock'
      })
        .then(() => {
          this.getUser()
        })
        .catch((err) => {
          console.error('Problema al restringir el catálogo')
          console.error(err)
        })
    }
  },
  created () {
    this.getItinerary()
    this.getCourses()
    this.getUser()
  }
}
</script>
<style scoped>

.active-link {
  @apply border-b-2
}
.custom-table-head-rank{
    @apply flex my-2 mb-7 rounded py-3 font-medium text-grey;
    background-color: #E3E5EC;
}
.custom-table-body-rank{
    @apply mb-2 rounded;
    background-color: #E3E5EC;
}
.custom-table-body-rank .custom-table-row-rank{
    @apply flex w-full rounded py-5;
    background-color: #E3E5EC;
}
.has-tooltip-itinerary .tooltip {
  @apply invisible absolute;
}

.has-tooltip-itinerary:hover .tooltip {
  @apply visible z-50;
}

.listCourses{
  padding-bottom: 100px;
  max-height: 45rem;
}
.bg-corporative {
  background-color: #8DAA9D;
}
</style>
