<template>
  <svg :id="props.id" width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_97_4331)">
    <path d="M12.3942 5.9778C12.394 5.61579 12.2501 5.26868 11.9941 5.0127C11.7381 4.75672 11.391 4.61281 11.029 4.61258L3.74242 4.61065C3.38 4.61065 3.03243 4.75462 2.77616 5.01089C2.51989 5.26716 2.37592 5.61474 2.37592 5.97716C2.37592 6.33958 2.51989 6.68716 2.77616 6.94343C3.03243 7.1997 3.38 7.34367 3.74242 7.34367L7.73055 7.34431L0.302326 14.7725C0.0461375 15.0287 -0.0977875 15.3762 -0.0977869 15.7385C-0.0977879 16.1008 0.0461375 16.4483 0.302326 16.7044C0.558514 16.9606 0.905979 17.1046 1.26828 17.1046C1.63059 17.1046 1.97805 16.9606 2.23424 16.7044L9.66246 9.27623L9.66246 13.2637C9.66246 13.4432 9.69781 13.6209 9.76648 13.7866C9.83516 13.9524 9.93581 14.1031 10.0627 14.23C10.1896 14.3569 10.3402 14.4575 10.506 14.5262C10.6718 14.5949 10.8495 14.6302 11.029 14.6302C11.2084 14.6302 11.3861 14.5949 11.5519 14.5262C11.7177 14.4575 11.8683 14.3569 11.9952 14.23C12.1221 14.1031 12.2228 13.9524 12.2915 13.7866C12.3601 13.6209 12.3955 13.4432 12.3955 13.2637L12.3942 5.9778Z"
    :fill="color"/>
    </g>
  </svg>
</template>
<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-corner-arrow'
  },
  color: {
    type: String,
    default: '#414141'
  }
})
</script>
