<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold pb-2 border-b-2 border-grey-lighter">Añadir formación</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <section class="px-12 mt-8">
        <nav class="flex justify-between w-full border-b border-grey-lighter">
          <ul class="flex">
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex font-medium items-center bg-transparent pb-4"
              :class="{'activeTab' : activeTab === 0}" @click="activeTab = 0">
                Formaciones
                <Balloon class="ml-3 px-2 py-0.5" :type="'secondary'"> {{ pagesCourses.count }}</Balloon>
              </button>
            </li>
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex font-medium items-center bg-transparent pb-4"
                :class="{'activeTab' : activeTab === 1}" @click="activeTab = 1">
                Ruta OW
                <Balloon class="ml-3" :type="'secondary'"> {{ pagesCareers.count }}</Balloon>
              </button>
            </li>
          </ul>
        </nav>
        <article v-if="activeTab === 0" class="my-6 text-sm">
          <div class=" w-full flex-auto flex mb-5">
            <div class="w-7/12 flex-auto">
              <SearchField
              :border="'border-none'"
              :bgClass="'bg-grey-lighter-2'"
              :placeholder="'Buscar por nombre'"
              v-model="search" @change="getCourses"/>
            </div>
        <Visibility :permission="'can_see_corporative_courses'">
          <div class="text-sm mr-2">
            <UiCustomSelect class="w-full" @change="getCourses()" v-model="catalog_selected" name="">
              <option value="">Catálogo</option>
              <option value="ow">OpenWebinars</option>
              <option value="business">Corporativo</option>
            </UiCustomSelect>
          </div>
        </Visibility>
          <div class="w-2/12 flex mr-2 ">
            <UiCustomSelect class="w-full text-sm" @change="getCourses" v-model="category_selected" name="">
              <option value="">Categoria</option>
              <option v-for="{id, name} in categories" :key="id" :value="id">{{name}}</option>
            </UiCustomSelect>
          </div>
          <div class="flex-auto flex w-2/12 mr-2">
            <UiCustomSelect class="w-full" v-model="dificult_selected"  name="dificult" @change="getCourses">
              <option value="" selected>Dificultad</option>
              <option v-for="{id, name, value} in dificult" :key="id" :value="value">{{name}}</option>
            </UiCustomSelect>
          </div>
          <div class="flex-auto flex w-2/12 ">
            <LearningKind class="w-full" @change="getCourses()" v-model="kinds"/>
          </div>
        </div>
        <nav class="custom-table-head-rank text-sm">
          <div class="w-2/12 flex pl-4">Tipo</div>
          <div class="w-3/12">
            <TableSorter
            :loading="loading"
            filter="name"
            v-model="activeFilter"
            @update="getCourses()"
            >
            Nombre
            </TableSorter>
          </div>
          <div class="w-2/12">Duración</div>
          <div class="w-2/12">Dificultad</div>
          <div class="w-2/12">Contenido</div>
          <div class="w-1/12"></div>
        </nav>
        <div v-if="first_loading && courses.length == 0" class="skeleton-pulse">
          <div v-for="num in 5" :key="num" class=" custom-table-body-rank flex flex-no">
            <ul class="custom-table-row-rank">
              <li class="flex-auto w-full py-4"></li>
            </ul>
          </div>
        </div>
        <div class="custom-table-body-rank flex flex-no"
          v-for="{
              id,
              name,
              kind,
              business,
              level_difficulty,
              academy_link,
              is_ow_plus,
              duration
              } in courses" :key="id">
          <ul class="custom-table-row-rank text-sm">
            <li class="flex pl-4 items-center w-2/12 capitalize">{{(is_ow_plus) ? translate_kind[kind] : kind}}</li>
            <li class=" flex w-3/12 items-center relative">
              <div class='has-tooltip truncate w-8/12' :title="name">
              {{name}}
              <span v-if="business" title="Formación corporativa" class="text-2xs bg-corporative absolute rounded-full right-8 px-2 py-1">C</span>
              </div>
            </li>
            <li class="w-2/12 text-sm flex items-center">{{$filters.hoursAndMinutes(duration)}}</li>
            <li class="w-2/12 text-sm flex items-center">{{dificult_in_spanish[level_difficulty]}}</li>
            <li class="w-2/12 text-sm flex items-center">
              <a class="text-primary font-semibold"
                :href="'http://www.openwebinars.net'+academy_link" target="_blank">
                Ver contenido</a>
            </li>
            <li class="w-1/12 flex justify-end pr-3  items-center"> <UiButton :size="'btn-sm'" :type="'secondary'"
                v-if="!assigned_courses_ids.includes(id)"
                @click="coursesItinerary.push({
                  name,
                  id,
                  kind,
                  level_difficulty,
                  academy_link,
                  duration
                }), updateItinerary()"
                >Añadir</UiButton>
              <UiButton
                :size="'btn-sm'"
                :type="'disabled'"
                v-else >Añadido</UiButton>
            </li>
          </ul>
        </div>
        <div class="flex justify-end">
          <Paginator :pages="pagesCourses" v-model="pagesCourses.page" @change="getCourses(this.pagesCourses.page)"/>
        </div>
      </article>
      <article v-if="activeTab === 1" class="my-6 text-sm">
        <div class=" w-full flex-auto flex mb-5">
          <div class="w-7/12 flex-auto">
            <SearchField
            :border="'border-none'"
            :bgClass="'bg-grey-lighter-2'"
            :placeholder="'Buscar por nombre'"
            v-model="pathSearch" @change="getCareers"/>
          </div>
      </div>
      <nav class="custom-table-head-rank text-sm">
        <div class="w-1/12 flex pl-4">Tipo</div>
        <div class="w-5/12">
          <TableSorter
          :loading="loading"
          filter="name"
          v-model="pathActiveFilter"
          @update="getCareers()"
          >
          Nombre
          </TableSorter>
        </div>
        <div class="w-2/12 flex justify-center">Duración</div>
        <div class="w-2/12 flex justify-center">Formaciones</div>
        <div class="w-2/12 flex justify-center">Contenido</div>
        <div class="w-1/12"></div>
      </nav>
      <div v-if="first_loading && courses.length == 0" class="skeleton-pulse">
        <div v-for="num in 5" :key="num" class=" custom-table-body-rank flex flex-no">
          <ul class="custom-table-row-rank">
            <li class="flex-auto w-full py-4"></li>
          </ul>
        </div>
      </div>
      <div class="custom-table-body-rank flex flex-no"
        v-for="{
          id,
          name,
          duration,
          academy_link,
          courses
        } in careers" :key="id">
        <ul class="custom-table-row-rank text-sm">
          <li class=" flex w-1/12 items-center pl-4">
            Ruta
          </li>
          <li class=" flex w-5/12 items-center relative">
            <div class='has-tooltip truncate w-8/12' :title="name">
            {{name}}
            </div>
          </li>
          <li class="w-2/12 text-sm flex items-center justify-center">{{$filters.hoursAndMinutes(duration)}}</li>
          <li class="w-2/12 text-sm flex items-center justify-center">{{courses.length}}</li>
          <li class="w-2/12 text-sm flex items-center justify-center">
            <a class="text-primary font-semibold"
              :href="'http://www.openwebinars.net'+academy_link" target="_blank">
              Ver contenido</a>
          </li>
          <li class="w-1/12 flex justify-end pr-3 whitespace-nowrap items-center"> <UiButton :size="'btn-sm'" :type="'secondary'"
              v-if="!assigned_courses_ids.includes(id)"
              @click="
              addPathToItinerary(courses)"
              >Añadir</UiButton>
            <UiButton
              :size="'btn-sm'"
              :type="'disabled'"
              v-else >Añadido</UiButton>
          </li>
        </ul>
      </div>
      <div class="flex justify-end">
        <Paginator :pages="pagesCareers" v-model="pagesCareers.page" @change="getCareers(this.pagesCareers.page)"/>
      </div>
    </article>
      </section>
    </div>
  </div>
  </transition>
</template>
<script>
import Balloon from '@/components/Balloon.vue'
import Paginator from '../Paginator.vue'
import SearchField from '../SearchField.vue'
import UiCustomSelect from '../Layout/UI/UiCustomSelect.vue'
import UiButton from '../Layout/UI/UiButton.vue'
import TableSorter from '../TableSorter.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
import LearningKind from '../Fields/LearningKind.vue'
import Visibility from '@/components/Visibility.vue'
export default {
  name: 'AddUserModal',
  components: {
    TableSorter,
    Paginator,
    UiButton,
    UiCustomSelect,
    SearchField,
    LearningKind,
    Visibility,
    Balloon
  },
  emits: ['modal:show', 'modal:hide', 'success'],
  data () {
    return {
      activeTab: 0,
      activeFilter: { filter: '', direction: false },
      pathActiveFilter: { filter: '', direction: false },
      first_loading: true,
      loading: false,
      open: false,
      courses: [],
      careers: [],
      coursesItinerary: [],
      categories: [],
      category_selected: '',
      dificult: [],
      dificult_selected: '',
      kinds: [],
      translate_kind: {
        premium: 'Empresas',
        taller: 'Taller',
        curso: 'Curso',
        lab: 'Laboratorio'
      },
      dificult_in_spanish: { low: 'Principiante', 'low-middle': 'Principiante - Intermedio', middle: 'Intermedio', 'middle-advanced': 'Intermedio - Avanzado', advanced: 'Avanzado' },
      itinerary_id: this.$route.params.id_itinerary,
      search: '',
      pathSearch: '',
      pagesCourses: {},
      pagesCareers: {},
      paginatorCourses: {
        limit: 5,
        page: 1
      },
      paginatorCareers: {
        limit: 5,
        page: 1
      },
      pages: {},
      paginator: {
        limit: 5,
        page: 1
      },
      catalog_selected: ''
    }
  },
  computed: {
    ...mapState(['active_business']),
    assigned_courses_ids () {
      return this.coursesItinerary.map((el) => el.id)
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.activeTab = 0
        this.search = ''
        this.pathSearch = ''
        this.pagesCourses = {}
        this.pagesCareers = {}
        this.paginatorCourses = {
          limit: 5,
          page: 1
        }
        this.paginatorCareers = {
          limit: 5,
          page: 1
        }
        this.getCourses()
        this.getCareers()
        this.getCoursesItinerary()
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        document.querySelector('body').style.overflow = 'auto'
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getCareers (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/itineraries/careers/`,
        {
          params: {
            limit: this.paginatorCareers.limit,
            page: pag,
            search: this.pathSearch,
            ordering: (this.pathActiveFilter.direction === true) ? `-${this.pathActiveFilter.filter}` : this.pathActiveFilter.filter
          }
        })
        .then(({ data }) => {
          this.status = (data.count === 0)
          this.careers = data.results
          this.pagesCareers = data.paginator
          this.loading = false
        })
        .catch(() => {
          console.error('Problema al cargar las carreras')
          this.loading = false
        })
      this.first_loading = false
    },
    async getCourses (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/courses/`,
        {
          params: {
            limit: 5,
            page: pag,
            search: this.search,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
            category: this.category_selected,
            kind: this.kinds,
            level_difficulty: this.dificult_selected,
            catalog: this.catalog
          }
        })
        .then(({ data }) => {
          this.courses = data.results
          this.pagesCourses = data.paginator
          this.loading = false
          this.getFilters()
        })
        .catch(() => {
          console.error('Problema al cargar los cursos')
          this.loading = false
        })
      this.first_loading = false
    },
    async getCoursesItinerary () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/courses`, {
        params: {
          pagination: 'False'
        }
      })
        .then(({ data }) => {
          this.coursesItinerary = data
          this.loading = false
        })
        .catch(() => {
          console.error('Ha habido un problema al cargar los cursos')
          this.loading = false
        })
      this.first_loading = false
    },
    async addPathToItinerary (courses) {
      this.coursesItinerary = [...this.coursesItinerary, ...courses]
      this.updateItinerary()
    },
    async updateItinerary () {
      await api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/courses/`, {
        courses: this.assigned_courses_ids
      })
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.$emit('success')
        })
        .catch(() => {
          console.error('Problema al cargar los cursos')
        })
      this.changeState(false)
    },
    async getFilters () {
      await api.get(`/jarvis/business/${this.active_business}/courses/filters`)
        .then(({ data }) => {
          this.categories = data.category
          this.dificult = data.level_difficulty
        })
        .catch(() => {
          console.error('Problema al cargar los filtros')
        })
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .activeTab {
    @apply font-bold border-b-2 border-primary;
  }
  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-8of12 rounded-lg bg-white
  }
  .custom-table-head-rank{
    @apply flex my-2 mb-7 rounded py-3 font-medium text-grey;
    background-color: #E3E5EC;
}
.custom-table-body-rank{
    @apply mb-2 rounded;
    background-color: #E3E5EC;
}
.custom-table-body-rank .custom-table-row-rank{
    @apply flex w-full rounded py-5;
    background-color: #E3E5EC;
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
.bg-corporative {
  background-color: #8DAA9D;
}
</style>
