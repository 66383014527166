<template>
  <button
    class=" w-full flex items-center justify-end font-semibold pr-5"
    :class="(reminderStatus) ? 'disabled' : 'primary'">
    <SVGIcons v-if="!reminderStatus" class="svg-14 mt-1 mr-2" icon="email"/>
    <div v-else
      class="w-4 h-4 border-2 border-blue-400 border-dotted border-t-0 rounded-full animate-spin mr-5"
      :class="'disabled'"></div>
    {{(reminderStatus) ? 'Enviando recordatorios' : 'Enviar recordatorio de alta a invitados'}}
  </button>

</template>
<script>
import SVGIcons from './Layout/SVGIcons.vue'
import { mapState } from 'vuex'
export default {
  components: {
    SVGIcons
  },
  computed: {
    ...mapState(['reminderStatus'])
  }
}
</script>
<style scoped>
.primary{
    color: #98A2B3
  }
  .disabled{
    @apply text-grey pointer-events-none
  }
</style>
