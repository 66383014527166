import { createStore } from 'vuex'
import { api } from '../api/instances'
import router from '../router'
/*
      'can_create_teams',
      'can_edit_teams',
      'can_delete_teams',
      'can_add_licenses_to_teams',
      'can_add_staffs',
      'can_see_available_licenses',
      'can_create_users',
      'can_create_users',
      'can_edit_user',
      'can_unlink_user_to_team',
      'can_delete_user_to_organization',
      'can_see_itineraries_page',
      'can_see_home',
      'can_see_list_users_page',
      'can_see_list_teams_page',
      'can_see_requests_page',
      'can_see_analytics'

            'can_delete_teams',
      'can_add_licenses_to_teams',
      'can_add_staffs',
      'can_see_available_licenses',
      'can_create_users',
      'can_create_users',
      'can_edit_user',
      'can_unlink_user_to_team',
      'can_delete_user_to_organization',
      'can_see_itineraries_page',
      'can_see_home',
      'can_see_list_users_page',
      'can_see_list_teams_page',
      'can_see_requests_page',
      'can_see_analytics',
      'can_see_planner'
      'can_see_analytics', 'can_see_available_licenses', 'can_see_planner', 'can_see_massive_planner'
*/
export default createStore({
  state: {
    collapsedMenu: false,
    user: null,
    role: null,
    business: [],
    permissions: [],
    overdue_payment: false,
    active_business: null,
    licenses: {
      available_seats: 0,
      disabled_licenses: 0,
      non_team_assigned_licenses: 0,
      total_licenses: 0,
      teams: []
    },
    team_create_limit: 5,
    academy_filters: {},
    teams: [],
    users: [],
    toast_messages: [],
    tags: [],
    filters: {
      users: {
        team_id: '',
        is_active: '',
        role_id: '',
        search: '',
        tags: [],
        activeFilter: { filter: 'user__first_name', direction: false },
        pages: {},
        paginator: {
          limit: 10,
          page: 1
        }
      },
      planner: {
        team_id: '',
        month: null,
        sprint_id: null
      }
    },
    reminderStatus: false,
    total_requests: 0,
    dialogBox: {
      open: false
    },
    clonables: [],
    files_downloading: [],
    campus_course_limit: 0,
    hasDisabledUsers: true,
    urlRegistration: '',
    hasDomain: false
  },
  mutations: {
    collapseMenu (state) {
      state.collapsedMenu = !state.collapsedMenu
    },
    updateUser (state, payload) {
      state.user = payload
      state.business = payload.business
      state.active_business = payload.business[0].business_id
      state.campus_course_limit = payload.business[0].campus_course_limit
      state.overdue_payment = (payload.business[0].overdue_payment) ? payload.business[0].overdue_payment : false
      state.role = payload.business[0].role
      state.permissions = [...state.permissions, ...payload.business[0].permissions]
      state.hasDisabledUsers = payload.business[0].has_disabled_users
      state.urlRegistration = payload.business[0].registration_url
      state.hasDomain = payload.business[0].has_domains
      if (state.active_business === 0) {
        router.push({ name: 'organizations' })
      }
    },
    addUsers (state, payload) {
      state.users = [...state.users, ...payload]
    },
    resetUsers (state) {
      state.users = []
    },
    changeBusinesss (state, payload) {
      state.active_business = payload.business_id
      state.role = payload.role
      state.permissions = payload.permissions
      state.team_create_limit = payload.team_create_limit || 5 // Fijo a la espera de que llegue el parametro de back
      state.overdue_payment = payload.overdue_payment
      state.hasDisabledUsers = payload.has_disabled_users
      state.urlRegistration = payload.registration_url
      state.hasDomain = payload.has_domains
      window.dataLayer.push({ user_id: this.user.id })
      window.dataLayer.push({ user_email: this.user.email })
      window.dataLayer.push({ user_role: this.user.user_role })
      window.dataLayer.push({ active_plan: payload.active_plan })
      window.dataLayer.push({ business_kind: payload.business_kind })
      window.dataLayer.push({ business_type: payload.business_kind })
      window.dataLayer.push({ business_status: payload.business_stage })
      window.dataLayer.push({ business_id: payload.business_id })
      window.dataLayer.push({ business_name: payload.name })
      window.dataLayer.push({ business_number_of_employees: payload.business_number_of_employees })
      window.dataLayer.push({ business_number_of_students: payload.business_number_of_students })
      window.dataLayer.push({ business_number_of_seats: payload.business_number_of_seats })
      window.dataLayer.push({ business_arr: payload.business_arr })
      window.dataLayer.push({ business_seats_assigned_rate: payload.business_seats_assigned_rate })
      window.dataLayer.push({ business_seats_unassigned_rate: payload.business_seats_unassigned_rate })
      window.dataLayer.push({ business_seats_low_performance: payload.business_seats_low_performance })
      window.dataLayer.push({ business_number_of_pending_requests: payload.business_number_of_pending_requests })
      window.dataLayer.push({ staff_role: payload.role })
      router.push({ name: 'reset' })
    },
    successBusinessChange (state, payload) {
      state.business = [payload]
      state.active_business = payload.business_id
      state.role = payload.role
      state.permissions = payload.permissions
      state.campus_course_limit = payload.campus_course_limit
      state.team_create_limit = payload.team_create_limit || 5 // Fijo a la espera de que llegue el parametro de back
      state.overdue_payment = payload.overdue_payment
      state.hasDisabledUsers = payload.has_disabled_users
      state.urlRegistration = payload.registration_url
      state.hasDomain = payload.has_domains
      router.push({ name: 'reset' })
    },
    setDisabledUsers (state) {
      state.hasDisabledUsers = true
    },
    updateTeams (state, payload) {
      state.teams = payload
    },
    updateTags (state, payload) {
      state.tags = payload
    },
    updateLicensesState (state, payload) {
      state.licenses = payload
    },
    addToastMessage (state, payload) {
      state.toast_messages.push({ id: new Date().getTime(), ...payload })
    },
    removeToastMessage (state, index) {
      state.toast_messages.splice(state.toast_messages.findIndex((el) => el.id === index), 1)
    },
    updateAcademyFilters (state, payload) {
      state.academy_filters = payload
    },
    reminderEmail (state, payload) {
      state.reminderStatus = payload
    },
    updateRequests (state, payload) {
      state.total_requests = payload
    },
    changeStateDialogBox (state, payload) {
      state.dialogBox = payload
    },
    updateClonables (state, payload) {
      state.clonables = payload
    },
    updateFilesDownloading (state, payload) {
      state.files_downloading.push(payload)
    }
  },
  actions: {
    updateBusiness ({ commit }, new_business) {
      commit('changeBusinesss', new_business)
    },
    successUpdateBusiness ({ commit }, new_business) {
      commit('successBusinessChange', new_business)
    },
    async getUser ({ commit }) {
      await api.get('/jarvis/business/me/')
        .then(({ data }) => {
          commit('updateUser', data)
        })
    },
    async getTeams ({ commit, state }, params = { limit: 10000 }) {
      await api.get(`/jarvis/business/${state.active_business}/teams/`, { params })
        .then(({ data }) => {
          commit('updateTeams', data)
        })
        .catch(console.error)
    },
    async getTags ({ commit, state }) {
      await api.get(`/jarvis/business/${state.active_business}/tags/`)
        .then(({ data }) => {
          commit('updateTags', data)
        })
        .catch(console.error)
    },
    async getTotalLicenses ({ commit, state }) {
      await api.get(`/jarvis/business/${state.active_business}/licenses/`)
        .then(({ data }) => {
          commit('updateLicensesState', data)
          commit('updateRequests', data.count_requests)
        })
        .catch(console.error)
    },
    openDialogBox ({ commit }, { $refs, ref, dialog }) {
      $refs[ref].dialog('show', dialog)
    },
    openMainDialogBox ({ commit }, { dialog }) {
      commit('changeStateDialogBox', { open: true, dialog })
    },
    closeMainDialogBox ({ commit }) {
      commit('changeStateDialogBox', { open: false })
    },
    async getClonableSprints ({ commit, state }, teamId) {
      const { data } = await api.get(`/jarvis/business/${state.active_business}/planner/${teamId}/clonables/`)
      commit('updateClonables', data.sprints)
    },
    async sendReminder ({ commit, state }) {
      commit('reminderEmail', true)
      await api.post(`/jarvis/business/${state.active_business}/stats/remainder-user-register/`)
        .then(({ data }) => {
          commit('addToastMessage', {
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          commit('reminderEmail', false)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getFilters ({ commit, state }) {
      await api.get(`/jarvis/business/${state.active_business}/courses/filters`)
        .then(({ data }) => {
          commit('updateAcademyFilters', data)
        })
        .catch(() => {
          console.error('Problema al cargar los filtros')
        })
    },
    updateProgressWait ({ state, commit }, info_file) {
      commit('updateFilesDownloading', {
        id: info_file.id,
        name_file: info_file.name_file,
        message: (info_file.message) ? info_file.message : 'Descargando archivo',
        progress: 0,
        is_downloading: true
      })
    }
  },
  modules: {
  }
})
