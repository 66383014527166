<template>
 <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
    <div v-if="open" class="overlay">
      <div
      class="animation_default animate__animated animate__slideInUp"
      :class="class_custom">
      <button v-if="xclose" @click.prevent="open=false" type="button" class="absolute right-8 top-8">
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0.5L10 9.5" stroke="black"/>
          <path d="M10 0.5L1 9.5" stroke="black"/>
        </svg>
      </button>
        <slot>
          <figure class="flex justify-center mb-6">
            <svg width="96" height="85" viewBox="0 0 96 85" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42.8546 2.99999C45.164 -1.00001 50.9375 -1 53.2469 3L94.86 75.0761C97.1694 79.0761 94.2826 84.0761 89.6638 84.0761H6.43756C1.81876 84.0761 -1.06798 79.0761 1.24142 75.0761L42.8546 2.99999Z" fill="#F9C84C"/>
              <path d="M42.5118 25.9196C42.4026 22.9566 44.7752 20.4951 47.7402 20.4951C50.7086 20.4951 53.0825 22.9621 52.9683 25.9283L51.8666 54.5456C51.7808 56.7757 49.948 58.5393 47.7162 58.5393C45.4818 58.5393 43.6478 56.7716 43.5656 54.5387L42.5118 25.9196Z" fill="#494949"/>
              <circle cx="48" cy="66.0747" r="4" fill="#494949"/>
            </svg>
          </figure>
        </slot>
        <h2 class="text-xl font-bold text-grey-dark flex items-center justify-center gap-1.5">
          {{title}}
          <div v-if="pro" class="pill-pro">PRO</div>
        </h2>
        <p>{{text}}</p>
        <div class=" flex justify-center mt-5">
          <button v-if="!xclose" @click.prevent="open=false" type="button" class="btn-outline-default mr-1">Cancelar</button>
          <button @click.prevent="callback" :class="`btn-${buttonCSS} ml-1`">
            <UiLoadingIcon class="mr-3" v-if="loading" />
            {{action.text}}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UiLoadingIcon from './Layout/UI/UiLoadingIcon.vue'
export default {
  name: 'DialogBox',
  components: {
    UiLoadingIcon
  },
  props: {
    typeButton: {
      type: String,
      default: 'danger'
    },
    class_custom: {
      type: String,
      default: 'dialog'

    },
    pro: {
      type: Boolean,
      default: false
    },
    xclose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      loading: false,
      title: '¿Estás seguro de desvincular a este usuario de su equipo?',
      text: 'Esta acción no se puede revertir.',
      buttonCSS: 'danger',
      action: {
        text: 'Desvincular',
        func: () => { console.error('error...') }
      }
    }
  },
  methods: {
    async callback () {
      this.loading = true
      await this.action.func()
      this.loading = false
    },
    dialog (state, obj = null) {
      if (state === 'show') {
        this.open = true
        this.title = obj.title
        this.text = obj.text
        this.action = { text: obj.btn_text, func: obj.func }
        if (obj.type) {
          this.buttonCSS = obj.type
        } else {
          this.buttonCSS = this.typeButton
        }
      }
      if (state === 'hide') {
        this.open = false
      }
    }
  },
  onMounted () {
    this.buttonCSS = this.typeButton
  }
}
</script>

<style scoped>
  .overlay {
    animation-duration: .5s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .animation_default {
    animation-duration: .5s;
  }
  .dialog{
    @apply relative lg:w-5of12 2xl:w-4of12 rounded-lg bg-white mb-24 text-center p-10
  }
  .pill-pro {
  flex: none;
  border-radius: 50px;
  background: #F63D68;
  display: inline-flex;
  width: 35px;
  height: 16px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;

}
</style>
