<template>
<div>
  <section class="container mx-auto px-12 mt-14">

    <div class="flex mt-6">
      <div class="w-full">
      <UiBox>
        <div class="font-bold flex items-center text-primary-dark border-b border-[#ccc] pb-2">
          Configuración de la gestión de licencias
        </div>
        <div class="font-semibold text-primary-darker mt-3">Licencias sin uso </div>
        <div class="flex justify-between text-sm mt-3">
          Tiempo de inactividad para considerar una licencia sin uso
          <PopOverPro
              @send="send"
              :isAuthorized="permissions.includes('can_configure_assistant')"
              position="left">
              <template v-slot:popover>
              <button class="border border-primary-lighter rounded relative pl-2 pr-4">
                {{ (inactivityDays/7) }} semanas
                <svg class="absolute right-0.5 top-1.5 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#000"/>
                </svg>
              </button>
              </template>
              <select @change="updateAutoRotation" v-model="inactivityDays" class="border border-primary-lighter rounded">
                <option value="7">1 semanas</option>
                <option value="14">2 semanas</option>
                <option value="21">3 semanas</option>
                <option value="28">4 semanas</option>
              </select>
          </PopOverPro>
        </div>
        <div class="flex text-sm mt-6 justify-between items-center">
          <div class="flex">
          Envío de email de aviso al usuario tras<span class="mx-3">
            <PopOverPro
              @send="send"
              :isAuthorized="permissions.includes('can_configure_assistant')"
              position="right">
              <template v-slot:popover>
                <button class="border border-primary-lighter rounded relative pl-2 pr-4">
                  {{ (inactivityWeeksinDays/7) }} semanas
                  <svg class="absolute right-0.5 top-1.5 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#000"/>
                  </svg>
                </button>
              </template>
              <select @change="updateAutoRotation" v-model="inactivityWeeksinDays" class="border border-primary-lighter rounded">
                <option value="7">1 semanas</option>
                <option value="14">2 semanas</option>
                <option value="21">3 semanas</option>
                <option value="28">4 semanas</option>
              </select>
            </PopOverPro>
          </span>
           sin actividad
           </div>
           <div>
            <ToggleButton @click="isActive = !isActive, updateAutoRotation()" v-model="isActive" :loading="loading"/>
           </div>
        </div>
        <hr class="mt-6 border-grey-lightest">
        <div class="flex text-sm mt-6 justify-between items-center">
          <div class="flex text-base font-semibold text-primary-dark">
            <ToggleButton @click="activateAutoRotation" v-model="isAutomaticRotation" :loading="loading" class="mr-2"/> Gestión automática de solicitudes de licencia
          </div>
        </div>
        <div class="mt-4" :class="{'opacity-50 pointer-events-none': !isAutomaticRotation}">
          <div class="flex justify-between text-sm text">
            <span>
              Tiempo de espera antes de activar la licencia del usuario automáticamente
            </span>
            <PopOverPro
              @send="send"
              :isAuthorized="permissions.includes('can_configure_assistant')"
              position="left">
              <template v-slot:popover>
              <button class="border border-primary-lighter rounded relative pl-2 pr-4 min-w-[90px] text-left">
                  {{ rotationDays }} día {{ rotationDays > 1 ? 's' : '' }}
                  <svg class="absolute right-0.5 top-1.5 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#000"/>
                  </svg>
              </button>
              </template>
              <select v-model="rotationDays" @change="updateAutoRotation" class="border border-primary-lighter rounded">
                <option value="0">Inmediato</option>
                <option value="1">1 día</option>
                <option value="2">2 días</option>
                <option value="3">3 días</option>
                <option value="4">4 días</option>
                <option value="5">5 días</option>
                <option value="6">6 días</option>
                <option value="7">7 días</option>
              </select>
            </PopOverPro>
          </div>
          <div class="flex justify-between items-center mt-4 text-sm">
            <div>Utilizar las siguientes licencias del equipo para la activación automática</div>
            <div class="flex gap-4">
              <div class="flex items-center">
                <input class="mr-1" @change="() => withoutActivity = true" v-model="withoutActivity" type="checkbox"> Licencias sin activar
              </div>
              <div>
            <input class="mr-1" @change="updateAutoRotation" v-model="withoutUse" type="checkbox"> Licencias sin uso
              </div>
            </div>
          </div>
        </div>
      </UiBox>
      </div>
    </div>
  </section>
  </div>
  <DialogBox ref="user-dialog" />
  <SendOK class="z-50" v-if="emailSendOK" @close="() => emailSendOK = false" :message="'En breve, nuestro equipo de Customer Success se pondrá en contacto contigo para ofrecerte más información.'"></SendOK>
  <AccessDenied :permission="'can_see_assistant_settings'" />
</template>

<script setup>
import DialogBox from '@/components/DialogBox.vue'
import UiBox from '@/components/Layout/UI/UiBox.vue'
import PopOverPro from './components/PopoverPro.vue'
import ToggleButton from '../../components/ToggleButton.vue'
import SendOK from '../Landings/components/SendOK.vue'
import AccessDenied from '@/components/AccessDenied.vue'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/api/instances'

const store = useStore()
const permissions = computed(() => store.state.permissions)
const user = computed(() => store.state.user)
const active_business = computed(() => store.state.active_business)
const addToastMessage = (data) => store.commit('addToastMessage', data)

const inactivityWeeksinDays = ref(14)
const inactivityDays = ref(7)
const isActive = ref(true)

const emailSendOK = ref(false)
const isAutomaticRotation = ref(false)
const withoutActivity = ref(true)
const withoutUse = ref(false)
const rotationDays = ref(1)
const activateAutoRotation = () => {
  api.patch(`/jarvis/business/${active_business.value}/assistant/settings/`,
    {
      automatic_license_management: !isAutomaticRotation.value
    })
    .then(({ data }) => {
      isAutomaticRotation.value = data.automatic_license_management
      addToastMessage({
        status: 'success',
        title: 'Completado',
        message: (isAutomaticRotation.value)
          ? '¡Se activó la gestión automática de licencias!'
          : '¡Se desactivó la gestión automática de licencias!'
      })
    })
    .catch(() => {
      addToastMessage({
        title: '¡Error!',
        status: 'danger',
        message: 'Ha habido un problema al actualizar la configuración.'
      })
      console.error('Ha habido un problema al actualizar la configuración.')
    })
}
const updateAutoRotation = () => {
  api.patch(`/jarvis/business/${active_business.value}/assistant/settings/`,
    {
      inactivity_days: inactivityDays.value,
      send_warning_email_days: inactivityWeeksinDays.value,
      automatic_license_management: isAutomaticRotation.value,
      enable_free_licenses: withoutActivity.value,
      rotate_unused_licenses: withoutUse.value,
      delay_activation_days: rotationDays.value,
      send_warning_email: isActive.value
    })
    .then(({ data }) => {
      console.log('Configuración actualizada correctamente.')
      isAutomaticRotation.value = data.automatic_license_management
      addToastMessage({
        status: 'success',
        title: 'Completado',
        message: 'Configuración actualizada correctamente.'
      })
    })
    .catch(() => {
      console.error('Ha habido un problema al actualizar la configuración.')
      addToastMessage({
        title: '¡Error!',
        status: 'danger',
        message: 'Ha habido un problema al actualizar la configuración.'
      })
    })
}
const send = () => {
  api.post(`/jarvis/business/${active_business.value}/stats/send-email/`, {
    subject: 'Solicitud de información de Asistente PRO desde el Panel',
    message: `El usuario ${user.value.first_name} (${user.value.email}) de la empresa ${user.value.business[0].name} ha solicitado información de las opciones PRO del asistente del panel de control.`
  })
    .then(() => {
      console.log('Mensaje enviado correctamente.')
      emailSendOK.value = true
    })
    .catch(() => {
      console.error('Ha habido un problema al enviar el mensaje.')
    })
}
onMounted(() => {
  api.get(`/jarvis/business/${active_business.value}/assistant/settings/`)
    .then(({ data }) => {
      inactivityWeeksinDays.value = data.send_warning_email_days
      inactivityDays.value = data.inactivity_days
      isActive.value = data.send_warning_email
      isAutomaticRotation.value = data.automatic_license_management
      withoutActivity.value = data.enable_free_licenses
      withoutUse.value = data.rotate_unused_licenses
      rotationDays.value = data.delay_activation_days
    })
    .catch(() => {
      console.error('Ha habido un problema al cargar la configuración.')
    })
})
</script>

<style scoped>
.managed-team_list:not(:last-child):not(:first-child)::after {
  content: ',';
  display: inline-block;
  margin-right: 5px;
}

.managed-team_list:last-child::before {
  content: 'y';
  display: inline-block;
  margin-right: 5px;
}

.massive-action-btn {
  @apply w-8 h-8 flex justify-center items-center mx-4 transition-all duration-300;
  /* @apply w-8 h-8 flex justify-center items-center mx-4 hover:bg-litepie-primary-400 rounded-lg transition-all duration-300;*/
}
.max-modal-scroll {
  @apply max-h-[65vh] overflow-y-scroll;
}
</style>
