<template>
<div class="block relative" v-click-outside="hide">

  <div class="flex items-center" >
    <button type="button" @click.prevent="open = !open" class="flex items-center whitespace-nowrap w-full bg-grey-lightest py-2 pl-4 pr-9 rounded">
      <ul v-if="selected_teams.length > 0" class="flex flex-wrap w-full mx-2">
        <li class="pm-1 tag_list truncate" v-for="team in selected_teams" :key="team.id" >
          <button type="button" class="truncate px-2 py-1 rounded bg-primary-light relative flex mr-2 mb-2" @click.stop="team.checked = false">
          <span class="text-xs flex mr-2   ">
            {{team.name}}
          </span>
            <svg  class="absolute top-2 right-1 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </button>
        </li>
      </ul>
      <span v-else class="text-xs text-grey-light">
        Selecciona una o varias equipos...
      </span>

      <span class="text-xs w-5 rounded bg-grey-lighter ml-1 mb-2" :class="{'opacity-0': selected_teams.length <= 0}">{{selected_teams.length}}</span>

    </button>
    <svg class="relative -left-5" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.35355 1.60355L8.70711 1.25L8 0.542893L7.64645 0.896447L8.35355 1.60355ZM4.5 4.75L4.14645 5.10355L4.5 5.45711L4.85355 5.10355L4.5 4.75ZM1.35355 0.896446L1 0.542893L0.292893 1.25L0.646447 1.60355L1.35355 0.896446ZM7.64645 0.896447L4.14645 4.39645L4.85355 5.10355L8.35355 1.60355L7.64645 0.896447ZM4.85355 4.39645L1.35355 0.896446L0.646447 1.60355L4.14645 5.10355L4.85355 4.39645Z" fill="#404040"/>
    </svg>
  </div>

  <div class="absolute z-20 bg-grey-lightest w-full mt-1 " :class="{'hidden': !open}">
    <hr class="border-grey-lightest">

    <div class="relative px-2 pb-2">
      <input @keydown.enter.prevent="addNewTeam(search), search = ''" v-model="search" class="pl-3 py-1 box-border text-sm outline-none w-full" type="text" placeholder="Filtrar o añadir">
      <button type="button" @click.prevent="addNewTeam(search), search = ''" v-if="filtered_teams.length == 0" class="bg-grey-light px-1 py-0.5 rounded absolute right-5 top-2 text-xs">Añadir</button>
    </div>

    <hr class="border-grey-lighter mb-1">
    <ul class="tags-list truncate overflow-y-scroll custom-scroll">
      <li class="pr-1 py-1 flex truncate " v-for="team in filtered_teams" :key="team.id">
        <button type="button" @click.prevent="team.checked = !team.checked" class="flex truncate cursor-pointer ml-1" :for="team.id">
          <span class="truncate">
            {{team.name}}
          </span>
        </button>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { api } from '../api/instances'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MultiSelectTeam',
  components: {
  },
  emits: ['change', 'update:modelValue'],
  props: {
    custom: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      teams: [],
      team_id: '',
      search: ''
    }
  },
  watch: {
    list () {
      this.addTeams()
    }
  },
  computed: {
    ...mapState(['active_business']),
    filtered_teams () {
      return this.teams.filter((el) => {
        return el.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) ? el : ''
      })
    },
    selected_teams () {
      return this.teams.filter((el) => {
        return el.checked === true
      })
    }
  },
  methods: {
    ...mapActions(['getTags']),
    hide () {
      this.open = false
      this.search = ''
      this.update()
    },
    async getTeams () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/teams/`, { params: { limit: 10000 } })
        .then(({ data }) => {
          this.teams = data.results
          this.loading = false
        })
        .catch(console.error)
    },
    update () {
      this.$emit('update:modelValue', this.selected_teams.map((el) => el.id))
      this.$emit('change')
    },
    addNewTeam (team) {
      if (this.teams.findIndex((el) => el.name.toLocaleLowerCase() === team.toLocaleLowerCase()) < 0) {
        this.teams.push(
          {
            id: null,
            name: team,
            checked: true
          }
        )
      }
      return null
    },
    addTeams () {
      this.custom.forEach(team => {
        const index = this.teams.findIndex((el) => el.name === team)
        this.teams[index].checked = true
      })
    }
  },
  async created () {
    await this.getTeams()
    this.addTeams()
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
  .tags-list {
    max-height: 150px;
    @apply bg-grey-lightest shadow w-full z-10 overflow-hidden overflow-y-scroll flex flex-wrap
  }
</style>
