<template>
  <UiPageBody>
    <UiTableFilters>
      <div class="flex-none w-2/3 flex">
        <div class="col-auto px-1 justify-center xl:w-38 lg:w-38">
          <SearchField v-model="search" @change="getItineraries" :placeholder="'Buscar ruta'" />
        </div>
      </div>
    </UiTableFilters>
    <UiTable>
      <UiTableHeader>
        <UiTableCell first class="w-4/12">
          <TableSorter
            :loading="loading"
            filter="student__user__first_name"
            v-model="activeFilter"
            @update="getItineraries()"
          >
            Nombre
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-2/12">Formaciones</UiTableCell>
        <UiTableCell center class="w-2/12">Duración</UiTableCell>
        <UiTableCell center class="w-2/12">Usuarios</UiTableCell>
        <UiTableCell center class="2xl:w-3/12 xl:w-4/12 ">
          <TableSorter
            class="m-auto"
            :loading="loading"
            filter="created_at"
            v-model="activeFilter"
            @update="getItineraries()">
            Fecha de creación
          </TableSorter>
        </UiTableCell>
        <UiTableCell  class="2xl:w-3/12 xl:w-2/12">Creado por</UiTableCell>
        <UiTableCell last class="w-10"></UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="first_loading && itineraries.length == 0"/>
      <UiTableRow
       class="custom-table-body flex flex-no" v-for="{name, id, courses_count, created_at, duration_in_hours, enrolled_count, can_edit, creator } in itineraries" :key="id">
          <UiTableCell first class=" flex-auto w-4/12 items-center">
            <router-link class="hover:underline" :title="name" :to="{ name: 'step-1', params: {id_itinerary: id, name: name} }">
              {{name}}
            </router-link>
          </UiTableCell>
          <UiTableCell center class="flex-auto w-2/12 text-sm">{{courses_count}}</UiTableCell>
          <UiTableCell center class="w-2/12 text-sm">{{duration_in_hours}} horas</UiTableCell>
          <UiTableCell center class="flex-auto w-2/12 text-sm">
            {{enrolled_count}}
          </UiTableCell>
          <UiTableCell center class="2xl:w-3/12 xl:w-4/12  text-sm flex"> {{$filters.shortDate(created_at)}}</UiTableCell>
          <UiTableCell class="flex-auto 2xl:w-3/12 xl:w-2/12 text-sm">
            {{creator.first_name}} {{creator.last_name}}
          </UiTableCell>
          <UiTableCell last center class="w-10">
            <MenuEllipsis
              class="relative -top-1"
              v-if="can_edit"
              :items="[
                {type: 'default', permission: (can_edit) ? 'all' : '', icon: 'edit', text: 'Editar', border: false, func: () => {
                    $router.push({name: 'step-1', params: {id_itinerary: id, name: name} })
                  }
                },
                {type: 'danger', permission: (can_edit) ? 'all' : '', icon: 'trash', text: 'Eliminar', border: true,  func: () => {
                    openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                        title: '¿Quieres eliminar esta ruta?',
                        text: 'La ruta será borrada de manera permanente.',
                        btn_text: 'Eliminar',
                        func: () => { deleteItinerary(id) }
                      }
                    })
                  }
                }
              ]"
            />
          </UiTableCell>
      </UiTableRow>
      <NotFoundSearch :status="status"/>
    </UiTable>
  </UiPageBody>
  <TableFooter v-if="!status" class="pl-12" :pages="pages" v-model="paginator" @change="getItineraries(this.paginator.page)" v-cloak/>
  <HistoricalModal ref="historical"/>
  <DialogBox ref="user-dialog" />
</template>

<script>
import DialogBox from '../../components/DialogBox.vue'
import SearchField from '../../components/SearchField.vue'
import MenuEllipsis from '../../components/MenuEllipsis/Menu.vue'
import NotFoundSearch from '../../components/NotFoundSearch.vue'
import TableFooter from '../../components/TableFooter.vue'
import HistoricalModal from '../../components/Modals/HistoricalModal.vue'
import TableSorter from '../../components/TableSorter.vue'
import { api } from '../../api/instances'
import { mapState, mapMutations, mapActions } from 'vuex'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import UiTableFilters from '../../components/Layout/UI/Table/UiTableFilters.vue'
import UiTable from '../../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableSkeleton from '../../components/Layout/UI/Table/UiTableSkeleton.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'

export default {
  name: 'Itinerary-drafts',
  components: {
    DialogBox,
    SearchField,
    TableFooter,
    HistoricalModal,
    TableSorter,
    NotFoundSearch,
    MenuEllipsis,
    UiPageBody,
    UiTableFilters,
    UiTable,
    UiTableHeader,
    UiTableCell,
    UiTableSkeleton,
    UiTableRow
  },
  data () {
    return {
      itineraries: [],
      search: '',
      team_id: '',
      pages: {},
      status: false,
      disabled: false,
      loading: false,
      first_loading: true,
      activeFilter: { filter: 'created_at', direction: false },
      paginator: {
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapActions(['openDialogBox']),
    ...mapMutations(['addToastMessage']),
    async getItineraries (pag = 1) {
      await api.get(`/jarvis/business/${this.active_business}/itineraries/`,
        {
          params: {
            limit: this.paginator.limit,
            page: pag,
            student__department_id: this.team_id,
            role: this.role_id,
            search: this.search,
            draft: true,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          if (data.count === 0) {
            this.status = true
          } else {
            this.status = false
          }
          this.itineraries = data.results
          this.pages = data.paginator
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
    },
    async deleteItinerary (id_itinerary) {
      await api.delete(`/jarvis/business/${this.active_business}/itineraries/${id_itinerary}/`)
        .then(({ data }) => {
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'Se ha eliminado con éxito'
          })
          this.getItineraries()
        })
        .catch(() => {
          console.error('Ha habido un problema para borrar la ruta')
        })
      this.$refs['user-dialog'].dialog('hide')
    }
  },
  created () {
    this.getItineraries()
  }
}
</script>

<style scoped>
li, nav div {
  /* border: 1px solid red; */
}
</style>
