<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold">{{title}}</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <form @submit.prevent="(validateForm())? formAction() : null" id="create-edit-user">
      <section class="pt-4">
        <nav class="px-12">
          <ul class="flex border-b border-grey-lighter">
            <li class="flex-none text-xl text-primary font-semibold border-b-2 border-primary pb-4 pr-1">Datos del equipo</li>
          </ul>
        </nav>
        <article class="px-12 py-3">
            <fieldset>
              <div class="mt-2 flex w-full">
                <label v-if="!team.default" class="flex flex-col w-1/2 mr-2" for="team">
                  <strong class="font-medium">Nombre del equipo*</strong>
                  <input id="team" autofocus required v-model="team.name" :class="{'error': errors.name}"
                    class="p-3 mt-2 rounded-lg bg-grey-lightest" placeholder="Introduce el nombre del equipo" type="text">
                  <div v-if="errors.name" class="mt-1 text-xs text-danger"><strong>Error:</strong> Para continuar con el alta del equipo debes añadir un nombre.</div>
                </label>
                <label class="flex flex-col w-1/2 ml-2" for="search">
                  <strong class="font-medium">Añadir gestor*</strong>
                  <SearchUserField :not_reset="true" :class="{'error': errors.managers}" v-model="team.managers" />
                  <div v-if="errors.managers" class="text-xs text-danger mt-1">
                    <strong>Error:</strong>
                    Para continuar con el alta del equipo debes añadir, al menos, un gestor.</div>
                </label>
              </div>
            </fieldset>
        </article>
      </section>
      <section class="pt-4">
        <nav class="px-12">
          <ul class="flex border-b border-grey-lighter ">
            <li class="flex-none text-xl font-semibold  pb-4 pr-1">Gestores del Equipo</li>
          </ul>
        </nav>
        <article class="px-12 py-3">
          <fieldset class="h-36 pb-5 rounded">
            <div class="mt-2 flex w-full">
              <ul class="flex flex-wrap w-full relative overflow-hidden overflow-y-auto h-32 custom-scroll">
                <li v-for="manager in team.managers" :key="manager.employee_id" class="flex w-1/2 px-2 py-1 relative">
                  <div class="bg-grey-lightest rounded p-2 border-white w-full h-14">
                    <UserIdentity :user="manager" :text="'dark'" />
                    <button @click.prevent="removeManager(manager.employee_id)" type="button" class="flex mr-2 absolute right-2 top-3">
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.56065 6.80333L4.12131 4.24268M4.12131 4.24268L6.68196 1.68202M4.12131 4.24268L1.56065 1.68202M4.12131 4.24268L6.68196 6.80333" stroke="#9B9B9B" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </fieldset>
        </article>
      </section>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <button type="button" @click.prevent="changeState(false)" class="btn-outline-default mr-3">Cancelar</button>
        <button @click="validateForm" class="btn-secondary">Guardar cambios</button>
      </footer>
      </form>
    </div>
  </div>
  </transition>
</template>

<script>
import SearchUserField from '../SearchUserField.vue'
import UserIdentity from '../UserIdentity.vue'
import { api } from '../../api/instances'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AddUserModal',
  components: {
    SearchUserField,
    UserIdentity
  },
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      title: 'Crear equipo',
      action: 'create',
      open: false,
      team: {
        id: null,
        name: '',
        managers: [],
        default: false
      },
      errors: {
        name: false,
        managers: false
      }
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    formAction () {
      if (this.action === 'edit') {
        this.editTeam()
      } else {
        this.createTeam()
      }
    },
    async modal (action, obj = null) {
      this.resetFileds()
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
        this.autoFocus()
        if (obj && obj.action === 'edit') {
          this.action = 'edit'
          this.title = 'Editar equipo'
          this.team.name = obj.name
          this.team.default = obj.default
          this.team.id = obj.id_team
          this.team.managers = await this.getTeamManagers(obj.id_team)
        } else {
          this.action = 'create'
        }
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getTeamManagers (id_team) {
      try {
        const { data } = await api.get(`/jarvis/business/${this.active_business}/teams/${id_team}/managers/`)
        return data
      } catch (err) {
        console.error('Se ha producido un error al cargar los datos del usuario')
      }
    },
    async createTeam () {
      try {
        await api.post(`/jarvis/business/${this.active_business}/teams/`,
          {
            name: this.team.name,
            managers: this.team.managers.map(el => el.employee_id)
          })
        this.$emit('success')
        this.changeState(false)
        this.addToastMessage({
          status: 'success',
          title: 'Completado',
          message: '¡El equipo fue creado con éxito!'
        })
      } catch (err) {
        this.$emit('error')
        console.error('Error en alta del equipo:', err)
        if (err.response) {
          const message = (err.response.data.email) ? err.response.data.email : '¡Se ha producido un error!'
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message
          })
        } else {
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message: '¡Se ha producido un error!'
          })
        }
      }
    },
    async editTeam () {
      try {
        await api.patch(`/jarvis/business/${this.active_business}/teams/${this.team.id}/`,
          {
            name: this.team.name,
            managers: this.team.managers.map(el => el.employee_id)
          })
        this.$emit('success')
        this.changeState(false)
        this.addToastMessage({
          status: 'success',
          title: 'Completado',
          message: '¡El equipo fue actualizado con éxito!'
        })
      } catch (err) {
        this.$emit('error')
        console.error('Error en editar el equipo:', err)
        if (err.response) {
          const message = (err.response.data.email) ? err.response.data.email : '¡Se ha producido un error!'
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message
          })
        } else {
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message: '¡Se ha producido un error!'
          })
        }
      }
    },
    removeManager (employee_id) {
      const index = this.team.managers.findIndex((el) => el.employee_id === employee_id)
      this.team.managers.splice(index, 1)
    },
    validateForm () {
      this.errors.name = (this.team.name === '')
      this.errors.managers = (this.team.managers.length === 0)

      return (!this.errors.name && !this.errors.managers)
    },
    resetFileds () {
      this.title = 'Crear equipo'
      this.team = {
        name: '',
        managers: [],
        id: null
      }
      this.action = 'create'
      this.errors = {
        name: false,
        managers: false
      }
    },
    autoFocus () {
      setTimeout(() => {
        document.querySelector('#create-edit-user input[autofocus]').focus()
      }, 100)
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white
  }
</style>
