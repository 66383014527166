<template>
 <div class="flex items-center relative rounded"
 :class="[(value !== '' && filter == true) ? 'border-primary' : 'border-primary-lighter', border ? 'border-2' : '', disabled ? 'disabled' : '']">
    <select
      :id="id" :data-ph="isPosthog ? 'dashboardFilter' : ''"  :required="required" @change="update" v-model="value" :disabled="disabled"
      :class="`w-full ${padding} pr-8 rounded outline-none ${bgClass} ${ disabled ? 'disabled' : ''}`" name="role">
      <slot></slot>
    </select>
    <svg v-if="value === '' || filter == false" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
    </svg>
    <svg @click="reset" v-if="value !== '' && filter == true" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'UiCustomSelect',
  props: {
    modelValue: [String, Number, Object, Boolean],
    id: String,
    required: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: 'py-2 pl-3'
    },
    bgClass: {
      type: String,
      default: 'bg-grey-lighter-2'
    },
    filter: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPosthog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      value: ''
    }
  },
  methods: {
    update () {
      this.$emit('update:modelValue', this.value)
      this.$emit('change')
    },
    reset () {
      this.value = ''
      this.update()
    }
  },
  watch: {
    modelValue () {
      this.value = this.modelValue
    }
  },
  created () {
    this.value = this.modelValue
  }
}
</script>

<style scoped>
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
  }
  .pe-none{
    pointer-events: none;
  }
  .disabled {
    opacity: .6;
  }
</style>
