<template>
<div>
  <UiHeader :title="'Usuarios'" slotCol :titleChild="`${this.$route.params.first_name || user.first_name} ${this.$route.params.last_name || user.last_name}`">
    <div class="pl-12 pb-6">
      <UiButton :size="'btn-sm'" :type="'default'" @click="$router.push({name: 'users'})">
        <SVGIcons class="svg-14 mr-4" :icon="'back-arrow'" />
        Volver al listado
      </UiButton>
    </div>
  </UiHeader>
  <section class="container mx-auto px-12 mt-14">
    <div class="flex">
      <div class="w-5/12 relative bg-white shadow-lg p-5 pt-10 rounded-lg mr-3">
      <Visibility :permission="'can_edit_user'">
        <button type="button"
          v-if="!user.is_disabled"
          @click="$refs['editusuario'].modal('show', user )"
          class=" absolute right-6 top-4 text-xs font-semibold text-primary">
          Editar</button>
      </Visibility>
        <UiBox :shadow="false" rounded :bgClass="'bg-grey-lightest'">
          <div class="flex">
            <a @click.prevent="() => false" v-if="user.login_as_url" :href="`https://openwebinars.net${user.login_as_url}`">
              <UserIdentity :user="user" class="w-1/3" dark xl show_cid />
            </a>
            <UserIdentity v-else :user="user" class="w-1/3" dark xl show_cid />
          </div>
          <hr class="my-4 border-grey-lighter">
          <dl class="flex justify-between my-2 mx-2 text-sm">
            <dt>Última conexión</dt>
            <dd class="font-bold text-grey-dark">{{$filters.shortDate(user.last_login)}}</dd>
          </dl>
          <dl class="flex justify-between mt-2 mx-2 text-sm">
            <dt>Última formación</dt>
            <dd class="font-bold text-grey-dark">{{$filters.shortDate(user.last_learning)}}</dd>
          </dl>
        </UiBox>
        <h2 class="mt-4 font-semibold text-grey-dark">Equipo</h2>
        <UiBox :shadow="false" rounded :bgClass="'bg-grey-lightest'">
          {{user.team_name}}
        </UiBox>
        <div v-if="user.is_disabled">
          <h2 class="mt-4 font-semibold text-grey-dark">Estado</h2>
          <div class="bg-grey-lightest p-3 rounded-lg mt-1 text-sm text-center">
            <div class="m-2">
              <div class="font-medium text-danger text-lg">Este usuario fue dado de baja el {{ $filters.shortDate(user.disabled_at) }}</div>
              Si deseas volver a activar este usuario, haz click en el botón.
            </div>
            <div class=" mt-4 p-2 justify-center flex">
              <UiButton @click="() => openDialogBox({
                            $refs, ref: 'user-dialog',
                             dialog: {
                              title: '¿Seguro que quieres reactivar a esta usuario?',
                              text: 'Esta usuario volverá a tener acceso a la plataforma y volverán a formar parte de tu organización',
                              btn_text: 'Reactivar usuario',
                              type: 'primary',
                              func: () => {
                                enableUser(employee_id)
                              }
                            }
                          })" :type="'secondary'">Reactivar</UiButton>
            </div>
          </div>
        </div>
        <div v-else>
          <h2 class="mt-4 font-semibold text-grey-dark">Licencia</h2>
          <div class="bg-grey-lightest p-3 rounded-lg mt-1 text-sm">
            <div class="flex justify-between mx-2">
              {{(user.is_active) ? 'Activa' : 'Inactiva'}}
              <ToggleLicenseState @change="getUserDetails" :user="{employee_id: user.employee_id, is_active: user.is_active, is_available: user.is_available, has_planning: user.has_planning}" />
            </div>
            <div class="flex justify-between mx-2 mt-4">
             Acceso catálogo
              <ToggleFilteredState :user="{employee_id: user.employee_id, is_filtered: user.is_filtered}" />
            </div>
            <hr class="my-4 border-grey-lighter">
            <div class="flex justify-between mx-2 mt-4">
              <span>Número de asignaciones de licencia</span>
              <strong>{{ user.total_assigned_licenses }}</strong>
            </div>
            <div class="flex justify-between mx-2 mt-4">
              <span>Última activación</span>
              <strong>{{ $filters.shortDate(user.last_activation) }}</strong>
            </div>
          </div>
        </div>
        <h2 class="mt-4 font-semibold text-grey-dark">Etiquetas</h2>
        <div class="bg-grey-lightest p-3 rounded-lg mt-1 text-sm">
          <div class="h-12 overflow-y-scroll custom-scroll">
            <TagsList :limit="1000" class="flex-wrap" :list="user.custom_tags" />
          </div>
        </div>
      </div>
      <div class="w-7/12 relative bg-white shadow-lg p-5 rounded-lg ml-3">
        <div class="mt-4">
          <h2 class="font-semibold text-lg">Reportes del usuario</h2>
          <p class=" text-grey-light text-xs">Uso de la plataforma en el último año.</p>
          <hr class="mb-6 mt-2 border-grey-lighter">
          <div class="flex-1 bg-grey-lightest p-3 rounded-lg mt-1 text-sm mb-4">
            <div class="h-">
              <div class="ml-[22px]">
                <h2 class="font-semibold text-base text-primary-dark">Horas de dedicación</h2>
                <p class="text-xs text-grey-light mb-3">Horas de dedicación del usuario en la plataforma.</p>
              </div>
              <BarChart />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex w-full mb-3">
              <div class="w-1/2 mr-2">
                <ValueBox class="py-5 w-full bg-grey-lightest mr-4" :title="'Matrículas'" :value="progress.enrolls" />
              </div>
              <div class="w-1/2 ml-2">
                <ValueBox class="py-5 w-full bg-grey-lightest mr-4" :title="'Certificados obtenidos'" :value="progress.certificates" />
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2 mr-2">
                <ValueBox class="py-5 w-full bg-grey-lightest mr-4" :title="'Horas de dedicación'" :value="progress.hours" />
              </div>
              <div class="w-1/2 ml-2">
                <ValueBox class="py-5 w-full bg-grey-lightest mr-4" :title="'Horas de conexión'" :value="progress.connected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="mt-12 bg-white shadow-lg p-5 pt-10 rounded-lg mb-12">
    <nav class="flex mb-4 pl-4">
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'user-progress'}">Formaciones
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.courses || 0}}</span>
        </router-link>
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'user-paths'}">Rutas corporativas
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.itineraries || 0}}</span>
        </router-link>
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'user-careers'}">Rutas OW
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.careers || 0}}</span>
        </router-link>
    </nav>
    <hr class="border-grey-lightest">
    <router-view />
  </div>
  </section>
  <EditUserModal ref="editusuario" @success="getUserDetails" @disable="backToUserList" @delete="backToUserList" />
  </div>
  <DialogBox ref="user-dialog" />
</template>

<script>
import EditUserModal from '../../components/Modals/EditUserModal.vue'
import BarChart from '../../components/Charts/BarChart.vue'
import TagsList from '../../components/TagsList.vue'
import ToggleFilteredState from '../../components/ToggleFilteredState.vue'
import ToggleLicenseState from '../../components/ToggleLicenseState.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import UiButton from '../../components/Layout/UI/UiButton.vue'
import UserIdentity from '../../components/UserIdentity.vue'
import ValueBox from '../../components/Layout/ValueBox.vue'
import Visibility from '../../components/Visibility.vue'
import UiBox from '../../components/Layout/UI/UiBox.vue'
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import { api } from '../../api/instances'
import DialogBox from '@/components/DialogBox.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'UserDetail',
  components: {
    EditUserModal,
    BarChart,
    TagsList,
    ToggleFilteredState,
    ToggleLicenseState,
    SVGIcons,
    UiButton,
    UserIdentity,
    ValueBox,
    Visibility,
    UiHeader,
    UiBox,
    DialogBox
  },
  data () {
    return {
      user: {},
      progress: {},
      tabs: {}
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapActions(['openDialogBox']),
    ...mapMutations(['addToastMessage']),
    async getUserDetails () {
      try {
        const { data } = await api.get(`/jarvis/business/${this.active_business}/users/${this.$route.params.employee_id}/`)
        this.user = data
        this.getTabs()
        this.getUserProgress()
      } catch (err) {
        console.error('Se ha producido un error al cargar los datos del usuario')
      }
    },
    async enableUser (employee_id) {
      try {
        await api.patch(`/jarvis/business/${this.active_business}/users/${this.$route.params.employee_id}/enable/`)
        this.addToastMessage({
          status: 'success',
          title: 'Usuario habilitado',
          message: 'Vuelve a tener acceso a la plataforma.'
        })
        this.getUserDetails()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al habilitar al usuario.'
        })
        console.error('Error al habilitar al usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
    },
    getTabs () {
      api.get(`/jarvis/progress/users/${this.$route.params.employee_id}/counters/`)
        .then(({ data }) => {
          this.tabs = data
        })
    },
    getUserProgress () {
      api.get(`/jarvis/business/${this.active_business}/users/${this.$route.params.employee_id}/counters/`)
        .then(({ data }) => {
          this.progress = data
        })
    },
    backToUserList () {
      this.$router.push({ name: 'users' })
    }
  },
  created () {
    this.getUserDetails()
  }
}
</script>

<style>

</style>
