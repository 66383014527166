<template>
  <span><!-- Redirect --></span>
</template>
<script>
export default {
  name: 'Reset',
  created () {
    this.$router.push({ name: 'home' })
  }
}
</script>
