<template>
<RouterParent :name="'users'">
  <UiHeader :title="'Usuarios'">
  </UiHeader>
  <section class="container mx-auto pl-12 mt-9">
    <UiTableFilters>
      <div class="flex-auto w-9/12 flex">
        <div class="w-3/6">
          <SearchField v-model="filters.users.search" @change="getUsers" :placeholder="'Buscar por nombre, email o ID'" />
        </div>
        <div class="flex items-end">
          <EmailsFilter @change="getUsers" v-model="emails" />
        </div>
      </div>
      <div class="flex h-10">
          <Visibility class="flex" :permission="'can_create_users'">
              <UiButton :size="'btn-sm'" class="mr-2" @click="$refs['altausuario'].modal('show')" :type="'secondary'">
                  Añadir usuario
                <SVGIcons class="svg-14 ml-2" icon="plus"/>
              </UiButton>
              <TooltipBtn :direction_arrow="'top'">
                <template v-slot:title>
                  <UiButton  @click="$refs['altamasivausuario'].modal('show')" :type="'outline-secondary-light'">
                      <SVGIcons class="svg-16" icon="team"/>
                      <SVGIcons class="svg-10 mb-3" icon="plus-primary"/>
                  </UiButton>
                </template>
                <li>
                  <p>Añadir usuarios Excel</p>
                </li>
              </TooltipBtn>
          </Visibility>
    </div>
    </UiTableFilters>
    <UiMoreFilters>
      <div class="col-auto px-1">
        <SelectTeam @change="getUsers" v-model="filters.users.team_id" :bg-class="'bg-white'" />
        </div>
        <div class="col-auto px-1">
          <TagsField  @change="getUsers" v-model="filters.users.tags" :bg-class="'bg-white'"/>
        </div>
        <div class="col-auto px-1">
          <SelectRole @change="getUsers" v-model="filters.users.role_id" :bg-class="'bg-white'" />
        </div>
        <div class="col-auto px-1">
          <UiCustomSelect class="w-full" @change="getUsers" v-model="filters.users.is_active" name="is_active" :bg-class="'bg-white'">
            <option value="">Licencias</option>
            <option :value="true">Activa</option>
            <option :value="false">Inactiva</option>
          </UiCustomSelect>
        </div>
        <div>
          <ToggleDisabledUser v-model="disabledUsers" @change="getUsers"/>
        </div>
    </UiMoreFilters>
    <UiTable class="2xl:text-base xl:text-sm">
      <UiTableHeader v-if="[...selected_users].length === 0">
        <UiTableCell class="w-16 pl-6">
            <UiCheck v-if="!disabledUsers" @click="toggleSelecterUsers" :state="[...selected_users].length > 0"></UiCheck>
        </UiTableCell>
        <UiTableCell class="w-4/12">
          <TableSorter
            :loading="loading"
            filter="user__first_name"
            v-model="filters.users.activeFilter"
            @update="getUsers()"
          >
            Nombre
          </TableSorter>
        </UiTableCell>
        <UiTableCell class="w-1/12" center>Licencia</UiTableCell>
        <UiTableCell class="w-2/12">
        <TableSorter
            :loading="loading"
            filter="department__name"
            v-model="filters.users.activeFilter"
            @update="getUsers()"
          >
            Equipo
          </TableSorter>
        </UiTableCell>
        <UiTableCell class="flex-auto 2xl:w-4/12 xl:w-3/12 2xl:pl-0 xl:pl-5">Etiquetas</UiTableCell>
        <UiTableCell class="w-10"></UiTableCell>
      </UiTableHeader>
      <nav v-else class="flex mt-2 mb-3 rounded font-medium text-grey" :class="{ 'bg-primary': [...selected_users].length > 0 }">
        <UiTableCell class="w-16 pl-6 pr-6">
            <button @click="toggleSelecterUsers">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="4" fill="white"/>
                <rect x="3" y="7" width="10" height="2" fill="#2D3960"/>
              </svg>
            </button>
        </UiTableCell>
        <UiTableCell class="w-3/12 text-white whitespace-nowrap pr-5">{{(allFilteredUsers) ? count : [...selected_users].length}} seleccionados</UiTableCell>
        <UiTableCell class="w-4/12 font-normal whitespace-nowrap pr-5">
          <Visibility class="flex" :permission="'can_create_teams'">
            <TinyToolTip content="Desvíncular de equipo" backgroundColor="#ffffff" textColor="#333">
              <button class="massive-action-btn" @click="openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                title: '¿Quieres desvincular a estas personas de sus equipos?',
                text: 'Las personas se eliminarán de sus equipos y pasarán a formar parte de un grupo de Alumnos sin equipo.',
                btn_text: 'Desvincular',
                func: () => {
                  massiveChangeManager({ action: 'unlink' })
                }
              } })">
              <UnlinkIcon />
            </button>
          </TinyToolTip>
         </Visibility>
          <TinyToolTip content="Dar de baja a los usuarios" backgroundColor="#ffffff" textColor="#333">
          <button class="massive-action-btn" @click="openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                      title: '¿Seguro que quieres dar de baja a estas personas?',
                      text: 'Estas personas dejarán de tener acceso a la plataforma y se ocultarán de los listados, pero seguirán formando parte de las estadísticas.',
                      btn_text: 'Dar de baja',
                      func: () => {
                        massiveChangeManager({ action: 'disable' })
                      }
                    } })">
                    <DisabledUserIcon />
            </button>
            </TinyToolTip>
            <Visibility class="flex" :permission="'can_modify_licenses_status'">
              <TinyToolTip v-if="areDesactivable || allFilteredUsers" content="Desactivar licencias" backgroundColor="#ffffff" textColor="#333">
                <button class="massive-action-btn" @click="massiveChangeManager({ action:'deactivate' })">
                  <SwitchOff />
                </button>
              </TinyToolTip>
              <TinyToolTip v-if="areActivable || allFilteredUsers" content="Activar licencias" backgroundColor="#ffffff" textColor="#333">
                <button class="massive-action-btn" @click="massiveChangeManager({ action: 'activate' })">
                  <SwitchOn />
                </button>
              </TinyToolTip>
            </Visibility>
            <TagMassiveManager
              :bussines="active_business"
              @action="(event) => openDialogBox({
                $refs, ref: 'user-dialog', dialog: {
                  ...event.dialog,
                  type: 'primary',
                  func: () => {
                    massiveChangeManager({ action: event.action, tags: event.tags })
                  }
                }
              })"
              :filters="{
                department_id: filters.users.team_id,
                role: filters.users.role_id,
                search: filters.users.search,
                custom_tags: filters.users.tags,
                is_active: filters.users.is_active
              }"
              :ids="[...selected_users]"
              :allUsers="allFilteredUsers"
            />
          <Visibility class="flex" :permission="'can_create_users'">
            <TinyToolTip  content="Cambiar equipo" backgroundColor="#ffffff" textColor="#333">
              <div class="massive-action-btn">
                <ChangeUserTeam @change="massiveChangeManager"/>
              </div>
            </TinyToolTip>
          </Visibility>
          </UiTableCell>
        <UiTableCell class="w-full"></UiTableCell>
        <UiTableHeader>
        </UiTableHeader>
      </nav>
      <div v-if="canAddAllUsers" class="bg-grey-lighter-2 p-3 flex justify-center">
        <div class="text-center" v-if="!allFilteredUsers">
           Se han seleccionado los {{ users.length }} usuarios de esta página.
          <button  @click.prevent="allFilteredUsers = true" class="text-secondary-darker font-bold ml-4 underline">Seleccionar todos los usuarios que coincidan con los criterios ({{count}})</button>
        </div>
        <div class="text-center" v-else>
          Se han seleccionado todos los usuarios.
          <button @click.prevent="resetSelectedUsers" class="text-secondary-darker font-bold ml-4 underline">Deshacer selección</button>
        </div>
      </div>
      <UiTableSkeleton :limit="this.filters.users.paginator.limit" v-if="first_loading && users.length == 0" />
      <UiTableRow
      :bg="'bg-white'"
      v-for="{
        avatar_url,
        first_name,
        last_name,
        email,
        team_name,
        team,
        role,
        user_id,
        unlink,
        employee_id,
        is_active,
        is_available,
        custom_tags,
        has_planning,
        has_future_planning,
        has_campus_access,
        cid,
        managed_teams,
        scheduled,
        is_disabled
      } in users" :key="user_id">
          <UiTableCell class="w-16 pl-6">
            <UiCheck :class="{ 'pointer-events-none opacity-50': is_disabled }" :state="selected_users.has(employee_id)" @click="addOrRemoveUser(employee_id)" ></UiCheck>
          </UiTableCell>
          <UiTableCell class="w-4/12">
            <router-link :class="{'opacity-50' : is_disabled } " :to="{ name: 'user-progress', params: { employee_id } }">
              <UserIdentity large :user="{first_name, email, last_name, avatar_url, role, is_disabled}" dark />
            </router-link>
          </UiTableCell>
          <UiTableCell center class="w-1/12">
            <ToggleLicenseState @change="getUsers(filters.users.paginator.page)" :class="{ 'hidden': is_disabled }" :user="{employee_id, is_active, is_available, has_planning, has_future_planning, scheduled }" />
          </UiTableCell>
          <UiTableCell class="w-2/12 flex-auto text-sm">
            <div class="flex flex-col w-full">
              <div class="xl:w-11/12 truncate" :title="team_name">
                {{team_name}}
              </div>
              <div v-if="role !== 'student' && managed_teams.length !== 0" class="flex italic font-thin text-grey-dark">
                <span class="mr-1">Gestiona</span>
                <span :title="managed_teams[0]" class="truncate w-1/2 " v-if="managed_teams.length === 1">{{managed_teams[0]}}</span>
                <div class="flex justify-end">
                  <TooltipBtn :direction_arrow="'right'" v-if="managed_teams.length > 1">
                  <template v-slot:title>
                    <span >{{managed_teams.length}} equipos</span>
                  </template>
                  <li>
                    <p class="not-italic">
                      <strong class="mr-2">Gestiona</strong>
                    <span class="managed-team_list"  v-for="managed_team in managed_teams" :key="managed_team">
                      {{managed_team}}
                    </span>
                    </p>
                  </li>
                  </TooltipBtn>
                </div>
              </div>
            </div>
            </UiTableCell>
          <UiTableCell class="flex-auto 2xl:w-4/12 xl:w-3/12">
            <TagsList :list="custom_tags" />
          </UiTableCell>
          <UiTableCell center class="w-10 pr-6">
            <MenuEllipsis
              class="relative -top-1"
              :items="(is_disabled)
                  ? [
                      {
                        type: 'info', permission: 'can_unlink_user_to_team', icon: 'enable-user', text: 'Reactivar usuario', border: false, func: () => {
                          openDialogBox({
                            $refs, ref: 'user-dialog',
                             dialog: {
                              title: '¿Seguro que quieres reactivar usuario a esta persona?',
                              text: 'Esta persona volverá a tener acceso a la plataforma y volverán a formar parte de tu organización',
                              btn_text: 'Reactivar usuario',
                              type: 'primary',
                              func: () => {
                                enableUser(employee_id)
                              }
                            }
                          })
                        }
                      },
                      {
                        type: 'danger', permission: 'can_delete_user_to_organization', icon: 'delete-user', text: 'Borrar usuario', border: true, func: () => {
                          openDialogBox({
                            $refs, ref: 'user-dialog', dialog: {
                              title: '¿Seguro que quieres borrar a esta persona?',
                              text: 'Esta persona dejara de tener acceso a la plataforma y sus datos se eliminarán permanentemente de las bases de datos de la plataforma y estadísticas de la empresa.',
                              btn_text: 'Borrar usuario',
                              func: () => { deleteUserFromBusiness(employee_id) }
                            }
                          })
                        }
                      }
                    ]
                   : [
                {type: 'default', permission:'can_edit_user', icon: 'edit', text: 'Editar', border: false, func: () => {
                    $refs['editusuario'].modal('show', {first_name, avatar_url, last_name, email, team, role, employee_id, custom_tags, cid, managed_teams, has_future_planning, has_campus_access })
                  }
                },
                {type: 'default', permission: (!unlink)?'unlink':'can_unlink_user_to_team', icon: 'unlink', text: 'Desvincular de equipo', border: false, func: () => {
                    openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                        title: '¿Quieres desvincular a esta persona del equipo?',
                        text: 'La persona se eliminará de este equipo y pasará a formar parte de un grupo de Alumnos sin equipo.',
                        btn_text: 'Desvincular',
                        func: () => {
                          removeUserFromTeam(employee_id)
                        }
                      }
                    })
                  }
                },
                {type: 'default', permission:(is_active) ? 'all' : '', icon: 'time-disabled', text: 'Programar desactivación', border: false, func: () => {
                    $refs['program-disabled'].modal('show', { employee_id })
                  }
                },
                {type: 'danger', permission:'can_delete_user_to_organization', icon: 'delete-user', text: 'Borrar usuario', border: true,  func: () => {
                    $refs['deleteusuario'].modal('show', { first_name, avatar_url, last_name, email, team, role, employee_id, custom_tags, cid, managed_teams, has_future_planning, has_campus_access })
                  }
                }
              ]"
            />
          </UiTableCell>
      </UiTableRow>
      <NotFoundSearch :status="status"/>
    </UiTable>
  </section>
  <TableFooter v-if="!status" class="pl-12"
    :pages="filters.users.pages"
    v-model="filters.users.paginator"
    @change="getUsers(filters.users.paginator.page)"
  />
  <AddUserModal ref="altausuario" @success="getUsers" />
  <EditUserModal ref="editusuario" @success="getUsers" />
  <DeleteUserModal ref="deleteusuario" @disable="getUsers" @delete="getUsers" />
  <AddUserMassiveModal ref="altamasivausuario" @success="getUsers" @disable="getUsers" @delete="getUsers"/>
  <ProgramDisabledLicense ref="program-disabled" @update="getUsers"/>
  <ChangeMassiveUsersTeam ref="change-massive-users-team" @change="massiveChangeManager"/>
  <DialogBox ref="user-dialog" />
</RouterParent>
<AccessDenied :permission="'can_see_list_users_page'" />
</template>

<script>
import AccessDenied from '../components/AccessDenied.vue'
import AddUserModal from '../components/Modals/AddUserModal.vue'
import ProgramDisabledLicense from '../components/Modals/ProgramDisabledLicense.vue'
import EditUserModal from '../components/Modals/EditUserModal.vue'
import DeleteUserModal from '../components/Modals/DeleteUserModal.vue'
import NotFoundSearch from '../components/NotFoundSearch.vue'
import DialogBox from '../components/DialogBox.vue'
import MenuEllipsis from '../components/MenuEllipsis/Menu.vue'
import SearchField from '../components/SearchField.vue'
import SelectTeam from '../components/SelectTeam.vue'
import SelectRole from '../components/SelectRole.vue'
import SVGIcons from '../components/Layout/SVGIcons.vue'
import RouterParent from '../components/RouterParent.vue'
import TagsList from '../components/TagsList.vue'
import TagsField from '../components/TagsField.vue'
import ToggleLicenseState from '../components/ToggleLicenseState.vue'
import TableFooter from '../components/TableFooter.vue'
import TableSorter from '../components/TableSorter.vue'
import UiButton from '../components/Layout/UI/UiButton.vue'
import UserIdentity from '../components/UserIdentity.vue'
import UiCustomSelect from '../components/Layout/UI/UiCustomSelect.vue'
import Visibility from '../components/Visibility.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { api } from '../api/instances'
import UiTable from '../components/Layout/UI/Table/UiTable.vue'
import UiTableRow from '../components/Layout/UI/Table/UiTableRow.vue'
import UiTableCell from '../components/Layout/UI/Table/UiTableCell.vue'
import UiTableSkeleton from '../components/Layout/UI/Table/UiTableSkeleton.vue'
import UiTableHeader from '../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableFilters from '../components/Layout/UI/Table/UiTableFilters.vue'
import UiHeader from '../components/Layout/UI/UiHeader.vue'
import UiCheck from '../components/Layout/UI/UiCheck.vue'
import AddUserMassiveModal from '../components/Modals/AddUserMassiveModal.vue'
import TooltipBtn from '../components/TooltipBtn.vue'
import TinyToolTip from '../components/TinyToolTip.vue'
import UiMoreFilters from '../components/Layout/UI/Table/UiMoreFilters.vue'
import SwitchOn from '../components/Icons/SwitchOn.vue'
import SwitchOff from '../components/Icons/SwitchOff.vue'
import TagMassiveManager from '../components/TagMassiveManager.vue'
import EmailsFilter from '../components/EmailsFilter.vue'
import UnlinkIcon from '../components/Icons/UnlinkIcon.vue'
import DisabledUserIcon from '../components/Icons/DisabledUserIcon.vue'
import ToggleDisabledUser from '../components/ToggleDisabledUser.vue'
import ChangeUserTeam from '../components/ChangeUserTeam.vue'
import ChangeMassiveUsersTeam from '../components/Modals/ChangeMassiveUsersTeam.vue'

export default {
  name: 'Users',
  components: {
    AccessDenied,
    AddUserModal,
    DialogBox,
    MenuEllipsis,
    RouterParent,
    SearchField,
    SelectRole,
    SelectTeam,
    SVGIcons,
    TagsList,
    TagsField,
    ToggleLicenseState,
    TableFooter,
    TableSorter,
    UserIdentity,
    UiButton,
    UiCustomSelect,
    NotFoundSearch,
    Visibility,
    UiTable,
    UiTableRow,
    UiTableCell,
    UiTableSkeleton,
    UiTableHeader,
    UiTableFilters,
    UiHeader,
    UiCheck,
    AddUserMassiveModal,
    TooltipBtn,
    TinyToolTip,
    UiMoreFilters,
    SwitchOn,
    SwitchOff,
    TagMassiveManager,
    UnlinkIcon,
    DisabledUserIcon,
    EmailsFilter,
    EditUserModal,
    DeleteUserModal,
    ToggleDisabledUser,
    ProgramDisabledLicense,
    ChangeUserTeam,
    ChangeMassiveUsersTeam
  },
  data () {
    return {
      loading: false,
      first_loading: true,
      users: [],
      status: false,
      team_id: '',
      is_active: '',
      role_id: '',
      search: '',
      tags: [],
      selected_users: new Set(),
      activeFilter: { filter: 'user__first_name', direction: false },
      count: 0,
      pages: {},
      paginator: {
        limit: 10,
        page: 1
      },
      allFilteredUsers: false,
      emails: [],
      disabledUsers: false
    }
  },
  watch: {
    isSelectedUsers (value) {
      if (value) {
        this.allFilteredUsers = false
      }
    }
  },
  computed: {
    ...mapState(['active_business', 'filters', 'total_licenses']),
    isSelectedUsers () {
      return [...this.selected_users].length === this.users.length
    },
    areActivable () {
      return this.users.filter(user => [...this.selected_users].includes(user.employee_id) && user.is_active === false).length > 0
    },
    areDesactivable () {
      return this.users.filter(user => [...this.selected_users].includes(user.employee_id) && user.is_active === true).length > 0
    },
    canAddAllUsers () {
      return this.users.length > 0 && [...this.selected_users].length === this.users.length && this.count > this.filters.users.paginator.limit
    }
  },
  methods: {
    ...mapActions(['openDialogBox', 'getTotalLicenses']),
    ...mapMutations(['addToastMessage', 'setDisabledUsers']),
    addOrRemoveUser (user) {
      (this.selected_users.has(user))
        ? this.selected_users.delete(user)
        : this.selected_users.add(user)
    },
    toggleSelecterUsers () {
      (this.selected_users.size > 0)
        ? this.selected_users.clear()
        : this.selected_users = new Set(this.users.map(user => user.employee_id))
    },
    resetSelectedUsers () {
      this.selected_users.clear()
      this.allFilteredUsers = false
    },
    async getUsers (pag = 1) {
      this.loading = true
      this.selected_users = new Set()
      await api.get(`/jarvis/business/${this.active_business}/managed-users/`,
        {
          params: {
            limit: this.filters.users.paginator.limit,
            page: pag,
            department_id: this.filters.users.team_id,
            emails: this.emails,
            role: this.filters.users.role_id,
            search: this.filters.users.search,
            custom_tags: this.filters.users.tags,
            is_active: this.filters.users.is_active,
            filter_disabled: this.disabledUsers,
            disabled: this.disabledUsers,
            ordering: (this.filters.users.activeFilter.direction === true) ? `-${this.filters.users.activeFilter.filter}` : this.filters.users.activeFilter.filter
          }
        })
        .then(({ data }) => {
          this.status = (data.count === 0)
          this.count = data.count
          this.users = data.results
          this.filters.users.pages = data.paginator
          this.loading = false
        })
        .catch(() => {
          console.error('Problema al cargar los usuarios')
          this.loading = false
        })
      this.first_loading = false
    },
    async removeUserFromTeam (employee_id) {
      try {
        const { data } = await api.delete(`/jarvis/business/${this.active_business}/users/${employee_id}/team/`)
        console.log('data', data)
        if (data.status) {
          this.addToastMessage({
            status: data.status,
            title: data.title,
            message: data.text
          })
        } else {
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'El usuario fue desvinculado de su equipo.'
          })
        }
        this.getUsers()
        this.getTotalLicenses()
      } catch (err) {
        console.error('Error al desvincular usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
    },
    async disableUserFromBusiness (employee_id) {
      try {
        const { data } = await api.patch(`/jarvis/business/${this.active_business}/users/${employee_id}/disable/`)
        this.setDisabledUsers()
        const { status } = data
        this.addToastMessage({
          status: status,
          title: data.title,
          message: data.text
        })
        this.getUsers()
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al desactivado al usuario.'
        })
        console.error('Error al desvincular usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
    },
    massiveChangeManager (action) {
      const object = { ...action }
      if (this.allFilteredUsers) {
        object.filters = {
          department_id: this.filters.users.team_id,
          role: this.filters.users.role_id,
          search: this.filters.users.search,
          custom_tags: this.filters.users.tags,
          is_active: this.filters.users.is_active
        }
      } else {
        object.ids = [...this.selected_users]
      }

      api.post(`/jarvis/business/${this.active_business}/massive/change/`,
        {
          ...object
        })
        .then(({ data }) => {
          let obj = {}
          if (data.success > 0 && data.errors > 0 && data.errors_planned === 0 && data.action === 'activate') {
            obj.status = 'info'
            obj.title = `${data.success} licencia${data.success !== 1 ? 's' : ''} activada${data.success !== 1 ? 's' : ''}`
            obj.message = `Para activar ${data.errors !== 1 ? 'las ' + data.errors + ' restantes,' : 'la restante,'} asigna más licencias a los equipos.`
          }

          if (data.success > 0 && data.errors === 0 && data.errors_planned === 0 && data.action === 'activate') {
            obj.status = 'success'
            obj.title = 'Licencias activadas'
            obj.message = `${data.success !== 1 ? data.success + ' licencias se activaron ' : '1 licencia se activo'} correctamente.`
          }

          if (data.success > 0 && data.errors === 0 && data.errors_planned === 0 && data.action === 'deactivate') {
            obj.status = 'success'
            obj.title = 'Licencias desactivadas'
            obj.message = `${data.success !== 1 ? data.success + ' licencias se desactivaron ' : '1 licencia se desactivo'} correctamente.`
          }

          if (data.success === 0 && data.errors > 0 && data.errors_planned === 0 && data.action === 'activate') {
            obj.status = 'danger'
            obj.title = 'Equipo sin licenicas suficiente'
            obj.message = `La activación de licencias ha fallado. Asigna ${data.errors} licencia${data.success !== 1 ? 's' : ''} y vuelve a intentarlo.`
          }

          // Planner cases
          if (data.success === 0 && data.errors === 0 && data.errors_planned > 0 && data.action === 'deactivate') {
            obj.status = 'info'
            obj.title = 'Licencias planificadas'
            obj.message = 'Para gestionarlas manualmente, accede al planificador y elimina sus planificaciones.'
          }

          if (data.success > 0 && data.errors === 0 && data.errors_planned > 0 && data.action === 'deactivate') {
            obj.status = 'info'
            obj.title = `${data.success} licencias desactivadas`
            obj.message = `Para desactivar ${data.errors_planned > 1 ? `las ${data.errors_planned} restantes` : 'la restante'}, accede al planificador y elimina sus planificaciones.`
          }

          if (data.success === 0 && data.errors === 0 && data.errors_planned > 0 && data.action === 'activate') {
            obj.status = 'info'
            obj.title = 'Licencias planificadas'
            obj.message = 'Para gestionarlas manualmente, accede al planificador y elimina sus planificaciones.'
          }

          if (data.success > 0 && data.errors === 0 && data.errors_planned > 0 && data.action === 'activate') {
            obj.status = 'info'
            obj.title = `${data.success} licencias desactivadas`
            obj.message = `Para activar ${data.errors_planned > 1 ? `las ${data.errors_planned} restantes` : 'la restante'} , accede al planificador y elimina sus planificaciones.`
          }

          if (data.action === 'deactivate' || data.action === 'activate') {
            this.getUsers(this.filters.users.paginator.page)
          }
          if (data.action === 'change_team' && data.status === 'success' && object.ignore_warnings && object.not_alerts) {
            obj.status = 'success'
            obj.title = 'Usuarios modificados'
            obj.message = `${data.success !== 1 ? data.success + ' usuarios cambiaron de equipo' : '1 usuario cambió de equipo'} correctamente.`
            this.getUsers()
          }
          if (data.action === 'change_team' && data.status === 'pending_confirmation' && data.students_with_license_count === 0 && data.students_with_planned_seats_count === 0) {
            this.handleChangeUserTeam(object.team)
            return
          }
          if (data.action === 'change_team' && data.status === 'pending_confirmation' && !object.ignore_warnings && (data.students_with_license_count > 0 || data.students_with_planned_seats_count > 0)) {
            this.$refs['change-massive-users-team'].modal('show', { team: object.team, users_with_license: data.students_with_license, users_with_planner: data.students_with_planned_seats, users_total: [...this.selected_users].length })
            return
          }
          if (data.action === 'change_team' && data.status === 'success' && object.ignore_warnings && !object.not_alerts) {
            obj.status = 'info'
            obj.title = 'Usuarios modificados'
            obj.message = `${data.success !== 1 ? data.success + ' usuarios cambiaron de equipo' : '1 usuario cambió de equipo'}. ${data.errors_planned > 0 ? ` ${data.errors_planned} no ${data.errors_planned > 1 ? 'han' : 'ha'} podido cambiarse porque tienen licencias planificadas. Elimina sus planificaciones e intentálo de nuevo.` : ''}`
            this.getUsers()
          }
          this.getTotalLicenses()
          this.$refs['user-dialog'].dialog('hide')

          if (data.action === 'disable') {
            if (data.success > 0 && data.errors_planned === 0) {
              this.setDisabledUsers()
              obj = {
                status: 'success',
                title: '¡Completado!',
                message: 'Los usuarios fueron dados de baja de la organización.'
              }
            }
            if (data.success === 0 && data.errors_planned > 0) {
              obj = {
                status: 'danger',
                title: 'Usuarios con licencias planificadas',
                message: 'Para dar de baja a estos usuarios, accede al planificador y elimina sus planificaciones.'
              }
            }
            if (data.success > 0 && data.errors_planned > 0) {
              obj = {
                status: 'info',
                title: `${data.success} usuarios eliminados`,
                message: `Para dar de baja ${data.errors_planned > 1 ? `los ${data.errors_planned} restantes` : 'el restante'}, accede al planificador y elimina sus planificaciones`
              }
            }
            if (data.success === 0 && data.errors_planned === 0 && data.errors === 0) {
              obj = {
                status: 'danger',
                title: `${data.success} usuarios dados de baja`,
                message: `${data.text}`
              }
            }
            this.getUsers()
          }

          if (data.action === 'unlink') {
            if (data.success > 0 && data.errors_planned === 0) {
              obj = {
                status: 'success',
                title: '¡Completado!',
                message: 'Los usuarios fueron desvinculados de su equipo.'
              }
            }
            if (data.success === 0 && data.errors_planned > 0) {
              obj = {
                status: 'danger',
                title: 'Usuarios con licencias planificadas',
                message: 'Para desvincular a estos usuarios de su equipo, accede al planificador y elimina sus planificaciones.'
              }
            }
            if (data.success > 0 && data.errors_planned > 0) {
              obj = {
                status: 'info',
                title: `${data.success} usuarios eliminados`,
                message: `Para desvincular ${data.errors_planned > 1 ? `los ${data.errors_planned} restantes` : 'el restante'}, accede al planificador y elimina sus planificaciones`
              }
            }
            this.getUsers()
          }

          if (data.action === 'add-tags' || data.action === 'set-tags' || data.action === 'remove-tags') {
            obj = {
              status: 'success',
              title: '¡Completado!',
              message: 'Los cambios han sido guardados con éxito.'
            }
            this.getUsers()
          }

          this.addToastMessage(obj)
          this.getTotalLicenses()
          this.$refs['user-dialog'].dialog('hide')
        })
        .catch(() => {
          let obj = {}
          if (action === 'delete') {
            obj = {
              status: 'danger',
              title: '¡Error!',
              message: 'Error al desvincular usuarios.'
            }
          }
          if (action === 'unlink') {
            obj = {
              status: 'danger',
              title: '¡Error!',
              message: 'Error al desvincular usuarios.'
            }
          }
          if (action === 'deactivate' || action === 'activate') {
            obj = {
              status: 'danger',
              title: '¡Error!',
              message: `Error al ${(action === 'activate') ? 'activar' : 'desactivar'} las licencias.`
            }
          }
          this.addToastMessage(obj)
        })
    },
    async deleteUserFromBusiness (employee_id) {
      try {
        await api.delete(`/jarvis/business/${this.active_business}/users/${employee_id}/`)
        this.addToastMessage({
          status: 'success',
          title: 'Usuario eliminado',
          message: 'Eliminado correctamente de la organización.'
        })
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al desactivado al usuario.'
        })
        console.error('Error al desvincular usuario', err)
      }
      this.getUsers()
      this.$refs['user-dialog'].dialog('hide')
    },
    async enableUser (employee_id) {
      try {
        await api.patch(`/jarvis/business/${this.active_business}/users/${employee_id}/enable/`)
        this.setDisabledUsers()
        this.addToastMessage({
          status: 'success',
          title: 'Usuario habilitado',
          message: 'Vuelve a tener acceso a la plataforma.'
        })
        this.getTotalLicenses()
        this.getUsers()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al habilitar al usuario.'
        })
        console.error('Error al habilitar al usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
    },
    handleChangeUserTeam (team) {
      const dialog = {
        title: `¿Quieres cambiar a ${[...this.selected_users].length > 1 ? `estos ${[...this.selected_users].length} usuarios` : 'este usuario'} al equipo ${team.name}?`,
        text: '',
        btn_text: 'Confirmar',
        type: 'primary',
        func: () => {
          this.massiveChangeManager({ action: 'change_team', team_id: team.id, team: team, ignore_warnings: true, not_alerts: true })
        }
      }
      this.$refs['user-dialog'].dialog('show', dialog)
    }
  },
  updated () {
    this.getUsers()
    this.getTotalLicenses()
  },
  mounted () {
    this.getUsers()
    this.getTotalLicenses()
  }
}
</script>
<style scoped>
.managed-team_list:not(:last-child):not(:first-child)::after{
  content: ',';
  display: inline-block;
  margin-right: 5px;
}
.managed-team_list:last-child::before{
  content: 'y';
  display: inline-block;
  margin-right: 5px;
}
.massive-action-btn {
  @apply w-8 h-8 flex justify-center items-center mx-4 transition-all duration-300;
  /* @apply w-8 h-8 flex justify-center items-center mx-4 hover:bg-litepie-primary-400 rounded-lg transition-all duration-300;*/
}
</style>
