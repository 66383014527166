<template>
  <svg aria-hidden="true" focusable="false">
    <use v-bind:xlink:href="`#icon-${icon}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'SVGIcons',
  props: {
    icon: {
      type: String,
      default: 'general'
    }
  }
}
</script>

<style>
.svg-9{
  width: 9px;
  height: 9px;
}
.svg-10{
  width: 10px;
  height: 10px;
}
.svg-11{
  width: 11px;
  height: 11px;
}
.svg-12{
  width: 12px;
  height: 12px;
}
.svg-14{
  width: 14px;
  height: 14px;
}
.svg-15{
  width: 15px;
  height: 15px;
}
.svg-16{
  width: 16px;
  height: 16px;
}
.svg-18{
  width: 18px;
  height: 18px;
}
.svg-20{
  width: 20px;
  height: 20px;
}
.svg-24{
  width: 24px;
  height: 24px;
}
.svg-32{
  width: 32px;
  height: 32px;
}
.svg-36{
   width: 36px;
  height: 36px;
}
.svg-38{
   width: 38px;
  height: 38px;
}
.svg-50{
   width: 50px;
  height: 50px;
}
</style>
