<script setup>
import { ref, defineExpose, computed } from 'vue'
import { api } from '@/api/instances'
import { VueDraggableNext } from 'vue-draggable-next'
import { useStore } from 'vuex'
import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import InfoIcon from '../icons/InfoIcon.vue'
import Tooltip from '@/components/Tooltip.vue'
import UiCustomSelect from '@/components/Layout/UI/UiCustomSelect.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import SVGIcons from '../../../components/Layout/SVGIcons.vue'
import IAIcon from '@/components/Icons/IAIcon.vue'
import LadySuccessAutoComplete from './LadySuccessAutoComplete.vue'
import SuccessImg from './SuccessImg.vue'
import IATyping from '@/views/Assistant/components/IATyping.vue'
const store = useStore()
const active_business = computed(() => store.state.active_business)
const modal = async (action, obj = null) => {
  if (action === 'show') {
    open.value = true
    if (obj.detail) {
      isDetailSprint.value = true
      infoSprint.value = obj
    } else {
      infoTeam.value = obj
      numberSprints.value = obj.count
      isDetailSprint.value = false
    }
  }
  if (action === 'hide') {
    open.value = false
  }
}
const emit = defineEmits(['change'])
defineExpose({ modal })
const open = ref(false)
const isDetailSprint = ref(false)
const closeModal = () => {
  if (isFinished.value) {
    emit('change')
  }
  open.value = false
  currentStep.value = 1
  isFinished.value = false
}
const isFinished = ref(false)
const infoSprint = ref({})
const numberSprints = ref(1)
const currentStep = ref(1)
const periodSprints = ref('simple')
const isLoading = ref(false)
const desactiveLicense = ref(true)
const addToastMessage = (message) => store.commit('addToastMessage', message)
const priorityUserTranslate = ref({
  unused: 'Usuarios que nunca se han formado',
  last_request_date: 'Usuarios con solicitudes de licencias más antiguas',
  no_cert: 'Usuarios sin certificados obtenidos',
  enrollments: 'Usuarios con menos formaciones comenzadas'
})
const filledSprints = ref(0)
const filledStudents = ref(0)
const priorityUser = ref(['unused', 'last_request_date', 'no_cert', 'enrollments'])
const infoTeam = ref({
  name: 'SOPORTE TÉCNICO',
  licenses: 0,
  licenses_avaliables: 0,
  licenses_not_avaliables: 0
})
const fillNumberSprints = computed(() => {
  return Array.from({ length: infoTeam.value.count }, (_, i) => i + 1)
})
const autoCompleteSprints = async () => {
  isLoading.value = true
  api.post(`/jarvis/business/${active_business.value}/planner/${infoTeam.value.team_id}/autofill/`, {
    sprints: numberSprints.value,
    fill: periodSprints.value,
    priorities: priorityUser.value,
    deactivate: desactiveLicense.value
  })
    .then(({ data }) => {
      isFinished.value = true
      addToastMessage({
        status: 'success',
        title: '¡Éxito!',
        message: 'Se ha actualizado con éxito.'
      })
      filledStudents.value = data.filling_students
      filledSprints.value = data.filled_sprints
    })
    .catch((error) => {
      console.log(error)
      addToastMessage({
        status: 'danger',
        title: '¡Error!',
        message: 'Se ha producido un error.'
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}
const autoCompleteSprint = async () => {
  isLoading.value = true
  api.post(`/jarvis/business/${active_business.value}/planner/${infoSprint.value.team}/${infoSprint.value.sprint.id}/autofill/`, {
    priorities: priorityUser.value
  })
    .then(({ data }) => {
      isFinished.value = true
      addToastMessage({
        status: 'success',
        title: '¡Éxito!',
        message: 'Se ha actualizado con éxito.'
      })
      filledStudents.value = data.filling_students
    })
    .catch((error) => {
      console.log(error)
      addToastMessage({
        status: 'danger',
        title: '¡Error!',
        message: 'Se ha producido un error.'
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}
</script>
<template>
  <Overlay class="z-50" :open="open">
    <Modal class="mt-24">
      <ModalHeader @close="closeModal">
        <ModalTitle>
          Autocompletar sprint{{ !isDetailSprint ? 's' : '' }}
        </ModalTitle>
      </ModalHeader>
      <ModalBody class="pb-5">
        <div v-if="!isDetailSprint" class="border rounded px-4 py-4 flex items-center border-grey-lighter">
          <span class="font-bold text-primary mr-5">Equipo {{ infoTeam.name }}</span>
          <div class="flex items-center" v-if="!isFinished">
            <span class="font-medium text-primary">Licencias <span class="ml-1 font-bold text-primary bg-grey-lightest p-1 rounded ">{{ infoTeam.licenses }}</span></span>
            <span class="text-success-700 text-xs  mx-5">Planificables <span class="ml-1 font-bold bg-green-200 p-1 rounded ">{{ infoTeam.licenses_avaliables }}</span></span>
            <span class="text-xs text-warning-700 flex items-center">
              No Planificables
              <Tooltip position="arrow-right">
                <template v-slot:icon>
                  <InfoIcon class="w-3 h-3 ml-1"/>
                </template>
                <template v-slot:default>
                  <li>
                    <p class="text-primary">Las licencias activas no son planificables. Para liberar más licencias planificables, desactivalas. <strong class="font-medium">Gestionar capacidad de los sprint</strong></p>
                  </li>
                </template>
              </Tooltip>
              <span class="ml-1 font-bold bg-orange-200 p-1 rounded ">{{ infoTeam.licenses_not_avaliables }}</span></span>
          </div>
        </div>
        <div v-else class="border rounded px-4 py-4 flex items-center border-grey-lighter">
          <span class="font-bold text-primary mr-5">{{ infoSprint.sprint.name }}</span>
        </div>
        <div v-if="isLoading" class="bg-grey-lightest w-full flex justify-center h-[443px] p-5">
          <div class="w-full flex items-center justify-center">
            <IATyping :messages="['Analizando el estado de la planificación', 'Iniciando la evaluación de los sprints', 'Iniciando la planificación de licencias']" />
          </div>
        </div>
        <div v-else>
          <div v-if="!isFinished" class="bg-grey-lightest">
            <nav  class="w-full py-7 flex justify-center">
              <ul v-if="!isDetailSprint"  class="flex justify-center items-center w-8/12">
                <li :class="(currentStep === 1) ? 'text-primary active' : 'text-grey-lighter-2'" class="flex items-center justify-center step w-1/3 text-sm font-medium"><button @click="currentStep = 1" :class="(currentStep === 1) ? 'bg-primary' : 'bg-grey-lighter-2'" class="rounded-full text-xl font-semibold text-white text-center mr-3 p-2 h-10 w-10">1</button><span class="pr-1 font-medium">Licencias</span></li>
                <li :class="(currentStep === 2) ? 'text-primary active' : 'text-grey-lighter-2'"  class="flex items-center justify-center  step w-1/3 text-sm font-medium"><button @click="currentStep = 2" :class="(currentStep === 2) ? 'bg-primary' : 'bg-grey-lighter-2'" class="rounded-full text-xl font-semibold text-white text-center mr-3 p-2 h-10 w-10">2</button><span class="pr-1 font-medium">Sprints</span></li>
                <li :class="(currentStep === 3) ? 'text-primary active' : 'text-grey-lighter-2'" class="flex items-center justify-center  step w-1/3 text-sm font-medium"><button @click="currentStep = 3" :class="(currentStep === 3) ? 'bg-primary' : 'bg-grey-lighter-2'" class="rounded-full text-xl font-semibold text-white text-center mr-3 p-2 h-10 w-10 ">3</button><span class="pr-1 font-medium">Prioridad</span></li>
              </ul>
            </nav>
            <div v-if="currentStep === 1 && !isDetailSprint">
              <p class="text-center text-primary px-9 pb-10">
                Te sugerimos desactivar las licencias activas, ya que <span class="text-warning-700 font-medium">no son planificables</span>. Al hacerlo, aumentarás la capacidad de licencias <span class="text-success-700 font-medium">planificables</span> disponibles para tus sprints.
              </p>
              <div class="w-full flex justify-center flex-col items-center px-9 font-bold text-primary pb-10">
                <p>¿Quieres desactivar las licencias activas?</p>
                <UiCustomSelect  class="mt-2 bg-white" v-model="desactiveLicense"  :filter="false">
                  <option :value="true">Sí</option>
                  <option :value="false">No</option>
                </UiCustomSelect>
              </div>
            </div>
            <div class="pb-5" v-if="currentStep === 2 && !isDetailSprint ">
              <p class="text-sm text-center px-4 text-primary">Los sprints son períodos de 14 días durante los cuales los usuarios planificados pueden acceder y formarse</p>
              <div class="text-primary font-medium flex flex-col items-center justify-center mt-8 mb-5">
                <p>¿Cuantos sprint quieres completar?</p>
                <UiCustomSelect  class="bg-white mt-3" :filter="false" v-model="numberSprints">
                  <option v-for="n in fillNumberSprints" :value="n" :key="n">{{ n }}</option>
                </UiCustomSelect>
              </div>
              <div class="text-primary font-medium flex flex-col items-center justify-center mt-8 mb-10">
                <p class="text-center">¿Quieres prolongar el periodo asignando dos sprints consecutivos para lograr 28 días de formación?</p>
                <span class="text-xs font-light mb-3">*Te sugerimos planificar en periodos de 14 días, ya que que los usuarios tienden a aprovechar mejor la formación en este intervalo.</span>
                <UiCustomSelect  class="bg-white" :filter="false" v-model="periodSprints">
                  <option value="double">Sí, prolongar el periodo de los sprints a 28 días.</option>
                  <option value="simple">No, mantener la duración recomendada de 14 días.</option>
                </UiCustomSelect>
              </div>
            </div>
            <div class="flex flex-col items-center" v-if="currentStep === 3 || isDetailSprint">
              <p class="text-sm text-primary font-medium mb-3">Elige el orden de selección de los usuarios</p>
              <span class="text-primary text-xs mb-4 font-normal">Arrastra las casillas para configurar el orden.</span>
              <div class="bg-white flex rounded-lg w-6/12 mb-10 justify-center px-1">
                <VueDraggableNext class="w-full" @change="updateItinerary" v-model="priorityUser">
                  <transition-group>
                    <div class="flex items-center px-3 w-full text-xs font-medium" v-for="priority, index in priorityUser" :key="index">
                      <div class="w-[21px] h-[32px] p bg-primary-lightest text-primary  flex items-center justify-center rounded mr-2 py-2">
                        {{ index + 1 }}
                      </div>
                      <div class="flex items-center border border-primary-300 w-11/12 my-3 rounded px-3 py-2">
                        <SVGIcons class="svg-14" icon="drag-and-drop"/>
                        <span class="w-full">
                          {{ priorityUserTranslate[priority] }}
                        </span>
                      </div>
                    </div>
                  </transition-group>
                </VueDraggableNext>
              </div>
            </div>
          </div>
          <div  v-else>
            <div v-if="!isDetailSprint" class="w-full flex flex-col px-2 pt-5 bg-white">
              <div class="text-primary pl-5 font-medium mb-5">
                <h2 class="mb-5">¡Completado!</h2>
                <h2>Se han autocompletado {{ filledSprints }} Sprints para {{ filledStudents }} usuarios</h2>
              </div>
              <div class="px-8">
                <LadySuccessAutoComplete/>
              </div>
            </div>
            <div v-else class="w-full flex flex-col items-center px-2 pt-5 ">
              <div class="text-primary pl-5 font-medium mb-5">
                <h2 class="mb-5">¡Se ha autocompletado el sprint con exito!</h2>
              </div>
              <div class="px-8">
                <SuccessImg/>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-10 pb-10">
            <UiButton v-if="currentStep !== 3 && !isFinished && !isDetailSprint" @click="currentStep++" size="btn-lg" type="btn-primary">
              Siguiente
            </UiButton>
            <UiButton @click.prevent="isDetailSprint ? autoCompleteSprint() : autoCompleteSprints()" v-if="!isFinished && currentStep === 3 || isDetailSprint && !isFinished" size="btn-lg" type="btn-primary">
              <IAIcon class="w-6 h-6 mr-2"/>
              Autocompletar sprints
            </UiButton>
            <UiButton v-if="isFinished" @click="closeModal" size="btn-lg" type="btn-primary">
              Finalizar
            </UiButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </Overlay>
</template>
<style scoped>
  .step {
    @apply flex items-center
  }
  li:not(:last-child).step.active::after {
    content: '------';
    width: 25%;
    overflow: hidden;
    --tw-text-opacity: 1;
    color: rgba(48, 65, 120, var(--tw-text-opacity));
  }
  li:not(:last-child).step::after {
    content: '------';
    width: 25%;
    overflow: hidden;
    --tw-text-opacity: 1;
    color: #D0D5DD;
  }
</style>
