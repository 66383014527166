<template>
  <nav class="flex justify-between items-center p-4">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'UiTableFilters'
}
</script>

<style>

</style>
