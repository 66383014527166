<template>
  <UiPageBody class="container mx-auto pl-12">
    <div v-if="!isAllUsers && users.length > 0" class=" custom-table-head items-center  m-4 justify-center bg-grey-lightest sm-text text-grey flex">
      <SVGIcons class="svg-32 mr-4" icon="infoBig"/>
      <p class="w-11/12">
      Hemos detectado inactividad de usuarios desde <span class="text-info">hace más de {{filter_day}} días</span>,
      te recomendamos que liberes sus licencias y las asignes a otros usuarios para que puedan aprovecharlas.
      </p>
    </div>
    <UiTableFilters class="flex justify-between p-4">
      <div class="flex-none 2xl:w-2/3 lg:w-10/12 flex">
        <div class="col-auto px-1 justify-center xl:w-50 lg:w-1/2">
          <SearchField v-model="search" @change="getUser" />
        </div>
      </div>
      <div class="flex xl:w-50">
         <DownloadXLS
            class="btn btn-outline-secondary mr-2"
            file="usuarios.xlsx"
            :path="`/jarvis/business/${this.active_business}/users/unused/`"
            formatted='[{"field":"first_name","visible":"Nombre"},
                    {"field":"last_name","visible":"Apellidos"},
                    {"field":"email","visible":"Email"},
                    {"field":"team_name","visible":"Equipo"},
                    {"field":"last_activity_ts","visible":"Última actividad", "output":"date"},
                    {"field":"last_time_active","visible":"Activación de licencia", "output":"date"},
                    {"field":"is_active","visible":"Licencia activa", "output":"bool"},
                    {"field":"manager_team","visible":"Gestor de equipo"},
                    {"field":"custom_tags","visible":"Etiquetas","output":"list"}]'
            :params="{
              limit: 1000000000,
              search: this.search,
              ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
              department_id: this.team_id,
              custom_tags: this.tags,
              days: (this.isAllUsers) ? '' : this.filter_day
              }">
              Descargar reporte
            </DownloadXLS>
        <UiButton :type="(users.length === 0) ? 'disabled' : 'secondary'"
        @click.prevent="
                openDialogBox({ $refs, ref: 'user-dialog', dialog: {
        title: '¿Quieres liberar todas las licencias de la organización?',
        text: 'Todas las licencias serán liberadas de la organización.',
        btn_text: 'Liberar licencias',
        func: () => { removeAllLicenses() }
        }})">
          Liberar todas
          </UiButton>
      </div>
    </UiTableFilters>
    <UiMoreFilters>
<div class="col-auto px-1 justify-center mr-1">
          <SelectTeam @change="getUser" v-model="team_id" :bgClass="'bg-white'"/>
        </div>
        <div class="col-auto justify-center mr-2">
          <TagsField  @change="getUser" v-model="tags" :bgClass="'bg-white'" />
        </div>
        <div class="col-auto justify-center">
          <UiCustomSelect :filter="false" :disabled="isAllUsers" v-model="filter_day" :modelValue="filter_day" @change="getUser()" :bgClass="'bg-white'" name="filter_day">
                <option value="30">30 días </option>
                <option value="21">21 días</option>
                <option value="14">14 días</option>
                <option value="7">7 días</option>
          </UiCustomSelect>
        </div>
        <div class="col-auto justify-center pl-2">
          <Toggle v-model="isAllUsers" @change="getUser">Mostrar todos los usuarios con licencia</Toggle>
        </div>
    </UiMoreFilters>

    <UiTable>
      <UiTableHeader class="custom-table-head">
        <UiTableCell class="w-10 flex"></UiTableCell>
        <UiTableCell class="2xl:w-4/12 xl:w-6/12  text-base xl:text-sm">
          <TableSorter
            :loading="loading"
            filter="user__first_name"
            v-model="activeFilter"
            @update="getUser()"
          >
            Nombre
          </TableSorter></UiTableCell>
        <UiTableCell class="2xl:w-2/12 xl:w-3/12 xl:text-sm">
          <TableSorter
            :loading="loading"
            filter="department__name"
            v-model="activeFilter"
            @update="getUser()"
          >
            Equipo
          </TableSorter></UiTableCell>
        <UiTableCell center class="w-3/12 xl:text-sm"><TableSorter
            :loading="loading"
            :margin="'xl:ml-0'"
            filter="last_activity_ts"
            v-model="activeFilter"
            @update="getUser()"
          >
          <span class="xl:w-3/4">
            Última actividad
          </span>
          </TableSorter></UiTableCell>
        <UiTableCell center class="w-3/12 xl:text-sm">
          <span class="xl:w-3/4">
            <TableSorter
              :loading="loading"
              filter="last_time_active"
              v-model="activeFilter"
              @update="getUser()"
            >
            Activación de licencia
            </TableSorter>
          </span>
        </UiTableCell>
        <UiTableCell center class="w-2/12 xl:text-sm">
            Licencia
        </UiTableCell>
        <UiTableCell class="2xl:w-6/12 xl:w-4/12 xl:text-sm">Etiquetas</UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="first_loading && users.length == 0"/>
      <UiTableRow
        v-else
        v-for="{
        avatar_url,
        first_name,
        last_name,
        email,
        team_name,
        role,
        user_id,
        employee_id,
        is_active,
        is_available,
        custom_tags,
        last_time_active,
        last_activity_ts
        } in users" :key="user_id">
          <UiTableCell class="w-10 flex justify-center items-center">
          </UiTableCell>
          <UiTableCell class="flex-auto 2xl:w-4/12 xl:w-5/12 2xl:pr-0 xl:pr-2 truncate flex items-center">
          <router-link class="truncate" :to="{ name: 'user-progress', params: { employee_id }}">
            <span class="truncate w-8/12 ">
              <UserIdentity truncate :user="{first_name, email, last_name, avatar_url, role}" dark />
            </span>
          </router-link>
          </UiTableCell>
          <UiTableCell class="2xl:w-2/12 xl:w-3/12 text-sm truncate "><span class="2xl:w-full truncate" :title="team_name">{{team_name}}</span></UiTableCell>
          <UiTableCell center class="w-3/12 text-sm ">{{$filters.shortDate(last_activity_ts)}}</UiTableCell>
          <UiTableCell center class="w-3/12 text-sm">
            {{$filters.shortDate(last_time_active)}}
          </UiTableCell>
          <UiTableCell center class="w-2/12">
            <ToggleLicenseState :user="{employee_id, is_active, is_available}" @change="getUser()" />
          </UiTableCell>
          <UiTableCell class="2xl:w-6/12 xl:w-4/12"><TagsList :limit="(!is_2xl) ? 1 : 3" :list="custom_tags" /></UiTableCell>
      </UiTableRow>
      <NotFoundSearch :status="status"/>
    </UiTable>
  </UiPageBody>
  <TableFooter v-if="!status" class="pl-12" :pages="pages" v-model="paginator" @change="getUser(this.paginator.page)" v-cloak/>
  <AddUserModal ref="altausuario" @success="getUser" />
  <DialogBox ref="user-dialog" />
</template>

<script>
import AddUserModal from '../../components/Modals/AddUserModal.vue'
import NotFoundSearch from '../../components/NotFoundSearch.vue'
import TableSorter from '../../components/TableSorter.vue'
import DialogBox from '../../components/DialogBox.vue'
import TagsList from '../../components/TagsList.vue'
import TagsField from '../../components/TagsField.vue'
import UserIdentity from '../../components/UserIdentity.vue'
import SearchField from '../../components/SearchField.vue'
import SelectTeam from '../../components/SelectTeam.vue'
import UiButton from '../../components/Layout/UI/UiButton.vue'
import UiCustomSelect from '../../components/Layout/UI/UiCustomSelect.vue'
import ToggleLicenseState from '../../components/ToggleLicenseState.vue'
import TableFooter from '../../components/TableFooter.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
import UiTableSkeleton from '../../components/Layout/UI/Table/UiTableSkeleton.vue'
import UiTableFilters from '../../components/Layout/UI/Table/UiTableFilters.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'
import UiTable from '../../components/Layout/UI/Table/UiTable.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiMoreFilters from '../../components/Layout/UI/Table/UiMoreFilters.vue'
import DownloadXLS from '../../components/DownloadXLS.vue'
import Toggle from '../../components/Toggle.vue'
export default {
  name: 'Users',
  components: {
    AddUserModal,
    DialogBox,
    UserIdentity,
    SearchField,
    SelectTeam,
    ToggleLicenseState,
    TableFooter,
    SVGIcons,
    UiButton,
    TableSorter,
    TagsList,
    TagsField,
    UiCustomSelect,
    NotFoundSearch,
    UiTableSkeleton,
    UiTableFilters,
    UiPageBody,
    UiTableRow,
    UiTable,
    UiTableCell,
    UiTableHeader,
    UiMoreFilters,
    DownloadXLS,
    Toggle
  },
  data () {
    return {
      users: [],
      pages: {},
      team_id: '',
      role_id: '',
      search: '',
      status: false,
      isAllUsers: false,
      first_loading: false,
      loading: false,
      tags: [],
      filter_day: '7',
      activeFilter: { filter: 'last_activity_ts', direction: false },
      paginator: {
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapState(['active_business']),
    is_2xl () {
      return window.screen.width > 1280
    }
  },
  methods: {
    ...mapActions(['openDialogBox']),
    ...mapMutations(['addToastMessage']),
    async getUser (pag = 1) {
      await api.get(`/jarvis/business/${this.active_business}/users/unused/`,
        {
          params: {
            limit: this.paginator.limit,
            page: pag,
            department_id: this.team_id,
            role: this.role_id,
            search: this.search,
            custom_tags: this.tags,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
            days: (this.isAllUsers) ? '' : this.filter_day
          }
        })
        .then(({ data }) => {
          if (data.count === 0) {
            this.status = true
          } else {
            this.status = false
          }
          this.users = data.results
          this.pages = data.paginator
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
    },
    async removeAllLicenses () {
      await api.delete(`/jarvis/business/${this.active_business}/users/unused/`)
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.$refs['user-dialog'].dialog('hide', {})
          this.getUser()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  created () {
    this.getUser()
  }
}
</script>

<style>
</style>
