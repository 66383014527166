<template>
  <section
    v-if="$route.name == 'itinerary-list' || $route.name == 'itinerary-archived' || $route.name == 'itinerary-drafts'">
    <UiHeader :title="($route.name === 'itinerary') ? 'Rutas/ Nueva ruta' : 'Rutas'">
      <div class="flex h-8 mx-12">
        <Visibility :permission="'can_see_available_licenses'">
        </Visibility>
      </div>
    </UiHeader>
    <div class="container mx-auto pl-16 pr-9 flex flex-col justify-beween mt-7 pt-1">
      <nav class="flex justify-between w-full border-b-2 border-grey-lighter py-3.5 pl-0">
        <ul class="flex justify-between">
          <li class="flex-none text-xl pb-1 mr-6 pr-1">
            <router-link class="text-xl bg-transparent"
              exact-active-class="active"
              :to="{name: 'itinerary-list'}">Rutas</router-link>
          </li>
          <li class="flex-none text-xl pb-1 mr-6 pr-1">
            <router-link class="text-xl bg-transparent"
              exact-active-class="active"
              :to="{name: 'itinerary-drafts'}">Borradores</router-link>
          </li>
          <li class="flex-none text-xl pb-1 pr-1">
            <router-link class="text-xl bg-transparent"
              exact-active-class="active"
              :to="{name: 'itinerary-archived'}">Archivadas</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </section>
  <router-view />
</template>

<script>

import { mapActions, mapState } from 'vuex'
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import Visibility from '../../components/Visibility.vue'
export default {
  name: 'Itinerary',
  components: {
    UiHeader,
    Visibility
  },
  computed: {
    ...mapState(['active_business', 'total_licenses'])
  },
  methods: {
    ...mapActions(['getTotalLicenses'])
  },
  created () {
    this.getTotalLicenses()
  }

}
</script>

<style scoped>
.active{
    @apply border-b-2 pb-5 text-primary
}
</style>
