<template>
  <header class="px-10 pt-14 pb-3">
    <div class="flex items-center justify-between">
        <slot></slot>
    </div>
    <hr v-if="underline" class="mt-2 mb-2"/>
    <button v-if="close" type="button" @click="update" class="absolute right-10 top-7">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </button>
  </header>
</template>

<script>
export default {
  name: 'ModalHeader',
  emits: ['close'],
  props: {
    underline: {
      type: Boolean,
      default: true
    },
    close: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    update () {
      this.$emit('close')
    }
  }

}
</script>

<style>

</style>
