<template>
  <div v-if="can_see_list_teams_page" class="rounded border-2 select-team" :class="(team_id !== '' && filter == true) ? 'border-primary' : 'border-primary-lighter'">
    <div class="flex items-center relative" v-if="!loading">
      <select
        :disabled="disabled"
        :id="id"
        :required="required"
        @change="update"
        v-model="team_id"
        :class="`w-full py-2 pl-3 pr-8 rounded outline-none ${bgClass}`" name="role">
        <option v-if="label" value="">Equipos</option>
        <option v-for="{id, name} in filtered_teams" :key="id" :value="id">{{name}}</option>
      </select>
      <svg v-if="team_id === '' || filter == false" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
      </svg>
      <svg @click="reset" v-if="team_id !== '' && filter == true" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </div>
    <div v-else :class="`w-full py-2 pl-3 flex items-center pr-8 rounded outline-none ${bgClass}`">
    <div
      class="w-4 h-4 border-2 border-primary border-dotted border-t-0 rounded-full animate-spin mr-5"
      :class="'disabled'">
      </div>
    Cargando...
    </div>
  </div>
  <div v-else>
    <Tooltip class="ml-1">
    <template v-slot:icon>
    <div class="relative rounded border-2 select-team border-primary-light py-2 pr-8 pl-3">
      Alumnos sin equipos
      <svg class="absolute top-4 right-3 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
      </svg>
    </div>
    </template>
    <template v-slot:default>
    <li>
      <p>Tu contrato actual no pueden tener equipos, si prefieres poder gestionar equipos <u>contacta con tu Customer Succes</u>.</p>
    </li>
  </template>
  </Tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '../api/instances'
import Tooltip from './Tooltip.vue'
export default {
  components: { Tooltip },
  name: 'SelectTeams',
  props: {
    id: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number]
    },
    bgClass: {
      type: String,
      default: 'bg-grey-lighter-2'
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Boolean,
      default: true
    }
  },
  emits: ['change', 'update:modelValue', 'licenses'],
  data () {
    return {
      teams: [],
      team_id: '',
      loading: false
    }
  },
  computed: {
    ...mapState(['active_business', 'permissions']),
    can_see_list_teams_page () {
      return this.permissions.includes('can_see_list_teams_page')
    },
    filtered_teams () {
      return this.teams
    }
  },
  methods: {
    async getTeams () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/teams/`, { params: { limit: 10000 } })
        .then(({ data }) => {
          this.teams = data.results
          this.loading = false
        })
        .catch(console.error)
    },
    update () {
      this.$emit('update:modelValue', this.team_id)
      const team = this.teams.find((el) => el.id === this.team_id)
      if (team) {
        this.$emit('licenses', team.licenses)
      } else {
        this.$emit('licenses', null)
      }
      this.$emit('change')
    },
    reset () {
      this.team_id = ''
      this.update()
    }
  },
  update () {
    this.team_id = this.modelValue
  },
  created () {
    this.team_id = this.modelValue
    this.getTeams()
  }
}
</script>

<style scoped>
select {
    -webkit-appearance: none;
  }
    .select-team:focus-within {
    border: 2px solid black;
  }
  .pe-none{
    pointer-events: none;
  }
</style>
