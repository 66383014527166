<template>
  <header class="border-solid border-b border-grey-lighter flex items-center mb-1 justify-between">
    <div class="flex flex-col">
      <div :class="`flex items-center ${(!with_child)?'justify-between':''} ${padding}`">
        <h2 class="pl-12 text-grey-darker font-semibold text-2xl relative">{{title}} {{with_child ? '/' : ''}} <div v-if="label" class="position-label px-2 py-0.5 rounded-lg bg-ow-primary text-white text-2xs">{{label}}</div> </h2>
        <h3 v-if="with_child" class="pl-2 text-grey-darker font-semibold text-xl">{{titleChild}}</h3>
        <slot v-if="!slotCol"></slot>
      </div>
      <div v-if="with_subtitle" class="flex items-center pl-12 text-grey mb-2">
      {{subtitle}}
      </div>
        <slot v-if="slotCol">
        </slot>
    </div>
    <Visibility class="flex items-center mt-6" :permission="'can_see_available_licenses'">
      <AvailableLicenses data-html2canvas-ignore="true" class="z-10 w-42 h-10 "/>
    </Visibility>
  </header>
</template>

<script>
import AvailableLicenses from '../../AvailableLicenses.vue'
import Visibility from '../../Visibility.vue'
export default {
  name: 'UiHeader',
  components: {
    AvailableLicenses,
    Visibility
  },
  props: {
    title: {
      type: String,
      default: 'Sin titulo'
    },
    slotCol: {
      type: Boolean,
      default: false
    },
    titleChild: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: 'py-6'

    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    with_child () {
      return this.titleChild !== ''
    },
    with_subtitle () {
      return this.subtitle !== ''
    }
  }
}
</script>
<style scoped>
.position-label {
  position: absolute;
  top: 0;
  right:0;
  transform: translate(calc(100% + 5px),calc(50% + 2px));
}
</style>
