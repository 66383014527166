<template>
  <nav
  class="flex mt-5 mb-10"
  :class="(period !== '') ? 'justify-end' : 'justify-between'"
  >
    <div class="flex items-center bg-grey-lightest px-1 py-1 rounded" v-if="period === ''">
      <button class="flex items-center" :class="{ 'pointer-events-none opacity-40': day_selected === 0 }" @click="previusPage"><SVGIcons class="svg-16 ml-2" icon="arrow-left"/></button>
      <div class=" w-14 items-center flex justify-center">
        {{ (days[day_selected - 1].num >= 10) ? days[day_selected - 1].num : `0${days[day_selected - 1].num}` }}
        /
        {{ (month_selected >= 10) ? month_selected : `0${month_selected}`}}
      </div>
      <button class="flex items-center" :class="{ 'pointer-events-none opacity-40': day_selected === days.length - 1 }" @click="nextPage"><SVGIcons class="svg-16 ml-2" icon="arrow-right"/></button>
    </div>
    <div class="flex">
      <UiCustomSelect class="mr-2" :filter="true" @change="getStats((month_selected !== '') ? 'monthly/' : 'yearly/')" v-model="month_selected" name="is_active" :bgClass="'bg-grey-lightest'">
        <option class="divide-y" value="">Mes</option>
        <option v-for="{name, num}  in months" :key="num" :value="num">{{`${name}`}}</option>
      </UiCustomSelect>
      <UiCustomSelect  :filter="false" @change="getStats('yearly/')" v-model="year_selected"  :bgClass="'bg-grey-lightest'">
        <option v-for="year in years_with_contract" :key="year" :value="year">{{year}}</option>
      </UiCustomSelect>
    </div>
  </nav>
  <div v-if="loading">
  <ul class="flex w-full relative">
    <div class="absolute -top-7 text-right" :class="{'opacity-0' : loading}">30h</div>
    <div class="ruler flex flex-col text-right" :class="{'opacity-0' : loading}">
      <span>{{Math.round(5 * 5)}}h</span>
      <span>{{Math.round(5 * 4)}}h</span>
      <span>{{Math.round(5 * 3)}}h</span>
      <span>{{Math.round(5 * 2)}}h</span>
      <span>{{Math.round(5)}}h</span>
      <span>0h</span>
    </div>
    <svg class="absolute mx-auto position-loading right-0 bottom-2/3 w-32 h-32 animate-spin" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#e4e4e4" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path></svg>
    <div class="flex flex-col justify-end w-full ml-4 min-h-sm">
      <ul class="flex w-full justify-between">
        <li class="w-full relative px-0.5 flex items-end justify-center"></li>
        <li v-for="{hours, num} in months" :key="num" class="w-full  relative px-0.5 flex items-end justify-center">
          <div v-if="Math.trunc(hours) > 0" class="relative cursor-zoom-in  bg-primary rounded-t w-full"
            :style="`height:${parseInt((Math.trunc(hours) * 350) / max_value)}px; max-width:35px;`">
            <span class="absolute w-full text-center -top-5 text-sm font-bold ">{{ Math.trunc(hours)}}</span>
          </div>
        </li>
      </ul>
      <ul class="flex w-full justify-between">
        <li class="relative w-full h-8 flex justify-center items-end">
          <span class="text-center absolute w-full flex justify-center">00:00</span>
        </li>
        <li v-for="col of 24" :key="col" class="w-full relative h-8 flex justify-center items-end">
          <span v-if="col % 3 === 0" class="absolute text-center w-full flex justify-center">{{`${!(col > 10) ? '0': ''}`}}{{(col == 24)?'00':col}}:00</span>
        </li>
      </ul>
    </div>
  </ul>
  </div>
  <ul v-else class="flex w-full relative">
    <div class="absolute -top-7 text-right">{{Math.trunc(max_value)}}h</div>
    <div class="ruler flex flex-col text-right">
      <span>{{Math.round(slot * 5)}}h</span>
      <span>{{Math.round(slot * 4)}}h</span>
      <span>{{Math.round(slot * 3)}}h</span>
      <span>{{Math.round(slot * 2)}}h</span>
      <span>{{Math.round(slot)}}h</span>
      <span>0h</span>
    </div>
    <div v-if="stats.reduce((a, b) => a + b, 0) === 0 || stats.reduce((a,b) => a + b, 0) < 1" class="absolute top-14 left-0 right-0 mx-auto w-2/3 flex flex-col items-center text-center">
      <h3 class=" font-bold text-xl text-primary">No se ha registrado actividad en este período de tiempo</h3>
      <p class="text font-light">Se mostrarán datos cuando los usuarios inviertan tiempo en la plataforma</p>
    </div>
    <div v-else class="flex flex-col justify-end w-full ml-4 min-h-sm">
      <ul v-if="period === 'yearly/'" class="flex w-full justify-between " >
        <li v-for="{hours, num} in months" :key="num" class="w-full  relative px-0.5 flex items-end justify-center">
          <div v-if="hours" @click="month_selected = num, getStats('monthly/')"
            class="bar cursor-zoom-in" :style="`height:${highBar(hours) > 10 ? highBar(hours) : 10}px; max-width:35px;`">
            <span class="bar-time">{{ $filters.hoursAndMinutes(hours*3600)}}</span>
          </div>
        </li>
      </ul>
      <ul v-if="period === 'monthly/'" class="flex w-full justify-between " >
        <li v-for="{hours, num} in days" :key="num" class="w-full  relative px-0.5 flex items-end justify-center">
          <div v-if="hours > 0.1" @click="day_selected = num, getStats(''), period = ''"
            class="bar cursor-zoom-in" :style="`height:${highBar(hours) > 5 ? highBar(hours) : 5}px; max-width:35px;`">
            <span class="bar-time">{{ hoursMinutesAndSeconds(hours * 3600)}}</span>
          </div>
        </li>
      </ul>
      <ul v-if="period === ''" class="flex w-full justify-between ">
        <li v-for="hour in roundHours" :key="hour" class="w-full relative px-0.5 flex items-end justify-center">
          <div v-if="hour > 0" class="bar cursor-default"
            :style="`height:${highBar(hour)}px; max-width:35px;`">
            <span class="bar-time">{{ hoursMinutesAndSeconds(hour * 3600) }}</span>
          </div>
        </li>
        <li class="w-full relative px-0.5 flex items-end justify-center"></li>
      </ul>
      <ul v-if="period === ''" class="flex w-full justify-between mt-4">
        <li class="relative w-full h-8 flex justify-center items-end">
          <span class="text-center absolute w-full flex justify-center">00:00</span>
        </li>
        <li v-for="col of 24" :key="col" class="w-full relative h-8 flex justify-center items-end">
          <span v-if="col % 3 === 0" class="absolute text-center w-full flex justify-center">{{`${!(col > 10) ? '0': ''}`}}{{(col == 24)?'00':col}}:00</span>
        </li>
      </ul>
      <ul v-if="period === 'yearly/'" class="flex w-full justify-between mt-4">
        <li v-for="{name} in months" :key="name" class="w-full relative h-8 flex justify-center items-end">
          <span class="absolute text-center w-full flex justify-center">{{name.slice(0, 3)}}</span>
        </li>
      </ul>
      <ul v-if="period === 'monthly/'" class="flex w-full justify-between mt-4">
        <li v-for="{num} in days" :key="num" class="w-full relative h-8 flex justify-center items-end">
          <span class="absolute text-center w-full flex justify-center">{{num}}</span>
        </li>
      </ul>
    </div>
  </ul>

</template>
<script>
import { mapState } from 'vuex'
import { api } from '../../api/instances'
import SVGIcons from '../Layout/SVGIcons.vue'
import UiCustomSelect from '../Layout/UI/UiCustomSelect.vue'
export default {
  components: {
    SVGIcons,
    UiCustomSelect
  },
  data () {
    return {
      stats: [],
      first_request: true,
      loading: true,
      timer: null,
      months: [
        { name: 'Enero', num: 1, hours: 0 },
        { name: 'Febrero', num: 2, hours: 0 },
        { name: 'Marzo', num: 3, hours: 0 },
        { name: 'Abril', num: 4, hours: 0 },
        { name: 'Mayo', num: 5, hours: 0 },
        { name: 'Junio', num: 6, hours: 0 },
        { name: 'Julio', num: 7, hours: 0 },
        { name: 'Agosto', num: 8, hours: 0 },
        { name: 'Septiembre', num: 9, hours: 0 },
        { name: 'Octubre', num: 10, hours: 0 },
        { name: 'Noviembre', num: 11, hours: 0 },
        { name: 'Dicembre', num: 12, hours: 0 }
      ],
      days: [],
      month_selected: '',
      year_selected: '',
      hours: [],
      period: 'yearly/',
      day_selected: '',
      years_with_contract: []
    }
  },
  computed: {
    ...mapState(['active_business']),
    max_value () {
      const max = Math.max(...this.stats)
      return (max / 3600 < 10) ? 10 : max / 3600
    },
    slot () {
      return ((58 * this.max_value) / 350)
    },
    current_year () {
      return new Date().getFullYear()
    },
    roundHours () {
      return this.hours.map(el => el.total_time / 3600)
    }
  },
  methods: {
    hoursMinutesAndSeconds (value) {
      const valMinutes = parseInt(value / 60)
      const hours = (parseInt(valMinutes / 60) > 0) ? `${parseInt(valMinutes / 60)}h` : ''
      const minutes = (valMinutes % 60 > 0) ? `${valMinutes % 60}m` : ''
      const seconds = (value % 60 > 0) ? `${parseInt(value % 60)}s` : ''
      if (hours !== '') {
        return `${hours} ${minutes}`
      } else {
        return `${minutes} ${seconds}`
      }
    },
    previusPage () {
      if (this.day_selected > 0) {
        this.day_selected--
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.stats = []
          this.loading = true
          this.getStats('')
        }, 400)
      }
    },
    highBar (hours) {
      const max_value = parseInt(((hours) * 350) / this.max_value)
      return max_value <= 3 ? 3 : max_value
      // return max_value
    },
    nextPage () {
      if (this.day_selected < this.days.length - 1) {
        this.day_selected++
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.stats = []
          this.loading = true
          this.getStats('')
        }, 400)
      }
    },
    async getStats (period) {
      this.stats = []
      this.period = period
      this.months.map((el) => { el.hours = 0 })
      this.year_selected = (this.year_selected === '') ? this.current_year : this.year_selected
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/activities/${(period !== undefined) ? period : 'yearly/'}`, {
        params: {
          month: this.month_selected,
          year: this.year_selected,
          date_day: this.day_selected
        }
      })
        .then(({ data }) => {
          if (this.period === 'yearly/') {
            this.fillEmptyMonths(data)
            this.day_selected = ''
          }
          if (this.period === 'monthly/') {
            this.fillEmptyDays(data)
            this.day_selected = ''
          }
          if (this.period === '') {
            this.hours = data
          }
          this.stats = data.map(el => el.total_time)
        })
        .catch((err) => {
          console.error(err)
        })
      this.loading = false
    },
    fillEmptyMonths (months) {
      this.months.filter(month_base => months.some(month_data => {
        if (month_base.num === month_data.month_num) {
          this.months[month_data.month_num - 1].hours = month_data.total_time / 3600
        }
      }))
    },
    fillEmptyDays (days) {
      const days_default = []
      const days_in_month = new Date(this.year_selected, this.month_selected, 0).getDate()
      for (let day = 0; day < days_in_month; day++) {
        days_default.push({ num: day + 1, hours: 0 })
      }
      this.days = days_default
      this.days.filter(day_base => days.some(day_data => {
        if (day_base.num === day_data.day_num) {
          this.days[day_data.day_num - 1].hours = day_data.total_time / 3600
        }
      }))
    },
    async getYears () {
      await api.get(`/jarvis/business/${this.active_business}/stats/activities/available_years/`)
        .then(({ data }) => {
          this.years_with_contract = data.years
        })
        .catch((err) => {
          console.error(err, 'Ha habido un problema al cargar los años disponibles.')
        })
    }
  },
  created () {
    this.getStats('yearly/')
    this.getYears()
  }

}
</script>
<style scoped>
  .cel-center {
    left: 50%;
    transform: translateX(-50%);
  }
  .ruler {
    height: 350px;
    min-width: 25px;
    border-right: 0px solid rgb(218, 218, 218);
  }
  .ruler span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 58px;
    font-size: 16px;
    line-height: 10px;
    position: relative;
    bottom: -2px;
    margin-right: 3px;
  }
.position-loading{
  left: -5.666667%;
}
.bar {
  @apply relative bg-secondary rounded-t w-full
}
.bar-time::after {
  content: '';
  position: absolute;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  transform: translateX(-50%) rotate(45deg);
  background-color: white;
  @apply border-primary-light
}
.bar-time {
  z-index: 100000;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 3px 5px;
  border-radius: 2px;
  @apply absolute border border-primary-light bg-white text-center -top-6 text-xs font-bold
}
.bar span {
  opacity: 0;
  transform: translate(-50%, 5px);
  transition: all 0.3s ease-in-out;
}
.bar:hover span {
  opacity: 1;
  transform: translate(-50%,-9px);
}
</style>
