<template>
  <div class="border border-primary-light rounded p-2 mt-3 font-medium">
    <button class="flex items-center justify-between w-full text-primary-dark" @click="open = !open">
      <div>{{ order }}. {{ title }} <span class="bg-grey-lighter px-1.5 rounded ml-2 rota">{{ value }}</span></div>
      <ArrowCaretIcon class="w-3 mr-1" :class="open ? 'rotate-0' : 'rotate-180'" />
    </button>
    <div v-if="open" class="mt-2 p-2 relative">
      <div v-if="blur" class="bg-white absolute p-6 shadow top-4 left-1/2 -translate-x-1/2 w-10/12 z-10 rounded font-medium border border-grey-light text-center">
        {{ blurMessage }}
      </div>
      <div :class="{'disabled-blur' : blur}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArrowCaretIcon from '@/components/Icons/ArrowCaretIcon.vue'
import { defineProps, ref, watch, onMounted } from 'vue'
const open = ref(false)

const props = defineProps({
  order: {
    type: Number,
    default: 1
  },
  title: {
    type: String,
    default: ''
  },
  value: {
    type: Number,
    default: 0
  },
  blur: {
    type: Boolean,
    default: false
  },
  blurMessage: {
    type: String,
    default: 'Completa la primera sugerencia para continuar mejorando la calidad de tu gestión'
  }
})
onMounted(() => {
  if (props.order === 1) {
    open.value = true
  }
})
watch(props.order, (value) => {
  if (props.order === 1) {
    open.value = true
  }
})
</script>

<style scoped>
.disabled-blur {
  opacity: 0.5;
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}
</style>
