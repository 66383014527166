<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_118_6699)">
  <path d="M14.112 5.33325C13.5929 4.14417 12.7381 3.13238 11.6525 2.42188C10.5668 1.71139 9.2975 1.33305 8.00003 1.33325C4.54337 1.33325 1.7007 3.96459 1.3667 7.33325" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 5.33341H14.2667C14.3192 5.33341 14.3712 5.32307 14.4197 5.30297C14.4683 5.28286 14.5124 5.2534 14.5495 5.21626C14.5866 5.17911 14.6161 5.13502 14.6362 5.08649C14.6563 5.03796 14.6667 4.98594 14.6667 4.93341V2.66675M1.92065 10.6667C2.94999 13.0214 5.29932 14.6667 8.03265 14.6667C11.49 14.6667 14.3327 12.0354 14.6667 8.66675" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.03337 10.6667H1.7667C1.66061 10.6667 1.55887 10.7089 1.48386 10.7839C1.40884 10.8589 1.3667 10.9607 1.3667 11.0667V13.3334" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_118_6699">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>

</template>
