<template>
  <svg :id="props.id" :width="props.size.width" :height="props.size.height" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(1,0,0,1,-2.2407,-1.31332)">
          <g>
              <g transform="matrix(1,0,0,1,2.51925,1.31332)">
                  <path d="M0.318,15.047C0.233,14.787 0.293,14.489 0.499,14.282L14.156,0.373C14.437,0.093 14.884,0.082 15.178,0.339C15.181,0.341 15.397,0.667 15.397,0.667C15.483,0.929 15.423,1.227 15.217,1.434L1.56,15.343C1.279,15.623 0.832,15.634 0.538,15.377C0.535,15.375 0.319,15.049 0.319,15.049L0.318,15.047Z"
                    :fill="props.color" style="fill-rule:nonzero;"/>
              </g>
              <g transform="matrix(1,0,0,1,2.51925,1.31332)">
                  <path d="M11.78,5.737L8.323,9.257C9.469,9.432 10.546,9.935 11.419,10.716C12.519,11.699 13.218,13.053 13.383,14.519C13.403,14.704 13.491,14.874 13.63,14.997C13.768,15.12 13.948,15.188 14.133,15.187L14.215,15.187C14.412,15.164 14.592,15.065 14.715,14.91C14.839,14.756 14.897,14.559 14.875,14.362C14.725,13.009 14.209,11.722 13.384,10.64C12.558,9.558 11.453,8.721 10.188,8.219C10.923,7.641 11.46,6.847 11.723,5.95C11.744,5.879 11.763,5.808 11.78,5.737ZM0.008,13.989C0.203,12.774 0.695,11.624 1.442,10.642C2.264,9.561 3.363,8.724 4.623,8.219C3.888,7.641 3.351,6.847 3.088,5.95C2.824,5.052 2.847,4.094 3.153,3.21C3.459,2.326 4.033,1.56 4.795,1.017C5.558,0.474 6.47,0.183 7.405,0.183C8.341,0.183 9.253,0.474 10.015,1.017C10.552,1.399 10.996,1.893 11.318,2.46L10.201,3.598C10.122,3.396 10.022,3.202 9.9,3.02C9.57,2.527 9.102,2.142 8.553,1.915C8.005,1.688 7.402,1.629 6.82,1.744C6.238,1.86 5.704,2.146 5.284,2.565C4.865,2.985 4.579,3.52 4.463,4.101C4.347,4.683 4.407,5.287 4.634,5.835C4.861,6.383 5.245,6.852 5.739,7.181C5.936,7.313 6.148,7.421 6.368,7.502L0.1,13.885C0.067,13.919 0.036,13.953 0.008,13.989Z"
                    :fill="props.color" style="fill-rule:nonzero;"/>
              </g>
          </g>
      </g>
  </svg>

</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-disabled-user'
  },
  color: {
    type: String,
    default: '#ffffff'
  },
  size: {
    type: Object,
    default: () => ({
      width: 16,
      height: 16
    })
  }
})
</script>
