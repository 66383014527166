import axios from 'axios'

const base = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_LOCAL_PATH : 'https://openwebinars.net'

const CancelToken = axios.CancelToken

const api = axios.create({
  baseURL: `${base}/api`
})

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_API_TOKEN) {
  api.defaults.headers.common.Authorization = `Token ${process.env.VUE_APP_API_TOKEN}`
} else {
  try {
    const token = document.cookie.split(';').filter(el => el.includes('jarvissession'))[0].trim().split('=')[1]
    api.defaults.headers.common.Authorization = `Token ${token}`
  } catch (error) {
    alert('No tienes credenciales')
    location.href = `https://openwebinars.net/accounts/login/?next=${window.location.pathname}`
  }
}

export { api, CancelToken }
