<template>
  <UiHeader  :title="'Rutas'"/>
  <UiPageBody>
    <div class="grid lg:grid-cols-2 gap-[67px] mt-5">
      <div class="shadow p-[25px] min-h-[524px] max-w-[500px] flex flex-col justify-between rounded">
        <h2 class="text-secondary-darker text-xl font-medium border-b pb-1">Crear ruta manual</h2>
        <div>
          <div class="bg-primary-lightest text-sm p-5 rounded-md grid gap-4">
            <p>Crea una ruta paso a paso, seleccionando los contenidos que compondran la ruta formativa.</p>
            <p>Busca y elige los contenidos que necesitas asignar a tus equipos o personas.</p>
          </div>
        </div>
        <div class="flex justify-center mb-4">
          <router-link data-ph="startPathManual" :to="{name: 'step-1'}"  class="btn btn-primary inline-flex">Crear ruta manual +</router-link>
        </div>
      </div>
      <div class="shadow p-[25px] min-h-[524px] max-w-[500px] flex flex-col justify-between">
        <h2 class="text-secondary-darker text-xl font-medium border-b pb-1 flex items-center justify-start">
          Crear ruta con IA
          <span class="uppercase flex text-white bg-ow-primary text-[10px] rounded-[150px] h-[20px] px-2 items-center ml-2">
            Nuevo
          </span>
        </h2>
        <div>
          <div class="bg-primary-lightest text-sm p-5 rounded-md grid gap-4">
            <p>Indica tus necesidades de formación y, en menos de un minuto, generaremos una ruta personalizada que se ajuste perfectamente a  ellas.</p>
            <p>Nuestro sistema está diseñado para entender y adaptarse a tus objetivos de aprendizaje, asegurando que cada recomendación sea relevante y útil para el desarrollo de tus equipos.</p>
            <p>Disponible temporalmente para usuarios del plan básico</p>
          </div>
        </div>
        <div>
          <div v-if="!permissions.includes('can_create_unlimited_ia_routes')" class="text-danger bg-danger-50 py-2 px-6 text-center rounded-md mb-4">
            ¡Pruébalo ahora, con usos limitados!
          </div>
          <div v-if="permissions.includes('can_create_unlimited_ia_routes')" class="flex justify-center mb-4">
            <router-link :to="{name: 'ai-step-1'}" class="btn btn-primary inline-flex">
              Crear ruta con IA
              <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
              </svg>
            </router-link>
          </div>
          <div  class="flex justify-center mb-4" v-else>
            <PopOverPro
              clain="Actualiza al Plan Pro para crear rutas con IA."
              message="Optimiza tu formación con recomendaciones personalizadas que mejorarán tu eficiencia y resultados."
              @send="send"
              :isAuthorized="canCreateAIPaths"
              position="left">
              <template v-slot:popover>
              <button data-ph="startPathMentorIA" class="btn btn-primary inline-flex">
                Crear ruta con IA
                <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
                </svg>
              </button>
              </template>
              <router-link data-ph="startPathMentorIA" :to="{name: 'ai-step-1'}" class="btn btn-primary inline-flex">
                Crear ruta con IA
                <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
                </svg>
              </router-link>
            </PopOverPro>
          </div>
        </div>
      </div>
    </div>
  </UiPageBody>
</template>

<script setup>
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
/* import { useRouter } from 'vue-router'
const $router = useRouter() */
import { api } from '../../api/instances'
import PopOverPro from '../Assistant/components/PopoverPro.vue'
const user = computed(() => store.state.user)
const store = useStore()
const active_business = computed(() => store.state.active_business)
const addToastMessage = (data) => store.commit('addToastMessage', data)
const permissions = computed(() => store.state.permissions)
const emailSendOK = ref(false)
const canCreateAIPaths = ref(false)

const send = () => {
  api.post(`/jarvis/business/${active_business.value}/stats/send-email/`, {
    subject: 'Solicitud de información de Actualiza al Plan Pro para crear rutas con IA.',
    message: `El usuario ${user.value.first_name} (${user.value.email}) de la empresa ${user.value.business[0].name} ha solicitado información de las opciones PRO para crear rutas con IA..`
  })
    .then(() => {
      console.log('Mensaje enviado correctamente.')
      emailSendOK.value = true
      addToastMessage({
        title: '¡Exito!',
        status: 'success',
        message: 'Se ha notificado la solicitud al equipo de Customer Success.'
      })
    })
    .catch(() => {
      console.error('Ha habido un problema al enviar el mensaje.')
    })
}

const canCreateAIPath = () => {
  api.get(`/jarvis/business/${active_business.value}/itineraries/can-create-ia/`)
    .then(({ data }) => {
      canCreateAIPaths.value = data.can_create_ia_path
    })
}
onMounted(() => {
  canCreateAIPath()
})
</script>
