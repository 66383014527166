<template>
  <div class="flex items-center px-5">
    <button @click="previusPage" v-if="pages.previous_page != null" class="pr-2">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6.5L11.5 6.5L11.5 5.5L11 5.5L11 6.5ZM1 6L0.639306 5.65374L0.306891 6L0.639306 6.34627L1 6ZM5.43931 11.3463L5.78557 11.707L6.50696 11.0144L6.16069 10.6537L5.43931 11.3463ZM6.16069 1.34627L6.50696 0.985572L5.78557 0.293041L5.43931 0.653735L6.16069 1.34627ZM11 5.5L1 5.5L1 6.5L11 6.5L11 5.5ZM0.639306 6.34627L5.43931 11.3463L6.16069 10.6537L1.36069 5.65374L0.639306 6.34627ZM1.36069 6.34627L6.16069 1.34627L5.43931 0.653735L0.639306 5.65374L1.36069 6.34627Z" fill="#444444"/>
      </svg>
    </button>
    <div>{{pages.actual_page}} de {{pages.total_page}}</div>
    <button @click="nextPage" v-if="pages.next_page != null" class="pl-2">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.5H0.5V6.5H1V5.5ZM11 6L11.3607 6.34627L11.6931 6L11.3607 5.65373L11 6ZM6.56069 0.653734L6.21443 0.29304L5.49304 0.985572L5.83931 1.34627L6.56069 0.653734ZM5.83931 10.6537L5.49304 11.0144L6.21443 11.707L6.56069 11.3463L5.83931 10.6537ZM1 6.5H11V5.5H1V6.5ZM11.3607 5.65373L6.56069 0.653734L5.83931 1.34627L10.6393 6.34627L11.3607 5.65373ZM10.6393 5.65373L5.83931 10.6537L6.56069 11.3463L11.3607 6.34627L10.6393 5.65373Z" fill="#444444"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Paginator',
  props: {
    pages: {
      type: Object
    },
    modelValue: {
      type: Number
    }
  },
  emits: ['change', 'update:modelValue'],
  methods: {
    previusPage () {
      this.$emit('update:modelValue', this.pages.previous_page)
      this.$emit('change')
    },
    nextPage () {
      this.$emit('update:modelValue', this.pages.next_page)
      this.$emit('change')
    }
  }

}
</script>

<style></style>
