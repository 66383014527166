<template>
  <div class="alerts-wrapper inline-block justify-start relative w-auto" @mouseover="isVisibleHelp = true" @mouseleave="isVisibleHelp = false">
      <SVGIcons class="svg-15" icon="info-about-disabled" />
      <div class="alerts" v-if="isVisibleHelp">
        <div class="flex items-center p-4 rounded shadow bg-white my-3" :class="types['warning'].text">
          <svg class="w-6 h-6 mr-4" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17.5" cy="17.5" r="17.5" :fill="types['warning'].color"/>
          <g transform="matrix(2.1119,0,0,2.1119,-885.047,-596.816)">
            <path d="M428.694,293.925C428.694,293.194 428.094,292.594 427.363,292.594C426.631,292.594 426.032,293.194 426.032,293.925C426.032,294.657 426.631,295.257 427.363,295.257C428.094,295.257 428.694,294.657 428.694,293.925ZM428.802,288.346C428.802,287.458 428.382,286.51 427.363,286.51C426.343,286.51 425.924,287.458 425.924,288.346C425.924,289.09 426.2,289.989 426.535,290.649C426.643,290.877 427.063,291.657 427.363,291.657C427.663,291.657 428.082,290.877 428.19,290.649C428.526,289.989 428.802,289.09 428.802,288.346Z" style="fill:white;fill-rule:nonzero;"/>
          </g>
        </svg>
        <span :style="`color:${types['warning'].text}`">
          <slot></slot>
        </span>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import SVGIcons from '../Layout/SVGIcons.vue'
const isVisibleHelp = ref(false)

const types = {
  danger: {
    color: '#F44336',
    text: 'text-white'
  },
  warning: {
    color: '#FDBA74',
    text: '#A16207'
  },
  success: {
    color: '#4CAF50',
    text: 'text-white'
  },
  info: {
    color: '#2196F3',
    text: 'text-white'
  },
  default: {
    color: '#E0E0E0',
    text: 'text-grey-darker'
  }
}
</script>

<style scoped>
  .alerts {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 600px;
    transform: translateX(-2%) translateY(85%);
}
.alerts-wrapper:hover .alerts {
  display: block;
}
.alerts-wrapper {
    position: relative;
    right: 10px;
}
</style>
