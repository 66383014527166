<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import HappyFaceIcon from './Icons/HappyFaceIcon.vue'
import SadFaceIcon from './Icons/SadFaceIcon.vue'
import CarelessFaceIcon from './Icons/CarelessFaceIcon.vue'
const props = defineProps({
  user: {
    type: Object,
    default: () => {
      return {
        avatar_url: 'https://openwebinars.net/static/images/onboarding/avatars/2.svg',
        first_name: 'NoName',
        last_name: 'NoLasName',
        email: 'NoEMail@NoEmail.com',
        role: '',
        cid: ''
      }
    }
  },
  avatar: {
    type: Boolean,
    default: true
  },
  borderColor: {
    type: Boolean,
    default: true
  },
  statusRequest: {
    type: String,
    default: 'old'
  },
  satisfyerStatus: {
    type: String,
    default: 'satiisfied'
  }

})
const dynamicColor = ref({})
const nameInitials = computed(() => {
  if (!props.user.first_name && !props.user.last_name) return ''
  const first = (props.user.first_name) ? props.user.first_name[0] : ''
  const last = (props.user.last_name) ? props.user.last_name[0] : ''
  return `${first}${last}`
})
const faceIcon = computed(() => {
  if (props.satisfyerStatus === 'satisfied') {
    return HappyFaceIcon
  } else if (props.satisfyerStatus === 'pasive') {
    return CarelessFaceIcon
  } else if (props.satisfyerStatus === 'not_satisfied') {
    return SadFaceIcon
  } else {
    return SadFaceIcon
  }
})
const loading = ref(true)
const loadingComplete = () => {
  loading.value = false
}
const setColor = () => {
  if (props.statusRequest === 'new') {
    dynamicColor.value = { '--color': '#356953' }
  } else if (props.statusRequest === 'almost_old') {
    dynamicColor.value = { '--color': '#F59E0B' }
  } else if (props.statusRequest === 'old') {
    dynamicColor.value = { '--color': '#EF4444' }
  }
}
watch(() => props.statusRequest, () => {
  setColor()
})
onMounted(() => {
  setColor()
})
</script>
<template>
    <div class="flex w-full items-center text-grey-dark">
      <div v-if="avatar" :style="dynamicColor" :class="{'color-border border-2' : props.borderColor}" class="flex-none rounded-full  flex items-center justify-center  w-[38px] h-[38px]">
        <figure  class="flex-none flex relative   flex-col items-center  ">
          <div class="bg-grey-lighter  rounded-full overflow-hidden flex justify-center items-center w-8 h-8">
            <span v-if="loading" class="text-avatar absolute">{{ nameInitials }}</span>
            <img class="cover avatar w-8 h-8" @load="loadingComplete" :src="user.avatar_url" alt="Avatar">
          </div>
        </figure>
      </div>
        <figure v-if="!avatar" class="text-avatar">
          <component :is="faceIcon"/>
        </figure>
        <div class="ml-2 flex 2xl:break-normal truncate w-48 xl:break-words">
          <div class="xl:w-5/6 2xl:w-full">
            <h2 class="font-semibold text-xs truncate" :title="`${user.first_name} ${user.last_name}`">{{ user.first_name }} {{ user.last_name }}</h2>
            <div class="text-xs font-normal 2xl:w-full truncate" :title="user.email">
              <span class=" truncate w-12">
                {{ user.email }}
              </span>
            </div>
          </div>
      </div>
    </div>
</template>
<style scoped>
.cover {
  object-fit: cover;
}
.color-border {
  --color: #356953;
  border-color: var(--color);
}
</style>
