<script setup>
import { api } from '@/api/instances'
import { useStore } from 'vuex'
import { ref, defineExpose, computed } from 'vue'

import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import ModalFooter from '@/components/Modals/components/ModalFooter.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'

const store = useStore()
const activeBusiness = computed(() => store.state.active_business)
const user = computed(() => store.state.user)
const addToastMessage = (data) => store.commit('addToastMessage', data)
const props = defineProps({
  title: {
    type: String
  }
})
const text_message = ref('')

const sendMessage = () => {
  api.post(`/jarvis/business/${activeBusiness.value}/stats/send-email/`, {
    subject: `${props.title} - ${user.value.email}`,
    message: text_message.value
  })
    .then(({ data }) => {
      addToastMessage({
        title: '¡Éxito!',
        message: 'Se ha enviado el mensaje correctamente',
        status: 'success'
      })
      text_message.value = ''
      open.value = false
    })
    .catch(() => {
      console.error('Ha habido un problema al enviar el mensaje.')
      addToastMessage({
        title: '¡Error!',
        message: 'Ha habido un problema al enviar el mensaje',
        status: 'error'
      })
      open.value = false
    })
}
const open = ref(false)

const modal = async (action, obj = null) => {
  if (action === 'show') {
    if (props.title) {
      title.value = props.title
    } else {
      title.value = 'Contactar con Customer Success'
    }
    open.value = true
  }
  if (action === 'hide') {
    open.value = false
  }
}
const title = ref('')

defineExpose({ modal })

const closeModal = () => {
  open.value = false
  text_message.value = ''
}
</script>
<template>
  <Overlay class="z-50" :open="open">
    <Modal>
      <ModalHeader @close="closeModal">
        <ModalTitle>
          {{ title }}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p class="text-black text-sm mt-5 mb-8">{{ props.subtitle }}</p>
            <label class="flex flex-col w-full" for="message">
              <strong class="font-bold text-grey-darkest">Mensaje:</strong>
              <textarea id="message"
             cols="30" rows="10" placeholder="Escribe aquí..."
             class="border-2 border-grey-lightest rounded mt-2 p-3 h-36 resize-none"
             v-model="text_message"
             ></textarea>
            </label>
      </ModalBody>
      <ModalFooter class="mt-5">
        <UiButton @click="closeModal" class="mr-2">Cancelar</UiButton>
        <UiButton @click="sendMessage" :class="{ 'disabled': text_message === '' }" :type="'secondary'">
          Añadir
        </UiButton>
      </ModalFooter>
    </Modal>
  </Overlay>
</template>
<style scoped>
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 3;
}

textarea::-webkit-input-placeholder {
  font-size: 14px;

}
</style>
