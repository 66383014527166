<template>
  <div
  :class="`flex rounded mb-2 py-2
  ${minHeight}
  ${width}
  ${bg}
  ${(shadow) ? 'shadow-custom' : ''}
  ${(border) ? 'border-custom' : ''}
  ${(disabled) ? 'disabled' : ''}
  ${(actions) ? 'actions' : ''}
  `">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiTableRow',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: 'w-full'
    },
    minHeight: {
      type: String,
      default: 'min-h-table-row'
    },
    bg: {
      type: String,
      default: 'bg-white'
    },
    shadow: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>
.shadow-custom {
 box-shadow: 0px 1px 26px 9px #F2F4F7;
}
.border-custom {
  border: 1px solid #F3F5F6
}
.disabled {
  opacity: 0.5;
}
.actions {
   /* pointer-events: all; */
}
</style>
