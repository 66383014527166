<template>
  <svg :id="props.id" width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z"
      stroke-width="1.5" stroke-linejoin="round" :stroke="props.color" />
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'ia-icon'
  },
  color: {
    type: String,
    default: 'white'
  }
})
</script>

<style scoped>

</style>
