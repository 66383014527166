<template>
  <button :class="`${size} ${type}`">
    <div v-if="loading" class="w-4 h-4 border-2 border-blue-400 border-dotted border-t-0 rounded-full animate-spin"></div>
    <slot v-else></slot>
  </button>
</template>
<script>

export default {
  name: 'UiButton',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'btn'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
.btn{
  @apply flex items-center py-2 px-4 rounded flex-nowrap
}
.btn-sm{
  @apply flex items-center py-1 px-3 rounded flex-nowrap
}
.btn-xs{
  @apply flex items-center py-1 px-3 text-sm rounded flex-nowrap
}
.btn-lg{
  @apply flex items-center py-1 px-6 rounded flex-nowrap
}
.secondary{
  @apply text-white bg-secondary-darker hover:bg-primary-dark
}
.danger{
  @apply text-white bg-danger hover:bg-danger-dark
}
.default{
  @apply text-grey-dark font-normal bg-grey-lightest  hover:bg-grey-lighter
}
.outline-secondary{
  @apply text-secondary-darker font-semibold border-2 border-secondary-darker  hover:bg-secondary-darker hover:text-white
}
.outline-secondary-lighter {
  @apply text-secondary-darker font-semibold border-2 border-secondary-darker  hover:bg-primary-light hover:text-secondary-darker
}
.outline-secondary-light{
  @apply text-secondary-darker font-semibold border-2 border-secondary-darker  hover:bg-litepie-secondary-200 hover:text-white
}
.outline-default{
  @apply text-grey-dark font-semibold border-2 border-grey-light  hover:bg-grey-lighter
}
.none {
  @apply text-grey-dark font-semibold border-0 bg-white hover:bg-grey-lightest
}
.light{
  @apply text-primary bg-white border-2 border-primary
}
.disabled{
  @apply text-grey bg-grey-lighter border-2 border-none pointer-events-none
}
</style>
