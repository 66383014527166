<template>
  <div id="chart">
    <VueApexChart type="treemap" :width="width" :options="chartOptions" :series="series"></VueApexChart>
  </div>

</template>
<script>
import VueApexChart from 'vue3-apexcharts'
export default {
  components: {
    VueApexChart
  },
  props: {
    skills: {
      type: Array
    },
    title: {
      type: String,
      default: 'Top Skills'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    skills_list () {
      return this.skills.map((el) => { return { x: el.name, y: el.total_users } })
    },
    max_range () {
      const num = this.series[0].data.map((el) => el.y)
      return Math.max(...num)
    }
  },
  data () {
    return {
      timer: null,
      series: [
        {
          data: [{
            x: 0,
            y: 0
          }]
        }
      ],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          type: 'treemap',
          toolbar: {
            show: false
          }
        },
        title: {
          text: this.title
        },
        plotOptions: {
          treemap: {
            distributed: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0.999,
                  color: '#CCD4E1'
                },
                {
                  from: 1,
                  to: this.max_range,
                  color: '#3F536F'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            if (val.length > 10) {
              return val.slice(0, 14) + '...'
            } else {
              return val
            }
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold'
          }
        }
      }
    }
  },
  watch: {
    skills_list () {
      this.series[0].data = this.skills_list
    },
    max_range () {
      this.chartOptions.plotOptions.treemap.colorScale.ranges[1].to = this.max_range
    }
  },
  methods: {
    truncateString (str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    },
    reset () {
      this.chartOptions.plotOptions.treemap.colorScale.ranges[1].to = this.max_range
    }
  },
  created () {
    this.series[0].data = this.skills_list
    window.addEventListener('resize', () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.reset()
      }, 300)
    })
  }
}
</script>
