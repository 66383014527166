<template>
  <svg width="575" height="244" viewBox="0 0 575 244" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_510_35120)">
  <path d="M557.195 120.465H243.002C238.579 120.469 234.339 122.228 231.211 125.355C228.084 128.482 226.325 132.722 226.321 137.145V188.064C226.325 192.487 228.084 196.727 231.211 199.854C234.339 202.982 238.579 204.74 243.002 204.744H557.195C561.618 204.74 565.858 202.982 568.985 199.854C572.113 196.727 573.871 192.487 573.875 188.064V137.145C573.871 132.722 572.113 128.482 568.985 125.355C565.858 122.228 561.618 120.469 557.195 120.465Z" fill="#F2F2F2"/>
  <path d="M107.646 97.9784C106.487 98.7101 105.502 99.6871 104.762 100.841C104.021 101.994 103.542 103.296 103.359 104.654C103.175 106.013 103.292 107.395 103.701 108.704C104.11 110.012 104.8 111.215 105.724 112.228L96.6367 131.633L107.083 140.032L119.531 112.447C121.129 110.782 122.062 108.589 122.151 106.283C122.24 103.976 121.48 101.718 120.015 99.9341C118.55 98.1506 116.482 96.9664 114.202 96.6059C111.923 96.2455 109.59 96.7339 107.646 97.9784Z" fill="#FFB8B8"/>
  <path d="M55.425 105.854C58.301 104.126 61.7316 103.572 65.0053 104.306C68.279 105.039 71.1447 107.005 73.008 109.795L91.6027 137.637L98.0582 123.406C98.4613 122.517 99.1754 121.807 100.066 121.408C100.957 121.009 101.962 120.949 102.894 121.24L112.637 124.284C113.146 124.443 113.618 124.702 114.025 125.046C114.433 125.39 114.767 125.812 115.009 126.288C115.251 126.763 115.395 127.282 115.434 127.814C115.472 128.346 115.404 128.88 115.233 129.385L108.563 149.079C107.72 151.571 106.31 153.832 104.445 155.687C102.58 157.542 100.311 158.939 97.8149 159.769C95.3191 160.6 92.6649 160.84 90.0605 160.472C87.4562 160.103 84.9726 159.136 82.8049 157.647C81.7919 156.951 80.8573 156.147 80.0174 155.25L52.6149 125.97C51.2843 124.548 50.2884 122.847 49.7004 120.99C49.1124 119.134 48.947 117.17 49.2164 115.241C49.4858 113.312 50.1832 111.468 51.2574 109.844C52.3317 108.22 53.7557 106.856 55.425 105.854Z" fill="#CCCCCC"/>
  <path d="M115.578 77.0855C116.077 77.0979 116.551 77.3081 116.895 77.6698C117.239 78.0315 117.425 78.515 117.413 79.0141L117.253 85.4768L117.577 85.5063L117.46 89.8957L117.144 89.8562L116.569 113.009C116.549 113.801 116.242 114.559 115.704 115.142C115.166 115.724 114.435 116.091 113.647 116.174L98.9251 117.724C98.5667 117.762 98.2043 117.721 97.8635 117.604C97.5226 117.486 97.2115 117.296 96.9519 117.046C96.6924 116.796 96.4907 116.492 96.3609 116.156C96.2312 115.82 96.1766 115.459 96.201 115.1L98.4338 82.1322C98.4807 81.4397 98.7617 80.7839 99.2308 80.2723C99.6999 79.7608 100.329 79.4241 101.015 79.3176L115.227 77.1097C115.343 77.0907 115.46 77.0826 115.578 77.0855Z" fill="#D0D5DD"/>
  <path d="M104.184 80.5161L111.656 79.3554C111.79 79.3345 111.914 79.2699 112.008 79.1715C112.102 79.0731 112.161 78.9464 112.176 78.8111C112.193 78.6579 112.26 78.5145 112.366 78.403C112.473 78.2916 112.613 78.2184 112.765 78.1947L114.339 77.9502C114.554 77.9169 114.773 77.931 114.982 77.9918C115.19 78.0525 115.383 78.1582 115.547 78.3016C115.71 78.445 115.84 78.6224 115.927 78.8214C116.014 79.0205 116.057 79.2362 116.052 79.4535L115.243 113.021C115.233 113.437 115.07 113.835 114.785 114.139C114.5 114.443 114.113 114.632 113.698 114.669L98.9867 115.977C98.7991 115.993 98.61 115.969 98.4325 115.906C98.2549 115.843 98.0931 115.742 97.958 115.611C97.823 115.48 97.7178 115.321 97.6498 115.145C97.5818 114.969 97.5525 114.781 97.5639 114.593L99.5489 81.9303C99.5781 81.4491 99.7711 80.9923 100.096 80.6358C100.42 80.2794 100.857 80.0446 101.334 79.9706L102.993 79.7129C103.054 79.9723 103.21 80.1991 103.431 80.348C103.652 80.4969 103.921 80.557 104.184 80.5161Z" fill="white"/>
  <path d="M67.8667 90.8672C81.4314 90.8672 92.4277 79.8708 92.4277 66.3061C92.4277 52.7415 81.4314 41.7451 67.8667 41.7451C54.302 41.7451 43.3057 52.7415 43.3057 66.3061C43.3057 79.8708 54.302 90.8672 67.8667 90.8672Z" fill="#FFB8B8"/>
  <path d="M80.5078 132.019C80.3072 125.704 78.5295 119.54 75.3367 114.088C72.1439 108.636 67.6375 104.07 62.2282 100.806C55.8829 97.0672 48.9374 95.609 43.4118 101.135C40.0938 104.536 37.4367 108.524 35.5758 112.896C30.4643 124.81 30.1299 138.233 34.6418 150.386L47.7933 186.72L78.2325 190.018C78.8043 190.08 79.3826 190.018 79.9282 189.836C80.4738 189.654 80.9737 189.356 81.394 188.964C81.8142 188.571 82.1449 188.093 82.3634 187.561C82.5819 187.029 82.6831 186.456 82.6601 185.881L80.5078 132.019Z" fill="#344054"/>
  <path d="M49.7225 182.246C49.7225 182.246 26.9812 190.736 32.9269 226.685C38.2622 258.943 34.0223 366.014 33.0956 387.658C33.0515 388.69 33.4083 389.698 34.0914 390.473C34.7745 391.247 35.7308 391.727 36.7599 391.811L50.0349 392.918C51.0746 393.004 52.1071 392.681 52.912 392.018C53.7169 391.354 54.2306 390.402 54.3435 389.364L61.1995 326.391C61.2264 326.144 61.3444 325.915 61.5304 325.751C61.7165 325.586 61.9572 325.496 62.2058 325.499C62.4543 325.502 62.6929 325.598 62.8749 325.767C63.0568 325.936 63.1692 326.167 63.1901 326.415L68.2988 387.01C68.3849 388.031 68.8594 388.98 69.6246 389.662C70.3897 390.343 71.3871 390.705 72.4112 390.672L83.5614 390.319C84.0868 390.303 84.6038 390.183 85.0828 389.966C85.5618 389.75 85.9935 389.441 86.353 389.057C86.7126 388.674 86.993 388.223 87.1782 387.731C87.3635 387.239 87.45 386.715 87.4327 386.19L80.8645 186.445L49.7225 182.246Z" fill="#344054"/>
  <path d="M72.7966 64.6872C81.0109 68.0562 91.501 64.1208 95.4725 56.1802C99.4439 48.2397 96.3009 37.4856 88.6783 32.9336C81.0556 28.3817 70.0973 30.7149 64.9902 37.9772C60.8357 30.4926 49.9523 28.5845 42.4798 32.7608C35.0074 36.937 30.9372 45.6847 30.4545 54.2314C29.9717 62.778 32.5701 71.1977 35.7398 79.1495C40.867 92.0121 57.3728 98.9378 70.143 93.5848C64.2968 84.8775 65.4509 72.1354 72.7966 64.6872Z" fill="#344054"/>
  <path d="M105.898 100.957C105.679 100.957 105.466 100.886 105.291 100.755L105.281 100.747L102.997 99.0001C102.891 98.919 102.802 98.8179 102.736 98.7025C102.669 98.5871 102.626 98.4598 102.608 98.3276C102.591 98.1955 102.6 98.0612 102.634 97.9325C102.669 97.8038 102.728 97.6831 102.809 97.5773C102.89 97.4715 102.991 97.3828 103.107 97.3161C103.222 97.2494 103.349 97.2061 103.482 97.1887C103.614 97.1712 103.748 97.18 103.877 97.2144C104.005 97.2488 104.126 97.3083 104.232 97.3894L105.711 98.5236L109.206 93.9636C109.287 93.8579 109.388 93.7692 109.504 93.7025C109.619 93.6359 109.746 93.5926 109.878 93.5751C110.011 93.5577 110.145 93.5664 110.273 93.6009C110.402 93.6353 110.523 93.6947 110.628 93.7758C110.842 93.9397 110.982 94.1817 111.017 94.4484C111.053 94.7151 110.98 94.9849 110.817 95.1985L106.706 100.56C106.611 100.683 106.488 100.783 106.348 100.852C106.208 100.92 106.055 100.956 105.899 100.955L105.898 100.957Z" fill="#0C7946"/>
  <path d="M557.49 0H243.297C238.874 0.00400616 234.634 1.76269 231.506 4.88998C228.379 8.01728 226.62 12.2576 226.616 16.6803V67.5991C226.62 72.0218 228.379 76.2621 231.506 79.3894C234.634 82.5167 238.874 84.2754 243.297 84.2794H557.49C561.913 84.2754 566.153 82.5167 569.28 79.3894C572.408 76.2621 574.166 72.0218 574.17 67.5991V16.6803C574.166 12.2576 572.408 8.01726 569.28 4.88997C566.153 1.76268 561.913 0.00400616 557.49 0ZM572.415 67.5991C572.409 71.5556 570.835 75.3484 568.037 78.1461C565.239 80.9438 561.447 82.518 557.49 82.5236H243.297C239.34 82.518 235.547 80.9438 232.75 78.1461C229.952 75.3484 228.378 71.5556 228.372 67.5991V16.6803C228.378 12.7238 229.952 8.93096 232.75 6.13329C235.547 3.33562 239.34 1.76141 243.297 1.75581H557.49C561.447 1.76141 565.239 3.33562 568.037 6.13329C570.835 8.93096 572.409 12.7238 572.415 16.6803V67.5991Z" fill="#D0D5DD"/>
  <path d="M307.236 71.1719C301.494 71.1719 295.88 69.4693 291.106 66.2792C286.332 63.0891 282.611 58.5549 280.413 53.25C278.216 47.945 277.641 42.1076 278.761 36.4759C279.881 30.8442 282.646 25.6711 286.706 21.6109C290.767 17.5506 295.94 14.7855 301.571 13.6653C307.203 12.5451 313.04 13.12 318.345 15.3174C323.65 17.5147 328.185 21.2359 331.375 26.0102C334.565 30.7845 336.268 36.3976 336.268 42.1397C336.259 49.8368 333.197 57.2161 327.755 62.6588C322.312 68.1015 314.933 71.1631 307.236 71.1719Z" fill="#0C7946"/>
  <path d="M514.622 65.8434H363.72C361.857 65.8434 360.07 65.1035 358.753 63.7864C357.436 62.4693 356.696 60.6828 356.696 58.8202C356.696 56.9575 357.436 55.1711 358.753 53.854C360.07 52.5369 361.857 51.7969 363.72 51.7969H514.622C516.485 51.7969 518.271 52.5369 519.588 53.854C520.905 55.1711 521.645 56.9575 521.645 58.8202C521.645 60.6828 520.905 62.4693 519.588 63.7864C518.271 65.1035 516.485 65.8434 514.622 65.8434Z" fill="#CCCCCC"/>
  <path d="M413.662 35.9938H363.72C361.857 35.9938 360.07 35.2539 358.753 33.9368C357.436 32.6196 356.696 30.8332 356.696 28.9706C356.696 27.1079 357.436 25.3215 358.753 24.0044C360.07 22.6872 361.857 21.9473 363.72 21.9473H413.662C415.525 21.9473 417.311 22.6872 418.628 24.0044C419.945 25.3215 420.685 27.1079 420.685 28.9706C420.685 30.8332 419.945 32.6196 418.628 33.9368C417.311 35.2539 415.525 35.9938 413.662 35.9938Z" fill="#CCCCCC"/>
  <path d="M305.753 52.3516C305.033 52.3528 304.331 52.1198 303.755 51.6876L303.719 51.6608L296.195 45.9046C295.845 45.6377 295.552 45.3046 295.332 44.9243C295.111 44.5439 294.968 44.1239 294.91 43.6881C294.852 43.2524 294.881 42.8095 294.994 42.3848C295.108 41.9601 295.304 41.5619 295.571 41.213C295.838 40.8642 296.172 40.5715 296.553 40.3517C296.933 40.1319 297.354 39.9893 297.79 39.932C298.225 39.8748 298.668 39.9041 299.093 40.0182C299.517 40.1323 299.915 40.329 300.264 40.597L305.138 44.3346L316.655 29.3089C316.922 28.9605 317.255 28.6682 317.635 28.4486C318.015 28.2289 318.435 28.0863 318.87 28.0288C319.305 27.9713 319.747 28.0001 320.171 28.1135C320.595 28.227 320.993 28.4228 321.341 28.6899L321.342 28.6906L321.271 28.7899L321.344 28.6906C322.047 29.2308 322.507 30.0276 322.623 30.9064C322.739 31.7852 322.501 32.674 321.962 33.3779L308.416 51.0436C308.102 51.4506 307.699 51.78 307.238 52.0061C306.777 52.2323 306.27 52.3491 305.756 52.3476L305.753 52.3516Z" fill="white"/>
  <path d="M422.49 137H108.297C103.874 137.004 99.6335 138.763 96.5062 141.89C93.3789 145.017 91.6202 149.258 91.6162 153.68V204.599C91.6202 209.022 93.3789 213.262 96.5062 216.389C99.6335 219.517 103.874 221.275 108.297 221.279H422.49C426.913 221.275 431.153 219.517 434.28 216.389C437.408 213.262 439.166 209.022 439.17 204.599V153.68C439.166 149.258 437.408 145.017 434.28 141.89C431.153 138.763 426.913 137.004 422.49 137Z" fill="white"/>
  <path d="M422.49 137H108.297C103.874 137.004 99.6335 138.763 96.5062 141.89C93.3789 145.017 91.6202 149.258 91.6162 153.68V204.599C91.6202 209.022 93.3789 213.262 96.5062 216.389C99.6335 219.517 103.874 221.275 108.297 221.279H422.49C426.913 221.275 431.153 219.517 434.28 216.389C437.408 213.262 439.166 209.022 439.17 204.599V153.68C439.166 149.258 437.408 145.017 434.28 141.89C431.153 138.763 426.913 137.004 422.49 137ZM437.415 204.599C437.409 208.556 435.835 212.348 433.037 215.146C430.239 217.944 426.447 219.518 422.49 219.524H108.297C104.34 219.518 100.547 217.944 97.7495 215.146C94.9518 212.348 93.3776 208.556 93.372 204.599V153.68C93.3776 149.724 94.9518 145.931 97.7495 143.133C100.547 140.336 104.34 138.761 108.296 138.756H422.49C426.447 138.761 430.239 140.336 433.037 143.133C435.835 145.931 437.409 149.724 437.415 153.68V204.599Z" fill="#D0D5DD"/>
  <path d="M172.236 208.172C166.494 208.172 160.88 206.469 156.106 203.279C151.332 200.089 147.611 195.555 145.413 190.25C143.216 184.945 142.641 179.108 143.761 173.476C144.881 167.844 147.646 162.671 151.706 158.611C155.767 154.551 160.94 151.786 166.571 150.665C172.203 149.545 178.041 150.12 183.345 152.317C188.65 154.515 193.185 158.236 196.375 163.01C199.565 167.785 201.268 173.398 201.268 179.14C201.259 186.837 198.197 194.216 192.755 199.659C187.312 205.102 179.933 208.163 172.236 208.172Z" fill="#0C7946"/>
  <path d="M379.622 202.843H228.72C226.857 202.843 225.07 202.103 223.753 200.786C222.436 199.469 221.696 197.683 221.696 195.82C221.696 193.957 222.436 192.171 223.753 190.854C225.07 189.537 226.857 188.797 228.72 188.797H379.622C381.485 188.797 383.271 189.537 384.588 190.854C385.905 192.171 386.645 193.957 386.645 195.82C386.645 197.683 385.905 199.469 384.588 200.786C383.271 202.103 381.485 202.843 379.622 202.843Z" fill="#CCCCCC"/>
  <path d="M278.662 172.994H228.72C227.797 172.994 226.884 172.812 226.032 172.459C225.18 172.106 224.406 171.589 223.753 170.937C223.101 170.285 222.584 169.51 222.231 168.658C221.878 167.806 221.696 166.893 221.696 165.971C221.696 165.048 221.878 164.135 222.231 163.283C222.584 162.431 223.101 161.657 223.753 161.004C224.406 160.352 225.18 159.835 226.032 159.482C226.884 159.129 227.797 158.947 228.72 158.947H278.662C280.525 158.947 282.311 159.687 283.628 161.004C284.945 162.321 285.685 164.108 285.685 165.971C285.685 167.833 284.945 169.62 283.628 170.937C282.311 172.254 280.525 172.994 278.662 172.994Z" fill="#CCCCCC"/>
  <path d="M170.753 189.352C170.033 189.353 169.331 189.12 168.755 188.688L168.719 188.661L161.195 182.905C160.845 182.638 160.552 182.305 160.332 181.924C160.111 181.544 159.968 181.124 159.91 180.688C159.852 180.252 159.881 179.809 159.994 179.385C160.108 178.96 160.304 178.562 160.571 178.213C160.838 177.864 161.172 177.571 161.553 177.352C161.933 177.132 162.354 176.989 162.79 176.932C163.225 176.875 163.668 176.904 164.093 177.018C164.517 177.132 164.915 177.329 165.264 177.597L170.138 181.335L181.655 166.309C181.922 165.961 182.255 165.668 182.635 165.449C183.015 165.229 183.435 165.086 183.87 165.029C184.305 164.971 184.747 165 185.171 165.114C185.595 165.227 185.993 165.423 186.341 165.69L186.342 165.691L186.271 165.79L186.344 165.691C187.047 166.231 187.507 167.028 187.623 167.906C187.739 168.785 187.501 169.674 186.962 170.378L173.416 188.044C173.102 188.451 172.699 188.78 172.238 189.006C171.777 189.232 171.27 189.349 170.756 189.348L170.753 189.352Z" fill="white"/>
  <path d="M98.1089 148.486C96.8464 147.952 95.4821 147.701 94.1122 147.751C92.7422 147.802 91.4 148.152 90.18 148.777C88.96 149.402 87.8919 150.287 87.051 151.37C86.21 152.452 85.6167 153.706 85.3127 155.043L64.1924 158.661L63.2607 172.032L93.015 166.504C95.2944 166.865 97.6274 166.379 99.5718 165.135C101.516 163.892 102.937 161.978 103.565 159.757C104.193 157.536 103.984 155.162 102.979 153.085C101.973 151.007 100.24 149.371 98.1089 148.486Z" fill="#FFB8B8"/>
  <path d="M61.8289 110.108C64.8892 111.483 67.3028 113.983 68.5694 117.09C69.8359 120.196 69.858 123.671 68.6312 126.794L56.3885 157.956L71.7582 155.131C72.7179 154.955 73.709 155.135 74.545 155.639C75.3809 156.142 76.0039 156.934 76.2968 157.865L79.3607 167.602C79.5208 168.11 79.5775 168.646 79.5275 169.177C79.4776 169.708 79.322 170.223 79.0699 170.693C78.8177 171.163 78.4742 171.578 78.0594 171.913C77.6447 172.248 77.1671 172.497 76.6547 172.645L56.6778 178.413C54.1507 179.143 51.489 179.277 48.9014 178.805C46.3139 178.332 43.8711 177.267 41.7646 175.692C39.6582 174.117 37.9454 172.075 36.7609 169.726C35.5764 167.378 34.9524 164.787 34.9378 162.157C34.9309 160.928 35.0572 159.701 35.3145 158.5L43.7085 119.286C44.1161 117.382 44.9445 115.593 46.133 114.05C47.3214 112.507 48.8399 111.25 50.5771 110.37C52.3144 109.49 54.2265 109.01 56.1733 108.964C58.1202 108.919 60.0525 109.31 61.8289 110.108Z" fill="#CCCCCC"/>
  </g>
  <defs>
  <clipPath id="clip0_510_35120">
  <rect width="574.171" height="411.085" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
