<template>
  <div class="alert-wrapp" v-if="closeAlert && role === 'admin'">
    <div class="alert-msg">
      <button @click="closeAlert = false" class="absolute right-3">
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.76636 13L0.511841 11.7L5.52993 6.5L0.511841 1.3L1.76636 0L6.78445 5.2L11.8025 0L13.0571 1.3L8.03897 6.5L13.0571 11.7L11.8025 13L6.78445 7.8L1.76636 13Z" fill="black"/>
        </svg>
      </button>
      <div class="alert-icon pr-4">
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z" fill="#FF600A"/>
        </svg>
      </div>
        <div class="alert-msg__content__text">
          <slot />
        </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const closeAlert = ref(false)
watch(() => store.state.overdue_payment, (val) => {
  closeAlert.value = val
})

const role = ref(store.state.role)
watch(() => store.state.role, (val) => {
  role.value = val
})

onMounted(() => {
  closeAlert.value = store.state.overdue_payment
})
</script>
<style>
.alert-wrapp {
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 1rem;
  width: calc(100% - 16rem);
}
.alert-msg {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0rem 0 0 1rem;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 6px;
  border: 1px solid #FF600A;
  background-color: #FFE5C6;
}
</style>
