<template>
  <svg width="150" height="118" viewBox="0 0 150 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M141.18 61.2899C144.244 56.5054 143.076 50.2968 139.819 48.2183C136.561 46.1399 130.427 47.6885 127.363 52.473C124.527 56.9016 125.464 62.6406 128.052 65.0225C128.002 65.0304 127.954 65.0477 127.911 65.0735L127.153 65.5477C127.095 65.5838 127.048 65.6341 127.015 65.6937C126.982 65.7534 126.965 65.8204 126.966 65.8885C126.966 65.9566 126.984 66.0234 127.017 66.0826C127.051 66.1419 127.099 66.1915 127.157 66.227L128.784 67.2168C128.843 67.2522 128.909 67.2722 128.977 67.2748C129.045 67.2774 129.113 67.2626 129.174 67.2318C129.234 67.201 129.286 67.1552 129.324 67.0987C129.363 67.0423 129.385 66.977 129.391 66.9092L129.466 66.0195C129.468 65.9884 129.466 65.9573 129.461 65.9266C132.714 67.2892 138.338 65.7282 141.18 61.2899Z" fill="#3F536F"/>
    <path d="M139.623 51.8513C140.121 53.4449 139.992 55.1678 139.264 56.6702C138.535 58.1726 137.261 59.3418 135.7 59.9405C135.343 60.0777 135.498 60.657 135.859 60.518C137.571 59.8727 138.972 58.6013 139.779 56.9621C140.585 55.3229 140.736 53.4387 140.202 51.6921C140.09 51.3243 139.511 51.481 139.623 51.8513Z" fill="white"/>
    <path d="M30.2488 43.8888C33.4115 39.6636 39.2001 38.6438 42.0765 40.7896C44.953 42.9354 45.6137 48.7662 42.451 52.9914C39.5235 56.9025 34.2196 57.9691 31.2983 56.5038C31.3077 56.5496 31.3083 56.5967 31.3 56.6427L31.1353 57.4556C31.1227 57.5175 31.0945 57.5753 31.0533 57.6233C31.0121 57.6713 30.9593 57.708 30.8999 57.7299C30.8405 57.7518 30.7765 57.7582 30.7139 57.7485C30.6513 57.7388 30.5923 57.7132 30.5424 57.6743L29.1477 56.5865C29.0978 56.5476 29.0586 56.4966 29.034 56.4384C29.0094 56.3801 29.0002 56.3165 29.0071 56.2537C29.014 56.1908 29.0369 56.1308 29.0736 56.0793C29.1103 56.0278 29.1596 55.9865 29.2168 55.9593L29.967 55.6031C29.9934 55.5913 30.0211 55.5826 30.0496 55.5772C27.7978 53.2007 27.315 47.8084 30.2488 43.8888Z" fill="#D0D5DD"/>
    <path d="M37.1665 42.2936C35.6194 42.5389 34.2257 43.3679 33.2731 44.6093C32.3205 45.8507 31.8817 47.4099 32.0472 48.965C32.0812 49.3212 31.5237 49.3189 31.4899 48.965C31.3304 47.2889 31.8116 45.6145 32.8369 44.2777C33.8623 42.9409 35.3559 42.0405 37.0184 41.7571C37.3692 41.6976 37.5199 42.2336 37.1665 42.2936Z" fill="white"/>
    <path d="M109.85 103.545C112.336 99.6633 111.388 94.6255 108.745 92.9391C106.102 91.2526 101.124 92.5091 98.6386 96.3913C96.3377 99.9847 97.0978 104.641 99.1973 106.574C99.1569 106.58 99.1181 106.595 99.0831 106.615L98.4682 107C98.4213 107.03 98.3827 107.07 98.3561 107.119C98.3295 107.167 98.3157 107.222 98.316 107.277C98.3164 107.332 98.3308 107.386 98.3581 107.434C98.3853 107.482 98.4244 107.523 98.4717 107.551L99.7919 108.355C99.8391 108.383 99.8929 108.399 99.9482 108.402C100.003 108.404 100.058 108.392 100.108 108.367C100.157 108.342 100.199 108.305 100.23 108.259C100.261 108.213 100.28 108.16 100.284 108.105L100.345 107.383C100.346 107.358 100.345 107.333 100.341 107.308C102.98 108.413 107.544 107.147 109.85 103.545Z" fill="#F2F2F2"/>
    <path d="M74.4238 54.8307C77.7763 54.8307 80.494 52.1176 80.494 48.7708C80.494 45.424 77.7763 42.7109 74.4238 42.7109C71.0713 42.7109 68.3535 45.424 68.3535 48.7708C68.3535 52.1176 71.0713 54.8307 74.4238 54.8307Z" fill="#2F2E41"/>
    <path d="M84.7161 107.308L82.5109 108.378L77.3281 100.396L80.5827 98.8172L84.7161 107.308Z" fill="#A0616A"/>
    <path d="M80.6289 108.513L84.8817 106.45L86.1829 109.123L79.2524 112.486C79.0815 112.135 78.9816 111.753 78.9583 111.364C78.935 110.974 78.9888 110.584 79.1166 110.215C79.2444 109.846 79.4437 109.506 79.7032 109.214C79.9627 108.922 80.2772 108.684 80.6289 108.513Z" fill="#344054"/>
    <path d="M80.3161 107.1C80.2447 107.1 80.1735 107.091 80.104 107.075C79.9714 107.043 79.8473 106.983 79.7405 106.898C79.6337 106.813 79.5468 106.706 79.4858 106.584L73.5683 94.8757C73.4715 94.6823 73.4422 94.4622 73.485 94.2503C73.5278 94.0384 73.6403 93.8469 73.8046 93.7061L77.0999 90.939C77.211 90.8443 77.3432 90.7776 77.4854 90.7446C77.6276 90.7117 77.7757 90.7133 77.9172 90.7495C78.0617 90.7855 78.1955 90.8557 78.3071 90.9542C78.4188 91.0526 78.5052 91.1764 78.5589 91.315L83.617 103.994C83.6963 104.191 83.7082 104.408 83.6507 104.612C83.5933 104.816 83.4699 104.996 83.2996 105.122L80.8637 106.919C80.7052 107.036 80.5133 107.1 80.3161 107.1Z" fill="#344054"/>
    <path d="M78.2151 112.94L75.9558 113.89L71.208 105.643L74.5425 104.24L78.2151 112.94Z" fill="#A0616A"/>
    <path d="M74.0696 113.925L78.4269 112.092L79.5831 114.831L72.4823 117.818C72.3305 117.458 72.2511 117.072 72.2487 116.682C72.2463 116.292 72.321 115.905 72.4683 115.543C72.6157 115.182 72.833 114.853 73.1078 114.575C73.3825 114.297 73.7094 114.076 74.0696 113.925Z" fill="#344054"/>
    <path d="M74.6145 112.913C74.4555 112.914 74.2993 112.871 74.1619 112.791C74.0246 112.711 73.911 112.596 73.8329 112.458C73.0104 111.004 70.7833 106.961 67.874 100.846C64.4213 93.5899 72.0689 85.5472 73.637 84.0087C73.7076 83.9392 73.7625 83.8555 73.7981 83.7631C73.8337 83.6707 73.8492 83.5718 73.8436 83.473L73.504 77.3696C73.4912 77.1479 73.5613 76.9295 73.7007 76.7565C73.8401 76.5835 74.0389 76.4683 74.2585 76.4332L83.2884 74.9707C83.4445 74.946 83.6044 74.9624 83.7522 75.0183C83.9001 75.0741 84.0307 75.1676 84.1313 75.2893C85.6568 77.138 86.3122 79.1266 86.0792 81.2003C85.6037 85.4339 81.6084 88.6304 81.1544 88.9809C81.1359 88.9951 81.1182 89.0104 81.1014 89.0265L72.9274 96.807C72.8252 96.9035 72.7547 97.0287 72.7252 97.1661C72.6957 97.3034 72.7086 97.4465 72.7622 97.5763L77.819 109.967C77.8955 110.154 77.9069 110.36 77.8512 110.554C77.7956 110.748 77.6762 110.917 77.5124 111.035L75.1426 112.743C74.9889 112.854 74.8041 112.913 74.6145 112.913Z" fill="#344054"/>
    <path d="M66.7036 70.9436C66.5014 70.9439 66.305 70.8763 66.1459 70.7517L58.8596 65.0353C58.7916 65.0592 58.7224 65.0795 58.6523 65.0962C58.2288 65.1955 57.7852 65.1673 57.3778 65.0151C56.9703 64.8628 56.6172 64.5933 56.3632 64.2407C56.1091 63.8881 55.9655 63.4683 55.9505 63.0342C55.9355 62.6001 56.0498 62.1714 56.2789 61.8021C56.508 61.4329 56.8416 61.1397 57.2375 60.9598C57.6335 60.7798 58.074 60.7211 58.5034 60.7911C58.9328 60.8611 59.3318 61.0566 59.6498 61.353C59.9679 61.6493 60.1908 62.0331 60.2904 62.456C60.3123 62.5514 60.3276 62.6482 60.3361 62.7457L66.5649 67.2634L68.9461 64.8263L71.2882 67.8873L67.2256 70.7778C67.0731 70.886 66.8906 70.9439 66.7036 70.9436Z" fill="#A0616A"/>
    <path d="M73.6872 56.3698C76.3999 56.3698 78.599 54.1745 78.599 51.4664C78.599 48.7583 76.3999 46.5629 73.6872 46.5629C70.9745 46.5629 68.7754 48.7583 68.7754 51.4664C68.7754 54.1745 70.9745 56.3698 73.6872 56.3698Z" fill="#A0616A"/>
    <path d="M76.0063 80.3824C75.6068 80.3855 75.2102 80.3147 74.8366 80.1734C74.2795 79.9574 73.8136 79.557 73.5167 79.0393C71.9997 76.3969 68.7297 68.2082 71.7751 62.1278C73.8256 58.0336 76.094 57.0603 77.6358 56.9646C79.0593 56.8768 79.335 57.1913 79.8106 58.4699C80.0744 59.179 80.5873 60.1926 81.1813 61.3663C83.3137 65.5799 86.5361 71.9474 83.8397 75.1142C80.9284 78.5333 78.152 80.3822 76.0063 80.3824Z" fill="#F63D68"/>
    <path d="M72.028 68.8368C71.8842 68.8367 71.7444 68.7894 71.6302 68.7022L67.8014 65.7856C67.7261 65.7282 67.6641 65.6553 67.6197 65.5718C67.5753 65.4884 67.5495 65.3963 67.5441 65.3019C67.5386 65.2075 67.5537 65.1131 67.5882 65.0251C67.6227 64.9371 67.6759 64.8576 67.7441 64.792L71.4238 61.2536C71.674 61.0131 71.9959 60.8605 72.3408 60.819C72.6856 60.7776 73.0346 60.8494 73.3349 61.0237C73.6352 61.198 73.8704 61.4653 74.0048 61.785C74.1393 62.1048 74.1657 62.4595 74.08 62.7956L72.6651 68.3427C72.629 68.484 72.5468 68.6093 72.4314 68.6988C72.316 68.7883 72.1741 68.8368 72.028 68.8368Z" fill="#F63D68"/>
    <path d="M75.5381 76.8699C75.063 76.8677 74.6013 76.7125 74.2216 76.4272C73.842 76.142 73.5648 75.742 73.4312 75.2868C73.3447 74.9896 73.322 74.6776 73.3647 74.3711C73.4074 74.0646 73.5144 73.7706 73.6788 73.5082C73.8432 73.2459 74.0613 73.0212 74.3188 72.8489C74.5762 72.6765 74.8672 72.5604 75.1727 72.5081C75.2695 72.4922 75.3674 72.483 75.4655 72.4804L81.9637 64.4684L79.7937 62.1344L82.9979 59.9887L85.6386 64.2141C85.7409 64.3793 85.7872 64.573 85.7705 64.7665C85.7539 64.96 85.6751 65.1429 85.5461 65.2882L77.6639 74.0924C77.6837 74.1616 77.6998 74.2317 77.7122 74.3026C77.7644 74.6076 77.7515 74.9203 77.6743 75.22C77.597 75.5197 77.4572 75.7997 77.264 76.0418C77.0708 76.2838 76.8286 76.4823 76.5532 76.6244C76.2778 76.7664 75.9754 76.8488 75.6659 76.866C75.6233 76.8685 75.5805 76.8699 75.5381 76.8699Z" fill="#A0616A"/>
    <path d="M79.8683 64.1284C79.7562 64.1282 79.646 64.0995 79.5481 64.045C79.4502 63.9904 79.3679 63.9118 79.309 63.8166L76.628 59.4749C76.4571 59.1966 76.3755 58.8728 76.3942 58.5469C76.4129 58.221 76.531 57.9086 76.7326 57.6516C76.9608 57.3642 77.2808 57.1637 77.6393 57.0836C77.9978 57.0036 78.3729 57.0487 78.702 57.2116L83.8307 59.7715C83.9278 59.8199 84.0113 59.8917 84.0739 59.9802C84.1364 60.0688 84.176 60.1715 84.189 60.2791C84.2021 60.3867 84.1882 60.4958 84.1487 60.5967C84.1091 60.6977 84.0452 60.7872 83.9625 60.8574L80.2936 63.972C80.1749 64.0728 80.0242 64.1283 79.8683 64.1284Z" fill="#F63D68"/>
    <path d="M69.6861 56.5853L73.1331 54.4657L72.8809 54.057L69.6355 56.0526C69.5503 55.8377 69.3902 55.6607 69.1847 55.5542C68.9793 55.4477 68.7422 55.4188 68.5171 55.4728C68.292 55.5268 68.0939 55.66 67.9593 55.8481C67.8248 56.0362 67.7627 56.2665 67.7846 56.4966C67.8064 56.7267 67.9107 56.9412 68.0783 57.1006C68.2459 57.2601 68.4655 57.3539 68.6968 57.3647C68.928 57.3755 69.1554 57.3026 69.3372 57.1594C69.519 57.0162 69.6428 56.8124 69.6861 56.5853Z" fill="#101828"/>
    <path d="M77.0936 43.8928C77.0936 43.8928 74.7912 46.1503 69.7934 46.863C64.7955 47.5758 68.7224 55.4162 68.7224 55.4162C68.7224 55.4162 67.2944 52.5652 70.1503 50.0705C73.0063 47.5758 77.0936 47.1002 77.0936 43.8928Z" fill="#2F2E41"/>
    <path d="M74.6471 42.2956C74.6471 42.2956 76.0751 47.285 78.217 47.9978C80.359 48.7105 79.288 56.1946 75.7181 56.9074C75.7181 56.9074 78.7525 52.809 76.9675 49.6015C75.1826 46.394 74.1116 43.8994 74.6471 42.2956Z" fill="#2F2E41"/>
    <path d="M77.9759 45.9824C77.9538 45.9777 77.9321 45.9717 77.9107 45.9645L72.3138 44.0568C72.2235 44.026 72.1428 43.9723 72.0796 43.9009C72.0163 43.8296 71.9728 43.7431 71.9532 43.6498C71.9335 43.5566 71.9385 43.4599 71.9677 43.3692C71.9968 43.2785 72.0491 43.1969 72.1193 43.1324L76.6043 39.0188C76.6767 38.9524 76.7656 38.9065 76.8618 38.886C76.958 38.8655 77.0579 38.8711 77.1511 38.9023C77.2444 38.9334 77.3276 38.9889 77.3921 39.063C77.4566 39.1371 77.5 39.2272 77.5178 39.3237L78.6297 45.3451C78.646 45.4337 78.6403 45.525 78.6129 45.6109C78.5856 45.6968 78.5375 45.7747 78.4729 45.8377C78.4083 45.9006 78.3292 45.9468 78.2425 45.972C78.1558 45.9972 78.0643 46.0008 77.9759 45.9824Z" fill="#344054"/>
    <path d="M75.376 40.2941L77.6746 40.7825L77.5502 40.1077L75.9503 39.7677L75.376 40.2941Z" fill="#E6E6E6"/>
    <path d="M74.8986 40.733L77.7791 41.3449L77.9037 42.0197L74.3242 41.2592L74.8986 40.733Z" fill="#E6E6E6"/>
    <path d="M74.4681 35.8879L75.2015 38.1527C75.2241 38.2284 75.2751 38.2924 75.3439 38.3314C75.4127 38.3703 75.4939 38.3813 75.5705 38.3619C75.6468 38.3401 75.7114 38.2893 75.7506 38.2204C75.7898 38.1515 75.8003 38.0701 75.78 37.9935L75.0466 35.7287C75.024 35.653 74.973 35.589 74.9042 35.55C74.8355 35.5111 74.7543 35.5001 74.6776 35.5195C74.6014 35.5413 74.5367 35.5921 74.4975 35.661C74.4584 35.7299 74.4478 35.8113 74.4681 35.8879Z" fill="#CCCCCC"/>
    <path d="M78.0308 35.1195L77.332 37.4909C77.2228 37.8615 77.8016 38.0197 77.9105 37.6502L78.6093 35.2788C78.7185 34.9082 78.1397 34.75 78.0308 35.1195Z" fill="#CCCCCC"/>
    <path d="M80.6156 37.2907L78.8875 39.0611C78.6175 39.3377 79.0551 39.7477 79.3244 39.4718L81.0525 37.7014C81.3225 37.4248 80.8849 37.0148 80.6156 37.2907Z" fill="#CCCCCC"/>
    <path d="M108.586 95.8869C108.99 97.18 108.886 98.5779 108.295 99.797C107.703 101.016 106.67 101.965 105.403 102.45C105.114 102.562 105.239 103.032 105.533 102.919C106.921 102.395 108.058 101.364 108.712 100.034C109.367 98.7037 109.49 97.1749 109.056 95.7577C108.965 95.4592 108.495 95.5864 108.586 95.8869Z" fill="white"/>
    <path d="M125.582 13.9743C128.645 9.18977 127.477 2.98114 124.22 0.902703C120.963 -1.17574 114.828 0.372818 111.764 5.15732C108.929 9.58595 109.866 15.325 112.453 17.7069C112.403 17.7147 112.355 17.732 112.312 17.7578L111.554 18.232C111.497 18.2682 111.449 18.3185 111.416 18.3781C111.383 18.4378 111.366 18.5048 111.367 18.5729C111.367 18.6409 111.385 18.7077 111.419 18.767C111.452 18.8262 111.501 18.8759 111.559 18.9113L113.186 19.9011C113.244 19.9366 113.31 19.9565 113.378 19.9592C113.447 19.9618 113.514 19.947 113.575 19.9162C113.636 19.8854 113.688 19.8396 113.726 19.7831C113.764 19.7266 113.787 19.6614 113.793 19.5936L113.867 18.7038C113.869 18.6728 113.867 18.6416 113.862 18.611C117.115 19.9736 122.74 18.4125 125.582 13.9743Z" fill="#344054"/>
    <path d="M124.025 4.53571C124.522 6.1293 124.394 7.8522 123.665 9.35457C122.936 10.8569 121.662 12.0262 120.101 12.6248C119.745 12.762 119.9 13.3414 120.261 13.2024C121.972 12.557 123.373 11.2857 124.18 9.64647C124.986 8.00725 125.138 6.12305 124.603 4.37649C124.491 4.00862 123.912 4.16541 124.025 4.53571Z" fill="white"/>
    <path d="M41.2401 11.6831C37.8353 7.13434 31.6037 6.03655 28.507 8.34658C25.4104 10.6566 24.699 16.9338 28.1039 21.4825C31.2555 25.6929 36.9654 26.8412 40.1104 25.2637C40.1002 25.313 40.0996 25.3637 40.1085 25.4132L40.2858 26.2883C40.2993 26.355 40.3297 26.4172 40.3741 26.4689C40.4184 26.5206 40.4753 26.5601 40.5392 26.5837C40.6032 26.6073 40.6721 26.6142 40.7394 26.6037C40.8068 26.5932 40.8704 26.5657 40.9241 26.5238L42.4256 25.3528C42.4793 25.3109 42.5214 25.256 42.5479 25.1932C42.5744 25.1305 42.5844 25.0621 42.5769 24.9944C42.5695 24.9268 42.5448 24.8622 42.5053 24.8067C42.4658 24.7512 42.4127 24.7068 42.3512 24.6775L41.5435 24.294C41.5151 24.2813 41.4852 24.272 41.4546 24.2662C43.8788 21.7077 44.3986 15.9026 41.2401 11.6831Z" fill="#F7557A"/>
    <path d="M33.7931 9.96568C35.4587 10.2298 36.9591 11.1222 37.9846 12.4587C39.0102 13.7951 39.4826 15.4737 39.3043 17.1478C39.2677 17.5313 39.8679 17.5288 39.9043 17.1478C40.076 15.3433 39.558 13.5408 38.4542 12.1017C37.3504 10.6626 35.7424 9.69327 33.9526 9.38814C33.575 9.32405 33.4127 9.90111 33.7931 9.96568Z" fill="white"/>
    <path d="M27.5713 74.2721C29.06 74.3657 30.5052 74.8102 31.7885 75.5691C33.0719 76.328 34.1568 77.3797 34.9544 78.638C35.1779 78.9947 35.4697 79.3867 35.3149 79.8258C35.261 79.9941 35.1515 80.1392 35.0043 80.2374C34.8571 80.3356 34.6808 80.381 34.5044 80.3662C34.0482 80.3108 33.7538 79.8652 33.6976 79.4383C33.6338 78.9541 33.9761 78.5739 34.3986 78.394C35.431 77.9543 36.4727 78.6338 37.3137 79.1761C39.23 80.4117 41.3378 81.5291 43.6598 81.6699C45.8082 81.8002 48.112 80.9593 49.307 79.0994C49.5161 78.774 48.997 78.4733 48.789 78.7971C47.6039 80.6416 45.208 81.3041 43.1249 81.019C41.8835 80.8235 40.6848 80.4172 39.5807 79.8178C39.0082 79.5211 38.4529 79.1923 37.9088 78.8466C37.391 78.486 36.8423 78.1719 36.2689 77.9082C35.3127 77.516 34.0468 77.5467 33.3788 78.4495C32.7557 79.2918 33.2373 80.694 34.2795 80.9308C35.2672 81.1552 36.1325 80.2279 35.9293 79.2582C35.8253 78.7622 35.4512 78.2769 35.1616 77.8714C34.2915 76.6495 33.1578 75.638 31.8442 74.9114C30.5306 74.1848 29.0704 73.7617 27.5713 73.6732C27.1854 73.6541 27.1864 74.2531 27.5713 74.2721Z" fill="#3F536F"/>
    <path d="M117.444 73.7599C116.069 74.4919 114.535 74.8723 112.977 74.867C111.419 74.8618 109.887 74.471 108.518 73.7298C108.207 73.5591 107.947 73.3643 107.912 72.9861C107.893 72.8303 107.923 72.6725 107.996 72.5337C108.069 72.3948 108.183 72.2813 108.322 72.208C108.752 71.9981 109.265 72.243 109.534 72.6014C109.824 72.987 109.708 73.4872 109.44 73.8503C108.747 74.7892 107.437 74.7002 106.401 74.6793C104.168 74.6343 101.821 74.8123 99.8214 75.8949C97.9424 76.9121 96.4333 78.8203 96.3917 81.0157C96.3844 81.4013 96.9843 81.4013 96.9916 81.0157C97.0327 78.8492 98.6729 77.0638 100.561 76.1941C101.734 75.6868 102.987 75.3924 104.263 75.3247C104.885 75.2784 105.51 75.2662 106.134 75.2738C106.757 75.3067 107.381 75.2934 108.002 75.234C109.072 75.0876 110.224 74.399 110.303 73.2102C110.374 72.1344 109.15 71.2217 108.141 71.6365C107.956 71.7192 107.791 71.8408 107.658 71.993C107.525 72.1452 107.426 72.3243 107.368 72.5182C107.31 72.712 107.295 72.916 107.324 73.1162C107.353 73.3164 107.424 73.508 107.534 73.678C107.804 74.0885 108.284 74.2985 108.714 74.5018C110.132 75.173 111.688 75.5024 113.257 75.4634C114.826 75.4243 116.364 75.018 117.747 74.2771C118.085 74.0916 117.783 73.5743 117.444 73.7599Z" fill="#FF6584"/>
    <path d="M90.3413 34.9239C93.0402 35.8508 94.9302 38.5819 95.0906 41.3938C95.172 42.8211 94.8329 44.4711 93.7665 45.4982C93.5306 45.7477 93.236 45.9344 92.9096 46.0416C92.5831 46.1487 92.235 46.1728 91.8969 46.1117C91.308 45.984 90.6936 45.5831 90.5929 44.9463C90.4956 44.3312 90.9167 43.8229 91.4272 43.5426C91.7171 43.3857 92.0356 43.2888 92.3639 43.2577C92.6921 43.2266 93.0233 43.2619 93.3375 43.3616C94.1703 43.62 94.8755 44.1744 95.5478 44.7095C96.2039 45.2527 96.9016 45.7438 97.6345 46.1782C100.526 47.8406 103.953 48.3096 107.186 47.4851C110.419 46.6605 113.201 44.6078 114.939 41.7646C115.15 41.4233 115.343 41.0722 115.519 40.7114C115.689 40.3666 115.171 40.0627 115.001 40.4091C114.238 41.9817 113.146 43.3721 111.797 44.486C110.449 45.6 108.876 46.4114 107.186 46.8653C105.496 47.3192 103.728 47.4049 102.002 47.1167C100.276 46.8285 98.6316 46.173 97.1815 45.1947C95.785 44.2606 94.446 42.6556 92.6297 42.6441C91.2922 42.6357 89.6836 43.6449 90.0263 45.164C90.1822 45.8551 90.7672 46.3529 91.4085 46.5922C91.7532 46.7197 92.1218 46.7703 92.4882 46.7402C92.8547 46.7102 93.2101 46.6004 93.5294 46.4184C94.9458 45.6245 95.5643 43.8619 95.6818 42.3303C95.9127 39.3209 94.2085 36.1995 91.531 34.7886C91.1995 34.615 90.8551 34.4671 90.5008 34.3464C90.1349 34.2207 89.9778 34.7991 90.3413 34.9239Z" fill="#E6E6E6"/>
  </svg>
</template>

<script setup>

</script>

<style scoped>

</style>
