<template>
  <Visibility :permission="permission">
    <hr :class="(border) ? 'border-grey-lighter' : 'border-grey-lightest'">
    <li class="flex items-center hover:bg-grey-lightest">
      <button @click.prevent="func()" type="button" :disabled="disabled" class="menu-item">
        <SVGIcons class="svg-15 mr-3 mt-1" :icon="icon" />
        <span :class="[{'text-danger': type == 'danger'}, {'text-grey-lighter' : disabled}]" v-html="text"></span>
      </button>
      <DisabledMsg v-if="disabled" :disabledMsg="disabledMsg">
        <span v-html="disabledMsg"></span>
        <span v-html="disableExtraMsg"></span>
        Si deseas activarlo, por favor, <button @click="$refs['contactCustomer'].modal('show')" class="underline text-primary">contacta con tu Customer Success</button>
      </DisabledMsg>
    </li>
    <ContactToCustomerModal ref="contactCustomer" />
  </Visibility>
</template>

<script>
import SVGIcons from '../Layout/SVGIcons.vue'
import Visibility from '../Visibility.vue'
import DisabledMsg from './DisabledMsg.vue'
import ContactToCustomerModal from '../Modals/ContactToCustomerModal.vue'
export default {
  name: 'ItemMenu',
  props: {
    type: String,
    text: String,
    permission: String,
    func: Function,
    border: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'default'
    },
    disabledMsg: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SVGIcons,
    Visibility,
    ContactToCustomerModal,
    DisabledMsg
  }
}
</script>

<style scoped>
  .menu-item{
    @apply flex text-sm py-2 px-4 hover:bg-grey-lightest whitespace-nowrap w-full
  }

</style>
