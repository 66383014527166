<template>
  <div class="relative flex flex-col p-3 mt-2 rounded-lg bg-grey-lightest" v-click-outside="hide">
    <div>
    <SVGIcons class="absolute top-4 svg-18" icon="search"/>
    <form class="w-full" @submit.prevent="setSubmit">
      <input v-model="search" @keyup="antispam" id="search" class="w-full bg-grey-lightest text-xs px-6 outline-none" type="text" :placeholder="placeholder">
      <button id="cross" @click.prevent="hide($event)" v-if="search != ''">
        <svg class="absolute top-5 right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
      </button>
    </form>
    </div>
    <ul v-if="users.length > 0" class="absolute left-0 bg-grey-lightest top-14 z-30 w-full rounded overflow-auto custom-scroll shadow-lg" style="max-height:15rem">
      <li class="py-1.5 border-t px-3 cursor-pointer hover:bg-white border-white " v-for="user in filtered_users" :key="user.id">
        <UserIdentity :user="user" :text="'dark'" @click.prevent="addManager(user)" />
      </li>
    </ul>
    <ul v-if="result" class="absolute left-0 bg-grey-lightest top-14 z-30 w-full rounded overflow-auto custom-scroll shadow-lg">
      <li class="py-1.5 border-t px-3 cursor-pointer hover:bg-white border-white text-sm">
        Sin resultados
      </li>
    </ul>
  </div>
</template>

<script>
import SVGIcons from './Layout/SVGIcons.vue'
import UserIdentity from './UserIdentity.vue'
import { mapState } from 'vuex'
import { api } from '../api/instances'

export default {
  name: 'SearchUserField',
  components: {
    SVGIcons,
    UserIdentity
  },
  props: {
    modelValue: {
      type: Array
    },
    placeholder: {
      type: String,
      default: 'Buscar por nombre o email'
    },
    not_reset: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      search: '',
      users: [],
      managers: [],
      result: false,
      timer: null
    }
  },
  watch: {
    modelValue (val) {
      this.managers = val
    }
  },
  computed: {
    ...mapState(['active_business']),
    filtered_users () {
      const managers_ids = this.modelValue.map(el => el.employee_id)
      return this.users.filter((el) => !managers_ids.includes(el.employee_id))
    }
  },
  methods: {
    antispam () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getUsers()
      }, 450)
    },
    addManager (obj) {
      const managers_ids = this.managers.map(el => el.employee_id)

      if (managers_ids.includes(obj.employee_id)) {
        return null
      }
      this.managers.push(obj)
      this.update()
      this.hide()
    },
    async getUsers () {
      this.result = false
      if (this.search.length < 3) {
        this.users = []
        return
      }
      await api.get(`/jarvis/business/${this.active_business}/managed-users/`,
        {
          params: {
            limit: 10,
            department_id: this.team_id,
            search: this.search
          }
        })
        .then(({ data }) => {
          this.users = data.results
          if (this.users.length === 0) {
            this.result = true
            setTimeout(() => {
              this.result = false
            }, 2000)
          }
        })
    },
    hide (e) {
      if (e !== undefined) {
        if (e.pointerId === 1 && this.not_reset) {
          this.search = ''
          this.users = []
        }
      } else {
        this.search = ''
        this.users = []
      }
    },
    update () {
      this.$emit('update:modelValue', this.managers)
    },
    setSubmit () {
      this.$emit('change')
    }
  },
  created () {
    this.getUsers()
    this.managers = this.modelValue
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style></style>
