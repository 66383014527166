<template>
  <div class="w-full p-3 rounded-lg">
    <dl>
      <dt class="flex items-center justify-center mx-auto  rounded-full text-center text-primary  font-extrabold text-[32px]">
        {{$filters.toLocaleNumber(value)}}
      </dt>
      <dd class="text-primary text-xs text-center font-bold mt-2">
        {{title}}
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'ValueBox',
  props: {
    title: {
      type: String,
      default: 'Cargando...'
    },
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>
