<script setup>
import { ref, defineExpose, computed } from 'vue'
import { api } from '@/api/instances'
import { useStore } from 'vuex'
import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import Balloon from '@/components/Balloon.vue'
import UiTableCell from '@/components/Layout/UI/Table/UiTableCell.vue'
import UiTableRow from '@/components/Layout/UI/Table/UiTableRow.vue'
import ArrowIcon from '../Icons/ArrowIcon.vue'
import UserIdentity from '../UserIdentity.vue'
import MenuButtonPlanner from '../MenuButtonPlanner.vue'
import UpdateIcon from '../Icons/UpdateIcon.vue'
import LoadingComponent from '../LoadingComponent.vue'

const store = useStore()
const activeBusiness = computed(() => store.state.active_business)
const open = ref(false)

const modal = async (action, obj = null) => {
  if (action === 'show') {
    open.value = true
    if (obj) {
      listRequest.value = obj.items
      number.value = obj.index + 1
      chargeRotateSuggestions(obj.items[obj.index].team_id)
    }
  }
  if (action === 'hide') {
    open.value = false
  }
}

defineExpose({ modal })

const closeModal = () => {
  open.value = false
}
const number = ref(1)
const addNumber = (team_id) => {
  if (listRequest.value.length > number.value) {
    number.value = number.value + 1
  } else {
    number.value = 1
  }
  chargeRotateSuggestions(team_id)
}
const subtractNumber = (team_id) => {
  if (number.value > 1) {
    number.value = number.value - 1
    chargeRotateSuggestions(team_id)
  } else {
    number.value = listRequest.value.length
  }
  chargeRotateSuggestions(team_id)
}
const addToastMessage = (data) => store.commit('addToastMessage', data)
const listRequest = ref([])
const suggestionUser = ref({})
const emit = defineEmits(['change'])
const answerRequest = async (student_id, accepted) => {
  loadingRotate.value = true
  await api.post(`/jarvis/business/${activeBusiness.value}/v2/requests/answer/${student_id}/`, { accepted })
    .then(({ data }) => {
      addToastMessage({
        status: 'success',
        message: data.text
      })
      listRequest.value = data.data
      if (data.data.length === 0) {
        closeModal()
      }
      emit('change')
    })
    .catch(({ data }) => {
      addToastMessage({
        status: 'danger',
        message: data.text
      })
    })
    .finally(() => {
      loadingRotate.value = false
    })
}
const chargeRotateSuggestions = async (team_id) => {
  await api.get(`/jarvis/business/${activeBusiness.value}/teams/${team_id}/rotate-suggestions/`)
    .then(({ data }) => {
      suggestionUser.value = data
    })
    .catch(() => {
      console.error('Error al obtener la sugerencia')
    })
}
const updateLicenseState = async (student_id, state, student_id_two) => {
  await api.patch(`/jarvis/business/${activeBusiness.value}/users/${student_id}/`, { is_active: state })
    .then(({ data }) => {
      answerRequest(student_id_two, true)
      addToastMessage({
        status: 'success',
        message: 'Licencia rotada con éxito'
      })
      chargeRotateSuggestions(listRequest.value[number.value].team_id)
    })
    .catch(({ data }) => {
      addToastMessage({
        status: 'danger',
        message: 'Ha habido un problema'
      })
    })
    .finally(() => {
      loadingRotate.value = false
    })
}
const loadingRotate = ref(false)
const rotateLicense = (student_id_one, student_id_two) => {
  loadingRotate.value = true
  updateLicenseState(student_id_one, false, student_id_two)
  // loadingRotate.value = false
}
const formatterDate = (date) => {
  if (date) {
    const fecha = new Intl.DateTimeFormat('en-EN', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(date))
    const fecha2 = new Date()
    const fechaFormated = new Date(fecha)

    const diferencia = fecha2.getTime() - fechaFormated.getTime()
    const diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24
    return (diasDeDiferencia.toFixed(0) === '1') ? 'Hoy' : `${diasDeDiferencia.toFixed(0)} días`
  } else {
    return '-'
  }
}
</script>
<template>
  <Overlay class="z-50" :open="open">
    <Modal class="mt-24 relative">
      <ModalHeader class="mx-16" @close="closeModal">
        <ModalTitle >
          <div class="flex items-center">
            Solicitudes de licencia
            <Balloon class="ml-3" :type="'secondary'"> {{ listRequest.length }}</Balloon>
          </div>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <section class="mb-7 flex items-center">
          <button @click="subtractNumber(listRequest[number - 1].team_id)" class="mr-10">
            <ArrowIcon/>
          </button>
          <div class="w-full flex flex-col justify-center">
            <section>
              <h3 class="mb-3 text-sm font-medium text-[#404040]">Solicitud {{ number }}/{{ listRequest.length }}</h3>
              <div v-for="{ id, has_planner_available, has_licenses, user_id, team_id, avatar_url, first_name, email, last_name, team, last_activity_ts, requests_status, last_requests_ts } in listRequest.slice(number - 1, number)" :key="id">
                <UiTableRow
                  class="text-[#626262] font-medium w-full ">
                  <UiTableCell first class="w-5/12">
                    <UserIdentity truncate :user="{ user_id, avatar_url, first_name, email, last_name }"  dark :statusRequest="requests_status"/>
                  </UiTableCell>
                  <UiTableCell class="w-3/12 text-sm font-medium">
                    {{ team }}
                  </UiTableCell>
                  <UiTableCell  class="w-3/12 text-sm font-medium">
                    <div class="flex flex-col items-center justify-center">
                      <span>Última Formación</span>
                      {{ $filters.shortDate(last_activity_ts) }}
                    </div>
                  </UiTableCell>
                  <UiTableCell  class="w-3/12 text-sm font-medium">
                    <div class="flex flex-col items-center justify-center">
                      <span>Solicitud</span>
                      {{ $filters.shortDate(last_requests_ts) }}
                    </div>
                  </UiTableCell>
                </UiTableRow>
                <div class="flex justify-end items-center">
                  <UiButton :type="'btn-danger'" @click="answerRequest(id, false)">Rechazar</UiButton>
                  <MenuButtonPlanner :has_planner_available="has_planner_available" :team_id="team_id" :employee_id="id" class="mr-2"/>
                  <UiButton :disabled="has_licenses === 0" :type="(has_licenses >= 1) ?'btn-primary' : 'btn-disabled pointer-events-none'" @click="answerRequest(id, true)">Activar licencia</UiButton>
                </div>
              </div>
            </section>
            <section v-if="suggestionUser" class="mt-5">
              <h2 class="text-base text-[#1D2939] font-semibold mb-5">Sugerencia de rotación de licencia </h2>
              <UiTableRow v-for="{ id, user_id, avatar_url, first_name, email, last_name, last_activity_ts} in listRequest.slice(number - 1, number)" :bg="'bg-[#12B76A0D]'" :key="id"
                class="text-[#626262] font-medium w-full ">
                <UiTableCell first class="w-5/12">
                  <UserIdentity :user="{ user_id, avatar_url, first_name, email, last_name }" :borderColor="false" />
                </UiTableCell>
                <UiTableCell  class="w-4/12 text-xs font-light">
                  Ultima activación de licencia: {{ $filters.shortDate(last_activity_ts) }}
                </UiTableCell>
                <UiTableCell class="w-3/12">
                  <span class="font-medium text-[#12B76A]">Activar licencia</span>
                </UiTableCell>
              </UiTableRow>
              <UiTableRow :bg="'bg-[#F322221A]'"
                class="text-[#626262] font-medium w-full ">
                <UiTableCell first class="w-5/12">
                  <UserIdentity :user="suggestionUser" :borderColor="false" dark />
                </UiTableCell>
                <UiTableCell class="w-4/12 text-xs font-light">
                  Usuario inactivo desde hace: {{ formatterDate(suggestionUser.last_academy_activity) }}
                </UiTableCell>
                <UiTableCell class="w-3/12">
                  <span class="font-medium text-[#D44141]">Desactivar licencia</span>
                </UiTableCell>
              </UiTableRow>
                <div class="flex justify-end items-center">
                  <UiButton :type="'btn-primary'" @click="rotateLicense(suggestionUser.id, listRequest[number - 1].id)">
                    <span>
                      {{ (loadingRotate) ? 'Rotando licencia...' : 'Rotar licencia' }}
                    </span>
                    <UpdateIcon class="ml-2 " :class="{'animate-spin' : loadingRotate}"/>
                  </UiButton>
                </div>
            </section>
          </div>
          <button @click="addNumber(listRequest[number - 1].team_id)" class="ml-10 flex items-center">
            <ArrowIcon class="rotate-180"/>
          </button>
        </section>
      </ModalBody>
      <div v-if="loadingRotate" class="absolute w-full h-full position-load">
        <LoadingComponent />
      </div>
    </Modal>
  </Overlay>
</template>
<style scoped>
.position-load {
  top: 0;
  left: 0;
  transform: translate( 0%, 0%);
}
</style>
