import { createApp, nextTick } from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters'
import '@/assets/styles/main.css'
import 'animate.css'
import '@/assets/styles/c3.min.css'
import posthogPlugin from './plugins/posthog'
import { api } from './api/instances'

api.get('/jarvis/business/me/')
  .then(({ data }) => {
    if (data.business.length > 0) {
      window.dataLayer.push({ userId: data.id })
      window.dataLayer.push({ isSuperuser: data.is_superuser })
      window.dataLayer.push({ user_id: data.id })
      window.dataLayer.push({ user_email: data.email })
      window.dataLayer.push({ user_role: data.user_role })
      window.dataLayer.push({ active_plan: data.active_plan })
      window.dataLayer.push({ business_kind: data.business[0].business_kind })
      window.dataLayer.push({ business_type: data.business[0].business_kind })
      window.dataLayer.push({ business_status: data.business[0].business_stage })
      window.dataLayer.push({ business_id: data.business[0].business_id })
      window.dataLayer.push({ business_name: data.business[0].name })
      window.dataLayer.push({ business_number_of_employees: data.business[0].business_number_of_employees })
      window.dataLayer.push({ business_number_of_students: data.business[0].business_number_of_students })
      window.dataLayer.push({ business_number_of_seats: data.business[0].business_number_of_seats })
      window.dataLayer.push({ business_arr: data.business[0].business_arr })
      window.dataLayer.push({ business_seats_assigned_rate: data.business[0].business_seats_assigned_rate })
      window.dataLayer.push({ business_seats_unassigned_rate: data.business[0].business_seats_unassigned_rate })
      window.dataLayer.push({ business_seats_low_performance: data.business[0].business_seats_low_performance })
      window.dataLayer.push({ business_number_of_pending_requests: data.business[0].business_number_of_pending_requests })
      window.dataLayer.push({ staff_role: data.business[0].role })
      const app = createApp(App)
      app.config.globalProperties.$filters = filters
      if (process.env.NODE_ENV !== 'development') {
        Sentry.init({
          app,
          dsn: 'https://32faea9fbe5b4b2b9d6e6f14273d4ff4@o59538.ingest.sentry.io/6242187',
          integrations: [
            new BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
              tracingOrigins: ['app.openwebinars.net', /^\//]
            })
          ],
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0
        })
        router.afterEach((to, from, failure) => {
          if (!failure) {
            nextTick(() => {
              app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath })
            })
          }
        })
      }
      app.use(store).use(router).use(posthogPlugin).mount('#app')
      if (data.business[0].business_id === undefined) {
        router.push({ name: 'organizations' })
      }
    } else {
      alert('¡Eh tú! ¿dónde vas? Te has equivocado de sitio.')
      location.href = 'https://openwebinars.net/'
    }
  })
  .catch(error => {
    if (error.response && error.response.status === 401) {
      alert('No está autorizado para acceder a esta zona')
      location.href = 'https://openwebinars.net/'
    } else {
      alert(error)
    }
  })
