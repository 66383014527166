<template>
  <div>
    <input
      type="range"
      class="opacity-0 form-range appearance-none-- w-full h-2 p-0 bg-white focus:outline-none focus:ring-0 focus:shadow-none"
      :min="min"
      :max="max"
      :step="step"
      v-model="custom_value"
    />
    <div class="bg-grey-lighter  pointer-events-none h-2 absolute top-2 rounded-r" :style="`width:100%;`"></div>
    <div
      class="bg-secondary pointer-events-none h-2 absolute top-2 rounded-l"
      :style="`width:${((custom_value) * 100) / this.max - 1}%;`"></div>
    <div
      class="bg-white border-2 border-secondary h-4 w-4 absolute rounded-full"
      :style="`top:4px;pointer-events: none;left:${((custom_value) * 100) / this.max - 1.35}%;`"></div>
  </div>
  <strong class="text-center absolute -top-4" :style="`left:${((custom_value) * 100) / this.max - 1.25}%;`">
    <span class="absolute whitespace-nowrap text-sm text-primary" style="left:50%; transform: translateX(-20%);">
      <slot></slot>
    </span>
  </strong>
  <ul class="flex justify-between">
    <li :class="`w-1/${ruler.steps.length+1} relative`">
      <span class="absolute -left-3 text-grey-darker font-semibold">{{ruler.init}}</span>
    </li>
    <li
      v-for="step in ruler.steps"
      :key="step"
      :class="`w-1/${ruler.steps.length+1} relative`">
      <span class="absolute -left-6 text-grey-darker  font-semibold">{{step}}</span>
    </li>
    <span class="absolute -right-3 text-grey-darker  font-semibold">{{ruler.end}}</span>
  </ul>
</template>

<script>
export default {
  name: 'CustomRange',
  props: {
    modelValue: {
      type: Number
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    ruler: {
      type: Object,
      default: () => ({
        init: '30min',
        steps: [
          '10h',
          '20h',
          '30h'
        ],
        end: '40h'
      })
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      custom_value: 0
    }
  },
  watch: {
    custom_value (val) {
      this.$emit('update:modelValue', val)
    },
    modelValue () {
      this.custom_value = this.modelValue
    }
  },
  created () {
    this.custom_value = this.modelValue
    if (this.custom_value < this.min) {
      this.custom_value = this.min
    }
  }
}
</script>

<style>

</style>
