<template>
  <footer class="container mx-auto">
    <nav class="flex items-center justify-between mb-5 mt-5 text-grey-dark">
      <PageLimit v-model="limit" :count="pages.count" @change="page = 1, update()"/>
      <Paginator :pages="pages"  v-model="page" @change="update"/>
    </nav>
  </footer>
</template>

<script>
import Paginator from '../components/Paginator.vue'
import PageLimit from '../components/PageLimit.vue'
export default {
  name: 'TableFooter',
  components: {
    Paginator,
    PageLimit
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return { limit: 10, page: 1 }
      }
    },
    pages: {
      type: Object
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      limit: 10,
      page: 1
    }
  },
  watch: {
    modelValue (val) {
      this.limit = val.limit
    }
  },
  methods: {
    update () {
      this.$emit('update:modelValue', { limit: this.limit, page: this.page })
      this.$emit('change')
    }
  },
  created () {
    this.limit = this.modelValue.limit
  }
}
</script>

<style></style>
