<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold">Editar ruta</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <form @submit.prevent="editItinerary()" id="create-edit-user">
      <section class="pt-4">
        <nav class="px-12 border-b-2 border-grey-lighter">
          <ul class="flex">
            <li class="flex-none text-xl text-primary font-semibold border-b-2 border-primary pb-4 pr-1">Dedicación de la ruta</li>
          </ul>
        </nav>
        <article class="px-12 py-3">
            <fieldset>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-full" for="name">
                  <strong class="font-medium text-xl text-grey-dark">Dedicación semanal</strong>
                  <p class="text-sm mt-2 text-grey">Selecciona un objetivo semanal para tu equipo y motívalos a realizar la ruta en su tiempo óptimo de aprendizaje.</p>
                  <div class="bg-grey-lighter-2 pt-2 px-12 pb-10 mt-5 rounded">
                    <div class="relative">
                      <div class="relative mt-7">
                        <CustomRange
                          :min="0.5"
                          :max="40"
                          :step="0.5"
                          v-model="weekly_dedication"
                          :ruler="{
                            init: '30min',
                            steps: [
                                  '10h',
                                  '20h',
                                  '30h'
                                  ],
                            end: '40h'
                            }"
                          >{{time}}
                        </CustomRange>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            <div class="mt-2 flex w-full">
              <div class="bg-grey-lighter-2 px-8 pb-5 pt-4 mt-4 rounded w-full">
                <h2 class="text-lg font-semibold text-grey-dark">Fecha de finalización</h2>
                <hr class="border-grey-lighter my-2">
                <p>Dedicando <strong class="text-primary">{{time_full}}</strong> a la semana la ruta será finalizado en <strong class="text-primary">{{ estimate_weeks}} semana{{(estimate_weeks > 1)? 's' : ''}}</strong>.</p>
                <p>Si se inicia hoy la ruta, la fecha de finalización será el <strong class="text-primary">{{$filters.shortDate(finish_date)}}</strong>.</p>
              </div>
            </div>
          </fieldset>
        </article>
      </section>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <UiButton @click="changeState(false)" class="mr-3" :type="'outline-default'">Cancelar</UiButton>
        <UiButton :type="'secondary'" @click="validateForm">Guardar cambios</UiButton>
      </footer>
      </form>
    </div>
  </div>
  </transition>
</template>
<script>
import CustomRange from '../CustomRange.vue'
import UiButton from '../Layout/UI/UiButton.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'AddUserModal',
  components: {
    CustomRange,
    UiButton
  },
  prop: ['id'],
  emits: ['modal:show', 'modal:hide', 'success'],
  data () {
    return {
      open: false,
      itinerary_id: this.id,
      weekly_dedication: 2.5,
      duration_in_hours: 1
    }
  },
  computed: {
    ...mapState(['active_business']),
    time () {
      const hours = parseInt(this.weekly_dedication)
      const minutes = this.weekly_dedication % 1
      return `${(hours > 0) ? hours + 'h' : ''} ${(minutes === 0.5) ? '30min' : ''}`
    },
    time_full () {
      const hours = parseInt(this.weekly_dedication)
      const minutes = this.weekly_dedication % 1
      return `${(hours > 0) ? hours : ''} ${(hours === 1) ? 'hora' : (hours > 0) ? 'horas' : ''} ${(hours > 0 && minutes === 0.5) ? ' y ' : ''} ${(minutes === 0.5) ? '30 minutos' : ''}`
    },
    finish_date () {
      const date = new Date()
      date.setDate(date.getDate() + parseInt((this.duration_in_hours * 2) / this.weekly_dedication) * 7)
      return date
    },
    estimate_weeks () {
      return Math.ceil((this.duration_in_hours * 2) / this.weekly_dedication)
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getItinerary (itinerary_id) {
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${itinerary_id}/`)
        .then(({ data }) => {
          this.weekly_dedication = data.weekly_dedication
          this.duration_in_hours = data.duration_in_hours
        })
        .catch(() => {
          console.error('Ha habido un problema para cargar la ruta')
        })
    },
    async validateForm () {
      api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/`,
        {
          weekly_dedication: this.weekly_dedication
        })
        .then(() => {
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'la ruta se ha actualizado correctamente.'
          })
          this.$emit('success')
          this.changeState(false)
        })
        .catch(() => {
          console.error('Ha habido un problema al actualizar la ruta')
        })
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }

   .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white
  }

</style>
