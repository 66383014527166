<template>
  <div class="flex w-full items-center" :class="(dark || text == 'dark')?'text-grey-dark':'text-primary-lightest'">
      <figure v-if="avatar == true" class="flex-none flex relative flex-col items-center">
        <div class="bg-grey-lighter rounded-full overflow-hidden flex justify-center items-center" :class="(xl)?'w-12 h-12':'w-8 h-8'">
          <span v-if="loading" class="text-avatar absolute">{{nameInitials}}</span>
          <img class="cover avatar" @load="loadingComplete" :src="user.avatar_url" alt="Avatar" :class="(xl)?'w-12 h-12':'w-8 h-8'">
        </div>
        <figcaption class="" v-if="user.is_disabled">Baja</figcaption>
        <figcaption :class="roles[user.role].color" v-if="user.role !='' && roles[user.role]">{{roles[user.role].text}}</figcaption>
      </figure>
      <figure v-if="avatar == false" class="text-avatar bg-grey-lighter">
        <span>{{user.first_name[0]}}{{user.last_name[0]}}</span>
      </figure>
      <div class="email-container ml-2 flex 2xl:break-normal xl:break-words" :class="{'truncate w-48' : truncate}">
        <div class="xl:w-5/6 2xl:w-full" :class="(large) ? 'xl:w-full' : 'xl:w-full'">
          <h2 class="font-semibold" :title="`${user.first_name} ${user.last_name}`" :class="[(xl)?'text-md':'text-xs', { 'truncate': truncate } ]">{{user.first_name}} {{user.last_name}}</h2>
          <div class="text-xs font-normal w-full truncate" :title="user.email" :class="(large) ? 'xl:w-full' : 'xl:w-full break-all'">
            <span class="xl:truncate truncate w-12">
              {{user.email}}
            </span>
          </div>
        </div>
        <small class="ml-2 mt-1" v-if="show_cid">{{(user.cid) ? `(ID:${user.cid})` : ''}}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserIdentity',
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          avatar_url: 'https://openwebinars.net/static/images/onboarding/avatars/2.svg',
          first_name: 'NoName',
          last_name: 'NoLasName',
          email: 'NoEMail@NoEmail.com',
          role: '',
          cid: ''
        }
      }
    },
    avatar: {
      type: Boolean,
      default: true
    },
    show_cid: {
      type: Boolean
    },
    text: {
      type: String
    },
    dark: {
      type: Boolean
    },
    xl: {
      type: Boolean
    },
    large: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      roles: {
        admin: { text: 'Admin', color: 'admin' },
        staff: { text: 'Gestor', color: 'staff' },
        free_manager: { text: 'Gestor', color: 'staff' },
        auditor: { text: 'Auditor', color: 'auditor' }
      }
    }
  },
  computed: {
    nameInitials () {
      if (!this.user.first_name && !this.user.last_name) return ''
      const first = (this.user.first_name) ? this.user.first_name[0] : ''
      const last = (this.user.last_name) ? this.user.last_name[0] : ''
      return `${first}${last}`
    }
  },
  methods: {
    loadingComplete () {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.email-container {
  width: calc(100% - 40px);
}
.cover {
  object-fit: cover;
}

.text-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: white;
  text-transform: uppercase;
}

figcaption.admin {
  --bg-color: #F6B44F;
  --text-color: #815109;
}

figcaption.staff {
  --bg-color: #6ED4EA;
  --text-color: #0B5E71;
}

figcaption.auditor {
  --bg-color: #EA80EC;
  --text-color: #8D0E8F;
}

figcaption {
  --bg-color: #e4e4e4;
  --text-color: #404040;
  color: var(--text-color);
  background-color: var(--bg-color);
  @apply absolute -bottom-2 text-2xs rounded text-center p-0.5
}
</style>
