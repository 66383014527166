<template>
<main class="min-h-screen" >
  <div data-html2canvas-ignore="true" class="aside-wrapper"
  :class="collapsedMenu ? 'cursor-pointer menu-close' :'menu-open'"
  >
    <button :class="collapsedMenu ? 'left-14 ' : 'left-60'" class="aside-arrow-button z-40 fixed bg-white  p-1 rounded-lg border border-grey-lighter" @click="collapseMenu">
      <svg
        :style="collapsedMenu?'transform: rotate(180deg);':''"
        width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.59998 1.59998L1.19998 7.99998L7.59998 14.4" stroke="#5A5A5A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <aside
      :class="collapsedMenu ? 'cursor-pointer menu-close' :'menu-open'"
      class="fixed bg-primary-darkest h-screen z-10 overflow-hidden">
      <div v-if="collapsedMenu" @click="collapseMenu" class="aside-button"></div>
      <figure class="logo-wrapp">
        <router-link :to="{ path: '/' }">
          <img v-show="collapsedMenu" class="ml-8 mr-6 isotipo" src="@/assets/images/isotipo.svg" alt="OpenWebinars">
          <img v-show="!collapsedMenu" class="logo ml-8 mr-6" src="@/assets/images/logo.svg" alt="OpenWebinars">
        </router-link>
      </figure>

      <UiMainMenu :class="`${collapsedMenu ? 'opacity-0 menu-close' :'opacity-1 menu-open'}`">
            <Visibility v-if="active_business" :permission="'can_see_home'">
              <UiMenuButton :path="{ path: '/' }" :icon="'home'">
                Inicio
              </UiMenuButton>
            </Visibility>
            <UiAccordionMenu v-if="active_business" :path="'/gestion/'" :name="'Gestión'" :icon="'request'">
              <Visibility :permission="'can_see_list_users_page'">
                <UiMenuButton :path="{ path: '/gestion/usuarios' }" :icon="''">
                  Usuarios
                </UiMenuButton>
              </Visibility>
              <Visibility :permission="'can_see_list_teams_page'">
                <UiMenuButton :path="{ path: '/gestion/equipos' }" :icon="''">
                  Equipos
                </UiMenuButton>
              </Visibility>
              <Visibility :permission="'can_see_assistant'">
                <UiMenuButton :path="{ path: '/gestion/asistente' }" :icon="''">
                  Asistente
                </UiMenuButton>
              </Visibility>
              <Visibility :permission="'can_see_itineraries_page'">
              <UiMenuButton :path="{ path: '/gestion/rutas' }" :icon="''">
                  Rutas
              </UiMenuButton>
              </Visibility>
              <Visibility :permission="'can_see_requests_page'">
                <UiMenuButton :path="{ path: '/gestion/solicitudes' }" :icon="''">
                  Solicitudes
                <Balloon class="ml-2">{{ (total_requests) ? total_requests : '0' }}</Balloon>
                </UiMenuButton>
              </Visibility>
            </UiAccordionMenu>

            <Visibility :permission="'can_see_analytics'">
            <UiAccordionMenu :path="'/analitica/'" :name="'Analíticas'" :icon="'analytics'">
              <UiMenuButton :path="{ path: '/analitica/global' }" :icon="''">
                Global
              </UiMenuButton>
              <UiMenuButton :path="{ path: '/analitica/graficas' }" :icon="''">
                Gráficas
              </UiMenuButton>
              <UiMenuButton :path="{ path: '/analitica/usuarios' }" :icon="''">
                Usuarios
              </UiMenuButton>
              <UiMenuButton :path="{ path: '/analitica/formaciones' }" :icon="''">
                Formaciones
              </UiMenuButton>
              <UiMenuButton :path="{ path: '/analitica/valoraciones' }" :icon="''">
                Valoraciones
              </UiMenuButton>
            </UiAccordionMenu>
            </Visibility>
            <UiMenuButton v-if="active_business" :path="{ path: '/catalogo' }" :icon="'single-book-white'">
              Catálogo
            </UiMenuButton>
            <Visibility :permission="'can_access_campus'">
              <UiMenuButton v-if="active_business" :path="{ name: 'corporative-campus' }" :icon="'corporative-campus'">
                Campus corporativo
              </UiMenuButton>
            </Visibility>
            <Visibility :permission="'can_see_banner_campus'">
              <UiDummyButton :is_pro="true" :path="{ name: 'landing-campus' }" :icon="'corporative-campus'">
                Campus corporativo
              </UiDummyButton>
            </Visibility>
            <Visibility :permission="'can_see_reports'">
            <UiMenuButton :path="{ path: '/reportes-personalizados' }" :icon="'reports'">
              Reportes
            </UiMenuButton>
            </Visibility>
            <Visibility :permission="'can_see_banner_reports'">
              <UiDummyButton :is_pro="true" :path="{ name: 'landing-reports' }" :icon="'reports'">
                Reportes
              </UiDummyButton>
            </Visibility>
            <Visibility :permission="'can_see_planner'">
            <UiMenuButton :path="{ name: 'planner-index' }" :icon="'planner'">
              Planificador
            </UiMenuButton>
            </Visibility>
            <Visibility :permission="'can_see_banner_planner'">
              <UiDummyButton :is_pro="true" :path="{ name: 'landing-planner' }" :icon="'planner'">
                Planificador
              </UiDummyButton>
            </Visibility>
            <Visibility :permission="'can_change_organization'">
            <UiMenuButton :path="{ name: 'organizations' }" :icon="'planner'">
              Organizaciones
            </UiMenuButton>
            </Visibility>

      </UiMainMenu>
      <div class="user-menu">
        <UserMenu class="ml-2 " v-if="user" :user="user">
          <BusinessMenu />
        </UserMenu>
      </div>
    </aside>
  </div>
  <div :class="`${collapsedMenu?'ml-16':'ml-64'} main-content flex-grow bg-green-light`">
      <router-view v-if="active_business !== null" />
      <AlerteMsg>
        Estimado cliente, le recordamos que tiene una factura pendiente de pago y el servicio podrá verse interrumpido. Para más información contacte con <u class="text-info"><a href="mailto:administracion@openwebinars.net">administracion@openwebinars.net</a></u>.
      </AlerteMsg>
  </div>
</main>
<ToastList />
<DownloadProgressBar/>
<SVGIconsList />
<MainDialogBox ref="dialog" />
<MentotIA />
</template>

<script>
import AlerteMsg from './components/AlertMsg.vue'
import SVGIconsList from './components/Layout/SVGIconsList.vue'
import ToastList from './components/Toast/ToastList.vue'
import UserMenu from './components/UserMenu.vue'
import MainDialogBox from './components/MainDialogBox.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Visibility from './components/Visibility.vue'
import UiMainMenu from './components/Layout/UI/MainMenu/UiMainMenu.vue'
import UiMenuButton from './components/Layout/UI/MainMenu/UiMenuButton.vue'
import UiAccordionMenu from './components/Layout/UI/MainMenu/UiAccordionMenu.vue'
import BusinessMenu from './components/BusinessMenu.vue'
import DownloadProgressBar from './components/DownloadProgressBar.vue'
import Balloon from './components/Balloon.vue'
import UiDummyButton from './components/Layout/UI/MainMenu/UiDummyButton.vue'
import MentotIA from './components/MentorIA.vue'

export default {
  name: 'app',
  components: {
    SVGIconsList,
    ToastList,
    UserMenu,
    MainDialogBox,
    Visibility,
    UiMainMenu,
    UiMenuButton,
    UiAccordionMenu,
    BusinessMenu,
    DownloadProgressBar,
    Balloon,
    AlerteMsg,
    UiDummyButton,
    MentotIA
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['user', 'active_business', 'total_licenses', 'total_requests', 'collapsedMenu'])
  },
  methods: {
    ...mapMutations(['updateUser', 'collapseMenu']),
    ...mapActions(['getUser'])
  },
  mounted () {
    this.getUser()
  }
}
</script>

<style>
  aside {
    transition: all .3s ease;
  }
  .main-content{
    transition: all .3s ease;
  }
  .menu-close {
    width: 72px;
  }
  .menu-open {
      @apply w-64
  }
  .user-menu {
    @apply absolute z-50 bottom-2 w-full
  }
  .menu-close .user-menu {
    padding-right: 4px;
  }
  .aside-button::after {
    content: '';
    left: 0;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .aside-arrow-button {
    opacity: 1;
    top: 45px;
    transition: opacity 0.2s;
    width: 26px;
    display: flex;
    justify-content: center;
  }
  .aside-wrapper{
    position: relative;
    transition: all .3s ease;
  }
  .logo-wrapp {
    position: relative;
    left: -12px;
    transition: left 0.4s ease-in;
    width: 240px;
    @apply mt-10 text-center
  }
  .logo {
    width: 175px;
    height: 39px;
  }
  .isotipo {
    width: 32px;
    height: 39px;
  }
  /** Botones */
  .btn-primary{
    @apply flex items-center py-2 px-4 rounded text-white bg-primary hover:bg-primary-dark
  }
  .btn-secondary{
    @apply flex items-center py-2 px-4 rounded text-white bg-secondary-darker hover:bg-secondary
  }
  .btn-danger{
    @apply flex items-center py-2 px-4 rounded text-white bg-danger hover:bg-primary-dark
  }
  .btn-outline-secondary{
    @apply flex items-center py-2 px-4 rounded text-secondary-darker font-semibold border-2 border-secondary-darker  hover:bg-secondary-darker hover:text-white
  }
  .btn-outline-default{
    @apply flex items-center py-2 px-4 rounded text-grey-dark font-semibold border-2 border-grey-light  hover:bg-grey-lighter
  }
  .btn-light{
    @apply flex items-center py-2 px-4 rounded text-primary bg-white border-2 border-primary
  }
  /* Custom Table */
  .custom-table-head{
    @apply flex my-2 bg-grey-lightest rounded py-3 font-medium text-grey
  }
  .custom-table-body{
    @apply mb-2
  }
  .custom-table-body .custom-table-row{
    @apply flex w-full bg-grey-lightest rounded py-2
  }
  .btn-disabled{
    @apply flex items-center py-2 px-4 rounded text-white font-medium border-grey-light bg-grey-lighter opacity-50 cursor-not-allowed
  }
  .custom-scroll::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #aaa;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 8px;
}

  .custom-scroll-sm::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    width: 8px;
  }
  .custom-scroll-sm::-webkit-scrollbar-thumb {
    background: #aaa;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 10px;
}
.skeleton-pulse{
  animation: pulse-opacity 1s cubic-bezier(.4,0,.6,1) infinite;
}
.loading {
    animation: pulse-opacity 1s cubic-bezier(.4,0,.6,1) infinite;
}
.disabled .toggle-license-state{
  display: none;
}
.disabled {
  opacity: .5;
}
@keyframes pulse-opacity {
  50% {
      opacity: .5;
  }
}
</style>
