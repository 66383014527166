<template>
  <li>
    <router-link
      :active-class="active"
      exact-active-class="none"
      :class="`flex items-center p-2 py-1 rounded ml-4`"
      :to="path">
        <SVGIcons v-if="icon" class="svg-14 mx-4" :icon="icon"/>
        <slot></slot>
      </router-link>
  </li>
</template>

<script>
import SVGIcons from '../../SVGIcons.vue'
export default {
  name: 'UiMenuButton',
  props: {
    path: {
      type: Object
    },
    icon: {
      type: String
    }
  },
  computed: {
    active () {
      return (this.icon === '') ? 'font-bold bg-secondary-dark w-3/4' : 'home-router-link-active'
    }
  },
  components: { SVGIcons }
}
</script>

<style>
  .home-router-link-exact-active,
  .home-router-link-active {
    @apply font-semibold min-w-3/4  bg-secondary-dark;
  }
</style>
<style scoped>
li {
  border: 1px solid transparent;
  min-height: 45px;
  min-width: 240px;
}
a {
  border: 1px solid transparent;
  min-height: 45px;
  white-space: nowrap;
}
</style>
