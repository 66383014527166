<template>
  <svg :id="props.id" :width="props.size.width" :height="props.size.height" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4166 11V20.4C17.4166 20.7314 17.1704 21 16.8666 21H5.13325C4.8295 21 4.58325 20.7314 4.58325 20.4V11" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.16675 17V11" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8333 17V11" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.25 7H14.6667M14.6667 7V3.6C14.6667 3.26863 14.4205 3 14.1167 3H7.88333C7.57958 3 7.33333 3.26863 7.33333 3.6V7M14.6667 7H7.33333M2.75 7H7.33333" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-trash'
  },
  color: {
    type: String,
    default: '#626262'
  },
  size: {
    type: Object,
    default: () => ({
      width: 22,
      height: 24
    })
  }
})
</script>
