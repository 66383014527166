<template>
<div class="block relative" v-click-outside="hide">
  <div class="flex items-center relative border-2 rounded"
  :class="(selected_kinds.length > 0 ) ? 'border-primary' : 'border-primary-lighter'">
    <button @click="open = !open" :class="`flex items-center whitespace-nowrap w-full ${bgClass} py-2 pl-4 pr-9 rounded`">
      Tipo
      <span v-if="selected_kinds.length > 0" class="text-xs w-5 rounded bg-primary text-white ml-1">{{selected_kinds.length}}</span>
      <svg v-if="selected_kinds.length <= 0" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
      </svg>
    </button>
    <svg @click="reset" v-if="selected_kinds.length > 0" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="absolute z-20 mt-1 rounded" :class="{'hidden': !open, bgClass}">
    <hr class="border-grey-lighter mb-1">
    <ul class="kinds-list">
      <li class="px-3 py-1 flex whitespace-nowrap pr-3 " v-for="kind in kind" :key="kind.value">
        <label class="cursor-pointer ml-1 text-sm" :for="kind.value">
        <input type="checkbox" v-model="kind.checked" :name="kind.value" :id="kind.value">
          {{kind.name}}
        </label>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { api } from '../../api/instances'

export default {
  name: 'LearningKind',
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: Array
    },
    bgClass: {
      type: String,
      default: 'bg-grey-lighter-2'
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      kind: []
    }
  },
  computed: {
    ...mapState(['active_business']),
    kinds_value () {
      return this.academy_filters.kind.map((el) => el.value)
    },
    selected_kinds () {
      if (this.kind && this.kind.length > 0) {
        return this.kind.filter((el) => el.checked === true)
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions(['getFilters']),
    hide () {
      (this.open) && this.update()
      this.open = false
    },
    async customGetFilters () {
      this.loading = true
      await this.getFilters()
      this.loading = false
    },
    async getFilters () {
      api.get(`/jarvis/business/${this.active_business}/courses/filters`)
        .then(({ data }) => {
          this.kind = data.kind
        })
        .catch(() => {
          console.error('Problema al cargar los filtros')
        })
    },
    update () {
      this.$emit('update:modelValue', this.selected_kinds.map((el) => el.value))
      this.$emit('change')
    },
    reset () {
      this.selected_kinds.forEach((el) => { el.checked = false })
      this.update()
    }
  },
  created () {
    this.customGetFilters()
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
  .kinds-list {
    max-height: calc(100vh - 50vh);
    @apply bg-grey-lightest shadow-lg z-10
  }
</style>
