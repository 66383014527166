import { computed } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/api/instances'

const useContextualMenu = () => {
  const store = useStore()
  const active_business = computed(() => store.state.active_business)
  const closeMainDialogBox = () => store.dispatch('closeMainDialogBox')

  const mailTo = (email) => {
    window.location.href = 'mailto:' + email
  }

  const deleteUserFromSlot = async (context, teamId, sprint_id, position) => {
    await api.delete(`/jarvis/business/${active_business.value}/planner/${teamId}/${sprint_id}/seat/${position}/`)
      .then(({ data }) => {
        context.emit('changeSeat')
        closeMainDialogBox()
      })
      .catch((err) => {
        console.error('No se ha podido borrar el usuarios al slot')
        console.error(err)
      })
    return new Promise((resolve) => {
      resolve()
    })
  }
  return {
    mailTo,
    deleteUserFromSlot
  }
}

export default useContextualMenu
