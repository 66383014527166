<template>
  <div class="flex items-center">
    <slot v-if="isAuthorized"></slot>
    <div @click="isOption = !isOption" v-click-outside="hidde" v-else class="popover-wrap">
      <slot name="popover"></slot>
      <div v-if="isOption" class="popover" :class="{'popover-right': position === 'right', 'popover-left': position === 'left'}">
        <div class="popover-title">Exclusivo para Plan <div class="pill-pro">PRO</div></div>
        <div>
          <p>{{ clain }}</p>
          <p>{{ message }}</p>
        </div>
        <div class="flex justify-center">
          <UiButton @click.stop="$emit('send')" type="secondary">Solicitar Demo</UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UiButton from '@/components/Layout/UI/UiButton.vue'
import { ref } from 'vue'
const $emit = defineEmits(['send'])
const isOption = ref(false)
const hidde = () => {
  isOption.value = false
}
defineProps({
  isAuthorized: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'right'
  },
  clain: {
    type: String,
    default: 'Actualiza al Plan Pro para configurar la gestión automática.'
  },
  message: {
    type: String,
    default: 'Ahorra tiempo y descubre funcionalidades que mejoran tu eficiencia y resultados.'
  }
})

const vClickOutside = { // directive in veu 3
  mounted (el) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        hidde()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
</script>

<style scoped>
.popover-wrap {
  position: relative;
  display: inline-block;
}
.popover {
  position: absolute;
  top: -18px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 289px;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #cccccc9f;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.175);
}
.popover-left {
  left: 0;
  transform: translateX( calc(-100% - 15px));
}
.popover-right {
  right: 0;
  transform: translateX( calc(100% + 15px));
}
.popover::after {
  content: '';
  position: absolute;
  top: 20px;
  width: 16px;
  height: 16px;
  background-color: inherit;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.popover-right::after {
  transform: rotate(45deg);
  left: -8px;
}
.popover-left::after {
  transform: rotate(-135deg);
  right: -8px;
}
.popover::before {
  content: '';
  position: absolute;
}
.popover-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #101828;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.pill-pro {
  flex: none;
  border-radius: 50px;
  background: #F63D68;
  display: flex;
  width: 35px;
  height: 16px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;

}
.popover p {
  color: #1D2939;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
</style>
