<script setup>
import { ref, defineExpose, computed } from 'vue'
import { api } from '@/api/instances'
import { useStore } from 'vuex'
import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import InfoIcon from '../icons/InfoIcon.vue'
import Tooltip from '@/components/Tooltip.vue'
import UserIdentity from '@/components/UserIdentity.vue'
import ToggleLicenseState from '@/components/ToggleLicenseState.vue'
const store = useStore()
const active_business = computed(() => store.state.active_business)
const addToastMessage = (message) => store.commit('addToastMessage', message)
const modal = async (action, obj = null) => {
  if (action === 'show') {
    users.value = []
    open.value = true
    info.value = obj
    getLicenseUsers()
  }
  if (action === 'hide') {
    open.value = false
  }
}
const emit = defineEmits(['change'])
const info = ref({})
const open = ref(false)
const users = ref([])
defineExpose({ modal })
const closeModal = () => {
  open.value = false
}
const changeInView = () => {
  getLicenseUsers()
  emit('change')
}
const getLicenseUsers = async (internal = false) => {
  try {
    const { data } = await api.get(`/jarvis/business/${active_business.value}/planner/${info.value.team_id}/manually-assigned-users/`)
    users.value = data
  } catch (error) {
    console.error(error)
  }
}
const checkLicenses = computed(() => {
  return info.value.licenses !== (info.value.licenses - users.value.length)
})
const desactiveMassiveLicense = async () => {
  const obj = {
    ids: users.value.map((user) => user.employee_id),
    action: 'deactivate'
  }
  api.post(`/jarvis/business/${active_business.value}/massive/change/`,
    {
      ...obj
    })
    .then(() => {
      getLicenseUsers()
      addToastMessage({
        status: 'success',
        title: '¡Éxito!',
        message: 'Se han desactivado las licencias con éxito.'
      })
      emit('change')
    })
    .catch((error) => {
      console.error(error)
      addToastMessage({
        status: 'danger',
        title: 'Error!',
        message: 'Se ha producido un error al desactivar las licencias.'
      })
    })
}
</script>
<template>
    <Overlay class="z-50" :open="open">
      <Modal class="mt-24 top-0" :size="'w-[1190px]'">
        <ModalHeader @close="closeModal">
          <ModalTitle>
            Gestionar Sprint {{ info.team_name }}
          </ModalTitle>
        </ModalHeader>
        <ModalBody class="pb-5">
          <div class="border rounded px-4 py-4 flex items-center justify-center border-grey-lighter">
            <span class="mr-5 font-bold text-primary">Capacidad del Sprint:</span>
            <span class="font-medium text-primary">Licencias <span class="ml-1 font-bold text-primary bg-grey-lightest p-1 rounded ">{{ info.licenses }}</span></span>
          </div>
          <div class="bg-primary-50 p-4 mb-5">
            <p class="text-sm text-primary">Tienes <span class="text-success-700 text-sm"><span class="mx-1 font-bold bg-green-200 p-1 rounded ">{{ info.licenses - users.length }}</span>Licencias planificables</span>
              {{ (checkLicenses) ? 'si quieres aumentar las licencias planificables, te recomendamos desactivar las licencias activas ya que no lo son:' : '¡Puedes usar el planificador con todas tus licencias asignadas!'}}
            </p>
            <div v-if="checkLicenses" class="mt-10">
              <div class="flex items-center mb-5 justify-between">
                <span class="text-warning-700  flex items-center">
                  Licencias no planificables
                  <Tooltip position="arrow-right">
                    <template v-slot:icon>
                      <InfoIcon class="w-3 h-3 ml-1"/>
                    </template>
                    <template v-slot:default>
                      <li>
                        <p class="text-primary">Las licencias activas no son planificables. Para liberar más licencias planificables, desactivalas. <strong class="font-medium">Gestionar capacidad de los sprint</strong></p>
                      </li>
                    </template>
                  </Tooltip>
                  <span class="ml-1 font-bold bg-orange-200 w-6 flex items-center justify-center h-6 rounded ">{{ users.length }}</span></span>
                  <UiButton @click="desactiveMassiveLicense" size="btn-lg" class="bg-white" type="outline-secondary">Desactivar licencias</UiButton>
              </div>
              <div  class="flex w-full justify-start items-center flex-wrap  custom-scroll  overflow-y-auto">
                <div v-for="{
                  avatar_url,
                  first_name,
                  last_name,
                  email,
                  employee_id,
                  is_active,
                  is_available,
                  has_planning,
                  has_future_planning,
                  user_id
                } in users" :key="user_id"
                class=" h-[49px] w-[351px] flex justify-center items-center rounded-sm border mr-2 mb-2 px-8 border-warning-600 bg-[#F8F4ED] "
                >
                  <UserIdentity  dark :user="{ first_name, email, last_name, avatar_url }" />
                  <div class="mb-2">
                    <ToggleLicenseState   @change="changeInView" :user="{ employee_id, is_active, is_available, has_planning, has_future_planning }" />
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="flex mt-8 justify-center pb-10">
            <button class="flex text-white text-center justify-center bg-secondary-darker hover:bg-primary-dark items-center py-2 px-4 rounded flex-nowrap w-[222px]" @click="closeModal">
              Finalizar
            </button>
            </div>
        </ModalBody>
      </Modal>
    </Overlay>
</template>
