<script setup>
const props = defineProps({
  label: {
    type: String,
    default: 'Descargar CSV'
  },
  styleBtn: {
    type: String,
    default: ''
  },
  nameCSV: {
    type: String,
    default: 'listado'
  },
  data: {
    type: Array,
    default: () => []
  }
})
const convertAndDownloadCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  let csvContent = '\uFEFF' // UTF-8

  // Obtener las claves únicas para el encabezado
  const keys = Array.from(new Set(array.flatMap(item => Object.keys(item))))
  const header = keys.map(key => `"${key}"`).join(',') + '\r\n'
  csvContent += header

  // Generar filas
  array.forEach(item => {
    const values = keys.map(key => item[key] === undefined ? '' : `"${item[key]}"`).join(',')
    csvContent += values + '\r\n'
  })

  // Crear el Blob y descargar el archivo
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', `${props.nameCSV}.csv`)
  a.click()
}
</script>
<template>
  <button :class="props.styleBtn" @click="convertAndDownloadCSV(props.data)">
    {{ props.label }}
  </button>
</template>
