<template>
  <svg :id="props.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 21L13 11M18 6L15.5 8.5"
     :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5 2L10.445 4.555L13 5.5L10.445 6.445L9.5 9L8.555 6.445L6 5.5L8.555 4.555L9.5 2ZM19 10L19.54 11.46L21 12L19.54 12.54L19 14L18.46 12.54L17 12L18.46 11.46L19 10Z"
     :stroke="props.color" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'wand-icon'
  },
  color: {
    type: String,
    default: '#344054'
  }
})
</script>

<style scoped>

</style>
