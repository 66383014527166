<template>
  <div class="my-5 relative">
    <hr v-if="barTop" class="mx-4 absolute -top-1 w-56 mb-3 border-custom">
    <nav class="main-menu overflow-y-scroll" @click="() =>{ barTop = false, barBot = false}"   @scroll="scrollWatcher">
        <ul class="list-none text-white pt-2 pr-4">
          <slot></slot>
        </ul>
      </nav>
      <hr v-if="barBot" class="mx-4 absolute w-56 border-custom">
  </div>
</template>

<script>
export default {
  name: 'UiMainMenu',
  data () {
    return {
      isHover: false,
      barTop: false,
      barBot: false
    }
  },
  methods: {
    scrollWatcher (e) {
      const list = e.target
      const containerRect = list.getBoundingClientRect()
      const elements = list.children
      let elementsAbove = 0
      let elementsBelow = 0

      for (let i = 0; i < elements.length; i++) {
        const elementRect = elements[i].getBoundingClientRect()

        if (elementRect.top < containerRect.top) {
          elementsAbove++
        }
        if (elementRect.bottom > containerRect.bottom) {
          elementsBelow++
        }
      }
      if (elementsAbove === 1) {
        this.barTop = true
      } else {
        this.barTop = false
      }
      if (elementsBelow === 1) {
        this.barBot = true
      } else {
        this.barBot = false
      }
    }
  }
}

</script>
<style scoped>
.main-menu {
  transition: all 0.3s ease;
  overflow-x: hidden;
  padding-right: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 190px);
}
.main-menu::-webkit-scrollbar {
  opacity: 0;
}
.main-menu:hover::-webkit-scrollbar {
  opacity: 1;
}
 .main-menu:hover::-webkit-scrollbar, .main-menu:hover::-webkit-scrollbar-thumb {
    border-radius: px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    width: 8px;
  }
 .main-menu:hover::-webkit-scrollbar-thumb {
    background: #aaa;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 10px;
}
.border-custom {
  border: 1px solid;
  border-color: rgba(54, 66, 99, 1);
  bottom: 0;
  left: 0;
  transform: translateX(0) translateY(50%);
}
</style>
