<template>
  <div class="license-counter" v-if="is_visible" v-click-outside="hide">
    <button @click.prevent="removeLicense()" :disabled="open" :class="{'bg-primary ' : !open, 'bg-grey-lighter' : open}" class="flex  mb-0 justify-center items-center w-6 h-6 rounded-full" type="button">
      <SVGIcons class="svg-16" icon="minus"/>
    </button>
    <div class="flex rounded-full items-center font-medium text-sm text-primary bg-white py-2 mx-1 w-10 justify-center" :class="{'px-3' : !open}" >
        <input v-if="open" @keypress.enter="updateTeamLicenses(), open = false" class="max-w-sm w-8 text-center border border-grey-light rounded" type="text" v-model="licenses" name="" id="">
        <button @dblclick.prevent="open=!open" v-else>{{licenses}}</button>
    </div>
    <button @click.prevent="addLicense()" :disabled="open" :class="{ 'bg-primary ': !open, 'bg-grey-lighter': open }" class="flex  justify-center items-center w-6 h-6 rounded-full" type="button">
      <SVGIcons class="svg-16" icon="plus"/>
    </button>
  </div>
  <div v-else>
    {{licenses}}
  </div>
</template>

<script>
import SVGIcons from '../components/Layout/SVGIcons.vue'
import { api } from '../api/instances'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'TeamLicenses',
  components: {
    SVGIcons
  },
  props: {
    team: {
      Type: Object
    },
    permission: {
      type: String
    }
  },
  emits: ['change'],
  data () {
    return {
      loading: false,
      initValue: 0,
      licenses: 0,
      open: false,
      timer: null
    }
  },
  watch: {
    team () {
      this.licenses = this.team.licenses
      this.initValue = this.team.licenses
    }
  },
  computed: {
    ...mapState(['active_business', 'permissions']),
    is_visible () {
      return this.permissions.includes(this.permission) || this.permission === 'all'
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    hide () {
      this.open = false
      this.licenses = this.initValue
    },
    addLicense () {
      this.licenses++
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateTeamLicenses()
      }, 600)
    },
    removeLicense () {
      this.licenses--
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateTeamLicenses()
      }, 600)
    },
    async updateTeamLicenses () {
      this.loading = true
      try {
        const { data } = await api.patch(`/jarvis/business/${this.active_business}/teams/${this.team.id}/modify-license/`, { number_licenses: parseInt(this.licenses) })
        const { state, text, number_license } = data
        this.licenses = number_license
        this.initValue = number_license
        this.addToastMessage({
          status: state,
          title: (state === 'success') ? 'Completado' : 'Error',
          message: text
        })
        this.$emit('change')
        setTimeout(() => {
          this.loading = false
        }, 800)
        this.loading = null
      } catch (err) {
        console.error('Se ha producido un error al añadir o quitar licencias a este equipo')
      }
    }
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  created () {
    this.licenses = this.team.licenses
  }
}
</script>

<style>
.license-counter{
    @apply flex flex-row items-center mx-auto whitespace-nowrap
  }
</style>
