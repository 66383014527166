<script setup>
import { ref, defineProps, watch, onMounted } from 'vue'
import html2canvas from 'html2canvas'

const props = defineProps({
  excel: {
    type: Object,
    required: true
  }
})
const show = ref(false)
const hide = () => {
  show.value = false
}
const dataExcel = ref({
  total_hours: 0,
  total_formations: 0,
  total_certificates: 0,
  media_polls: 0
})
const getScreem = () => {
  const a = document.createElement('a')
  a.download = 'captura.png'
  html2canvas(document.querySelector('#general-data'), {
    'x-offset': 200
  }).then(canvas => {
    a.href = canvas.toDataURL('image/png')
    a.click()
  })
}
watch(() => props.excel, () => {
  dataExcel.value = { ...props.excel }
})
const exportTableToExcel = () => {
  const filename = 'datos_globales.xlsx'
  const dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const tableHTML = `
    <table>
      <tr>
        <th>Horas de uso totales</th>
        <th>Formaciones comenzadas</th>
        <th>Certificados obtenidos</th>
        <th>Valoraci&oacuten media formaciones Openwebinars</th>
      </tr>
      <tr>
        <td>${dataExcel.value.total_hours}</td>
        <td>${dataExcel.value.total_formations}</td>
        <td>${dataExcel.value.total_certificates}</td>
        <td>${dataExcel.value.media_polls}</td>
      </tr>
    </table>
  `
  const downloadLink = document.createElement('a')

  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob(['\uFEFF', tableHTML], {
      type: dataType
    })
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    downloadLink.href = window.URL.createObjectURL(new Blob(['\uFEFF', tableHTML]))
    downloadLink.setAttribute('download', filename)
    downloadLink.click()
  }
}

const vClickOutside = {
  mounted (el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        hide()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
onMounted(() => {
  dataExcel.value = { ...props.excel }
})
</script>
<template>
  <div class="download-data relative" data-html2canvas-ignore="true" v-click-outside="hide">
    <button
      @click.prevent="show = !show"
      class="flex items-center relative border-2 border-primary rounded py-2 pl-2 pr-5 w-full"
      :class="{'bg-primary text-white': show}"
      >
      Exportar datos
      <svg class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z"
        :fill="(!show)?'#304178':'#ffffff'"/>
      </svg>
    </button>
    <div class="menu" v-if="show">
      <button class="p-2 text-left bg-white hover:bg-grey-lightest border-b border-grey-lighter" @click.prevent="exportTableToExcel">Exportar en Excel</button>
      <button class="p-2 text-left bg-white hover:bg-grey-lightest border-b border-grey-lighter" @click.prevent="getScreem">Exportar como imagen</button>
    </div>
  </div>
</template>
<style scoped>
.download-data {
  min-width: 175px;
  @apply relative;
}
.menu {
  width: 260px;
  @apply absolute border flex flex-col border-grey-lighter rounded-lg z-50 mt-1 shadow;
}
</style>
