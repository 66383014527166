<template>
  <span><!-- --></span>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AccessDenied',
  props: {
    permission: {
      type: String
    },
    redirect: {
      type: String,
      default: 'home'
    }
  },
  computed: {
    ...mapState(['permissions']),
    is_visible () {
      return this.permissions.includes(this.permission) || this.permission === 'all'
    }
  },
  created () {
    if (!this.is_visible) {
      this.$router.push({ name: this.redirect })
    }
  }
}
</script>
