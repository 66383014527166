<script setup>
import { ref, onMounted, watch } from 'vue'
const props = defineProps({
  name: String,
  color: Object,
  limit: {
    type: Boolean,
    default: true
  },
  close: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: Boolean,
    default: false
  }
})
const dynamicColors = ref({})
const setColor = () => {
  if (props.limit) {
    dynamicColors.value = {
      '--text-color': '#0C8B9C',
      '--bg-color': '#D6FAFF'
    }
  }
  if (props.color) {
    dynamicColors.value = {
      '--text-color': props.color.text_color,
      '--bg-color': props.color.bg_color
    }
  }
}
watch(() => props.color, () => {
  setColor()
})
onMounted(() => {
  setColor()
})
</script>
<template>
  <div class="tag" :class="` ${(name.length >= 15 && tooltip)?'title-tooltip':''}`" :style="dynamicColors" :data-title="name">
    <slot></slot>
    <svg v-if="close" class="mr-1" width="10" height="10" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199"
        class=""
        stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
    <span :class="{'tag-max-w':name.length >= 15 && tooltip}">
      {{name}}
    </span>
  </div>
</template>

<style scoped>
.title-tooltip {
  position: relative;
}
.title-tooltip:hover::after{
  opacity: 1;
  transition: all .4s ease-in-out;
}
.title-tooltip:hover::before{
  opacity: 1;
  top: -5px;
  transition: all .4s ease-in-out .1s;
}
.title-tooltip::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateY(-108%) translateX(-50%) rotate(45deg);
  display: block;
  width: 10px;
  height: 10px;
  background-color: inherit;
  pointer-events: none;
  opacity: 0;
  transition: all .1s ease-in-out;
}
.title-tooltip::after {
  content: attr(data-title);
  pointer-events: none;
  z-index: 10;
  position: absolute;
  background-color: inherit;
  border-radius: 10px;
  padding: 2px 10px;
  left: 50%;
  top: -10px;
  display: block;
  transform: translateY(-103%) translateX(-50%);
  opacity: 0;
  transition: all .1s ease-in-out .1s;

}
.tag-max-w {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag{
  --text-color: #404040;
  --bg-color: #e4e4e4;
  width: 100%;
  color: var(--text-color);
  background-color: var(--bg-color);
  stroke: var(--text-color);

  @apply flex items-center font-medium 2xl:text-xs xl:text-2xs px-3 rounded-full mr-1 capitalize whitespace-nowrap py-0.5
}
</style>
