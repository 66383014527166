<template>
  <div class="flex items-center relative border-2 rounded select-role"
  :class="(role_id !== '' && filter == true) ? 'border-primary' :  'border-primary-lighter'">
    <select
      :id="id"
      :required="required"
      @change="update"
      v-model="role_id"
      :class="`w-full py-2 pl-3 pr-8 rounded outline-none ${bgClass}`" name="role">
      <option v-if="label" value="">Rol</option>
        <option v-for="{value:id, label:name} in check_restrictition" :key="id" :value="id">
            {{name}}
        </option>
    </select>
    <svg v-if="role_id === '' || filter == false" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
    </svg>
    <svg @click="reset" v-if="role_id !== '' && filter == true" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '../api/instances'
export default {
  name: 'SelectRole',
  props: {
    id: {
      type: String
    },
    modelValue: {
      type: String
    },
    bgClass: {
      type: String,
      default: 'bg-grey-lighter-2'
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Boolean,
      default: true
    },
    is_restricted: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      roles: [],
      role_id: ''
    }
  },
  computed: {
    ...mapState(['active_business']),
    check_restrictition () {
      if (this.is_restricted) {
        return this.roles.filter(el => el.value !== 'admin')
      } else {
        return this.roles
      }
    }
  },
  methods: {
    async getRoles () {
      await api.get(`/jarvis/business/${this.active_business}/roles/`)
        .then(({ data }) => {
          this.roles = data
        })
        .catch(console.error)
    },
    update () {
      this.$emit('update:modelValue', this.role_id)
      this.$emit('change')
    },
    reset () {
      this.role_id = ''
      this.update()
    }
  },
  update () {
    this.role_id = this.modelValue
  },
  created () {
    this.role_id = this.modelValue
    this.getRoles()
  }
}
</script>

<style scoped>
select {
    -webkit-appearance: none;
  }
  .pe-none{
    pointer-events: none;
  }
  .select-role:focus-within {
    border: 2px solid black;
  }
</style>
