<template>
  <UiPageBody >
      <UiTableFilters >
    <div class="flex-none w-1/2 flex">
      <div class="col-auto px-1 justify-center xl:w-38 lg:w-38">
        <SearchField v-model="search" @change="getItineraries" :placeholder="'Buscar rutas'" />
      </div>
    </div>
    <div class="flex justify-end w-1/2">
      <UiButton @click="downloadMassiveItineraries" class="mr-3 2xl:text-base xl:text-sm" type="outline-secondary">
        Descargar informes de rutas
      </UiButton>
      <router-link class="2xl:text-base 2xl:bg-white xl:bg-primary hover:bg-primary-dark rounded xl:text-sm 2xl:py-0 xl:py-1"
        :to="{name: 'itinerary-type'}">
        <UiButton class="w-full h-full"  :type="'secondary'">
          Nueva ruta
          <SVGIcons class="svg-14 ml-2" icon="plus"/>
        </UiButton>
      </router-link>
    </div>
  </UiTableFilters>
  <UiTable>
      <UiTableHeader class="2xl:text-base xl:text-sm">
        <UiTableCell first class="w-4/12">
          <TableSorter
            :loading="loading"
            filter="name"
            v-model="activeFilter"
            @update="getItineraries()"
          >
            Nombre
          </TableSorter>
          </UiTableCell>
        <UiTableCell center class="w-2/12 flex">
          <TableSorter
            :loading="loading"
            filter="courses_count"
            v-model="activeFilter"
            @update="getItineraries()"
          >
            Formaciones
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-2/12">
          <TableSorter
            :loading="loading"
            filter="video_duration"
            v-model="activeFilter"
            @update="getItineraries()"
          >
            Duración
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-2/12">
          <TableSorter
            :loading="loading"
            filter="enrolled_count"
            v-model="activeFilter"
            @update="getItineraries()"
          >
            Usuarios
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-3/12 ">
         <TableSorter
            :loading="loading"
            :margin="'2xl:ml-2 xl:ml-1'"
            filter="created_at"
            v-model="activeFilter"
            @update="getItineraries()">
            Fecha de creación
          </TableSorter>
        </UiTableCell>
        <UiTableCell class="2xl:w-3/12 xl:w-4/12">Creado por</UiTableCell>
        <UiTableCell last class="w-10"></UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="first_loading && itineraries.length == 0"/>
      <UiTableRow class="custom-table-body flex flex-no"
        v-for="{
          name,
          id,
          can_edit,
          courses_count,
          created_at,
          duration_in_hours,
          enrolled_count,
          creator
        } in itineraries" :key="id">
          <UiTableCell first class="w-4/12" :title="name">
            <router-link class="hover:underline" :title="name" :to="{ name: 'itinerary-detail', params: {id_itinerary: id} }">
              {{name}}
            </router-link>
          </UiTableCell>
          <UiTableCell center class="flex-auto w-2/12 text-sm flex">{{courses_count}}</UiTableCell>
          <UiTableCell center class="w-2/12 text-sm flex">{{duration_in_hours}} {{(duration_in_hours === 1) ? 'hora' : 'horas'}}</UiTableCell>
          <UiTableCell center class="flex-auto w-2/12 text-sm flex items-center">
            {{enrolled_count}}
          </UiTableCell>
          <UiTableCell center class="2xl:w-3/12 xl:w-3/12 text-sm flex"> {{$filters.shortDate(created_at)}}</UiTableCell>
          <UiTableCell class="flex-auto 2xl:w-3/12 xl:w-4/12 text-sm flex">{{creator.first_name}} {{creator.last_name}}</UiTableCell>
          <UiTableCell last class="w-10 flex">
            <MenuEllipsis
              v-if="can_edit"
              class="relative -top-1"
              :items="[
                {type: 'default', permission: (can_edit) ? 'all' : '', icon: 'edit', text: 'Editar', border: false, func: () => {
                    $router.push({name: 'itinerary-detail', params: {id_itinerary: id} })
                  }
                },
                {type: 'default', permission: (can_edit) ? 'all' : '', icon: 'folder', text: 'Archivar', border: false, func: () => {
                  archiveItinerary(id)
                  }
                },
                {type: 'default', permission: (can_edit) ? 'all' : '', icon: 'arrow-down', text: 'Descargar informe', border: false, func: () => {
                  downloadItinerary(id, name)
                  }
                },
                {type: 'danger', permission: (can_edit) ? 'all' : '', icon: 'trash', text: 'Eliminar', border: true,  func: () => {
                    openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                        title: '¿Quieres eliminar esta ruta?',
                        text: 'La ruta será borrada de manera permanente.',
                        btn_text: 'Eliminar',
                        func: () => { deleteItinerary(id) }
                      }
                    })
                  }
                }
              ]"
            />
          </UiTableCell>
      </UiTableRow>
      <NotFoundSearch :status="status"/>
  </UiTable>
    </UiPageBody>
    <TableFooter v-if="!status" class="pl-12" :pages="pages" v-model="paginator" @change="getItineraries(this.paginator.page)" v-cloak/>
    <HistoricalModal ref="historical"/>
    <DialogBox ref="user-dialog" />
    <AccessDenied :permission="'can_see_itineraries_page'" />
</template>

<script>
import DialogBox from '../../components/DialogBox.vue'
import SearchField from '../../components/SearchField.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import MenuEllipsis from '../../components/MenuEllipsis/Menu.vue'
import NotFoundSearch from '../../components/NotFoundSearch.vue'
import TableFooter from '../../components/TableFooter.vue'
import UiButton from '../../components/Layout/UI/UiButton.vue'
import HistoricalModal from '../../components/Modals/HistoricalModal.vue'
import TableSorter from '../../components/TableSorter.vue'
import { api } from '../../api/instances'
import { mapState, mapMutations, mapActions } from 'vuex'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import UiTableFilters from '../../components/Layout/UI/Table/UiTableFilters.vue'
import UiTable from '../../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'
import UiTableSkeleton from '../../components/Layout/UI/Table/UiTableSkeleton.vue'
import AccessDenied from '../../components/AccessDenied.vue'

export default {
  name: 'Itinerary',
  components: {
    AccessDenied,
    DialogBox,
    SearchField,
    TableFooter,
    HistoricalModal,
    TableSorter,
    NotFoundSearch,
    MenuEllipsis,
    UiButton,
    SVGIcons,
    UiPageBody,
    UiTableFilters,
    UiTable,
    UiTableHeader,
    UiTableCell,
    UiTableRow,
    UiTableSkeleton
  },
  data () {
    return {
      itineraries: [],
      search: '',
      team_id: '',
      pages: {},
      status: false,
      disabled: false,
      loading: false,
      first_loading: true,
      activeFilter: { filter: 'created_at', direction: true },
      paginator: {
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapState(['active_business', 'user', 'files_downloading'])
  },
  methods: {
    ...mapActions(['openDialogBox', 'updateProgressWait']),
    ...mapMutations(['addToastMessage', 'progressWait']),
    async getItineraries (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/itineraries/`,
        {
          params: {
            limit: this.paginator.limit,
            page: pag,
            student__department_id: this.team_id,
            role: this.role_id,
            archived: false,
            draft: false,
            search: this.search,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          if (data.count === 0) {
            this.status = true
          } else {
            this.status = false
          }
          this.loading = false
          this.itineraries = data.results
          this.pages = data.paginator
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
    },
    async archiveItinerary (id_itinerary) {
      await api.patch(`/jarvis/business/${this.active_business}/itineraries/${id_itinerary}/archived/`)
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.getItineraries()
        })
        .catch(() => {
          console.error('Ha habido un problema para archivar la ruta')
        })
    },
    async downloadItinerary (id_itinerary, name) {
      const id = this.files_downloading.length
      this.updateProgressWait({ id, name_file: `${name}.csv` })
      const idx = this.files_downloading.findIndex(file => file.id === id)
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${id_itinerary}/download-detail/`, { responseType: 'blob' })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.csv`)
          document.body.appendChild(link)
          link.click()
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'Se ha descargado con éxito la ruta!'
          })
        })
        .catch(() => {
          console.error('Ha habido un problema al descargar el informe.')
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.addToastMessage({
            status: 'danger',
            title: '¡Error!',
            message: 'Ha ocurrido un error al descargar la ruta.'
          })
        })
      setTimeout(() => {
        this.files_downloading[idx].is_downloading = false
        this.files_downloading.splice(idx, 1)
      }, 2000)
    },
    async downloadMassiveItineraries () {
      const id = this.files_downloading.length
      this.updateProgressWait({ id, name_file: 'Rutas-corporativas.zip' })
      const idx = this.files_downloading.findIndex(file => file.id === id)
      await api.post(`/jarvis/business/${this.active_business}/itineraries/download-summary/`,
        {
          ids: this.itineraries.map(el => el.id),
          responseType: 'blob'
        })
        .then((res) => {
          const link = document.createElement('a')
          link.setAttribute('href', 'data:application/zip;base64,' + res.data.base64)
          link.setAttribute('download', res.data.name)
          document.body.appendChild(link)
          link.click()
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.addToastMessage({
            status: 'success',
            title: '¡Completado!',
            message: 'Se ha descargado con éxito la ruta!'
          })
          document.body.removeChild(link)
        })
        .catch((err) => {
          console.error('Ha habido un problema al descargar el informe.', err)
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.addToastMessage({
            status: 'danger',
            title: '¡Error!',
            message: 'Ha ocurrido un error al descargar las rutas.'
          })
        })
      setTimeout(() => {
        this.files_downloading[idx].is_downloading = false
        this.files_downloading.splice(idx, 1)
      }, 2000)
    },
    async deleteItinerary (id_itinerary) {
      await api.delete(`/jarvis/business/${this.active_business}/itineraries/${id_itinerary}/`)
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.getItineraries()
        })
        .catch(() => {
          console.error('Ha habido un problema para borrar la ruta')
        })
      this.$refs['user-dialog'].dialog('hide')
    }
  },
  created () {
    this.getItineraries()
  }
}
</script>

<style scoped>

</style>
