<template>
  <div class="fixed right-10 bottom-5 lg:w-1/4 z-50">
    <ToastMessage
      v-for="({id, title, message, status}, index) in toast_messages" :key="id" @click="state = false"
      :index="index"
      :title="title"
      :message="message"
      :status="status"
    ></ToastMessage>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ToastMessage from './ToastMessage.vue'

export default {
  name: 'ToastList',
  components: {
    ToastMessage
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    ...mapState(['toast_messages'])
  }
}
</script>

<style></style>
