<template>
  <div class="px-5 text-sm">
    Mostrar
    <select v-model="limit" @change="update" class="p-1 rounded bg-grey-lightest" name="limit" id="limit">
      <option v-for="option in options" :key="option" :value="option">{{option}}</option>
    </select>
    resultados por página de un total de <strong>{{count}} resultados.</strong>
  </div>
</template>

<script>
export default {
  name: 'PageLimit',
  props: {
    modelValue: {
      type: Number
    },
    count: {
      type: Number,
      default: 0
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      limit: 10,
      options: [10, 25, 50, 100]
    }
  },
  methods: {
    update () {
      this.$emit('update:modelValue', this.limit)
      this.$emit('change')
    }
  },
  created () {
    this.limit = this.modelValue
    this.options = (this.limit % 2 !== 0) ? this.options.map(el => el - 1) : [10, 25, 50, 100]
  }
}
</script>

<style></style>
