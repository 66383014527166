<template>
  <article :class="`custom-table ${padding}`">
    <slot></slot>
  </article>
</template>

<script>
export default {
  name: 'UiTable',
  props: {
    padding: {
      type: String,
      default: 'm-4'
    }
  }
}
</script>
