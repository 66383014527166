<template>
  <nav class="flex items-center px-4 pb-2">
    <div>
      <svg class="mr-3" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.94443 1H17.0554C17.577 1 17.9998 1.42946 17.9998 1.95926L18 3.48066C18.0001 3.73517 17.9006 3.97924 17.7234 4.15919L11.6653 10.3128C11.4883 10.4927 11.3887 10.7368 11.3887 10.9912V17.0401C11.3887 17.6643 10.8113 18.1223 10.2153 17.9709L8.3264 17.4912C7.90594 17.3844 7.611 17.0007 7.611 16.5604V10.9912C7.611 10.7368 7.5115 10.4927 7.33439 10.3128L1.27661 4.15914C1.0995 3.97922 1 3.7352 1 3.48076V1.95938C1 1.42953 1.42283 1 1.94443 1Z" stroke="#304178" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'UiMoreFilters'
}
</script>

<style>

</style>
