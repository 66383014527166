<template>
  <div class="chart-wrap flex">
    <div class="relative" id="chart">
      <apexchart type="donut" width="100%" :options="options" :series="series"></apexchart>
      <dl class="data absolute">
        <dd>{{total}}</dd>
        <dt>{{label}}</dt>
      </dl>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  name: 'APXDonutChart',
  props: {
    label: {
      type: String,
      default: ''
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      series: [],
      timer: null
    }
  },
  computed: {
    total () {
      if (this.chartData.length === 0) {
        return 0
      }
      return this.chartData.map(el => el.count)
        .reduce(
          (v1, v2) => v1 + v2, 0
        )
    },
    options () {
      return {
        chart: {
          width: '100%',
          type: 'donut'
        },
        colors: this.chartData.map((el) => el.color) || [],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%'
            }
          }
        },
        legend: {
          show: false,
          position: 'right',
          offsetY: 0,
          height: 230
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="px-2 pb-1" style="background: ' + this.chartData[seriesIndex].color + '">' +
                    '<span class="text-xs">' + this.chartData[seriesIndex].name + ' ' + this.chartData[seriesIndex].count + ' </span>' +
                    '</div>'
          }
        }
      }
    }
  },
  watch: {
    chartData () {
      this.series = this.chartData.map((el) => el.count)
    }
  },
  methods: {
    reset () {
      this.series = this.chartData.map((el) => el.count)
    }
  },
  created () {
    this.series = this.chartData.map((el) => el.count)
    window.addEventListener('resize', () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.reset()
      }, 300)
    })
  }

}
</script>

<style>
  .data {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 100px;
    text-align: center;
  }
  .data dd {
    font-size: 38px;
    line-height: 28px;
    font-weight: 600;
    color: #344054;
    padding-bottom: .5rem;
    @apply 2xl:text-4xl xl:text-3xl
  }
  .data dt {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
    color: #344054;
    @apply 2xl:px-0 xl:px-2
  }
</style>
