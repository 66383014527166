<script setup>
import UiTable from '@/components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '@/components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '@/components/Layout/UI/Table/UiTableCell.vue'
import UiTableBody from '@/components/Layout/UI/Table/UiTableBody.vue'
import UiTableRow from '@/components/Layout/UI/Table/UiTableRow.vue'
import UiTableSkeleton from '@/components/Layout/UI/Table/UiTableSkeleton.vue'
import TableSorter from '@/components/TableSorter.vue'
import MenuEllipsis from '@/components/MenuEllipsis/Menu.vue'
import UserIdentity from '@/components/UserIdentity.vue'
import useContextualMenu from '../composables/useContextualMenu'
import NotFoundSearch from '@/components/NotFoundSearch.vue'
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import { api } from '@/api/instances'
const store = useStore()
const props = defineProps({
  userSearch: {
    type: String
  },
  teamId: {
    type: Number,
    required: true
  },
  dataFromSearch: {
    type: Object
  }
})
const active_business = computed(() => store.state.active_business)
const openMainDialogBox = (msg) => store.dispatch('openMainDialogBox', msg)
const closeMainDialogBox = () => store.dispatch('closeMainDialogBox')
const addToastMessage = (message) => store.commit('addToastMessage', message)
const activeFilter = ref({ filter: 'student', direction: true })
const { mailTo } = useContextualMenu()
const dataSprint = ref({
  results: []
})
const loading = ref(false)
const deleteUserSprint = async (context, teamId, sprint_id, position) => {
  await api.delete(`/jarvis/business/${active_business.value}/planner/${teamId}/${sprint_id}/seat/${position}/`)
    .then(({ data }) => {
      addToastMessage({
        status: 'success',
        title: '¡Borrado con éxito!',
        message: 'Se ha borrado al usuario del sprint.'
      })
      closeMainDialogBox()
    })
    .catch((err) => {
      addToastMessage({
        status: 'danger',
        title: 'Error!',
        message: 'Se ha producido un error al borrar el usuario del sprint.'
      })
      console.error('No se ha podido borrar el usuarios al slot')
      console.error(err)
    })
    .finally(() => {
      searchUser()
    })
}
const isSearchingUser = ref(false)
const searchUser = async () => {
  loading.value = true
  api.get(`/jarvis/business/${active_business.value}/planner/${props.teamId}/users/`,
    {
      params: {
        s: props.userSearch,
        ordering: activeFilter.value.direction === true ? `-${activeFilter.value.filter}` : activeFilter.value.filter
      }
    })
    .then(({ data }) => {
      dataSprint.value = data
      isSearchingUser.value = true
      loading.value = false
    })
    .catch((error) => {
      console.log(error)
    })
}

onMounted(() => {
  searchUser()
})
</script>
<template>
  <div>
    <h2 class="text-lg font-medium text-primary ">Resultados de la búsqueda</h2>
    <UiTable class="mt-14">
      <UiTableHeader>
        <UiTableCell class="w-4/12" first>
          <TableSorter
            :loading="loading"
            @update="searchUser"
            filter="student"
            v-model="activeFilter"
          >
            Nombre
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-2/12 flex justify-center">
          <TableSorter
            :loading="loading"
            @update="searchUser"
            class="flex justify-center"
            filter="sprint__order"
            v-model="activeFilter"
          >
            Sprint
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-2/12">
          <span>Fecha de inicio</span>
        </UiTableCell>
        <UiTableCell center class="w-2/12">
          <span>Fecha de fin</span>
        </UiTableCell>
        <UiTableCell last class="w-20">
        </UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="dataSprint.count === 0 && loading"/>
      <UiTableBody class="text-sm">
        <UiTableRow v-for="sprint in dataSprint.results" :key="sprint.id">
          <UiTableCell class="w-4/12" first>
             <UserIdentity  :user="sprint.user" dark />
          </UiTableCell>
          <UiTableCell center class="w-2/12">
             Sprint {{ sprint.sprint_order }}
          </UiTableCell>
          <UiTableCell center class="w-2/12">
            {{ $filters.shortDate(sprint.sprint_start) }}
          </UiTableCell>
          <UiTableCell center class="w-2/12">
             {{ $filters.shortDate(sprint.sprint_end) }}
          </UiTableCell>
          <UiTableCell last class="w-2/12 flex justify-end">
        <MenuEllipsis
          class="relative mr-3"
          :items="[
              { type: 'default', permission:'can_see_planner', icon: 'email-g', text: 'Enviar mensaje', border: false,
                func: () => { mailTo(seat.user.email) }
              },
              {type: 'danger', permission:'can_see_planner', icon: 'trash', text: 'Quitar del sprint', border: true,  func: () => {
                  openMainDialogBox({ dialog: {
                      title: '¿Estás seguro de eliminar a este usuario del sprint?',
                      text: 'El usuario será eliminado del sprint.',
                      btn_text: 'Eliminar',
                      func: () => {
                        deleteUserSprint(context, props.teamId, sprint.sprint_id, sprint.position)
                      }
                    }
                  })
                }
              }
            ]" />
          </UiTableCell>
        </UiTableRow>
      </UiTableBody>
      <NotFoundSearch :status="dataSprint.count === 0 && !loading" />
    </UiTable>
  </div>
</template>
