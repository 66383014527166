<template>
  <RouterLink  v-if="!loading" class="w-full relative flex" :to="{ path: '/gestion/asistente' }">
  <div data-ph="widgetLicenseAssistant" class="flex extend flex-col w-full items-center justify-center border border-primary rounded hover:bg-grey-lighter-2 cursor-pointer">
    <div class="flex flex-col w-full items-center">
      <div v-if="score < 50" class="w-full text-center leading-5 font-medium flex justify-center items-center">
        <div>Realizar mejoras<br> pendientes</div>
        <div class="bg-grey-lighter p-1 ml-2 rounded">{{ pendingImprovements + pendingRequest }}</div>
      </div>
      <div v-if="score > 50 && score < 80" class="w-full text-center leading-5 font-medium text-primary-dark">
        <div class="">Tienes licencias <br> sin actividad</div>
      </div>
      <div v-if="score === 80" class="w-full text-center leading-5 font-medium text-primary-dark">
        <div class="">Tienes solicitudes de<br> licencia sin contestar</div>
      </div>
      <div v-if="score > 80" class="w-full text-center leading-5 font-medium text-primary-dark">
        <div class="">¡Enhorabuena!</div>
        <div class="">Tienes la gestión al dia</div>
      </div>
      <div v-if="permissions.includes('can_create_teams')"></div>
      <div class="relative w-[140px]">
        <SpeedometerBG :color="degreeTeamState.color" :id="`speedHome`" />
        <div class="flex justify-center absolute" style="width: 10%; bottom: 10%; left: 50%; transform: translateX(-50%);">
          <div class="absolute z-10" style="bottom: -14.5%">
            <svg width="100%" height="100%" viewBox="0 0 75 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M37.1,0c20.49,-0.014 37.136,16.61 37.15,37.1c0.014,20.49 -16.61,37.136 -37.1,37.15c-20.49,0.014 -37.136,-16.61 -37.15,-37.1c-0.014,-20.49 16.61,-37.136 37.1,-37.15Z" style="fill:#323232;"/><path d="M37.1,0c20.49,-0.014 37.136,16.61 37.15,37.1c0.014,20.49 -16.61,37.136 -37.1,37.15c-20.49,0.014 -37.136,-16.61 -37.15,-37.1c-0.014,-20.49 16.61,-37.136 37.1,-37.15Zm0.005,7.5l0.005,0c16.346,-0.011 29.629,13.255 29.64,29.605c0.011,16.351 -13.255,29.634 -29.605,29.645c-16.351,0.011 -29.634,-13.255 -29.645,-29.605c-0.011,-16.351 13.255,-29.634 29.605,-29.645Z" style="fill:#fff;"/></svg>
          </div>
          <div class="transition-all" :style="`width: 50%; transform-origin: bottom center; transform: rotate(${degree - 90}deg);`">
            <svg width="100%" height="100%" viewBox="0 0 43 322" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M22.366,-0l20.052,321.627l-42.418,-0.152l22.366,-321.475Z" style="fill:#323232;fill-rule:nonzero;"/></svg>
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm text-center text-[11px] w-full leading-3 mt-2">
      <div class="whitespace-nowrap mb-1">La calidad de la gestión:</div>
      <div class="font-bold" :style="`color:${degreeTeamState.color}`">{{ degreeTeamState.text }}</div>
    </div>
  </div>
</RouterLink>
<div v-else class="flex w-full items-center justify-center border border-primary-dark rounded relative overflow-hidden">
  <IATyping class="relative z-10" :small="true" :messages="[
      'Cargando...',
      'Analizando gestión...',
      'Analizando equipos...',
      'Analizando licencias...',
      'Generando información...',
      'Exploración finalizada...',
      'Integrando todos...',
      'Generando información final...'
  ]" />
  <svg class="absolute -bottom-6 opacity-50 scale-150" width="100%" height="60%" viewBox="0 0 281 172" version="1.1">
    <path
      class="pulse"
      d="M279.714,118.954l1.126,52.341l-280.84,0l0,-33.318c0,0 2.542,-111.67 43.784,-111.67c41.242,0 36.763,94.258 75.584,94.258c38.821,0 46.953,-58.678 92.492,-58.678c45.538,-0 67.854,57.067 67.854,57.067Z"
      style="fill:#d0e3f0;" />
    <path
      class="pulse-reverse"
      d="M280.84,113.908l-0.143,57.387l-282.602,0l-0,-60.468c-0,-0 27.409,-73.949 64.999,-71.253c37.59,2.696 40.509,79.341 64.697,77.415c24.187,-1.926 18.349,-77.03 78.818,-77.415c60.469,-0.385 74.231,74.334 74.231,74.334Z"
      style="fill:#7ebae2;fill-opacity:0.54;" />
  </svg>
</div>
</template>
<script setup>
import IATyping from './IATyping.vue'
import SpeedometerBG from './SpeedometerBG.vue'
import { ref, computed, onMounted } from 'vue'
import { api } from '@/api/instances'
import { useStore } from 'vuex'

const store = useStore()
const loading = ref(false)
const permissions = computed(() => store.state.permissions)
const generalConunter = ref(null)
const idBusiness = computed(() => {
  return store.state.active_business
})

const degree = computed(() => {
  if (!generalConunter.value) return 0
  return (180 / 100) * generalConunter.value.management_score
})

const pendingImprovements = computed(() => {
  if (!generalConunter.value) return 0
  const total = generalConunter.value.available_licenses_for_teams
  return generalConunter.value.available_licenses_for_people + generalConunter.value.unactive_licenses + generalConunter.value.unactive_licenses + (total) ? total : 0
})

const pendingRequest = computed(() => {
  if (!generalConunter.value) return 0
  return generalConunter.value.pending_requests
})
const score = computed(() => {
  if (!generalConunter.value) return 0
  return generalConunter.value.management_score
})
const degreeTeamState = computed(() => {
  if (degree.value < 30) {
    return { color: '#ff1a1a', text: 'Urge mejoría' }
  } else if (degree.value < 60) {
    return { color: '#ff1a1a', text: 'Urge mejoría' }
  } else if (degree.value < 90) {
    return { color: '#e4a149', text: 'Necesita mejorar' }
  } else if (degree.value < 120) {
    return { color: '#d1d80f', text: 'Requiere tu atención' }
  } else if (degree.value < 150) {
    return { color: '#84bd32', text: 'Buena' }
  } else if (degree.value <= 180) {
    return { color: '#30ad43', text: 'Excelente' }
  } else {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  }
})

const getCounter = async () => {
  loading.value = true
  try {
    const { data } = await api.get(`/jarvis/business/${idBusiness.value}/assistant/general/`)
    generalConunter.value = data
    const score = generalConunter.value.management_score
    generalConunter.value.management_score = 0
    setTimeout(() => {
      generalConunter.value.management_score = score
    }, 300)
    loading.value = false
  } catch (error) {
    console.log(error)
    loading.value = false
  }
}

onMounted(() => {
  getCounter()
})

</script>

<style scoped>
  .transition-all {
    transition: all 0.3s ease-in-out;
  }
  .pulse {
    animation: pulse 5s infinite;
  }
  .pulse-reverse {
    animation: pulse-reverse 3s infinite;
  }
  .pulse-reverse-2 {
    transform: scaleX(-1);
    animation: pulse-reverse 3s infinite;
  }
  @keyframes pulse {
    0% {
      transform: translate(-50%, 0);
      opacity: 0.5;
    }
    50% {
      transform: translate(0%, 0%);
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
      transform: translate(-50%, 0);
    }
}
  @keyframes pulse-reverse {
    0% {
      transform: translate(30%, 0);
    }
    50% {
      transform: translate(0%, 10%);
      opacity: 0.5;
    }
    100% {
        transform: translate(30%, 0);
    }
}
.extend::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
