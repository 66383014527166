<script setup>
import { api } from '@/api/instances'
import { useStore } from 'vuex'
import { ref, computed, defineExpose } from 'vue'

import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import ModalFooter from '@/components/Modals/components/ModalFooter.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import UiLoadingIcon from '@/components/Layout/UI/UiLoadingIcon.vue'

const store = useStore()
const active_business = computed(() => store.state.active_business)

const loading = ref(false)
/*
const first_loading = ref(true)
*/

const open = ref(false)
const emails = ref('')
const result = ref(false)
const slot = ref({})
const step = ref(0)
const messege = ref(null)

const modal = async (action, obj = null) => {
  if (action === 'show') {
    slot.value = { ...obj }
    open.value = true
  }
  if (action === 'hide') {
    open.value = false
  }
}

const closeModal = () => {
  open.value = false
  slot.value = {}
  step.value = 0
  messege.value = null
  result.value = false
  emails.value = ''
}
defineExpose({ modal })
const emit = defineEmits(['change'])
const emailRegex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,63}$/i

const allCorrectEmails = computed(() => {
  return emails.value.split('\n').filter((email) => emailRegex.test(email))
})

const allIncorrectLines = computed(() => {
  return emails.value.split('\n').filter((email) => email !== '' && !emailRegex.test(email))
})

const isValid = computed(() => {
  if (messege.value === null) return null
  if (messege.value.invalid_emails.length > 0) {
    return false
  } else {
    return true
  }
})

const clearBadLines = () => {
  emails.value = allCorrectEmails.value.join('\n')
}

const setUsersToRoute = () => {
  if (allCorrectEmails.value.length === 0) { return false }
  loading.value = true
  api.patch(`/jarvis/business/${active_business.value}/itineraries/${slot.value.route_id}/students/by-email/`,
    {
      emails: allCorrectEmails.value
    })
    .then(({ data }) => {
      loading.value = false
      step.value = 1
      messege.value = data
      emit('change')
    })
    .catch((err) => {
      loading.value = false
      step.value = 1
      messege.value = null
      console.error('No se ha podido asignar el usuarios al slot')
      console.error(err)
    })
}

</script>
<template>
  <Overlay :open="open">
    <Modal>
      <ModalHeader :underline="false" @close="closeModal">
        <ModalTitle v-if="isValid === null">
          Añadir usuarios con lista de correos
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div v-if="step == 0">
          <div class="flex flex-col">
            <p class="mb-2 text-grey">Escribe o pega aquí la lista de correos. Un correo por línea.</p>
            <textarea class="border border-grey-light  p-5 rounded-md" placeholder="correo@dominio.com" v-model="emails" name="emails" id="emails" cols="30" rows="10"></textarea>
            <p class="text-sm mt-2 text-grey-light">Introducidos <span><strong>{{allCorrectEmails.length}}</strong> email{{allCorrectEmails.length !== 1?'s':''}} correctos, <strong>{{allIncorrectLines.length}}</strong> incorrectos.</span> <a v-if="allIncorrectLines.length > 0" @click.prevent="clearBadLines" href="#"><small>( Borrar líneas incorrectas )</small></a></p>
          </div>
        </div>
        <div v-if="step == 1">
          <div class="flex flex-col p-5">
            <figure class="flex justify-center mb-6">
              <svg v-if="isValid" width="50" height="50" viewBox="0 0 50 50" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="#12C85B" />
                <path
                  d="M17.0669 25.8739L15.5939 24.5211L12.8882 27.4672L14.3613 28.82L17.0669 25.8739ZM21.7141 32.8571L20.3613 34.3302L21.7141 35.5726L23.0669 34.3302L21.7141 32.8571ZM37.0669 21.4731L38.54 20.1202L35.8344 17.1741L34.3613 18.5269L37.0669 21.4731ZM14.3613 28.82L20.3613 34.3302L23.0669 31.3841L17.0669 25.8739L14.3613 28.82ZM23.0669 34.3302L37.0669 21.4731L34.3613 18.5269L20.3613 31.3841L23.0669 34.3302Z"
                  fill="white" />
              </svg>
              <svg v-else width="53" height="53" viewBox="0 0 53 53" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="26.5" cy="26.5" r="26.5" fill="#F32222" />
                <path
                  d="M18.1714 36.3429L27.2571 27.2572M27.2571 27.2572L36.3428 18.1714M27.2571 27.2572L18.1714 18.1714M27.2571 27.2572L36.3428 36.3429"
                  stroke="white" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" />
              </svg>
            </figure>
            <h2 class="text-xl text-center font-bold text-grey-dark">
              {{(isValid)
              ? 'Planificación completada'
              : 'Ups, algo no ha ido como esperábamos'
              }}</h2>
            <div class="w-full text-grey-dark flex flex-col items-center">
              <p v-if="messege.added_users > 0 && !isValid" class="text-grey-dark mt-2">La selección de usuarios se ha completado con errores</p>
              <p v-if="!isValid && messege.added_users == 0" class="text-grey-dark mt-2">No se ha podido completar la selección</p>
              <p class="text-sm mt-2" v-if="!isValid">No hemos podido añadir <strong>{{messege.invalid_emails.length}} usuario{{(messege.invalid_emails.length > 1) ? 's' : ''}}</strong></p>
              <p class="text-sm mt-5" v-else>Se han asignado <strong>{{messege.added_users}}</strong> usuario{{(messege.added_users > 1) ? 's' : ''}} correctamente</p>
            </div>
          </div>
          <div class="mt-8 text-grey" v-if="!isValid">
            A continuación detallamos qué errores hemos encontrado
          </div>
          <div class="my-5 text-sm" v-if="!isValid">
            <div class="flex py-5 px-5 mb-1 bg-grey-lightest">
              <div class="w-1/2 font-bold text-grey-dark">Email</div>
              <div class="w-1/2 font-bold text-grey-dark">Error</div>
            </div>
            <div v-for="messege in messege.invalid_emails" :key="messege.email"
              class="flex mt-3 py-5 px-5 bg-grey-lightest">
              <div class="w-1/2">{{messege.email}}</div>
              <div class="w-1/2">{{messege.error}}</div>
            </div>
          </div>
       <!--    <hr>
          {{messege}} -->
        </div>
      </ModalBody>
      <ModalFooter>
        <UiButton v-if="step == 0" @click="closeModal" class="mr-2">Cancelar</UiButton>
        <UiButton v-if="step == 0" @click="setUsersToRoute" :type="'secondary'">
          <UiLoadingIcon class="mr-4" v-if="loading" />
          Validar lista
        </UiButton>
        <UiButton v-if="step == 1" @click="closeModal" :type="'secondary'">
          Aceptar
        </UiButton>
      </ModalFooter>
    </Modal>
  </Overlay>
</template>

<style>
  .h-users {
    height: 275px;
  }
</style>
