<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold border-b-2 border-black p-3.5 pl-0">Petición de licencias</h2>
            <button type="button" @click="changeState(false)" class="absolute right-10 top-12">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
  <article class="px-12">
    <p class="text-black text-sm mt-5 mb-8">{{message}}</p>
        <label class="flex flex-col w-full" for="message">
          <strong class="font-bold text-grey-darkest">Mensaje:</strong>
          <textarea id="message"
         cols="30" rows="10" placeholder="Escribe aquí..."
         class="border-2 border-grey-lightest rounded mt-2 p-3 h-36 resize-none"
         v-model="text_message"
         ></textarea>
        </label>
  </article>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <UiButton :type="'outline-default'" @click="changeState(false)">Cancelar</UiButton>
        <UiButton :size="'btn-lg'" class="ml-5" :type="(text_message === '') ? 'disabled' : 'secondary'" @click="sendMessage()">Enviar</UiButton>
      </footer>
    </div>
  </div>
  </transition>
</template>
<script>
import UiButton from '../Layout/UI/UiButton.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'AddUserModal',
  components: {
    UiButton
  },
  prop: ['id'],
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      open: false,
      requests: [],
      id: this.id,
      text_message: ''
    }
  },
  computed: {
    ...mapState(['active_business', 'user']),
    message () {
      if (this.user.business[0].role === 'staff') {
        return 'Ponte en contacto con el manager de tu organización para solicitar más licencias.'
      } else if (this.user.business[0].role === 'admin') {
        return 'Ponte en contacto con tu Customer Success para solicitar más licencias.'
      }
      return ''
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async sendMessage () {
      api.post(`/jarvis/business/${this.active_business}/stats/send-email/`, {
        subject: `Solicitud de asientos - ${this.user.email}`,
        message: this.text_message
      })
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.text_message = ''
          this.changeState(false)
        })
        .catch(() => {
          console.error('Ha habido un problema al enviar el mensaje.')
        })
    }
  }
}
</script>

<style scoped>
  textarea::placeholder {
    font-size: 14px;
      line-height: 2;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white mb-24
  }
  .status-accepted{
    @apply text-success text-base
  }
   .status-pending{
    @apply text-warning text-base
  }
   .status-rejected{
    @apply text-danger text-base
  }
</style>
