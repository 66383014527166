<template>
  <UiBox rounded :bgClass="'bg-white flex items-center border border-grey-lightest'" :class="{'loading' : loading}">
    <div>
      <SVGIcons v-if="icon" class="svg-38" :icon="icon"/>
      <slot v-else></slot>
    </div>
      <dl class="flex flex-col justify-center ml-2">
        <dd class="text-primary text-xs font-medium pt-1">
          {{text}}
        </dd>
        <dt class="flex items-center rounded-full text-primary font-bold text-1-4rem ">
          {{(typeof stat === 'string')? stat : $filters.toLocaleNumber(stat) }}
        </dt>
      </dl>
  </UiBox>
</template>

<script>
import SVGIcons from '@/components/Layout/SVGIcons'
import UiBox from './UI/UiBox.vue'
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    stat: {
      type: [Number, String],
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SVGIcons,
    UiBox
  }
}
</script>

<style scoped>
.text-1-4rem {
  font-size: 1.4rem;
}
</style>
