<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'email-icon'
  },
  color: {
    type: String,
    default: '#344054'
  }
})
</script>
<template>
  <svg :id="props.id" width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 4H21M21 4H24M21 4V1M21 4V7" :stroke="props.color" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M4.6665 8L7.99984 10.3333L11.3332 8" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1.3335 13.333V6.66634C1.3335 5.92996 1.93045 5.33301 2.66683 5.33301H13.3335C14.0699 5.33301 14.6668 5.92996 14.6668 6.66634V13.333C14.6668 14.0694 14.0699 14.6663 13.3335 14.6663H2.66683C1.93045 14.6663 1.3335 14.0694 1.3335 13.333Z" :stroke="props.color" stroke-width="1.5"/>
  </svg>
</template>
