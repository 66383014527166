<template>
  <div class="relative">
    <div :class="`${hasTooltip}`" class="inline-block relative w-auto" >
      <div class="tooltip">
        <button @click="open = !open" class="border  rounded relative" :class="[{'bg-primary-light' : asignedEmails.length > 0 }, `${props.styleCustom}`]">
          <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10.5L13.7143 14.5L19.4286 10.5" stroke="#344054" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.1428 14.5V8.21443C25.1428 6.95206 24.1195 5.92871 22.8571 5.92871H4.57136C3.30899 5.92871 2.28564 6.95206 2.28564 8.21443V19.643C2.28564 20.9054 3.30899 21.9287 4.57136 21.9287H18.5" stroke="#344054" stroke-width="1.14286"/>
            <path d="M26 22L28 24" stroke="#344054" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 19.4286C20 21.3221 21.535 22.8571 23.4286 22.8571C24.377 22.8571 25.2355 22.4721 25.8562 21.8497C26.4747 21.2295 26.8571 20.3737 26.8571 19.4286C26.8571 17.535 25.3221 16 23.4286 16C21.535 16 20 17.535 20 19.4286Z" stroke="#344054" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
                    <!-- <div class="absolute bottom-0 right-0 bg-primary text-white rounded-full w-4 h-4 text-xs flex justify-center items-center" v-if="asignedEmails.length > 0">{{ asignedEmails.length }}</div> -->
        </button>
      </div>
      <ul class="z-50 arrow-down absolute w-fit w-full bg-white rounded-md border-2 border-[#8FA0BC] text-xs leading-4 text-grey font-normal">
        <li class=" text-primary-800 w-[160px] text-xs font-normal">
          <p class="w-full">Filtrar usuarios por email</p>
        </li>
      </ul>
    </div>
    <div v-if="open" class="fixed h-screen w-screen top-0 left-0 z-10 flex justify-center items-center" style="background-color: rgba(0,0,0,0.4);">
    <div class="absolute  z-10 w-5/12 bg-white p-12 shadow rounded">
          <div>
            <div class="flex flex-col">
              <p class="mb-5 font-bold text-2xl text-grey-dark">Filtrar usuarios por lista de correos</p>
              <button type="button" @click="open = false" class="absolute right-7 top-6">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
              </button>
              <p class="mb-2 text-sm font-light text-grey">Escribe o pega aquí la lista de correos. Un correo por línea.</p>
              <textarea class="border border-grey-lighter resize-none p-6 rounded-md" placeholder="correo@dominio.com" v-model="emails" name="emails" id="emails" cols="30" rows="7"></textarea>
              <p class="text-xs font-light mt-2 text-grey-light">Introducidos <span ><strong class="font-semibold">{{ allCorrectEmails.length }}</strong> email{{ allCorrectEmails.length !== 1 ? 's' : '' }} correctos, <strong class="font-semibold">{{ allIncorrectLines.length }}</strong> incorrectos.</span> <a v-if="allIncorrectLines.length > 0" @click.prevent="clearBadLines" href="#"><small>( Borrar líneas incorrectas )</small></a></p>
            </div>
          </div>
          <div class="mt-4 flex items-center justify-end">
            <button v-if="asignedEmails.length === 0" class="border border-primary text-primary px-2 py-2 text-sm rounded" @click="discardEmails">
              Cancelar
            </button>
            <button @mouseenter="colorIcon = 'white'" @mouseleave="colorIcon = 'red'" v-if="asignedEmails.length > 0" class="border pr-2 pl-1 py-2 hover:bg-danger hover:text-white rounded border-danger text-sm text-danger flex items-center" @click="resetEmails">
                <TrashIcon class="h-4 mr-2" :color="colorIcon" />
              Eliminar filtro
            </button>
            <button class="bg-primary hover:bg-primary-dark border-primary text-white px-3 py-2 text-sm rounded ml-2" :class="{'disabled' : allCorrectEmails.length === 0 }" @click="setEmails">
              {{ asignedEmails.length === 0 ? 'Filtrar' : ' Actualizar filtro' }}
            </button>
          </div>
       </div>
       </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import TrashIcon from './Icons/TrashIcon.vue'

const emails = ref('')
const open = ref(false)
const emit = defineEmits(['update:modelValue', 'change'])
const asignedEmails = ref([])
const colorIcon = ref('red')

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  styleCustom: {
    type: String,
    default: 'px-1'
  },
  hasTooltip: {
    type: String,
    default: ''
  }
})

onMounted(() => {
  asignedEmails.value = props.modelValue
  emails.value = props.modelValue.join('\n')
})

const emailRegex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,63}$/i

const allCorrectEmails = computed(() => {
  return emails.value.split('\n').filter((email) => emailRegex.test(email))
})

const allIncorrectLines = computed(() => {
  return emails.value.split('\n').filter((email) => email !== '' && !emailRegex.test(email))
})

const setEmails = () => {
  open.value = false
  asignedEmails.value = allCorrectEmails.value
  emit('update:modelValue', asignedEmails.value)
  emit('change')
}
const resetEmails = () => {
  asignedEmails.value = []
  emit('update:modelValue', asignedEmails.value)
  emit('change')
  discardEmails()
}

const discardEmails = () => {
  open.value = false
  emails.value = ''
}
/* const isValid = computed(() => {
  if (messege.value === null) return null
  if (messege.value.invalid_emails.length > 0) {
    return false
  } else {
    return true
  }
})
*/

const clearBadLines = () => {
  emails.value = allCorrectEmails.value.join('\n')
}
</script>

<style scoped>
  .disabled {
    pointer-events: none;
  }
.w-fit {
  min-width: fit-content;
}
.arrow-down {
  display: none;
  max-width: 300px;
  top: 10px;
  left: 49%;
  transform: translate(-50%, calc(107% - 12px));
  @apply py-2 px-2;
  text-align: center;
}

.arrow-down::before {
  content: '';
  position: absolute;
  bottom: 12px;
  left: 55%;
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  transform: rotate(222deg) translateX(100%);
  @apply border-b-2 border-r-2 border-primary-light
}
.tooltip:hover:not(.no-tooltip) .arrow-down {
  display: block;
}
</style>
