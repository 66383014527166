<template>
  <div v-if="$route.name == name">
    <slot></slot>
  </div>
  <router-view v-else />
</template>

<script>
export default {
  name: 'RouterParent',
  props: {
    name: {
      type: String
    }
  }
}
</script>
