<template>
  <button @click="updateLicenseState(!active)" class="toggle-button" :class="{'active': !active}">
      <div class="toggle-button-label yes">Si</div>
      <div class="toggle-button-label none">No</div>
    <div class="toppo">
      <div v-if="!active" class="toppo-null"></div>
      <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 5H6.7C6.8657 5 7 5.1343 7 5.3V8.7C7 8.8657 6.8657 9 6.7 9H1.3C1.13431 9 1 8.8657 1 8.7V5.3C1 5.1343 1.13431 5 1.3 5H2M6 5V3C6 2.33333 5.6 1 4 1C2.4 1 2 2.33333 2 3V5M6 5H2"
          :stroke="(active)?'#D54141':'#34c558'"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { api } from '../api/instances'

export default {
  name: 'ToggleFilteredState',
  props: {
    user: {
      type: Object
    }
  },
  emits: ['change'],
  data () {
    return {
      active: false
    }
  },
  watch: {
    user () {
      this.active = this.user.is_filtered
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    async updateLicenseState (state) {
      await api.patch(`/jarvis/business/${this.active_business}/users/${this.user.employee_id}/`, { is_filtered: state })
        .then(({ data }) => {
          this.active = data.is_filtered
          this.addToastMessage({
            status: 'success',
            title: (data.is_filtered) ? 'Activado' : 'Desactivado',
            message: (data.is_filtered) ? 'El catálogo ha sido restringido.' : 'Ahora tiene acceso a todo el catálogo.'
          })
        })
        .catch((err) => console.error(err))

      this.$emit('change')
    }
  },
  created () {
    this.active = this.user.is_filtered
  }
}
</script>

<style scoped>
  .toggle-button{
    position: relative;
    transition: all .3s ease-in-out;
    @apply bg-danger-dark h-6 p-1 rounded-full w-11 flex-none relative
  }

  .toggle-button.active{
    @apply bg-green-license
  }
  .toggle-button.active .toppo{
    left: calc(100% - 22px);
  }

  .toggle-button-label {
    position: absolute;
    color: white;
    top: 2px;
    font-size: 12px;
  }
  .toggle-button-label.yes {
    left: 6px;
  }
  .toggle-button-label.none {
    right: 4px;
  }

  .toppo-null {
    position: absolute;
    transform: rotate(45deg);
    display: block;
    width: 13px;
    border-bottom: 1px solid #34c558;
    border-top: 1px solid white;
  }

  .toppo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2px;
    left: 2px;
    transition: all .3s ease-in-out;
    @apply w-5 h-5 bg-white rounded-full absolute shadow
  }

</style>
