<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold pb-2 border-b-2 border-grey-lighter">Añadir usuario</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <article class="px-12 my-6">
        <div class=" w-full flex-auto flex mb-5">
          <div class="w-6/12">
            <SearchField
            :border="'border-none'"
            :bgClass="'bg-grey-lighter-2 min-h-[44px]'"
            :placeholder="'Buscar por nombre'" v-model="search" @change="getUsers" />
          </div>
          <div class="col-auto w-2/12">
            <SelectTeam @change="getUsers" v-model="filters.users.team_id" />
          </div>
          <div class="col-auto px-1">
            <TagsField  @change="getUsers" v-model="filters.users.tags" />
          </div>
          <div class="col-auto flex justify-end mr-0 mb-5">
            <UiButton class="2xl:text-base xl:text-sm whitespace-nowrap min-h-[44px]" @click.prevent="getMassiveUsers" type="secondary">
              Añadir todos los usuarios
            </UiButton>
          </div>
        </div>
      <nav class="custom-table-head-rank">
        <div class="w-10"></div>
        <div class="w-4/12">
          <TableSorter
            :loading="loading"
            filter="name"
            v-model="activeFilter"
            @update="getUsers()"
            >
            Nombre
          </TableSorter>
        </div>
        <div class="w-3/12">Equipo</div>
        <div class="w-3/12">Etiquetas</div>
        <div class="w-2/12"></div>
      </nav>
      <div v-if="first_loading && users.length == 0" class="skeleton-pulse">
        <div v-for="num in 5" :key="num" class=" custom-table-body-rank flex flex-no">
          <ul class="custom-table-row-rank">
            <li class="flex-auto w-full py-4"></li>
          </ul>
        </div>
      </div>
      <div class="custom-table-body" :class="{ 'loading' : loading }"
        v-for="{
          avatar_url,
          first_name,
          last_name,
          email,
          team_name,
          role,
          user_id,
          custom_tags
        } in users" :key="user_id">
        <ul class="flex w-full rounded py-5 bg-grey-lighter-2 ">
          <li class="w-10"></li>
          <li class="w-4/12 flex items-center">
            <UserIdentity :user="{first_name, email, last_name, avatar_url, role}" dark />
          </li>
          <li class="w-3/12 text-sm flex items-center">{{team_name}}</li>
          <li class="w-3/12 flex items-center">
            <TagsList :limit="3" :list="custom_tags" />
          </li>
          <li class="w-2/12 flex justify-end pr-3  items-center">
            <UiButton :size="'btn-sm'" :type="'secondary'"
              v-if="!students_ids.includes(user_id)"
              @click="students.push({
                avatar_url,
                first_name,
                last_name,
                email,
                team_name,
                role,
                user_id,
                custom_tags
              }), updateItinerary()"
              >Añadir</UiButton>
            <UiButton
              :size="'btn-sm'"
              :type="'disabled'"
              v-else >Añadido</UiButton>
          </li>
        </ul>
      </div>
      <div class="flex justify-end">
        <Paginator :pages="pages" v-model="paginator.page" @change="getUsers(this.paginator.page)"/>
      </div>
    </article>
    </div>
  </div>
  </transition>
</template>
<script>
import UserIdentity from '../UserIdentity.vue'
import Paginator from '../Paginator.vue'
import SearchField from '../SearchField.vue'
import UiButton from '../Layout/UI/UiButton.vue'
import SelectTeam from '../SelectTeam.vue'
import TableSorter from '../TableSorter.vue'
import TagsField from '../TagsField.vue'
import TagsList from '../TagsList.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'AddUserModal',
  components: {
    TableSorter,
    Paginator,
    UiButton,
    SearchField,
    SelectTeam,
    TagsList,
    TagsField,
    UserIdentity
  },
  emits: ['modal:show', 'modal:hide', 'success'],
  data () {
    return {
      activeFilter: { filter: '', direction: false },
      first_loading: true,
      loading: false,
      open: false,
      users: [],
      students: [],
      search: '',
      pages: {},
      paginator: {
        limit: 5,
        page: 1
      }
    }
  },
  computed: {
    ...mapState(['active_business', 'filters']),
    students_ids () {
      return this.students.map((el) => el.user_id)
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getUsers (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/managed-users/`,
        {
          params: {
            limit: this.paginator.limit,
            page: pag,
            search: this.search,
            department_id: this.filters.users.team_id,
            custom_tags: this.filters.users.tags,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          this.users = data.results
          this.pages = data.paginator
          this.loading = false
        })
        .catch(() => {
          console.error('Problema al cargar los usuarios')
          this.loading = false
        })
      this.first_loading = false
    },
    async getStudents () {
      await api.get(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/students/`,
        {
          params: {
            pagination: 'False' // Este False en string to feo es culpa de Ale!
          }
        }
      )
        .then(({ data }) => {
          this.students = data
        })
        .catch(() => {
          console.error('Problema al cargar los usuarios')
          this.loading = false
        })
    },
    async updateItinerary () {
      await api.patch(`/jarvis/business/${this.active_business}/itineraries/${this.$route.params.id_itinerary}/students/`, {
        students: this.students_ids
      })
        .then(({ data }) => {
          this.addToastMessage({
            status: data.status,
            title: '¡Completado!',
            message: data.text
          })
          this.$emit('success')
        })
        .catch(() => {
          console.error('Problema al actualizar los usuarios')
        })
        /* Se comenta para que no se cierre al añadir un usuario
        // this.changeState(false)
        */
    },
    async getMassiveUsers () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/managed-users/`,
        {
          params: {
            limit: 1000000,
            search: this.search,
            department_id: this.filters.users.team_id,
            custom_tags: this.filters.users.tags,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          console.log(data.results)
          this.students = [...this.students, ...data.results]
          console.log(this.students)
          this.loading = false
          this.updateItinerary()
        })
        .catch(() => {
          console.error('Problema al cargar los usuarios')
          this.loading = false
        })
      this.first_loading = false
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }

  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-8of12 rounded-lg bg-white
  }
  .custom-table-head-rank{
    @apply flex my-2 mb-7 rounded py-3 font-medium text-grey;
    background-color: #E3E5EC;
}
.custom-table-body-rank{
    @apply mb-2 rounded;
    background-color: #E3E5EC;
}
.custom-table-body-rank .custom-table-row-rank{
    @apply flex w-full rounded py-5;
    background-color: #E3E5EC;
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

</style>
