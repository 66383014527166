<script setup>
import { ref, defineExpose, computed } from 'vue'

import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import ModalFooter from '@/components/Modals/components/ModalFooter.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import Balloon from '@/components/Balloon.vue'
import UiTableHeader from '@/components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '@/components/Layout/UI/Table/UiTableCell.vue'
import UiTableRow from '@/components/Layout/UI/Table/UiTableRow.vue'
import SadFaceIcon from '../components/../Icons/SadFaceIcon.vue'
import HappyFaceIcon from '../components/../Icons/HappyFaceIcon.vue'

const open = ref(false)
const pending = ref(true)
const modal = async (action, obj = null) => {
  if (action === 'show') {
    open.value = true
    if (obj) {
      requestsPending.value = obj.pending_requests
      requestsSolved.value = obj.solved_requests
      if (obj.satisfyerView) {
        pending.value = false
      }
    }
  }
  if (action === 'hide') {
    open.value = false
  }
}

defineExpose({ modal })

const closeModal = () => {
  open.value = false
  pending.value = true
}
const acceptedRequests = computed(() => {
  return { items: requestsSolved.value.filter(request => request.accepted === true), icon: HappyFaceIcon, color: '#0C7946', msg: 'Activada' }
})
const deniedRequests = computed(() => {
  return { items: requestsSolved.value.filter(request => request.accepted === false), icon: SadFaceIcon, color: '#DD1313', msg: 'Rechazada' }
})
const overduedRequests = computed(() => {
  return { items: requestsSolved.value.filter(request => !request.accepted && request.overdued), icon: SadFaceIcon, color: '#DD1313', msg: 'Caducada' }
})
const requests = computed(() => {
  return [acceptedRequests.value, deniedRequests.value, overduedRequests.value]
})
const requestsPending = ref([])
const requestsSolved = ref([])

</script>
<template>
  <Overlay class="z-50" :open="open">
    <Modal class="mt-24">
      <ModalHeader @close="closeModal">
        <ModalTitle>
          Histórico de Solicitudes
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <nav class="flex justify-between w-full border-b border-grey-lighter">
          <ul class="flex">
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex font-medium items-center bg-transparent pb-4"
                :class="(pending === true) ? 'active-link' : 'desactive'" @click="pending = true">
                Pendientes
                <Balloon class="ml-3 px-2 py-0.5" :type="'secondary'"> {{ requestsPending.length }}</Balloon>
              </button>
            </li>
            <li class="flex-none text-xl mr-6 pr-1">
              <button class="text-xl flex font-medium items-center bg-transparent pb-4"
                :class="(pending === false) ? 'active-link' : 'desactive'" @click="pending = false">
                Resueltas
              </button>
            </li>
          </ul>
        </nav>
        <section v-if="pending" class="my-7">
          <div v-if="requestsPending.length > 0">
            <UiTableHeader class="mr-4" >
              <UiTableCell first class="w-4/12">
                Fecha
              </UiTableCell>
              <UiTableCell  class="w-8/12">
                Contenido solicitado
              </UiTableCell>
            </UiTableHeader>
            <div class="custom-scroll h-[300px] w-full overflow-y-scroll" >
              <UiTableRow v-for="{id, created_at, content} in requestsPending" :key="id" class="text-[#626262] font-medium w-full " :bg="'bg-grey-lightest'">
                <UiTableCell first class="w-4/12">
                  {{ $filters.shortDate(created_at) }}
                </UiTableCell>
                <UiTableCell  class="w-8/12">
                  {{(content) ? content : 'Petición no asociada a contenido'}}
                </UiTableCell>
              </UiTableRow>
            </div>
          </div>
          <div v-else>
            <h3 class="text-center text-primary font-bold text-xl">No hay solicitudes pendientes</h3>
          </div>
        </section>
        <section v-else class="my-7">
          <div v-if="requestsSolved.length > 0">
            <UiTableHeader class="mr-4">
              <UiTableCell first class="w-2/12">
                Fecha
              </UiTableCell>
              <UiTableCell  class="w-8/12">
                Contenido solicitado
              </UiTableCell>
              <UiTableCell  class="w-2/12">
                Estado
              </UiTableCell>
            </UiTableHeader>
            <div class="custom-scroll h-[320px] w-full overflow-y-scroll">
              <div v-for="request in requests" :key="request.msg" >
                <UiTableRow v-if="request.items.length > 0"  class="text-[#626262] font-medium w-full "    :bg="'bg-grey-lightest'">
                  <div class="flex flex-col w-10/12" >
                    <div class="flex items-center my-4" v-for="{ id, created_at, content } in request.items" :key="id" >
                      <UiTableCell first class="w-full">
                        <div class="w-2/12">
                          {{ $filters.shortDate(created_at) }}
                        </div>
                        <div class="w-10/12">
                          {{ (content) ? content : 'Petición no asociada a ningún contenido' }}
                        </div>
                      </UiTableCell>
                    </div>
                  </div>
                  <UiTableCell last  class="w-2/12">
                    <component class="w-6 h-6" :is="request.icon"/>
                    <span class="font-bold ml-2" :style="`color: ${request.color}`">{{ request.msg }}</span>
                  </UiTableCell>
                </UiTableRow>
              </div>
            </div>
          </div>
          <div v-else>
            <h3 class="text-center text-primary font-bold text-xl">No hay solicitudes resueltas</h3>
          </div>
        </section>
      </ModalBody>
      <ModalFooter>
        <UiButton :type="'btn-primary'" @click="closeModal">Volver al listado</UiButton>
      </ModalFooter>
    </Modal>
  </Overlay>
</template>
<style scoped>
.active-link {
  @apply border-b-2 text-primary
}

.custom-scroll::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #9D9D9D;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 8px;
}</style>
