<template>
  <UiHeader :title="''" >
    <div class="flex justify-between items-center">
      <div class="text-grey-darker">
        <h2 class="font-semibold text-2xl">¡Hola, {{user.first_name}}!</h2>
        <p>Aquí tienes las métricas y datos más relevantes de <strong>{{selected_business.name}}</strong></p>
      </div>
    </div>
  </UiHeader>
  <UiPageBody class="mt-3">
    <section class="mb-8">
      <div class="flex w-full ">
        <Visibility :permission="'can_see_assistant'">
          <div class="w-1/3 flex">
            <HomeSpeedometer :degree="degree" />
          </div>
        </Visibility>
        <div class="w-full flex flex-col rounded">
          <span class="pl-5 mb-2 text-sm font-medium text-grey">Datos mostrados a partir del {{$filters.shortDate(date)}} hasta la actualidad.</span>
          <div class="md:flex w-full px-5 gap-5">
            <div class="md:w-1/4 flex">
              <UiBox rounded padding="xl:p-3 2xl:p-5" class="flex items-center" :class="{'loading' : loading}">
                <div>
                  <Tooltip class="ml-1">
                    <template v-slot:icon>
                      <SVGIcons class="svg-38" :icon="'time'"/>
                    </template>
                    <template v-slot:default>
                      <li>
                        <p><strong>Horas de conexión:</strong> Número histórico del total de horas conectados en la plataforma por los usuarios de la organización.</p>
                      </li>
                    </template>
                    </Tooltip>
                </div>
                  <dl class="flex flex-col justify-center ml-2">
                    <dd class="text-primary text-xs font-medium pt-1">
                      Horas de conexión
                    </dd>
                    <dt class="flex items-center rounded-full text-primary font-bold text-2xl">
                      {{(typeof total_hours === 'string')? total_hours : $filters.toLocaleNumber(total_hours) }}
                    </dt>
                  </dl>
              </UiBox>
            </div>
            <div class="md:w-1/4 flex">
              <UiBox rounded padding="xl:p-3 2xl:p-5" class="flex items-center" :class="{'loading' : loading}">
                <div>
                  <Tooltip class="ml-1">
                    <template v-slot:icon>
                      <SVGIcons class="svg-38" :icon="'book'"/>
                    </template>
                    <template v-slot:default>
                      <li>
                        <p> <strong>Matrículas:</strong> Número histórico del total de formaciones que los usuarios han comenzado.</p>
                      </li>
                    </template>
                    </Tooltip>
                </div>
                  <dl class="flex flex-col justify-center ml-2">
                    <dd class="text-primary text-xs font-medium pt-1">
                      Matrículas
                    </dd>
                    <dt class="flex items-center rounded-full text-primary font-bold text-2xl">
                      {{(typeof total_formations === 'string')? total_formations : $filters.toLocaleNumber(total_formations) }}
                    </dt>
                  </dl>
              </UiBox>
            </div>
            <div class="md:w-1/4 flex">
              <UiBox rounded padding="xl:p-3 2xl:p-5" class="flex items-center" :class="{'loading' : loading}">
                <div>
                  <Tooltip class="ml-1">
                    <template v-slot:icon>
                      <SVGIcons class="svg-38" :icon="'certificate'"/>
                    </template>
                    <template v-slot:default>
                      <li>
                        <p><strong>Certificados obtenidos:</strong> Número histórico del total de certificados obtenidos por los usuarios de la organización.</p>
                      </li>
                    </template>
                    </Tooltip>
                </div>
                  <dl class="flex flex-col justify-center ml-2">
                    <dd class="text-primary text-xs font-medium pt-1">
                      Certificados obtenidos
                    </dd>
                    <dt class="flex items-center rounded-full text-primary font-bold text-2xl">
                      {{(typeof total_certificates === 'string')? total_certificates : $filters.toLocaleNumber(total_certificates) }}
                    </dt>
                  </dl>
              </UiBox>
            </div>
           <div class="w-full flex md:w-1/4 md:pl-3">
            <UiBox rounded padding="xl:p-3 2xl:p-5" class="flex items-center" :class="{'loading' : loading}">
              <div>
                <Tooltip class="ml-1">
                  <template v-slot:icon>
                    <SVGIcons class="svg-38" :icon="'star'"/>
                  </template>
                  <template v-slot:default>
                    <li>
                      <p><strong>Valoración media formaciones Openwebinars:</strong> Número medio del total de valoraciones realizadas por los usuarios de la organización.</p>
                    </li>
                  </template>
                  </Tooltip>
              </div>
                <dl class="flex flex-col justify-center ml-2">
                  <dd class="text-primary text-xs font-medium pt-1">
                    Valoración media formaciones Openwebinars
                  </dd>
                  <dt class="flex items-center rounded-full text-primary font-bold text-2xl">
                    {{(typeof media_polls === 'string')? media_polls : $filters.toLocaleNumber(media_polls) }}
                  </dt>
                </dl>
            </UiBox>
            </div>
          </div>
        </div>
      </div>
    </section>
  <section class="md:flex mb-8">
    <div class="mx-5 md:w-6/12 flex">
      <UiBox rounded :padding="'px-9 py-8'" class="w-full">
        <div class="flex justify-between mb-5">
        <h2 class="font-bold text-primary">
          Top 10 Skills
          <Tooltip class="ml-1">
            <li>
              <p>
                Estas son las 10 skills más aprendidas de tu organización y el número de usuarios que las han obtenido.
              </p>
            </li>
          </Tooltip>
        </h2>
        <button @click="$refs['opinion'].modal('show')" class="border-0 underline text-primary text-xs font-semibold">¿Qué son las skills?</button>
        </div>
        <div class="w-full">
          <TreeMap :title="''" :skills="skills"/>
        </div>
      </UiBox>
    </div>
    <div class="mx-5 md:w-6/12 flex">
      <UiBox rounded :padding="'px-9 py-12'" class="w-full">
        <h2 class="font-bold text-primary mb-5">
          Alta de usuarios
        </h2>
        <div class="flex flex-col xl:flex-row 2xl:flex-row">
          <div class="2xl:w-auto xl:w-4/6 flex justify-center items-center">
            <APXDonutChart :label="'Usuarios totales'" :chart-data="[
              { name: 'Invitados', color: '#CCD4E1', count: users_without_login },
            { name: 'Registrados', color: '#8FA0BC', count: users_without_formations },
            { name: 'Activos', color: '#3F536F', count: users_formations }
            ]"/>
          </div>
          <div class="2xl:w-auto xl:w-2/6 flex flex-col justify-center items-center">
            <ChartLegend :chart-data="[
                { name: 'Invitados', color: '#CCD4E1', count: users_without_login },
                { name: 'Registrados', color: '#8FA0BC', count: users_without_formations },
                { name: 'Activos', color: '#3F536F', count: users_formations }
              ]"
              :has_tooltip="[
                {name : 'Invitados', text: 'Usuarios que han sido invitados a darse de alta.'},
                {name: 'Registrados', text: 'Usuarios que han aceptado la invitación y han iniciado sesión.'},
                {name: 'Activos', text: 'Usuarios que se han formado, al menos, una vez.'},
              ]"
               />
          </div>
        </div>
          <div class="w-full flex justify-end">
            <BtnRemainder @click="openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                title: `Recordatorio a los usuarios`,
                text: `Vas a enviar un email recordatorio a ${ users_without_login } usuarios animándoles para que terminen el proceso de alta y comiencen a usar la plataforma.`,
                btn_text: 'Enviar',
                func: () => {
                  this.sendReminder()
                  this.$refs['user-dialog'].dialog('hide')
                  }
              }
            })"
            />
            <button @click="$refs['pendingInvitation'].modal('show')" class="font-bold text-primary-dark whitespace-nowrap items-center">
              Ver pendientes ({{ users_without_login }})
            </button>
            <DialogBox ref="user-dialog" :typeButton="'primary'" :defaultType="false">
                <figure class="flex justify-center mb-6">
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#304178"/>
                    <path d="M48 48V67.8621" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 34.7585C51.6565 34.7585 54.6207 31.7943 54.6207 28.1378C54.6207 24.4813 51.6565 21.5171 48 21.5171C44.3435 21.5171 41.3793 24.4813 41.3793 28.1378C41.3793 31.7943 44.3435 34.7585 48 34.7585Z" fill="white"/>
                  </svg>
                </figure>
            </DialogBox>
          </div>

      </UiBox>
    </div>
  </section>
  <div class="mx-5">
  <UiBox rounded>
    <h2 class="font-bold text-lg mx-5 text-primary-dark">
      Horas de dedicación
      <Tooltip class="ml-1">
        <li>
          <p><b>Horas de dedicación:</b> Conoce los periodos de dedicación y las horas que dedican por año, mes y día.</p>
        </li>
      </Tooltip>
    </h2>
      <div class="w-full pb-10 pr-10">
        <BarChartHome />
    </div>
  </UiBox>
  </div>
  <section class="my-8">
    <h2 class="py-6 mx-5 text-grey-darker font-semibold text-2xl ml-5">TOP usuarios y formaciones</h2>
    <div class="flex flex-col xl:flex-row px-5">
      <div class="xl:w-1/2 xl:mr-5 xl:mb-0 mb-6">
      <UiBox>
        <nav class="flex flex-wrap items-center justify-between my-5">
          <div class="flex items-center justify-between w-full">
            <div class="w-2/3 flex">
              <div class="col-auto px-1 justify-center xl:w-38 lg:w-38">
                <h2 class="font-semibold text-primary">
                  Usuarios
                  <Tooltip class="ml-1">
                    <li>
                      <p>Información sobre los usuarios que dedican más tiempo a formarse o más formaciones finalizan.</p>
                    </li>
                  </Tooltip>
                </h2>
              </div>
            </div>
          <div class="flex w-full justify-end 2xl:text-base xl:text-sm">
            <UiButton :type="'none'" @click="is_open_top_users = !is_open_top_users" class="relative px-6 shadow ">
              <svg class="mr-3" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.94443 1H17.0554C17.577 1 17.9998 1.42946 17.9998 1.95926L18 3.48066C18.0001 3.73517 17.9006 3.97924 17.7234 4.15919L11.6653 10.3128C11.4883 10.4927 11.3887 10.7368 11.3887 10.9912V17.0401C11.3887 17.6643 10.8113 18.1223 10.2153 17.9709L8.3264 17.4912C7.90594 17.3844 7.611 17.0007 7.611 16.5604V10.9912C7.611 10.7368 7.5115 10.4927 7.33439 10.3128L1.27661 4.15914C1.0995 3.97922 1 3.7352 1 3.48076V1.95938C1 1.42953 1.42283 1 1.94443 1Z" stroke="#304178" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                <span class="pr-6 text-primary font-semibold">Filtros</span>
                <svg class="absolute right-4 pe-none" :class="{'transform rotate-180': is_open_top_users}" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
                </svg>
            </UiButton>
          </div>
          </div>
          <div v-if="is_open_top_users"  class="flex w-full justify-end mt-2 shadow-lg py-2 px-3 2xl:text-base xl:text-sm">
            <UiCustomSelect :filter="false" class="w-auto mr-2" :bg-class="'bg-white'" @change="getTopUser"
              v-model="top_user_formations" name="">
              <option value="formations">Certificados</option>
              <option value="time" >Horas invertidas</option>
            </UiCustomSelect>
            <UiCustomSelect :filter="false" class="w-auto" :bg-class="'bg-white'" @change="getTopUser"  v-model="time_selected_users" name="">
              <option value="monthly">Últimos 30 días</option>
              <option value="yearly">Últimos 365 días</option>
            </UiCustomSelect>
          </div>
        </nav>
        <UiTable class="2xl:text-base xl:text-sm" :padding="'my-4'">
          <UiTableHeader>
            <UiTableCell center class="w-10">#</UiTableCell>
            <UiTableCell class="w-8/12 2xl:mr-0 xl:mr-5">Nombre</UiTableCell>
            <UiTableCell center last class="2xl:w-4/12 xl:w-3/12 xl:text-center">
              {{(top_user_formations === 'formations') ? 'Formaciones finalizadas' :'Horas invertidas'}}
            </UiTableCell>
          </UiTableHeader>
          <UiTableBody>
            <UiTableRow :bg="'bg-none'" :class="{'loading' : loading}"
              v-for="({id, first_name, email, last_name, avatar_url, total_certificates, total_time},index) in users"
              :key="id"
              >
              <UiTableCell center class="w-10">{{index + 1}}</UiTableCell>
              <UiTableCell class="2xl:w-8/12 xl:w-9/12">
                <UserIdentity large :user="{first_name, email, last_name, avatar_url}" dark />
              </UiTableCell>
              <UiTableCell last center class="2xl:w-4/12 xl:w-3/12">
                {{(top_user_formations === 'formations') ? total_certificates : $filters.hoursAndMinutes((total_time))}}
              </UiTableCell>
            </UiTableRow>
          </UiTableBody>
          <div class="flex text-sm text-primary rounded justify-center items-center bg-grey-lightest min-h-table-row-sm" v-if="users.length == 0">
            No hay resultados
          </div>
        </UiTable>
      </UiBox>
      </div>
      <div class="xl:w-1/2 shadow pt-4 ">
        <nav class="flex flex-wrap items-center justify-between mx-5 my-5">
          <div class="flex items-center justify-between w-full">
            <div class="w-2/3 flex">
              <div class="col-auto px-1 justify-center xl:w-38 lg:w-38">
                <h2 class="font-semibold text-primary">
                  Formaciones
                  <Tooltip class="ml-1">
                    <li>
                      <p>Información sobre las formaciones con más interés y las que más certificados se obtienen.</p>
                    </li>
                  </Tooltip>
                </h2>
              </div>
            </div>
            <div class="flex w-full justify-end 2xl:text-base xl:text-sm">
            <UiButton :type="'none'" @click="is_open_top_learnings = !is_open_top_learnings" class="relative px-6 shadow ">
              <svg class="mr-3" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.94443 1H17.0554C17.577 1 17.9998 1.42946 17.9998 1.95926L18 3.48066C18.0001 3.73517 17.9006 3.97924 17.7234 4.15919L11.6653 10.3128C11.4883 10.4927 11.3887 10.7368 11.3887 10.9912V17.0401C11.3887 17.6643 10.8113 18.1223 10.2153 17.9709L8.3264 17.4912C7.90594 17.3844 7.611 17.0007 7.611 16.5604V10.9912C7.611 10.7368 7.5115 10.4927 7.33439 10.3128L1.27661 4.15914C1.0995 3.97922 1 3.7352 1 3.48076V1.95938C1 1.42953 1.42283 1 1.94443 1Z" stroke="#304178" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                <span class="pr-6 text-primary font-semibold">Filtros</span>
                <svg class="absolute right-4 pe-none" :class="{'transform rotate-180': is_open_top_users}" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
                </svg>
              </UiButton>
            </div>
          </div>
          <div v-if="is_open_top_learnings"  class="flex w-full justify-end mt-2 shadow-lg py-2 px-3 2xl:text-base xl:text-sm">
            <UiCustomSelect :filter="false" class="w-auto mr-2" :bgClass="'bg-white'" @change="getTopCourses"  v-model="top_formations" name="">
              <option value="starts">Matrículas</option>
              <option value="certificates">Certificados</option>
            </UiCustomSelect>
            <UiCustomSelect :filter="false" class="w-auto" :bgClass="'bg-white'" @change="getTopCourses" v-model="time_selected_courses" name="">
              <option value="monthly">Últimos 30 días</option>
              <option value="yearly">Últimos 365 días</option>
            </UiCustomSelect>
          </div>
        </nav>
        <UiTable class="2xl:text-base xl:text-sm" :padding="'m-4'">
          <UiTableHeader>
            <UiTableCell center class="w-10">#</UiTableCell>
            <UiTableCell class="w-8/12 2xl:mr-0 xl:mr-5">Nombre</UiTableCell>
            <UiTableCell center class="w-4/12">
              {{(top_formations === 'starts') ? 'Matrículas' : 'Certificados'}}
            </UiTableCell>
          </UiTableHeader>
          <UiTableBody>
            <UiTableRow :bg="'bg-none'" :class="{'loading' : loading}"
              v-for="({id, name, total_certificates, total_enrollments}, index) in courses"
              :key="id"
              >
              <UiTableCell center class="w-10">{{index + 1}}</UiTableCell>
              <UiTableCell class="2xl:w-8/12 xl:w-7/12 truncate">
                <div :title="name" class="truncate">{{name}}</div>
              </UiTableCell>
              <UiTableCell center class="2xl:w-4/12 xl:w-5/12">
                {{(top_formations === 'starts') ? total_enrollments : total_certificates}}
              </UiTableCell>
            </UiTableRow>
          </UiTableBody>
          <div class="flex text-sm text-primary rounded justify-center items-center bg-grey-lightest min-h-table-row-sm" v-if="courses.length == 0">
            No hay resultados
          </div>
        </UiTable>
      </div>
    </div>
  </section>
  </UiPageBody>
  <PendingInvitation @send="openDialogBox({ $refs, ref: 'user-dialog', dialog: {
                title: `Recordatorio a los usuarios`,
                text: `Vas a enviar un email recordatorio a ${ users_without_login } usuarios animándoles para que terminen el proceso de alta y comiencen a usar la plataforma.`,
                btn_text: 'Enviar',
                func: () => {
                  this.sendReminder()
                  this.$refs['user-dialog'].dialog('hide')
                  }
                }
            })" ref="pendingInvitation" :id="id" />
  <OpinionSkills ref="opinion"/>
</template>

<script>
import Visibility from '@/components/Visibility.vue'
import BarChartHome from '../components/Charts/BarChartHome.vue'
import UserIdentity from '../components/UserIdentity.vue'
import UiCustomSelect from '../components/Layout/UI/UiCustomSelect.vue'
import { mapState, mapActions } from 'vuex'
import { api } from '../api/instances'
import UiBox from '../components/Layout/UI/UiBox.vue'
import UiTable from '../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableBody from '../components/Layout/UI/Table/UiTableBody.vue'
import UiTableCell from '../components/Layout/UI/Table/UiTableCell.vue'
import UiTableRow from '../components/Layout/UI/Table/UiTableRow.vue'
import APXDonutChart from '../components/Charts/APXDonutChart.vue'
import ChartLegend from '../components/Charts/ChartLegend.vue'
import DialogBox from '../components/DialogBox.vue'
import BtnRemainder from '../components/BtnRemainder.vue'
import Tooltip from '../components/Tooltip.vue'
import SVGIcons from '../components/Layout/SVGIcons.vue'
import OpinionSkills from '../components/Modals/OpinionSkills.vue'
import UiPageBody from '../components/Layout/UI/UiPageBody.vue'
import TreeMap from '../components/Charts/TreeMap.vue'
import UiButton from '../components/Layout/UI/UiButton.vue'
import UiHeader from '../components/Layout/UI/UiHeader.vue'
import HomeSpeedometer from '@/views/Assistant/components/HomeSpeedometer.vue'
import PendingInvitation from '../components/Modals/PendingInvitation.vue'
export default {
  name: 'Home',
  components: {
    BarChartHome,
    UserIdentity,
    UiCustomSelect,
    UiBox,
    UiTable,
    UiTableHeader,
    UiTableBody,
    UiTableCell,
    UiTableRow,
    APXDonutChart,
    ChartLegend,
    DialogBox,
    BtnRemainder,
    Tooltip,
    SVGIcons,
    OpinionSkills,
    UiPageBody,
    TreeMap,
    UiButton,
    UiHeader,
    HomeSpeedometer,
    Visibility,
    PendingInvitation
  },
  data () {
    return {
      activeFilter: { filter: '', direction: false },
      total_hours: 0,
      total_formations: 0,
      total_certificates: 0,
      media_polls: 0,
      time_selected_users: 'monthly',
      time_selected_courses: 'monthly',
      top_user_formations: 'formations',
      top_formations: 'starts',
      loading: false,
      first_loading: true,
      total_notifications: 0,
      total_users: 0,
      skills: [],
      users_without_login: 0,
      users_without_formations: 0,
      users_formations: 0,
      users: [],
      loading_metrics: true,
      total_users_skills: 0,
      courses: [],
      date: '',
      is_open_top_users: false,
      is_open_top_learnings: false
    }
  },
  computed: {
    ...mapState(['total_licenses', 'business', 'user', 'active_business']),
    selected_business () {
      return this.business.find((el) => el.business_id === this.active_business)
    }
  },
  methods: {
    ...mapActions(['getTotalLicenses', 'getUser', 'sendReminder', 'openDialogBox']),
    async getCounters () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/counters/`)
        .then(({ data }) => {
          this.total_hours = Math.round(data.data.total_hours)
          this.total_formations = data.data.total_formations
          this.total_certificates = data.data.total_certificates
          this.date = data.data.date
          this.media_polls = Number(data.data.media_polls.toFixed(2))
          this.loading = false
          this.loading_metrics = false
        })
        .catch(() => {
          console.error('Problema al cargar los datos')
        })
    },
    async getUsers () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/registers/`,
        {
          params: {

          }
        })
        .then(({ data }) => {
          this.total_users = data.total
          this.users_without_login = data.users_without_login
          this.users_without_formations = data.users_without_formations
          this.users_formations = data.users_formations
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
      this.loading = false
    },
    async getTopSkills () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/skills/`)
        .then(({ data }) => {
          this.skills = data.skills
          this.total_users_skills = data.total_users
        })
        .catch((err) => {
          console.error(err)
        })
      this.loading = false
    },
    async getTopHours () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/leaderboard/students/hours/`,
        {
          params: {
            option: this.time_selected_users
          }
        })
        .then(({ data }) => {
          this.users = data
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
      this.loading = false
    },
    async getTopCertificates () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/leaderboard/students/certificates/`,
        {
          params: {
            option: this.time_selected_users
          }
        })
        .then(({ data }) => {
          this.users = data
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
        })
      this.first_loading = false
      this.loading = false
    },
    getTopUser () {
      if (this.top_user_formations === 'formations') {
        this.getTopCertificates()
      } else {
        this.getTopHours()
      }
    },
    async getTopEnrollments () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/leaderboard/courses/enrollments/`, {
        params: {
          option: this.time_selected_courses
        }
      })
        .then(({ data }) => {
          this.courses = data
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
        })
      this.loading = false
      this.first_loading = false
    },
    async getTopCoursesCertificates () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/stats/leaderboard/courses/certificates/`, {
        params: {
          option: this.time_selected_courses
        }
      })
        .then(({ data }) => {
          this.courses = data
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
        })
      this.loading = false
      this.first_loading = false
    },
    getTopCourses () {
      if (this.top_formations === 'starts') {
        this.getTopEnrollments()
      } else {
        this.getTopCoursesCertificates()
      }
    }
  },
  created () {
    this.getTotalLicenses()
    this.getCounters()
    this.getUsers()
    this.getTopSkills()
    this.getTopCertificates()
    this.getTopEnrollments()
  }
}
</script>
<style scoped>

.color-bar{
  background: #CBCEDC;

}
.custom-table-head-rank{
    @apply flex my-2 mb-5 rounded py-3 font-medium text-grey bg-grey-lightest ;

}
.custom-table-body-rank{
    @apply mb-2 rounded bg-white;

}
.custom-table-body-rank .custom-table-row-rank{
    @apply flex w-full rounded py-5 bg-white;
}
</style>
