<template>
  <li :class="`flex items-center ${(last)? 'pr-6' : ''} ${(first)? 'pl-6' : ''} ${(center)? 'justify-center' : ''}`">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'UiTableCell',
  props: {
    center: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    }
  }
}
</script>
