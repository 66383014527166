import posthog from 'posthog-js'

export default {
  install (app) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_UerTf0PJVbH3KKBDqOa14hX7asnrjfsmwyuNWcOBaUe',
      {
        api_host: 'https://eu.i.posthog.com',
        capture_pageview: false
      }
    )
  }
}
