<template>
<div v-if="this.business.length > 1" class="w-full relative flex flex-col bg-primary bg-opacity-30" :class="(open) ? 'active-button' : 'user'" v-click-outside="hide">
  <div class="" v-if="open">
    <ul class="flex flex-col justify-items-center ">
      <li class="px-2 py-1" v-for="company in business" :key="company.business_id">
        <button class="flex pl-3 w-full pr-4 rounded py-2 hover:bg-primary-darker" @click="change(company)">{{company.name}}</button>
      </li>
    </ul>
  </div>
  <hr v-if="open" class="my-3 mx-3">
  <button @click="open = !open" class="flex w-full items-center px-2 mx-2">
    <figure class="flex-none flex relative flex-col items-center mr-2">
      <div class="flex justify-center items-center bg-grey-lighter rounded-full overflow-hidden" :class="'w-8 h-8'">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.33331 3.66667H10.6666M5.33331 3.66667H2.66665C1.93027 3.66667 1.33331 4.26362 1.33331 5V11.6667C1.33331 12.4031 1.93027 13 2.66665 13H13.3333C14.0697 13 14.6666 12.4031 14.6666 11.6667V5C14.6666 4.26362 14.0697 3.66667 13.3333 3.66667H10.6666H5.33331ZM5.33331 3.66667V1.4C5.33331 1.17909 5.5124 1 5.73331 1H10.2666C10.4876 1 10.6666 1.17909 10.6666 1.4V3.66667H5.33331Z" stroke="#304178" stroke-width="1.5"/>
        </svg>
      </div>
    </figure>
    <div class="flex flex-col items-start text-left">
      <h2 class="font-semibold w-36 ml-1 truncate text-white" :class="'text-md'">{{selected_business.name}}</h2>
    </div>
    <svg class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#ffffff"/>
    </svg>
  </button>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'BusinessMenu',
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapState(['active_business', 'business']),
    selected_business () {
      return this.business.find((el) => el.business_id === this.active_business)
    }
  },
  methods: {
    ...mapActions(['updateBusiness']),
    change (company) {
      this.updateBusiness(company)
      this.hide()
    },
    hide () {
      this.open = false
    }
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
.user{
  @apply text-white hover:bg-primary-darker py-2 rounded
}
.active-button{
  /* background-color: #5c6a99; */
  @apply text-white py-2 rounded
}
</style>
