<template>
    <button class="sorter btn btn-link p-0 flex items-center w-full" :class="{'active': modelValue.filter ==filter }" @click="update">
        <slot class="w-20"></slot>
        <div :class="margin">
        <svg v-if="!selectedSorter" width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.70711 11.7071L10.4142 11L9 9.58579L8.29289 10.2929L9.70711 11.7071ZM5.5 14.5L4.79289 15.2071L5.5 15.9142L6.20711 15.2071L5.5 14.5ZM2.70711 10.2929L2 9.58579L0.585787 11L1.29289 11.7071L2.70711 10.2929ZM8.29289 10.2929L4.79289 13.7929L6.20711 15.2071L9.70711 11.7071L8.29289 10.2929ZM6.20711 13.7929L2.70711 10.2929L1.29289 11.7071L4.79289 15.2071L6.20711 13.7929Z"
            :fill="(arrows == 'arrow-down')?'#304178':'#C4C4C4'"/>
          <path d="M1.29289 4.79289L0.585786 5.5L2 6.91421L2.70711 6.20711L1.29289 4.79289ZM5.5 2L6.20711 1.29289L5.5 0.585786L4.79289 1.29289L5.5 2ZM8.29289 6.20711L9 6.91421L10.4142 5.5L9.70711 4.79289L8.29289 6.20711ZM2.70711 6.20711L6.20711 2.70711L4.79289 1.29289L1.29289 4.79289L2.70711 6.20711ZM4.79289 2.70711L8.29289 6.20711L9.70711 4.79289L6.20711 1.29289L4.79289 2.70711Z"
            :fill="(arrows == 'arrow-up')?'#304178':'#C4C4C4'"/>
        </svg>
        <div v-if="loading && selectedSorter" class="w-3 ml-2 h-3 border-2 border-blue-400 border-dotted border-t-0 rounded-full animate-spin"></div>
        </div>
    </button>
</template>

<script>
export default {
  name: 'TableSorter',
  props: {
    modelValue: {
      required: true
    },
    filter: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    selected: Boolean,
    margin: {
      type: String,
      default: 'ml-2'
    }
  },
  emits: ['update', 'update:modelValue'],
  data () {
    return {
      direction: false,
      selectedSorter: false
    }
  },
  computed: {
    arrows () {
      /* if (this.modelValue.filter === this.filter && this.loading === true) {
        return 'fa fa-spinner fa-spin'
      } */
      if (this.modelValue.filter === this.filter) {
        return (this.direction) ? 'arrow-down' : 'arrow-up'
      } else {
        return ''
      }
    }
  },
  watch: {
    loading () {
      if (this.loading === false) {
        this.selectedSorter = false
      }
    }
  },
  methods: {
    update () {
      this.selectedSorter = true
      this.direction = !this.direction
      this.$emit('update:modelValue', { filter: this.filter, direction: this.direction })
      this.$emit('update')
    }
  },
  updated () {
    this.direction = this.modelValue.direction
  },
  created () {
    if (this.modelValue.direction) {
      this.direction = this.modelValue.direction
    }
  }
}
</script>

<style lang="scss" scoped>
    .sorter{
        position: relative;
        color: #333;
        .fa, .fas, .far{
            color: #6c757d;
        }
        &.active{
            // color: #f33784;
        }
        &:focus{
            text-decoration: none;
            // color: #ce296e;
        }
        &:hover{
            color: #304178;
            text-decoration: none;
        }
    }
</style>
