<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold">Añadir usuarios en Excel {{ selected_business }}</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <form @submit.prevent="createUser()" id="create-edit-user">
      <section class="pt-4" v-if="step_one">
        <p class="px-12 text-grey">Para agregar usuarios de forma masiva, seleccione un archivo XLSX que contenga la información de los usuarios.</p>
        <div class="px-12 pt-6">
          <div class="pl-3 pt-3 pb-5 flex border-2 rounded border-grey-lightest">
              <SVGIcons class="svg-32 mr-2" icon="infoBig"/>
              <div class="">
                <p class="text-sm text-grey">Te proporcionamos una plantilla base . XLXS para ayudarte a crear tu documento.</p>
                <button class="text-sm font-bold text-primary underline mb-3" @click.prevent="downloadTemplate()">Descargar plantilla</button>
                <p class="text-grey font-semibold text-2xs">Recuerda que sólo se aceptan archivos XLSX sin cabecera y con los siguientes campos:</p>
                <p class="text-grey mb-4 text-2xs">ID,nombre,apellidos,email,equipo,etiquetas</p>
                <p class="text-grey text-2xs">El campo ID es opcional y corresponde al identificador único del alumno en su organización.</p>
              </div>
          </div>
        </div>
        <article class="px-16 py-3">
            <fieldset>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-full mr-2" for="email">
                  <strong class="font-medium">Selecciona el archivo</strong>
                  <UiInputFile ref="xlsxDocument" @success="this.has_file = !this.has_file" v-model="file" class="mt-3"/>
                </label>
              </div>
            </fieldset>
        </article>
      </section>
      <section class="px-16 pt-6" v-else>
        <div class="mb-6">
          <p class="font-medium text-grey">La carga se ha completado {{(errors_count > 0) ? 'con errores' : 'correctamente'}}</p>
          <p v-if="errors_count > 0" class="text-grey">Algunos usuarios no han podido ser dados de alta</p>
        </div>
        <div v-if="success_count > 0" class="bg-grey-lightest mb-5 rounded flex py-3 px-8 items-center">
          <SVGIcons class="svg-36 mr-5" icon="success"/>
          <span class="text-grey"><strong class="mr-3 text-grey-dark">{{success_count}}</strong>Usuarios se han dado de alta correctamente</span>
        </div>
        <div v-if="errors_count > 0" class="bg-grey-lightest rounded flex py-3 px-8 items-center">
          <SVGIcons class="svg-36 mr-5" icon="danger"/>
          <span class="text-grey"><strong class="mr-3 text-grey-dark">{{errors_count}}</strong>Usuarios no han podido ser dados de alta</span>
        </div>
          <div v-if="errors_count > 0" class="flex justify-end">
            <button @click="errorLogsShow = !errorLogsShow" class="underline text-sm mt-2 text-[#304178]">{{ (errorLogsShow) ? 'Ocultar' : 'Ver' }} log de errores</button>
          </div>
          <div v-if="errorLogsShow" class="border-2 mt-14 rounded border-grey-lightest py-2 pl-8">
            <div class="flex items-start">
              <SVGIcons class="svg-36" icon="infoBig"/>
              <p class="ml-2 text-grey-dark font-semibold text-sm">Log de errores:</p>
            </div>
              <ul class="ml-10 text-grey text-sm max-h-[224px] overflow-y-scroll custom-scroll">
                <li v-for="error, index in errors" :key="index" class="mb-2">
                {{ (error.cell) ? `Error en la línea ${error.row} en la celda ${error.cell}: ${error.error}` : error.error}}
                </li>
                <li class="mt-5">
                  <strong>Para resolver este error:</strong>
                  <p>
                    - Carga tu archivo corregido nuevamente.
                  </p>
                </li>
              </ul>
          </div>
      </section>
      <footer class="px-14 pb-8 mt-5 flex justify-between items-center">
        <div  v-if="step_one" class="flex items-center">
          <UiCheck :state="update_users" @click="update_users = !update_users"></UiCheck>
          <span class="text-sm text-grey ml-2">Actualizar los usuarios existentes al importar</span>
        </div>
        <div class="flex w-full justify-end">
          <button v-if="step_one" type="button" @click.prevent="changeState(false)" class="btn-outline-default mr-3">Cancelar</button>
          <UiButton :type="(has_file) ? 'secondary' : 'disabled'"
          @click.prevent="
          (!confirmation_step && update_users)
          ? openDialogBox({
            $refs, ref: 'user-dialog', dialog: {
              title: 'Esta acción sobrescribirá los datos existentes',
              text: 'Los datos que se actualicen ya no podrán recuperarse.',
              btn_text: 'Continuar',
              func: () => {
                confirmation_step = true
                createUser()
                this.$refs['user-dialog'].dialog('hide')
              }
            }
          })
          : (step_one) ? createUser() : changeState(false)">
          {{(step_one) ? 'Continuar' : 'Aceptar'}}
        </UiButton>
        </div>
      </footer>
      </form>
    </div>
    <DialogBox class_custom="relative lg:w-4of12 mt-16 2xl:w-2/6 rounded-lg bg-white mb-24 text-center p-14" ref="user-dialog" typeButton="primary"/>
  </div>
  </transition>
</template>

<script>

import UiButton from '../Layout/UI/UiButton.vue'
import { api } from '../../api/instances'
import { mapMutations, mapState, mapActions } from 'vuex'
import SVGIcons from '../Layout/SVGIcons.vue'
import UiInputFile from '../Layout/UI/UiInputFile.vue'
import UiCheck from '../Layout/UI/UiCheck.vue'
import DialogBox from '../DialogBox.vue'

export default {
  name: 'AddUserMassiveModal',
  components: {
    UiButton,
    SVGIcons,
    UiInputFile,
    UiCheck,
    DialogBox
  },
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      open: false,
      step_one: true,
      file: {},
      team_id: '',
      errors_count: 0,
      success_count: 0,
      has_file: false,
      update_users: false,
      confirmation_step: false,
      errorLogsShow: false,
      errors: []
    }
  },
  computed: {
    ...mapState(['active_business', 'permissions', 'files_downloading', 'business']),
    selected_business () {
      return this.business.find((el) => el.business_id === this.active_business).name.replaceAll('.', '')
    }
  },
  methods: {
    ...mapMutations(['addToastMessage', 'progressWait']),
    ...mapActions(['updateProgressWait', 'openDialogBox']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
      this.step_one = true
      this.file = {}
      this.has_file = false
      this.update_users = false
      this.errorLogsShow = false
    },
    modal (action, obj = null) {
      this.resetFileds()
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async createUser () {
      const formData = new FormData()
      const file = this.$refs.xlsxDocument.file
      formData.append('file', file)
      const id = this.files_downloading.length
      this.updateProgressWait({ id, name_file: this.file.name, message: 'Importando archivo XLSX espere por favor' })
      const idx = this.files_downloading.findIndex(file => file.id === id)
      await api.post(`/jarvis/business/${this.active_business}/create-massive-users/`,
        formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            department_id: this.team_id,
            update: this.update_users
          }
        })
        .then(({ data }) => {
          this.errors_count = data.data.users_with_errors
          this.success_count = data.data.users_without_errors
          this.files_downloading[idx].progress = 100
          this.errors = data.data.errors
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          this.step_one = false
          this.$emit('success')
          this.addToastMessage({
            status: 'success',
            title: 'Completado',
            message: data.text
          })
        }).catch((err) => {
          this.$emit('error')
          console.error('Error en alta de usuario:', err)
          this.files_downloading[idx].progress = 100
          setTimeout(() => {
            this.files_downloading[idx].is_downloading = false
            this.files_downloading.splice(idx, 1)
          }, 1000)
          if (err.response) {
            this.addToastMessage({
              status: err.response.data.status,
              title: 'Error',
              message: err.response.data.text || 'Se ha producido un error al a cambiar el estado de su licencia.'
            })
          } else {
            this.addToastMessage({
              status: 'danger',
              title: 'Error',
              message: '¡Se ha producido un error!'
            })
          }
        })
    },
    checkFile () {
      this.has_file = (this.file === {})
    },
    resetFileds () {
      this.file = {}
      this.team_id = ''
      this.has_file = false
      this.update_users = false
      this.confirmation_step = false
    },
    async downloadTemplate () {
      await api.get(
        `/jarvis/business/${this.active_business}/template-users/`,
        { responseType: 'blob' }
      ).then((response) => {
        var fileURL = window.URL.createObjectURL(response.data)
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Plantilla-${this.selected_business}`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }

}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white
  }
    li:not(:last-child)::before {
    content: "•";
    margin-right: 5px;
  }
    .custom-scroll::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #344054;
    background-clip: padding-box;
    border: 5px solid transparent;
    border-radius: 8px;
}

</style>
