<template>
<div class="block relative" v-click-outside="hide">
  <div class="flex items-center relative border-2 rounded"
  :class="(selected_tags.length > 0) ? 'border-primary' : 'border-primary-lighter'">
    <button @click="open = !open" :class="`flex items-center whitespace-nowrap w-full ${bgClass} py-2 pl-4 pr-9 rounded`">
      {{(tags.length == 0 && loading) ? 'Cargando...' : 'Etiquetas'}}
      <span class="text-xs w-5 rounded bg-primary text-white ml-1" :class="{'opacity-0': selected_tags.length <= 0}">{{selected_tags.length}}</span>
    </button>
    <svg v-if="selected_tags.length <= 0" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
    </svg>
    <svg @click="reset" v-else class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="absolute z-20 w-64 mt-1 rounded shadow-lg bg-grey-lightest" :class="{'hidden': !open}">
    <ul class="flex flex-wrap w-full px-3 pt-2">
      <li class="pm-1" v-for="tag in selected_tags" :key="tag.id" >
        <button class="flex" @click.stop="tag.checked = false">
          <Tag :color="{ text_color: tag.text_color, bg_color: tag.bg_color }" :name="tag.name" :close="true"></Tag>
        </button>
      </li>
    </ul>
    <div v-if="filtered_tags.length === 0" class="flex justify-center py-3 text-sm">
      Aún no hay etiquetas
    </div>
    <div v-else>
      <hr class="border-white mt-2">
      <input v-model="search" class="px-4 text-sm outline-none ml-2 mt-2 mb-2 rounded h-7" type="text" placeholder="Filtrar">
      <hr class="border-grey-lighter mb-1">
      <ul class="tags-list custom-scroll">
        <li class="px-3 py-1 flex" v-for="tag in filtered_tags" :key="tag.id">
          <label class="cursor-pointer ml-1" :for="tag.slug">
              <Tag :color="{text_color:tag.text_color, bg_color:tag.bg_color}" :name="tag.name">
                <input type="checkbox" class="mr-2" v-model="tag.checked" :name="tag.slug" :id="tag.slug">
              </Tag>
          </label>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import Tag from './Tag.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TagsField',
  components: {
    Tag
  },
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: Array
    },
    fullTag: {
      type: Boolean,
      default: false
    },
    bgClass: {
      type: String,
      default: 'bg-grey-lighter-2'
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      field_tags: [],
      search: ''
    }
  },
  computed: {
    ...mapState(['active_business', 'tags']),
    filtered_tags () {
      return this.tags.filter((el) => {
        return el.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) ? el : ''
      })
    },
    selected_tags () {
      return this.tags.filter((el) => {
        return el.checked === true
      })
    }
  },
  methods: {
    ...mapActions(['getTags']),
    hide () {
      if (this.open) {
        this.update()
      }
      this.open = false
      this.search = ''
    },
    async customGetTags () {
      this.loading = true
      await this.getTags()
      this.field_tags = [...this.tags]
      this.loading = false
      if (this.modelValue) {
        this.tags.forEach((el) => {
          if (this.modelValue.includes(el.id)) {
            el.checked = true
          }
        })
      }
    },
    update () {
      if (this.fullTag) {
        this.$emit('update:modelValue', this.selected_tags.map((el) => el.name))
      } else {
        this.$emit('update:modelValue', this.selected_tags.map((el) => el.id))
      }
      this.$emit('change')
    },
    reset () {
      this.selected_tags.forEach((el) => { el.checked = false })
      this.update()
    }
  },
  created () {
    this.customGetTags()
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
  .tags-list {
    max-height: calc(100vh - 50vh);
    @apply bg-grey-lightest shadow-lg w-64 z-10 overflow-hidden overflow-y-scroll
  }
</style>
