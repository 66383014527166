<template>
<div v-if="status" class="flex flex-col items-center w-full">
  <img class="w-1/4 mt-20" src="../assets/images/notFound.svg" alt="notfound">
  <div class="flex flex-col items-center">
    <slot>
      <h2 class="text-3xl text-grey-dark">No se han encontrado resultados</h2>
      <p class="text-xl text-grey-light">{{subtitle}}</p>
    </slot>
  </div>
</div>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: 'Prueba a introducir otros datos o inténtalo de nuevo.'
    }
  }
}
</script>
