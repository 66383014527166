<template>
  <div class="balloon" :class="type">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Balloon',
  props: {
    type: {
      type: String,
      default: 'default'
    }
  }
}

</script>

<style scoped>
.balloon.primary{
  --text-color: #304178;
  --bg-color: #D8DAE1;

}
.balloon{
  --text-color: #404040;
  --bg-color: #e4e4e4;

  color: var(--text-color);
  background-color: var(--bg-color);
  min-width: 20px;
  border-radius: 10px;
  @apply flex items-center justify-center font-medium text-xs px-1 mr-1 h-5 capitalize whitespace-nowrap
}

</style>
