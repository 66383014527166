const filters = {
  shorterDate (input) {
    if (input === '' || input === undefined || input === null) {
      return '-'
    }
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
    return new Intl.DateTimeFormat('es-ES', options).format(new Date(input)).substring(0, 5)
  },
  shortDate (input) {
    if (input === '' || input === undefined || input === null) {
      return '-'
    }
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
    return new Intl.DateTimeFormat('es-ES', options).format(new Date(input))
  },
  standardShortDate (input) {
    if (input === '' || input === undefined || input === null) {
      return '-'
    }

    var date = new Date(input)
    var year = date.getFullYear()
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var day = date.getDate().toString().padStart(2, '0')

    return `${day}-${month}-${year}`
  },
  longDate (input) {
    if (input === '' || input === undefined || input === null) {
      return '-'
    }
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZoneName: 'short'
    }
    return new Intl.DateTimeFormat('es-ES', { ...options }).format(new Date(input))
  },
  monthlyDate (input) {
    if (input === '' || input === undefined || input === null) {
      return '-'
    }
    var options = {
      month: 'long',
      day: '2-digit'
    }
    return new Intl.DateTimeFormat('es-ES', { ...options }).format(new Date(input))
  },
  fileDate (date) {
    return `${date.getFullYear()}-${
      new Intl.DateTimeFormat('es-ES', { month: '2-digit' }).format(date)}-${
      new Intl.DateTimeFormat('es-ES', { day: '2-digit' }).format(date)}`
  },
  hoursAndMinutes (value) {
    const valMinutes = parseInt(value / 60)
    const hours = (parseInt(valMinutes / 60) > 0) ? `${parseInt(valMinutes / 60)}h` : ''
    const minutes = (valMinutes % 60 > 0) ? `${valMinutes % 60}m` : ''
    const output = (hours === '' && minutes === '') ? '-' : ''
    return `${hours} ${minutes} ${output}`
  },
  roundedHoursCounterBySeconds (seconds) {
    const diffTime = Math.abs(seconds)
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60))
    return diffHours
  },
  dayCounterBySeconds (seconds) {
    const diffTime = Math.abs(seconds)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  },
  DayCounterByDate (date) {
    const date1 = new Date(date)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  },
  toLocaleNumber (value) {
    if (value !== '' && value !== undefined && value !== null) {
      return value.toLocaleString()
    } else {
      return 0
    }
  }
}
export default filters
