<template>
  <section class="container mx-auto xl:pl-12 lg:pl-10 p-5">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'UiPageBody'
}
</script>
