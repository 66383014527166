<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'arrow-icon'
  },
  color: {
    type: String,
    default: '#344054'
  }
})
</script>
<template>
  <svg :id="props.id" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.841797 1.43457L6.81302 7.40579L0.841797 13.377" :stroke="props.color" stroke-width="1.49281" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>
