<template>
  <svg :id="props.id" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1555 8.91293C14.0197 7.90431 15.1722 7.18464 16.4577 6.8508C17.7433 6.51696 19.1003 6.58497 20.3461 7.04567C21.5918 7.50637 22.6665 8.33765 23.4256 9.42762C24.1846 10.5176 24.5915 11.8139 24.5915 13.1421C24.5915 14.4703 24.1846 15.7667 23.4256 16.8567C22.6665 17.9466 21.5918 18.7779 20.3461 19.2386C19.1003 19.6993 17.7433 19.7673 16.4577 19.4335C15.1722 19.0996 14.0197 18.38 13.1555 17.3713M16.6776 23.7487C16.115 24.3113 15.352 24.6274 14.5563 24.6274C13.7607 24.6274 12.9976 24.3113 12.435 23.7487L11.0208 22.3345M11.0208 22.3345H13.8492M11.0208 22.3345V25.1629M9.60656 2.53553C10.1692 1.97292 10.9322 1.65685 11.7279 1.65685C12.5235 1.65685 13.2866 1.97292 13.8492 2.53553L15.2634 3.94975M15.2634 3.94975H12.435M15.2634 3.94975V1.12132M3.59615 17.7383C2.37716 16.5193 1.69234 14.866 1.69234 13.1421C1.69234 11.4182 2.37716 9.76493 3.59615 8.54594C4.81514 7.32695 6.46844 6.64213 8.19234 6.64213C9.91625 6.64213 11.5696 7.32695 12.7885 8.54594C14.0075 9.76493 14.6923 11.4182 14.6923 13.1421C14.6923 14.866 14.0075 16.5193 12.7885 17.7383C11.5696 18.9573 9.91625 19.6421 8.19234 19.6421C6.46844 19.6421 4.81514 18.9573 3.59615 17.7383Z" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-change'
  },
  color: {
    type: String,
    default: '#626262'
  }
})
</script>
