<template>
  <li>
    <router-link :active-class="active" exact-active-class="none" :class="`flex items-center p-2 py-1 rounded ml-4 relative`"
      :to="path">
      <SVGIcons v-if="icon" class="svg-14 mx-4" :icon="icon" />
      <div class="relavite">
        <span :class="(is_pro) ? 'bg-[#FFFFFF] text-[#1D2335]' : 'bg-ow-primary text-white'" class="tag"> {{(is_pro) ? 'Pro' : 'Nuevo'}}</span>
        <slot></slot>
      </div>
    </router-link>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import SVGIcons from '../../SVGIcons.vue'

const props = defineProps({
  path: {
    type: Object
  },
  icon: {
    type: String
  },
  is_pro: {
    type: Boolean,
    default: false
  }
})

const active = computed(() => {
  return (props.icon === '') ? 'font-bold bg-secondary-dark w-3/4' : 'home-router-link-active'
})

</script>

<style scoped>
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 11px;
  font-size: 6px;
  text-transform: uppercase;
  width: 37px;
  height: 11px;
  top: 4px;
  @apply  absolute rounded-lg font-semibold ;
}
.home-router-link-exact-active,
.home-router-link-active {
  @apply font-semibold min-w-3/4 bg-secondary-dark;
}

li {
  border: 1px solid transparent;
  min-height: 49px;
  min-width: 240px;
}

a {
  border: 1px solid transparent;
  min-height: 49px;
  white-space: nowrap;
}
</style>
