<template>
  <UiHeader :title="'Solicitudes'"></UiHeader>
    <section class="container mx-auto pl-16 pr-5 flex justify-beween mt-12">
      <nav class="flex justify-between  w-full border-b-2 border-grey-lighter pl-0 mr-4">
          <ul class="flex justify-between ">
            <li class="flex-none text-xl mr-6 pr-1">
              <router-link class="text-xl flex items-center bg-transparent" exact-active-class="active" :to="{name: 'request-list'}">
                Solicitudes
                <Balloon class="ml-2">{{count}}</Balloon>
              </router-link>
              </li>
            <li class="flex-none text-xl mr-6 pr-1">
              <router-link class="text-xl flex items-center bg-transparent" exact-active-class="active" :to="{name: 'satisfyer-list'}">
               Histórico y Satisfacción
              </router-link>
              </li>
            <li class="flex-none text-xl pb-5 pr-1">
              <router-link class="text-xl bg-transparent" exact-active-class="active" :to="{name: 'licenses'}">Liberar licencias </router-link>
            </li>
        </ul>
    </nav>
  </section>

    <router-view @change="counter"/>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import Balloon from '../../components/Balloon.vue'
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
export default {
  name: 'Requests',
  components: {

    UiHeader,
    Balloon
  },
  computed: {
    ...mapState(['active_business', 'total_licenses'])
  },
  data () {
    return {
      count: 0
    }
  },
  methods: {
    ...mapActions(['getTotalLicenses']),
    counter (total_request) {
      this.count = total_request
    }
  },
  created () {
    this.getTotalLicenses()
  }
}
</script>

<style scoped>
.active{
    @apply border-b-2 pb-5 text-primary font-medium
}
</style>
