<template>
  <div class="tooltip-wrapper" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
    <div class="tooltip" :class="position" v-show="isVisible && !disabled" :style="tooltipStyle">
      {{ content }}
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'bottom'
    },
    backgroundColor: {
      type: String,
      default: '#333'
    },
    textColor: {
      type: String,
      default: '#fff'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  watch: {
    disabled: function (val) {
      if (!val) {
        this.isVisible = false
      }
    }
  },
  computed: {
    tooltipStyle () {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor
      }
    }
  },
  methods: {
    showTooltip () {
      this.isVisible = true
    },
    hideTooltip () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px;
  padding: 4px 10px;
  font-size: 12px;
  z-index: 5;
  white-space: nowrap;
  animation-duration: .2s;
  border: 1px solid #000;
}
/*.tooltip::after {
  content: '';
  position: absolute;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateX(-50%);
  background-color: inherit;
}*/

.tooltip.top::after {
  bottom: -8px;
}
.tooltip.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
}
.tooltip.bottom::after {
  top: 0;
}
.tooltip.bottom {
  top: 80%;
  left: 100%;
  transform: translateX(-25%) translateY(8px);
}
</style>
