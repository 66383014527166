<template>
<div class="contextual-menu relative" v-click-outside="hide">
  <button @click="open = !open" class="p-1">
    <SVGIcons class="svg-16 h-1" icon="ellipsis-h" />
  </button>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="menu">
    <ul class="relative">
      <ItemMenu
        v-for="({type, permission, text, icon, border, disabled, disabledMsg, func}, index) in items"
        :key="index"
        :func="func"
        :type="type"
        :disabledMsg="disabledMsg"
        :text="text"
        :disabled="disabled"
        :icon="icon"
        :permission="permission"
        :border="border" />
    </ul>
  </div>
  </transition>
</div>
</template>

<script>
import ItemMenu from './ItemMenu.vue'
import SVGIcons from '../Layout/SVGIcons.vue'

export default {
  name: 'TableMenu',
  components: {
    ItemMenu,
    SVGIcons
  },
  props: {
    items: Array
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    hide () {
      this.open = false
    }
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
.menu{
  animation-duration: .3s;

  @apply absolute z-10 bg-white shadow mt-2 -right-2 py-2 rounded
}
.menu::before{
  content: '';
  position: absolute;
  top: -6px;
  right: 12px;
  transform: rotate(45deg);
  display: block;
  width: 15px;
  height: 15px;
  background-color: white;
}
.menu-item{
    @apply flex text-sm py-2 px-4 hover:bg-grey-lightest w-full
  }
</style>
