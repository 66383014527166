<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'xl:w-8/12 2xl:w-6/12 lg:w-8/12'
  }
})
</script>
<template>
  <div class="modal animate__animated animate__slideInUp" :class="props.size">
    <slot></slot>
  </div>
</template>
<style scoped>
.modal{
    animation-duration: .4s;
    @apply relative  rounded-lg bg-white
  }
</style>
