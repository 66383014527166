<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp max-w-[536px]">
      <header class="px-12 pt-8 pb-3">
        <button data-ph="closeModalMentorIA" @click="close" type="button" class="absolute right-4 top-4">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <div class="px-4">
        <figure class="w-full h-auto mb-5 overflow-hidden">
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/953893800?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Creación de rutas con IA"></iframe>
          </div>
        </figure>
      </div>
      <article class="px-12">
        <div class="pill-new">Nuevo</div>
        <h2 class="text-xl font-bold text-[#425D94] mt-1">Optimiza la creación de rutas con nuestro generador con IA</h2>
        <p class="text-black text-sm mt-5 mb-8">Crea rutas personalizadas en base a tus objetivos formativos, en un solo clic.</p>
      </article>
      <footer class="px-12 pb-8 mt-5 flex justify-start">
        <button data-ph="clickTryModalMentorIA" @click="goTo({name: 'itinerary-type'})" class="btn btn-primary inline-flex">
          Pruébalo ahora
          <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </button>
      </footer>
    </div>
  </div>
  </transition>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const open = ref(false)
const $router = useRouter()

const updateState = () => {
  if (localStorage.getItem('mentor-ia') === null) {
    open.value = true
    localStorage.setItem('mentor-ia', 'true')
  } else {
    open.value = false
  }
}

const goTo = (path) => {
  $router.push(path)
  close()
}

const close = () => {
  localStorage.setItem('mentor-ia', 'true')
  open.value = false
}

onMounted(() => {
  updateState()
})

</script>
<style scoped>
figure {
  aspect-ratio: 16/9;
  border-radius: 10px;
  border: 1px solid #E9ECF0;
  background: rgba(242, 244, 247, 0.50);
}
.pill-new {
  flex: none;
  border-radius: 50px;
  background: #F63D68;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;

}
  textarea::placeholder {
    font-size: 14px;
    line-height: 2;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white mb-24
  }
  .status-accepted{
    @apply text-success text-base
  }
   .status-pending{
    @apply text-warning text-base
  }
   .status-rejected{
    @apply text-danger text-base
  }
</style>
