<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'info-icon'
  },
  color: {
    type: String,
    default: '#B17302'
  }
})
</script>
<template>
  <svg :id="props.id" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.16667 6.54134V4.87467M5.16667 3.20801H5.17083M9.33333 4.87467C9.33333 7.17586 7.46785 9.04134 5.16667 9.04134C2.86548 9.04134 1 7.17586 1 4.87467C1 2.57349 2.86548 0.708008 5.16667 0.708008C7.46785 0.708008 9.33333 2.57349 9.33333 4.87467Z" :stroke="props.color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
