<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { api } from '@/api/instances'
import { useStore } from 'vuex'
import UiHeader from '@/components/Layout/UI/UiHeader.vue'
import UiPageBody from '@/components/Layout/UI/UiPageBody.vue'
import UiCustomSelect from '@/components/Layout/UI/UiCustomSelect.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import LadyPlanner from './components/LadyPlanner.vue'
import CalendarPlanner from './components/CalendarPlanner.vue'
import UiBox from '@/components/Layout/UI/UiBox.vue'
import IAIcon from '@/components/Icons/IAIcon.vue'
import ListSprints from './components/ListSprints.vue'
import AutoCompleteModal from './components/AutoCompleteModal.vue'
import InfoIcon from './icons/InfoIcon.vue'
import Tooltip from '@/components/Tooltip.vue'
import ManageSprint from './components/ManageSprint.vue'
import LoadingComponent from './components/LoadingComponent.vue'
import SearchField from '@/components/SearchField.vue'
import ListSprintsByUser from './components/ListSprintsByUser.vue'
const store = useStore()
const active_business = computed(() => store.state.active_business)
const teams = ref([])
const sprints = ref([])
const team_id = ref(0)
const isLoading = ref(false)
const countSprint = ref(0)
const team_name = computed(() => {
  const team = teams.value.find((team) => team.id === team_id.value)
  return team ? team.name : ''
})
const userSearch = ref('')
const licenses = ref({})
const free_licenses = ref(0)
const month = computed(() => store.state.filters.planner.month)
const first_loading = ref(true)
const start_day = ref('5')
const displayView = ref('firstStep')
const getTeams = async () => {
  isLoading.value = true
  const { data } = await api.get(`/jarvis/business/${active_business.value}/teams/`, { params: { limit: 90000 } })
  teams.value = data.results
  if (store.state.filters.planner.team_id && teams.value.some((team) => team.id === store.state.filters.planner.team_id)) {
    team_id.value = store.state.filters.planner.team_id
    displayView.value = 'sec '
    store.state.filters.planner.team_id = ''
    getSprints()
  } else if (first_loading.value) {
    store.state.filters.planner.team_id = ''
    team_id.value = [...teams.value].shift() && [...teams.value].shift().id
    first_loading.value = false
    getSprints()
  }
}
const getSprints = async (isAutoCompleting = null) => {
  if (!isAutoCompleting) {
    isLoading.value = true
  }
  api.get(`/jarvis/business/${active_business.value}/planner/${team_id.value}/`)
    .then(({ data }) => {
      isSearchingUser.value = false
      sprints.value = []
      sprints.value = data.sprints
      store.state.filters.planner.team_id = team_id.value
      free_licenses.value = data.sprints.reduce((acc, el) => {
        return acc + el.free_seats
      }, 0)
      countSprint.value = data.count
      licenses.value = data.licenses
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      isLoading.value = false
    })
}
// const dataFromSearch = ref([])
const searchUser = () => {
  isSearchingUser.value = true
}
const isSearchingUser = ref(false)
const setSprintStartDay = async () => {
  await api.patch(`/jarvis/business/${active_business.value}/teams/${team_id.value}/`, { sprint_start_day: start_day.value })
    .then(({ data }) => {
      getSprints()
    })
    .catch(console.error)
}
watch(userSearch, () => {
  if (userSearch.value === '') {
    isSearchingUser.value = false
  }
})
onMounted(() => {
  isLoading.value = true
  getTeams()
})
</script>
<template>
  <UiHeader :title="'Planificador'"></UiHeader>
  <UiPageBody class="relative overflow-hidden">
    <div class="flex items-center absolute z-50 transition-opacity w-full bg-opacity-50 bg-white min-h-full" v-if="isLoading">
      <LoadingComponent/>
    </div>
    <div class="min-h-screen">
      <div v-if="sprints.length === 0 && !isLoading">
        <div>
          <p class="mb-5 text-sm text-grey-light">Elige un equipo y planifica qué día de la semana quieres que de comienzo el sprint.</p>
          <p class="text-sm font-semibold text-grey-light mb-2">Equipo</p>
          <div class="flex">
            <UiCustomSelect class="text-2xl font-semibold text-grey-dark w-auto" @change="getSprints()" :border="false" :bgClass="'bg-white'" :padding="'py-2'" :filter="false"  v-model="team_id">
              <option disabled value="">Elige equipo</option>
              <option v-for="{ id, name } in teams" :key="id" :value="id">{{ name }}</option>
            </UiCustomSelect>
          </div>
        </div>
        <div v-if="team_id" class="rounded-xl flex justify-between mt-5 w-[1032px] bg-grey-lightest pl-12 pr-24 py-10">
              <div>
                <h2 class="font-semibold text-3xl">Planifica tu sprint</h2>
                <p class="text-xl mt-1 mb-5">¿Qué día de la semana quieres empezar tu sprint?</p>
                <div class="border w-[533px] pl-12 pr-6 flex justify-between items-center py-4 rounded border-primary-300">
                  <div class="flex items-center">
                    <label class="mr-10 flex items-center">
                      <input class="mr-1" v-model="start_day" value="5" type="radio" name="start_date">
                      <span class="text-sm text-grey">Viernes</span>
                    </label>
                    <label class="flex ml-10 items-center">
                      <input class="mr-1" v-model="start_day" value="1" type="radio" name="start_date">
                      <span class="text-sm text-grey">Lunes</span>
                    </label>
                  </div>
                  <UiButton @click="setSprintStartDay"  :type="'bg-primary-1000 text-white'" >Siguiente</UiButton>
                </div>
                <p class="mt-2 text-sm text-grey-light">*Sugerimos que los sprints den comienzo <strong>los viernes</strong></p>
                <p class="mt-4 w-[530px] text-sm text-grey-light">
                  Según las estadísticas, la planificación de formación que comienza los viernes tiene un mayor aprovechamiento por parte de los alumnos.
                </p>
              </div>
              <LadyPlanner/>
            </div>
      </div>
      <div v-else>
            <UiBox classCustom="flex items-end justify-between">
              <div class="flex items-center">
                <div>
                  <p class="text-sm font-semibold text-grey-light mb-2">Elige un equipo </p>
                  <UiCustomSelect @change="getSprints()" class=" text-grey-dark w-64" :bgClass="'bg-white'"  :filter="false"  v-model="team_id">
                    <option disabled value="">Elige equipo</option>
                    <option v-for="{ id, name } in teams" :key="id" :value="id">{{ name }}</option>
                  </UiCustomSelect>
                </div>
                <div class="ml-5">
                  <div class="flex justify-between items-center mb-1">
                    <span>Capacidad de los sprint</span>
                    <button @click="$refs['managesprint'].modal('show', { team_id: team_id, licenses: licenses.total, licenses_avaliables: licenses.planning_available , licenses_not_avaliables: licenses.manual_assigned, team_name: team_name })" class="font-bold text-primary">Gestionar</button>
                  </div>
                  <div class="border-2 py-2 flex items-center px-3 border-primary-lighter rounded">
                    <div class="flex items-center">
                      <span class="font-medium text-sm text-primary mr-2">Licencias</span>
                      <span class="bg-primary-lighter text-primary text-xs font-bold p-1 rounded">{{ licenses.total }}</span>
                    </div>
                    <div class="mx-1 flex items-center">
                      <span class="text-success-700 text-xs mr-2">Planificables</span>
                      <span class="bg-green-200 text-success-700 text-xs font-bold p-1 rounded">{{ licenses.planning_available   }}</span>
                    </div>
                    <div class="flex items-center ml-1" >
                      <span class="text-warning-700 text-xs flex items-center  mr-1">
                        No Planificables
                        <Tooltip position="arrow-right">
                          <template v-slot:icon>
                            <InfoIcon class="w-3 h-3 ml-1"/>
                          </template>
                          <template v-slot:default>
                            <li>
                              <p class="text-primary">Las licencias activadas manualmente no son planificables. Para poder planificarlas, desactívalas. <button @click="$refs['managesprint'].modal('show', { team_id: team_id, licenses: licenses.total, licenses_avaliables: licenses.planning_available, licenses_not_avaliables: licenses.manual_assigned, team_name: team_name })"><strong class="font-semibold">Gestionar capacidad de los sprint</strong></button></p>
                            </li>
                          </template>
                        </Tooltip>
                      </span>
                      <span class="bg-orange-200 text-warning-700 text-xs font-bold p-1 rounded">{{ licenses.manual_assigned }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex ml-2 items-center">
                <UiButton @click="$refs['autocompletemodal'].modal('show', { count: countSprint, team_id: team_id, name: team_name, licenses: licenses.total, licenses_avaliables: licenses.planning_available, licenses_not_avaliables: licenses.manual_assigned })" class="flex items-center" type="btn-primary">
                  <IAIcon class="w-6 h-6 mr-2"/>
                  Autocompletar sprints
                </UiButton>
              </div>
            </UiBox>
            <UiBox classCustom="flex relative mt-5">
              <div v-if="!isSearchingUser" class="flex w-full">
                <div class="w-3/12">
                  <ListSprints :sprints="sprints"/>
                </div>
                <div class="w-9/12">
                  <CalendarPlanner :team_id="team_id" :monthSaved="month" :sprints="sprints"/>
                </div>
              </div>
              <div class="w-full" v-else>
                <ListSprintsByUser :teamId="team_id" :userSearch="userSearch"/>
              </div>
              <div class="w-4/12 absolute top-9 right-3">
                <SearchField @change="searchUser" v-model="userSearch" :placeholder="'Buscar sprints asignados a un nombre, email o ID'"/>
              </div>
            </UiBox>
      </div>
    </div>

  </UiPageBody>

  <AutoCompleteModal @change="getSprints(true)" ref="autocompletemodal"/>
  <ManageSprint @change="getTeams" ref="managesprint"/>
</template>
<style scoped>
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 999%;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
}

input[type="radio"]:checked {
  @apply border-primary relative;
}

input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 999%;
  background-color: #3F536F;
}

</style>
