<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold border-b-2 border-black p-3.5 pl-0">Usuarios invitados</h2>
            <button type="button" @click="changeState(false)" class="absolute right-10 top-12">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
  <article class="px-12">
    <div class="flex items-center p-2 bg-primary-lightest mb-2">
      <div class="w-6/12 font-bold text-primary">
        Usuario
      </div>
      <div class="w-6/12 font-bold text-primary">
        <TableSorter
        :loading="loading"
        filter="date_joined"
        v-model="activeFilter"
        @update="getUsers()"
        >
          Fecha de invitación
          </TableSorter>
      </div>
    </div>
    <div class="max-h-[450px] overflow-y-auto custom-scroll-sm">
      <div class="flex items-center p-2 bg-primary-lightest mb-2" v-for="user in users" :key="user.id">
        <div class="w-6/12">
          <UserIdentity large :user="user" dark />
        </div>
        <div class="w-6/12">{{ $filters.shortDate(user.date_joined) }}</div>
      </div>
    </div>
  </article>
      <footer class="px-12 pb-8 mt-5 flex justify-end w-full">
        <DownloadCSV :label="'Descargar reporte'" :data="users.map(({first_name, last_name, date_joined, email}) => {
          return { 'Nombre': `${first_name} ${last_name}`, 'Email': email, 'Fecha de invitación': $filters.shortDate(date_joined) }
        })" :styleBtn="'btn btn-outline-secondary'"/>
        <UiButton :size="'btn-lg'" class="ml-5 flex items-center" :type="'secondary'" @click="sendMessages()">
          <svg class="mr-2" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 3.3999L7 5.4999L10 3.3999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 8.2V2.2C1 1.53726 1.53726 1 2.2 1H11.8C12.4628 1 13 1.53726 13 2.2V8.2C13 8.86276 12.4628 9.4 11.8 9.4H2.2C1.53726 9.4 1 8.86276 1 8.2Z" stroke="white" stroke-width="1.5"/>
          </svg>
          Enviar recordatorio a todos
        </UiButton>
    </footer>
    </div>
  </div>
  </transition>
</template>
<script>
import UiButton from '../Layout/UI/UiButton.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
import UserIdentity from '../UserIdentity.vue'
import TableSorter from '@/components/TableSorter.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'
export default {
  name: 'PendingInvitation',
  components: {
    UiButton,
    UserIdentity,
    TableSorter,
    DownloadCSV
  },
  prop: ['id'],
  emits: ['modal:show', 'modal:hide', 'success', 'error', 'send'],
  data () {
    return {
      open: false,
      id: this.id,
      users: [],
      activeFilter: { filter: 'date_joined', direction: false }
    }
  },
  computed: {
    ...mapState(['active_business', 'user'])
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
        this.getUsers()
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getUsers () {
      this.loading = true
      this.selected_users = new Set()
      await api.get(`/jarvis/business/${this.active_business}/managed-users/`,
        {
          params: {
            limit: 100000000,
            registered_status: 'invited',
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter
          }
        })
        .then(({ data }) => {
          this.loading = false
          this.users = data.results
        })
        .catch(() => {
          console.error('Problema al cargar los usuarios')
          this.loading = false
        })
      this.first_loading = false
    },
    async sendMessages () {
      this.changeState(false)
      this.$emit('send')
    }
  }
}
</script>

<style scoped>
  textarea::placeholder {
    font-size: 14px;
      line-height: 2;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white mb-24
  }
  .status-accepted{
    @apply text-success text-base
  }
   .status-pending{
    @apply text-warning text-base
  }
   .status-rejected{
    @apply text-danger text-base
  }
</style>
