<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import BellIcon from '@/components/Icons/BellIcon.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import RequestSeatingsModal from './Modals/RequestSeatingsModal.vue'
import router from '@/router'

const store = useStore()

const isOpen = ref(false)
const activeTab = ref(null)

const licenses = computed(() => store.state.licenses)
const role = computed(() => store.state.role)

const unUsedLicenses = computed(() => {
  return licenses.value.teams.reduce((acc, el) => {
    return acc + el.unused_licenses
  }, 0)
})

const totalTeamsLicenses = computed(() => {
  return licenses.value.teams.reduce((acc, el) => {
    return acc + el.total_licenses
  }, 0)
})

const nonAssignedLicenses = computed(() => {
  return (role.value === 'admin') ? licenses.value.non_team_assigned_licenses : unUsedLicenses
})

const totalLicenses = computed(() => {
  return (role.value === 'admin') ? licenses.value.total_licenses : totalTeamsLicenses
})

const isAlert = computed(() => {
  if (licenses.value.non_team_assigned_licenses > 0 || unUsedLicenses.value > 0) {
    return true
  }
  return false
})

const showOrHideInfo = () => {
  if (isAlert.value) {
    isOpen.value = !isOpen.value
    activeTab.value = (role.value === 'admin') ? 'licenses' : 'teams'
  }
}

const hiddeInfo = () => {
  isOpen.value = false
  activeTab.value = (role.value === 'admin') ? 'licenses' : 'teams'
}

const goToTeams = () => {
  router.push({ path: '/gestion/equipos' })
  isOpen.value = false
}

onMounted(() => {
  store.dispatch('getTotalLicenses')
  activeTab.value = (role.value === 'admin') ? 'licenses' : 'teams'
})

const vClickOutside = { // directive in veu 3
  mounted (el) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        hiddeInfo()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
</script>

<template>
  <div v-click-outside="hiddeInfo">
    <div class="flex items-center pr-5 relative">
      <button class="mr-3 p-2 rounded relative cursor-default" @click.prevent="showOrHideInfo" :class="{'bg-grey-lightest alert cursor-pointer' : isAlert}">
        <div class="absolute right-2 top-2 border border-grey-lightest bg-danger w-2 h-2 rounded-full" v-if="isAlert"></div>
        <BellIcon />
      </button>
      <p class="text-primary" :class="{'font-bold' : isAlert}">Tiene {{ nonAssignedLicenses }} licencias sin asignar de {{totalLicenses}}</p>

      <div
        class="arrow-top absolute w-full top-12 -left-5 bg-white shadow"
        :class="{'hidden' : !isOpen}">
        <nav class="flex border-b border-primary-light pt-6 px-6">
          <button
            v-if="role === 'admin'"
            @click.prevent="activeTab = 'licenses'"
            class="text-grey-dark font-semibold w-24 text-left border-b-4 border-transparent pb-4 mr-2"
            :class="{'border-primary' : activeTab == 'licenses'}">
            Licencias
            <small class="absolute block text-2xs text-primary">{{licenses.total_licenses}} contratadas</small>
          </button>
          <button
            @click.prevent="activeTab = 'teams'"
            class="text-grey-dark font-semibold w-24 border-b-4 border-transparent pb-4"
            :class="{'border-primary' : activeTab == 'teams'}">
            Equipos</button>
        </nav>
        <div class="mt-4" v-if="activeTab == 'licenses'">
          <ul class="pb-3 px-6">
            <li class="mb-4 relative pl-3 text-primary">
              <div v-if="licenses.non_team_assigned_licenses > 0" class="absolute top-2 left-0 bg-danger rounded-full w-2 h-2"></div>
              <strong class="font-bold">{{ licenses.non_team_assigned_licenses }}</strong> licencias <span class="text-danger font-bold">sin asignar a equipos</span>
              <small class="text-2xs block">Este es el total de licencias que no se han asignado a equipos.</small>
            </li>
            <li class="mb-4 relative pl-3 text-primary">
              <div v-if="unUsedLicenses > 0" class="absolute top-2 left-0 bg-danger rounded-full w-2 h-2"></div>
              <strong class="font-bold">{{unUsedLicenses}}</strong> licencias <span class="text-danger font-bold">sin activar</span>
              <small class="text-2xs block">Este es el total de licencias sin activar de alumnos asignados a equipos.</small>
            </li>
          </ul>
          <div class="flex justify-center pb-6 px-6">
            <UiButton @click="$refs['request-seating'].modal('show')" :size="'btn-xs'" :type="'outline-secondary'">
              Contratar más licencias
            </UiButton>
          </div>
        </div>
        <div class="mt-4" v-if="activeTab == 'teams'">
          <ul class="max-h-tab custom-scroll-sm pb-3 px-6">
            <li v-for="team in licenses.teams.filter((el) => el.unused_licenses > 0)" :key="team.name" class="mb-4 relative pl-3">
              <div class="absolute top-2 left-0 bg-danger rounded-full w-2 h-2"></div>
              <strong class="font-bold text-sm text-primary">{{ team.name }}</strong>
              <small class="text-2xs block">{{ team.unused_licenses }} licencias sin activar</small>
            </li>
          </ul>
          <div class="flex justify-center py-3 px-6 border-t border-primary-light">
            <UiButton @click.prevent="goToTeams" :size="'btn-xs'" :type="'outline-secondary'">
              Gestionar licencias
            </UiButton>
          </div>
        </div>
      </div>
    </div>
    <RequestSeatingsModal ref="request-seating"/>
  </div>
</template>

<style scoped>
.max-h-tab {
  max-height: 260px;
  overflow-y: auto;
}
.arrow-top::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 25px;
  transform: rotate(45deg);
  display: block;
  width: 22px;
  height: 22px;
  background-color: white;
  @apply shadow
}
.arrow-top::after {
  content: '';
  position: absolute;
  top: 0;
  left: 17px;
  display: block;
  width: 40px;
  height: 20px;
  background-color: white;
}
</style>
