<template>
  <section :class="`${width} ${(shadow) && 'shadow-lg'} rounded-lg`">
    <div :class="`relative w-full h-full ${bgClass} ${padding} ${classCustom} ${(rounded) && 'rounded-lg'}`">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Box',
  props: {
    rounded: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: true
    },
    bgClass: {
      type: String,
      default: 'bg-white'
    },
    padding: {
      type: String,
      default: 'p-5'
    },
    width: {
      type: String,
      default: 'w-full'
    },
    classCustom: {
      type: String,
      default: ''
    }

  }
}
</script>
