<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold border-b-2 border-grey-lighter p-3.5 pl-0">Histórico de solicitudes</h2>
            <button type="button" @click="changeState(false)" class="absolute right-10 top-12">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
  <article class="custom-table px-12">
      <nav class="custom-table-head px-8 mb-4 text-grey">
        <div class="w-3/12 text-sm">Fecha inicial</div>
        <div class="w-4/12 text-sm">Fecha actualizada</div>
        <div class="w-7/12 text-sm">Contenido solicitado</div>
        <div class="w-3/12 text-sm">Estado</div>
      </nav>
      <div class="custom-table-body flex flex-no items-center overflow-y-auto custom-scroll" v-for="{ content, accepted, created_at, id, updated_at} in requests" :key="id">
        <ul class="custom-table-row text-grey px-8">
          <li class="flex-auto w-3/12 text-sm flex items-center py-2">{{$filters.shortDate(created_at)}}</li>
          <li class="flex-auto w-4/12 text-sm flex items-center py-2">{{(accepted != null) ? $filters.shortDate(updated_at) : '-'}}</li>
          <li class="w-7/12 text-sm flex items-center">{{content}}</li>
          <li class="w-3/12 text-sm flex items-center">
          <span v-if="accepted != null" :class="(accepted == true)? 'status-accepted' : 'status-rejected'">{{(accepted == true) ? 'Aceptada' : 'Rechazada'}}</span>
          <span v-else class="status-pending">Pendiente</span>
          </li>
        </ul>
      </div>
  </article>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <UiButton :type="'secondary'" @click="changeState(false)" class="btn-secondary">Volver al listado</UiButton>
      </footer>
    </div>
  </div>
  </transition>
</template>
<script>
import UiButton from '../Layout/UI/UiButton.vue'
import { mapMutations, mapState } from 'vuex'
import { api } from '../../api/instances'
export default {
  name: 'AddUserModal',
  components: {
    UiButton
  },
  prop: ['id'],
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      open: false,
      requests: [],
      id: this.id
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action = null, student_id = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        if (student_id) {
          this.getRecord(student_id)
        }
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async getRecord (student_id) {
      await api.get(`/jarvis/business/${this.active_business}/requests/${student_id}/subrequests/`)
        .then(({ data }) => {
          this.requests = data
          this.changeState(true)
        })
        .catch(() => {
          console.error('Error al solititar historico')
        })
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    @apply fixed top-0 left-0 z-40 flex items-center justify-center bg-black w-screen h-screen bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white mb-24
  }
  .status-accepted{
    @apply text-success text-base
  }
   .status-pending{
    @apply text-warning text-base
  }
   .status-rejected{
    @apply text-danger text-base
  }
</style>
