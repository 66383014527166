<template>
  <li class="flex flex-col ml-2 rounded mb-3">
    <button @click.prevent="open = !open" class="flex items-center ml-4 pt-2 rounded relative">
      <SVGIcons class="svg-14 mx-4" :icon="icon" />
      {{ name }}
      <SVGIcons v-if="open || $route.path.indexOf(this.path) >= 0" class="absolute right-0 svg-14 mx-4"
        :icon="'minus'" />
      <SVGIcons v-else class="absolute right-0 svg-14 mx-4" :icon="'plus'" />
    </button>
    <div v-if="open || $route.path.indexOf(this.path) >= 0" class="ml-10 pl-1 border-l pt-1">
      <slot></slot>
    </div>
  </li>
</template>

<script>
import SVGIcons from '../../SVGIcons.vue'
export default {
  name: 'UiAccordionMenu',
  props: {
    name: String,
    icon: String,
    path: String
  },
  data () {
    return {
      open: false
    }
  },
  components: { SVGIcons }
}
</script>
<style scoped>
li {
  min-height: 45px;
  min-width: 240px;
}
button {
  min-height: 45px;
}
</style>
