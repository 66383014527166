<script setup>
import { ref, computed, onMounted } from 'vue'
import { api } from '@/api/instances'
import { useStore } from 'vuex'

import UiPageBody from '@/components/Layout/UI/UiPageBody.vue'
import UiTableFilters from '@/components/Layout/UI/Table/UiTableFilters.vue'
import UiTable from '@/components/Layout/UI/Table/UiTable.vue'
import SearchField from '@/components/SearchField.vue'
import DownloadXLS from '@/components/DownloadXLS.vue'
import UiMoreFilters from '@/components/Layout/UI/Table/UiMoreFilters.vue'
import SelectTeam from '@/components/SelectTeam.vue'
import UiCustomSelect from '@/components/Layout/UI/UiCustomSelect.vue'
import UiTableHeader from '@/components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '@/components/Layout/UI/Table/UiTableCell.vue'
import TableSorter from '@/components/TableSorter.vue'
import UiTableSkeleton from '@/components/Layout/UI/Table/UiTableSkeleton.vue'
import UiTableRow from '@/components/Layout/UI/Table/UiTableRow.vue'
import UserIdentity from './components/UserIdentity.vue'
import NotFoundSearch from '@/components/NotFoundSearch.vue'
import TableFooter from '@/components/TableFooter.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import HistoricalModal from './components/Modals/HistoricalModal.vue'
import ReplyModal from './components/Modals/ReplyModal.vue'
import UiBox from '@/components/Layout/UI/UiBox.vue'
import InfoIcon from './components/Icons/InfoIcon.vue'
import Tooltip from './components/Tooltip.vue'
import filters from '@/filters'
const store = useStore()
const activeBusiness = computed(() => store.state.active_business)
const updateRequests = (data) => store.commit('updateRequests', data)
const emit = defineEmits(['change'])
const search = ref('')
const team_id = ref('')
const statusRequest = ref('')
const loading = ref(false)
const activeFilter = ref({ filter: 'last_requests_ts', direction: true })
const paginator = ref({ limit: 10, page: 1 })
const pages = ref({})
const users = ref([])
const isEmpty = ref(false)
const getUser = async (pag = 1) => {
  loading.value = true
  isEmpty.value = true
  await api.get(`/jarvis/business/${activeBusiness.value}/v2/requests/`, {
    params: {
      page: pag,
      limit: paginator.value.limit,
      search: search.value,
      department_id: team_id.value,
      requests_status: statusRequest.value,
      ordering: (activeFilter.value.direction) ? `-${activeFilter.value.filter}` : activeFilter.value.filter
    }
  })
    .then(({ data }) => {
      users.value = data.results
      isEmpty.value = data.results.length === 0
      pages.value = data.paginator
      loading.value = false
      emit('change', data.count)
      updateRequests(data.count)
    })
    .catch(() => {
      loading.value = false
      isEmpty.value = false
    })
}
const counters = ref({})
const satisfaction = ref({})
const getCounters = async () => {
  await api.get(`/jarvis/business/${activeBusiness.value}/v2/requests/counters/`)
    .then(({ data }) => {
      counters.value = data.pending
      satisfaction.value = data.satisfaction
    })
    .catch(() => {
      console.error('Error al obtener los contadores')
    })
}
const roundCounters = (value) => {
  const numberRounded = Math.round(value * 100) / 100
  return filters.toLocaleNumber(Number(numberRounded.toFixed(1)))
}
const updateInfo = () => {
  getUser()
  getCounters()
}
onMounted(() => {
  getUser()
  getCounters()
})
</script>
<template>
  <UiPageBody>
    <div class="flex mx-3">
      <div class="w-1/3 flex">
        <UiBox rounded :classCustom="'shadow-[#40404040]'">
          <div class="w-full flex justify-between text-success-700 border-b border-[#D0D5DD]">
            <div class="flex items-center">
              <h3 class="text-[14px] font-semibold">Nuevas solicitudes</h3>
              <Tooltip direction_arrow="left"  class="ml-1">
                  <template v-slot:title >
                  <InfoIcon :color="'#39725A'" />
                </template>
                <template v-slot:default>
                  <li class="w-full text-primary-800 text-[9px] font-bold">
                    <p>Solicitudes pendientes con menos de 10 días.</p>
                  </li>
                  <li class="w-full mt-3 text-primary-800 text-[9px]">
                    <p>En esta etapa, los usuarios están altamente motivados e interesados en la formación que solicitan.</p>
                  </li>
                </template>
              </Tooltip>

            </div>
            <h1 class="text-[32px] font-extrabold">{{ counters.new }}</h1>
          </div>
          <p class="text-[11px] mt-2 text-primary-800 leading-2">El {{ roundCounters(satisfaction.satisfied) }}% de las solicitudes se atienden cuando los usuarios tienen motivación por la formación</p>
        </UiBox>
      </div>
      <div class="w-1/3 mx-5 flex">
          <UiBox rounded :classCustom="'shadow-[#40404040]'">
            <div class="w-full flex justify-between text-warning border-b border-[#D0D5DD]">
              <div class="flex items-center">
                <h3 class="text-[14px] font-semibold">Solicitudes a punto de caducar</h3>
                <Tooltip direction_arrow="left"  class="ml-1">
                    <template v-slot:title >
                    <InfoIcon :color="'#CF8702'" />
                  </template>
                  <template v-slot:default>
                    <li class="w-full  text-primary-800 text-[9px] font-bold">
                      <p>Estas son las solicitudes pendientes entre 10 y 25 días.</p>
                    </li>
                    <li class="w-full mt-3  text-primary-800 text-[9px]">
                      <p>En este punto, los usuarios están perdiendo gradualmente su motivacion de formación. Es fundamental atender estas solicitudes antes de que pierdan su motivación.</p>
                    </li>
                  </template>
                </Tooltip>

              </div>
              <h1 class="text-[32px] font-extrabold">{{ counters.almost_old }}</h1>
            </div>
            <p class="text-[11px] mt-2 text-primary-800 leading-2">El {{ roundCounters(satisfaction.pasive) }}% de las solicitudes se atienden cuando los usuarios empiezan a perder motivación por la formación</p>
          </UiBox>
      </div>
      <div class="w-1/3 flex">
          <UiBox rounded :classCustom="'shadow-[#40404040]'">
            <div class="w-full flex justify-between text-danger border-b border-[#D0D5DD]">
              <div class="flex items-center">
                <h3 class="text-[14px] font-semibold">Solicitudes no satisfechas</h3>
                <Tooltip direction_arrow="left"  class="ml-1">
                    <template v-slot:title >
                    <InfoIcon :color="'#F32222'" />
                  </template>
                  <template v-slot:default>
                    <li class="w-full  text-primary-800 text-[9px] font-bold">
                      <p>Estas son las solicitudes pendientes entre 25 y 40 días.</p>
                    </li>
                    <li class="w-full mt-3  text-primary-800 text-[9px]">
                      <p>En esta zona se encuentran los usuarios que ya han perdido la motivacion por formarse.</p>
                    </li>
                  </template>
                </Tooltip>

              </div>
              <h1 class="text-[32px] font-extrabold">{{ counters.old }}</h1>
            </div>
            <p class="text-[11px] mt-2 text-primary-800 leading-2">El {{ roundCounters(satisfaction.not_satisfied) }}% de las solicitudes no han sido satifechas</p>
          </UiBox>
      </div>
    </div>
    <UiTableFilters class="flex justify-between p-4">
      <div class="flex-none w-1/2 xl:w-2/3 flex">
        <div class="col-auto px-1 justify-center lg:w-1/2">
          <SearchField v-model="search" @change="getUser"/>
        </div>
      </div>
      <div class="flex">
        <DownloadXLS
        class="btn btn-outline-secondary whitespace-nowrap"
        file="usuarios.xlsx"
        :path="`/jarvis/business/${activeBusiness}/v2/requests/`"
        formatted='[{"field":"first_name","visible":"Nombre del alumno"},
        {"field":"last_name","visible":"Apellidos del alumno"},
        {"field":"email","visible":"email"},
        {"field":"last_activity_ts","visible":"Última formación", "output":"date"},
        {"field":"team","visible":"Equipo"},
        {"field":"last_requests_ts","visible":"Fecha de la petición","output":"date"}]'
        :params="{ limit: 1000000000, search: search, ordering: (activeFilter.direction === true) ? `-${activeFilter.filter}` : activeFilter.filter }"
        >Descargar reporte</DownloadXLS>
      </div>
    </UiTableFilters>
    <UiMoreFilters>
      <div class="col-auto px-1 justify-center">
        <SelectTeam @change="getUser" v-model="team_id" :bgClass="'bg-white'" />
      </div>
      <div>
        <UiCustomSelect @change="getUser" v-model="statusRequest">
          <option value="">Solicitudes pendientes</option>
          <option value="new">Nuevas</option>
          <option value="almost_old">A punto de caducar</option>
          <option value="old">No satisfechas</option>
        </UiCustomSelect>
      </div>
    </UiMoreFilters>
    <UiTable>
      <UiTableHeader>
        <UiTableCell first class="2xl:w-4/12 1.5xl:w-5/12 2xl:pr-0   xl:w-6/12 2xl:text-base xl:text-sm">
          <TableSorter
            :loading="loading"
            filter="user__first_name"
            v-model="activeFilter"
            @update="getUser()"
          >
            Nombre
          </TableSorter>
        </UiTableCell>
            <UiTableCell class="w-3/12 2xl:text-base xl:text-sm">
             <TableSorter
                :loading="loading"
                filter="department__name"
                v-model="activeFilter"
                @update="getUser()"
              >
                Equipo
              </TableSorter>
            </UiTableCell>
            <UiTableCell class="w-3/12 2xl:justify-center 2xl:text-base xl:text-sm">
             <TableSorter
                class="2xl:text-left"
                :margin="'2xl:ml-2 xl:ml-0 md:ml-0'"
                :loading="loading"
                filter="last_activity_ts"
                v-model="activeFilter"
                @update="getUser()"
              >
              <span class="2xl:w-full xl:w-3/4 md:w-3/4 2xl:text-base xl:text-sm">
                Última formación
              </span>
              </TableSorter>
            </UiTableCell>
            <UiTableCell class="w-3/12 text-sm  2xl:justify-center 2xl:text-base xl:text-sm">
            <TableSorter
                class="text-left 1.5xl:text-center 1.5xl:justify-center"
                :margin="'2xl:ml-2 xl:ml-0 md:ml-0'"
                filter="last_requests_ts"
                v-model="activeFilter"
                :selected="true"
                @update="getUser()">
                <span class="2xl:w-full xl:w-2/4 md:w-3/4">
                Solicitud
                </span>
              </TableSorter>
            </UiTableCell>
            <UiTableCell class="w-4/12 justify-center 2xl:text-base  xl:text-sm">
              <span class="w-full 2xl:text-center 1.5xl:text-left xl:text-left">
                Histórico
              </span>
            </UiTableCell>
            <UiTableCell class="w-4/12 2xl:justify-center 2xl:px-0 xl:px-5 1.5xl:justify-center xl:justify-start 2xl:text-base xl:text-sm">
              Contestar solicitud
            </UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="loading"/>
      <div v-else>
        <UiTableRow v-for="({ user_id, avatar_url, first_name, requests_status, email, last_name, id, last_activity_ts, team, last_requests_ts, pending_requests, solved_requests }, index) in users" :key="id">
              <UiTableCell first class="flex-auto 2xl:w-4/12 1.5xl:w-5/12 xl:w-6/12 truncate items-center">
                <div class="truncate w-10/12">
                  <UserIdentity truncate :user="{user_id, avatar_url, first_name, email, last_name }" dark :statusRequest="requests_status"/>
                </div>
              </UiTableCell>
              <UiTableCell class="flex-auto w-3/12  text-sm truncate "><p class="truncate 2xl:w-full xl:w-3/4" :title="team">{{ team }}</p></UiTableCell>
              <UiTableCell class="w-3/12 text-sm justify-center"> {{ $filters.shortDate(last_activity_ts) }}</UiTableCell>
              <UiTableCell class="w-3/12 text-sm  2xl:justify-center xl:justify-center md:justify-start"> {{ $filters.shortDate(last_requests_ts) }}</UiTableCell>
              <UiTableCell class="w-4/12 justify-center text-sm">
                <button class="text-primary-dark font-bold 1.5xl:w-full xl:w-1/2"
                  @click="$refs['historical'].modal('show', { pending_requests, solved_requests })">
                  Ver histórico {{ (pending_requests.length > 1) ? `(${pending_requests.length})` : ''  }}
                </button>
              </UiTableCell>
              <UiTableCell center class="flex-auto w-4/12 text-sm">
                <UiButton  @click="$refs['reply'].modal('show', {items: users, index: index})" :type="'btn-primary'">
                  Contestar solicitud
                </UiButton>
              </UiTableCell>
        </UiTableRow>
      </div>
      <NotFoundSearch :status="isEmpty && !loading" />
    </UiTable>
  </UiPageBody>
  <TableFooter
    v-if="!isEmpty"
    class="pl-12"
    :paginator="paginator"
    :pages="pages"
    v-model="paginator"
    v-cloak
    @change="getUser(paginator.page)"/>
    <HistoricalModal ref="historical"/>
    <ReplyModal @change="updateInfo" ref="reply"/>
</template>
