<template>
    <slot v-if="is_visible"></slot>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Visibility',
  props: {
    permission: {
      type: String
    }
  },
  computed: {
    ...mapState(['permissions']),
    is_visible () {
      return this.permissions.includes(this.permission) || this.permission === 'all'
    }
  }
}
</script>
