<template>
  <span class="text-xs font-semibold" :class="color">{{progress_state}}</span>
</template>

<script>

export default {
  name: 'Progress',
  props: {
    progress: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      colors: []
    }
  },
  computed: {
    progress_state () {
      return (this.progress) ? parseInt(this.progress) + '%' : '-'
    },
    color () {
      if (this.progress) {
        if (this.progress > 70) {
          return 'text-success'
        } else if (this.progress > 50) {
          return 'text-warning'
        } else {
          return 'text-danger'
        }
      }
      return ''
    }
  }
}
</script>

<style>

</style>
