<template>
  <svg :id="props.id" width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.92471 0H14.4098C14.7281 9.63564e-05 15.0418 0.0571737 15.3248 0.166488C15.6078 0.275801 15.8519 0.434194 16.0368 0.6285L20.7379 5.56425C20.8594 5.69129 20.9247 5.84368 20.9247 6C20.9247 6.15632 20.8594 6.30871 20.7379 6.43575L16.0368 11.3715C15.8519 11.5658 15.6078 11.7242 15.3248 11.8335C15.0418 11.9428 14.7281 11.9999 14.4098 12H2.92471C1.82169 12 0.924684 11.3273 0.924684 10.5V1.5C0.924684 0.67275 1.82169 0 2.92471 0ZM2.92471 10.5H14.4098L18.6959 6L14.4098 1.5H2.92471V10.5Z"
            :fill="props.color" />
        </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-trash'
  },
  color: {
    type: String,
    default: 'white'
  }
})
</script>
