<template>
  <h2 :class="`font-semibold ${color} text-2xl mt-6`"><slot></slot></h2>
</template>

<script>
export default {
  name: 'UiPageTitle',
  props: {
    color: {
      type: String,
      default: 'text-grey-darker'
    }
  }
}
</script>
