<template>
  <svg class="flex-none" :id="props.id" :width="props.size.width" :height="props.size.height" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3537 2.5V1.25V2.5ZM11.3537 2.5V3.75V2.5ZM11.3537 2.5H7.94757H11.3537ZM2.27075 6.25V11.875C2.27075 12.5654 2.94851 13.125 3.78457 13.125H14.3813C15.2174 13.125 15.8951 12.5654 15.8951 11.875V6.25H2.27075Z" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.27075 6.25V3.75C2.27075 3.05964 2.94851 2.5 3.78457 2.5H5.29839" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.29834 1.25V3.75" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8952 6.25V3.75C15.8952 3.05964 15.2175 2.5 14.3814 2.5H14.0029" :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'icon-date-filter'
  },
  color: {
    type: String,
    default: '#1D2939'
  },
  size: {
    type: Object,
    default: () => ({
      width: 19,
      height: 15
    })
  }
})
</script>
