<template>
  <section>
  <div class="bg-grey-lightest mt-6">
    <button @click="toggleOpenLog" class="flex justify-between w-full py-4 pl-6 pr-5 text-primary font-bold">
      Ver eventos de Log
      <svg class="svg-12 mt-1 animate-spin" v-if="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#304178" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/>
      </svg>
      <SVGIcons v-else class="svg-12 mt-1" :icon="(!open)?'plus-primary':'minus-primary'" />
    </button>
  </div>
  <div class="px-6" v-if="open">
    <div class="flex justify-between items-center mt-4">
      <UiCustomSelect :filter="false" :padding="'px-2 py-1'" v-model="days" @change="getEventLogs()">
        <option value="7">7 días</option>
        <option value="15">15 días</option>
        <option value="30">30 días</option>
        <option value="90">90 días</option>
        <option value="180">180 días</option>
        <option value="365">365 días</option>
      </UiCustomSelect>
      <DownloadXLS
          v-if="count > 0"
          class="btn btn-outline-primary"
          file="usuarios.xlsx"
          :path="`/jarvis/business/${this.active_business}/analitics/students/${this.$route.params.employee_id}/logs/`"
          formatted='[{"field":"kind","visible":"Tipo"},
                {"field":"created_ts","visible":"Fecha","output":"date"},
                {"field":"name","visible":"Evento"},
                {"field":"lesson_name","visible":"Lección"},
                {"field":"course_name","visible":"Curso"}]'
          :params="{limit:1000000000, days: this.days}"
          >
          Descargar reporte
      </DownloadXLS>
    </div>
    <div class="flex justify-center items-center border rounded border-primary-lighter text-sm py-4 text-primary mt-3" v-if="count === 0">
      No hay eventos de log en este periodo de tiempo.
    </div>
    <div class="mt-4">
      <ul>
        <li v-for="{created_ts, name, kind, lesson_name, course_name} in event_logs" :key="created_ts">
          <div class="text-sm text-primary pb-4" v-if="kind == 'LOGIN'">
            <div class="flex items-center">
              <SVGIcons class="svg-20 -ml-0.5" :icon="'single-login'"/>
              <strong class="mx-3 text-primary">Inicio sesión</strong>
              <span class="text-primary flex items-center"><SVGIcons class="svg-16 mr-2" :icon="'single-clock'"/> {{ $filters.longDate(created_ts)}}</span>
            </div>
            <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'DAILY_LOGIN'">
              El usuario ha iniciado sesión.
            </div>
          </div>

          <div class="text-sm text-primary pb-4" v-if="kind == 'LICENSE'">
            <div class="flex items-center">
              <SVGIcons class="svg-20 -ml-0.5" :icon="'single-clipboard'"/>
              <strong class="mx-3 text-primary">Licencia</strong>
              <span class="text-primary flex items-center"><SVGIcons class="svg-16 mr-2" :icon="'single-clock'"/> {{ $filters.longDate(created_ts)}}</span>
            </div>

            <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'ENABLED_LICENSE'">
              Licencia asignada.
            </div>

            <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'DISABLED_LICENSE'">
              Licencia retirada.
            </div>
          </div>

          <div class="text-sm text-primary pb-3" v-if="kind == 'FORMATION'">
          <div class="flex items-center">
            <SVGIcons class="svg-20 -ml-0.5" :icon="'single-book'"/>
            <strong class="mx-3 text-primary-dark">Formación</strong>
            <span class="text-primary flex items-center">
              <SVGIcons class="svg-16 mr-2" :icon="'single-clock'"/> {{ $filters.longDate(created_ts)}}
              <span class="ml-4">|</span>
              <SVGIcons class="svg-16 mr-2 ml-4" :icon="'single-user'"/> {{user.first_name}} {{user.last_name}}
            </span>
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'COURSE_COMPLETED'">
            Ha superado la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'COURSE_ENROLLED'">
            Ha comenzado la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'COURSE_UNENROLLED'">
            Ha abandonado la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'COURSE_PASSED'">
            Ha superado la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'LESSON_COMPLETED'">
            Ha completado la lección {{lesson_name}} de la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'SECTION_COMPLETED'">
            Ha completado una sección de la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'NEW_EXAM'">
            Ha comenzado el examen para {{course_name}}.
          </div>
          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'EXAM_PASSED'">
            Ha superado el examen para {{course_name}}.
          </div>
          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'EXAM_FAILED'">
            Ha suspendido el examen para {{course_name}}.
          </div>
          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'EXAM_COMPLETED'">
            Ha completado el examen para {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'PATH_ENROLLMENT'">
            Ha sido añadido a una ruta.
          </div>
          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'PATH_COMPLETED'">
            Ha completado una ruta.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'WEBINAR_NAVIGATION'">
            Ha visualizado un webinar.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'LIVE_NAVIGATION'">
            Ha presenciado un directo.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'NEW_COMMENT'">
            Ha añadido un comentario a la formación {{course_name}}.
          </div>

          <div class="text-sm text-primary pl-4 ml-2 mt-2 pb-3 border-l border-grey-light" v-if="name == 'PROFILE_FILLED'">
            Ha completado su perfil.
          </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="count > 0" class="flex justify-center relative mt-4 min-h-table-row-sm">
      <button v-if="!(limit  > count)" class="font-bold text-primary flex items-center" :class="{'disabled' : !(limit  > count)}" @click="(limit < count) ? getEventLogs() : ''">
        Mostrar más
        <svg class="svg-12 mt-1 animate-spin ml-2" v-if="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="#304178" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/>
        </svg>
      </button>
      <div class="absolute right-0 text-sm text-grey">
        Total de {{count}} <strong>resultados</strong>.
      </div>
    </div>
    <hr class="border-grey-light">
  </div>
  </section>
</template>

<script>
import SVGIcons from './Layout/SVGIcons.vue'
import UiCustomSelect from './Layout/UI/UiCustomSelect.vue'
import DownloadXLS from './DownloadXLS.vue'
import { mapState } from 'vuex'
import { api } from '../api/instances'
export default {
  name: 'EventLog',
  components: { SVGIcons, UiCustomSelect, DownloadXLS },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          first_name: 'Anónimo',
          last_name: ''
        }
      }
    }
  },
  data () {
    return {
      open: false,
      days: '7',
      limit: 0,
      count: 0,
      event_logs: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    toggleOpenLog () {
      if (this.open === true) {
        this.limit = 0
        this.open = false
      } else {
        this.open = true
        this.getEventLogs()
      }
    },
    getEventLogs () {
      this.loading = true
      this.limit += 5
      api.get(`/jarvis/business/${this.active_business}/analitics/students/${this.$route.params.employee_id}/logs/`,
        { params: { days: this.days, limit: this.limit } })
        .then(({ data }) => {
          this.event_logs = data.results
          this.count = data.paginator.count
          if (this.count === 0) {
            this.limit = 0
          }
          this.loading = false
        })
    }
  }
}
</script>
