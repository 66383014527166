<template>
  <UiHeader :title="'Campus corporativo'"/>
  <UiPageBody class="mt-3">
    <UiTableFilters class="ml-0 text-sm">
        <div class="flex-auto flex">
          <div class="w-1/3">
            <SearchField v-model="search" @change="getCatalog" :placeholder="'Buscar por nombre...'"/>
          </div>
        </div>
        <div class="ml-1 flex items-center">
            <DownloadXLS
            class="btn btn-outline-secondary"
            file="formaciones-corporativas.xlsx"
            :path="`/campus/${this.active_business}/courses/`"
            formatted='[{"field":"kind","visible":"Tipo","output":"capitalize"},
                  {"field":"name","visible":"Nombre"},
                  {"field":"duration_video","visible":"Duración","output":"tiempo"},
                  {"field":"category_name","visible":"Categoría"},
                  {"field":"level_difficulty","visible":"Dificultad", "output": "level_difficulty"},
                  {"field":"start_at","visible":"Fecha de creación", "output":"date"}]'
            :params="{
              limit: 1000000000,
              search: this.search,
              ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
              category: this.category_id,
              level_difficulty: this.difficulty,
              start: this.formmated_date_value(this.date_value.startDate),
              end: this.formmated_date_value(this.date_value.endDate),
              status_formation: this.status_formation
              }">
            Descargar reporte
            </DownloadXLS>
            <a href="https://campus.openwebinars.net/" :class="{'btn-disabled pointer-events-none' : pages.count >= campusCourseLimit }" target="_blank" class="ml-2 btn btn-secondary">
              Añadir formación
              <LinkIcon class="ml-2"/>
            </a>
        </div>
    </UiTableFilters>
    <div class="flex w-full justify-between">
      <UiMoreFilters class=" w-auto text-sm">
            <div class="col-auto px-1">
              <div class="flex items-center relative border-2 rounded"
              :class="(category_id !== '') ? 'border-primary' : 'border-primary-lighter'">
              <select
                @change="getCatalog()"
                v-model="category_id"
                :class="`w-full py-2 pl-3 pr-8 rounded outline-none bg-white`">
                <option :value="''">Categoría</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
              </select>
              <svg v-if="category_id === ''" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
              </svg>
              <svg @click="category_id = '', getCatalog()" v-if="category_id !== ''" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              </svg>
              </div>
            </div>
            <div class="col-auto px-1">
              <div class="flex items-center relative border-2 rounded"
              :class="(difficulty !== '') ? 'border-primary' : 'border-primary-lighter'">
              <select
                @change="getCatalog()"
                v-model="difficulty"
                :class="`w-full py-2 pl-3 pr-8 rounded outline-none bg-white`">
                <option :value="''">Dificultad</option>
                <option v-for="difficulty in level_difficulty" :key="difficulty.value" :value="difficulty.value">{{ difficulty.name }}</option>
              </select>
              <svg v-if="difficulty === ''" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
              </svg>
              <svg @click="difficulty = '', getCatalog()" v-if="difficulty !== ''" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              </svg>
              </div>
            </div>
              <div class="col-auto px-1">
                <div class="flex items-center relative border-2 rounded"
                :class="(status_formation !== '') ? 'border-primary' : 'border-primary-lighter'">
                <select
                  @change="getCatalog()"
                  v-model="status_formation"
                  :class="`w-full py-2 pl-3 pr-8 rounded outline-none bg-white`">
                  <option :value="''">Estado</option>
                  <option :value="'published'">Publicado</option>
                  <option :value="'draft'">Borrador</option>
                  <option :value="'archived'">Archivado</option>
                </select>
                <svg v-if="status_formation === ''" class="absolute right-4 pe-none" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.70711 2.95711L10.4142 2.25L9 0.835786L8.29289 1.54289L9.70711 2.95711ZM5.5 5.75L4.79289 6.45711L5.5 7.16421L6.20711 6.45711L5.5 5.75ZM2.70711 1.54289L2 0.835786L0.585787 2.25L1.29289 2.95711L2.70711 1.54289ZM8.29289 1.54289L4.79289 5.04289L6.20711 6.45711L9.70711 2.95711L8.29289 1.54289ZM6.20711 5.04289L2.70711 1.54289L1.29289 2.95711L4.79289 6.45711L6.20711 5.04289Z" fill="#304178"/>
                </svg>
                <svg @click="status_formation = '', getCatalog()" v-if="status_formation !== ''" class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                </div>
              </div>
      </UiMoreFilters>
      <div class="flex w-6/12 items-center justify-end mr-4" v-if="campusCourseLimit">
        <p class="mr-1 font-medium text-end text-grey text-sm"> <span :class="{'text-danger' : pages.count >= campusCourseLimit }">{{ pages.count }} de {{ campusCourseLimit }} </span> {{ (campusCourseLimit > 1) ? 'cursos permitidos' : 'curso permitido' }} se ha publicado en la plataforma </p>
        <Tooltip position="arrow-right">
          <template v-slot:icon>
            <InfoIcon/>
          </template>
          <template v-slot:default>
            <li>
              <p> <strong>El plan actual sólo permite publicar {{ (campusCourseLimit > 1) ? `hasta un máximo de ${campusCourseLimit} cursos corporativos en la plataforma.` : 'un único curso corporativo en la plataforma.'  }}</strong> Si deseas publicar más, consulta con tu comercial.</p>
            </li>
          </template>
          </Tooltip>
      </div>
    </div>
      <UiTable class=" 2xl:text-base xl:text-sm">
        <UiTableHeader>
          <UiTableCell class="w-4/12" first>
            <TableSorter
              :loading="loading"
              filter="name"
              v-model="activeFilter"
              @update="getCatalog()"
              >
              Nombre
            </TableSorter>
          </UiTableCell>
          <UiTableCell class="w-2/12">
            <TableSorter
              :loading="loading"
              filter="duration_video"
              v-model="activeFilter"
              @update="getCatalog()"
              >
              Duración
            </TableSorter>
          </UiTableCell>
          <UiTableCell class="w-2/12">Categoría</UiTableCell>
          <UiTableCell class="w-2/12">Dificultad</UiTableCell>
          <UiTableCell class="w-3/12">
            <TableSorter
              :loading="loading"
              filter="start_at"
              v-model="activeFilter"
              @update="getCatalog()"
              >
              Fecha de creación
            </TableSorter>
          </UiTableCell>
          <UiTableCell class="w-3/12">
            <TableSorter
              :loading="loading"
              filter="status_formation"
              v-model="activeFilter"
              @update="getCatalog()"
              >
              Estado
            </TableSorter>
          </UiTableCell>
          <UiTableCell last class="w-1/12"/>
        </UiTableHeader>
        <UiTableBody>
          <NotFoundSearch :status="not_found"/>
          <UiTableSkeleton v-if="first_loading && catalog.length == 0 && !not_found" :limit="paginator.limit"/>
          <UiTableRow v-for="{ id, name, duration_video, category_name, level_difficulty, status_formation, start_at, url } in catalog" :key="id">
            <UiTableCell first class="w-4/12">
              <a :href="`https://www.openwebinars.net${url}`" target="_blank" :title="name" class="font-bold text-primary">{{ name }}</a>
            </UiTableCell>
            <UiTableCell class="w-2/12 text-sm">{{ $filters.hoursAndMinutes(duration_video) }}</UiTableCell>
            <UiTableCell class="w-2/12 text-sm"> <div class="mr-2">{{ category_name }}</div></UiTableCell>
            <UiTableCell :title="translateDifficulty(level_difficulty)" class="w-2/12 text-sm">
              {{ translateDifficulty(level_difficulty) }}
            </UiTableCell>
            <UiTableCell class="w-3/12 text-sm">{{ $filters.shortDate(start_at) }}</UiTableCell>
            <UiTableCell class="w-3/12 text-sm"><TagStatus :status_formation="translateStatus[status_formation]"/></UiTableCell>
            <UiTableCell last class="w-1/12 justify-end">
              <Visibility :permission="'can_access_campus'">
                <MenuEllipsis
                v-if="status_formation === 'published'"
                  class="relative -top-1"
                    :items="[
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'external-link', text: 'Editar', border: false, func: () => {
                          editCourse(id)
                        }
                      },
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'folder', text: 'Archivar', border: false, func: () => {
                          archiveCourse(id)
                        }
                      }
                ]"
                />
                <MenuEllipsis
                v-if="status_formation === 'draft'"
                  class="relative -top-1"
                    :items="[
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'external-link', text: 'Editar', border: false, func: () => {
                          editCourse(id)
                        }
                      },
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'folder', text: 'Archivar', border: false, func: () => {
                          archiveCourse(id)
                        }
                      },
                      {
                        type: 'danger', permission: 'can_access_campus', icon: 'trash', text: 'Eliminar', border: true, func: () => {
                          openDialogBox({
                            $refs, ref: 'user-dialog', dialog: {
                              title: '¿Estás seguro de eliminar esta formación?',
                              text: 'Esta acción no se puede revertir.',
                              btn_text: 'Eliminar',
                              func: () => {
                                deleteCourse(id)
                              }
                            }
                          })
                    }
                  }
                ]"
                />
                <MenuEllipsis
                v-if="status_formation === 'archived'"
                  class="relative -top-1"
                    :items="[
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'external-link', text: 'Editar', border: false, func: () => {
                          editCourse(id)
                        }
                      },
                      {
                        type: 'default', permission: 'can_access_campus', icon: 'restore-formation', text: 'Restaurar', border: true, func: () => {
                          openDialogBox({
                            $refs, ref: 'user-dialog', dialog: {
                              title: 'Este curso se volverá a publicar',
                              type:'primary',
                              text: 'Una vez publicada, la formación estará disponible para los usuarios.',
                              btn_text: 'Restaurar',
                              func: () => {
                                restoreCourse(id)
                              }
                            }
                          })
                        }
                      }
                ]"
                />
              </Visibility>
            </UiTableCell>
          </UiTableRow>
        </UiTableBody>
      </UiTable>
        <TableFooter class="pl-12"
        v-if="!not_found"
        :pages="pages"
        v-model="paginator"
        @change="getCatalog(paginator.page)"
      />
  </UiPageBody>
  <DialogBox ref="user-dialog" />
</template>
<script>
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import UiTableFilters from '../../components/Layout/UI/Table/UiTableFilters.vue'
import UiMoreFilters from '../../components/Layout/UI/Table/UiMoreFilters.vue'
import DownloadXLS from '../../components/DownloadXLS.vue'
import SearchField from '../../components/SearchField.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { api } from '../../api/instances'
import LinkIcon from './icons/LinkIcon.vue'
import InfoIcon from './icons/InfoIcon.vue'
import TagStatus from './components/TagStatus.vue'
import UiTable from '../../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableBody from '../../components/Layout/UI/Table/UiTableBody.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'
import TableFooter from '../../components/TableFooter.vue'
import TableSorter from '../../components/TableSorter.vue'
import UiTableSkeleton from '../../components/Layout/UI/Table/UiTableSkeleton.vue'
import NotFoundSearch from '../../components/NotFoundSearch.vue'
import MenuEllipsis from '@/components/MenuEllipsis/Menu.vue'
import DialogBox from '@/components/DialogBox.vue'
import Visibility from '@/components/Visibility.vue'
import Tooltip from '@/components/Tooltip.vue'
export default {
  components: {
    UiHeader,
    UiPageBody,
    DialogBox,
    UiTableFilters,
    UiMoreFilters,
    DownloadXLS,
    SearchField,
    LinkIcon,
    UiTable,
    UiTableCell,
    UiTableBody,
    UiTableRow,
    TableFooter,
    TableSorter,
    UiTableSkeleton,
    NotFoundSearch,
    UiTableHeader,
    TagStatus,
    MenuEllipsis,
    Visibility,
    InfoIcon,
    Tooltip
  },
  data () {
    return {
      loading: false,
      category_id: '',
      search: '',
      difficulty: '',
      first_loading: true,
      translateStatus: {
        published: 'publicado',
        draft: 'borrador',
        archived: 'archivado'
      },
      level_difficulty: [
        {
          value: 'low',
          name: 'Principiante'
        },
        {
          value: 'middle',
          name: 'Intermedio'
        },
        {
          value: 'advanced',
          name: 'Avanzado'
        }
      ],
      activeFilter: { filter: 'status_formation', direction: true },
      catalog: [],
      status_formation: '',
      categories: [],
      pages: {},
      not_found: false,
      dates: {},
      paginator: {
        limit: 10,
        page: 1
      },
      formatter_datepicker: {
        date: 'DD-MM-YYYY',
        month: 'MMMM'
      },
      options: {
        footer: {
          apply: 'Seleccionar',
          cancel: 'Cancelar'
        }
      },
      date_value: {
        startDate: '',
        endDate: ''
      }
    }
  },
  watch: {
    date_value () {
      this.getCatalog()
    }
  },
  computed: {
    ...mapState(['active_business', 'filters', 'academy_filters', 'campus_course_limit', 'permissions']),
    campusCourseLimit () {
      if (this.campus_course_limit === null) {
        return 99
      }
      return this.campus_course_limit
    }
  },
  methods: {
    ...mapActions(['getFilters', 'openDialogBox']),
    ...mapMutations(['addToastMessage']),
    async getCatalog (pag = 1) {
      this.loading = true
      await api.get(`/campus/${this.active_business}/courses/`,
        {
          params: {
            limit: this.pages.limit,
            page: pag,
            search: this.search,
            category: this.category_id,
            level_difficulty: this.difficulty,
            start: this.formmated_date_value(this.date_value.startDate),
            end: this.formmated_date_value(this.date_value.endDate),
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
            catalog: 'business',
            status_formation: this.status_formation
          }
        })
        .then(({ data }) => {
          this.not_found = (data.count === 0)
          this.catalog = data.results
          this.pages = data.paginator
          this.loading = false
          this.first_loading = false
        })
        .catch((err) => {
          console.error(err)
        })
    },
    customShortcuts () {
      return [
        {
          label: 'Últimos 7 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 7)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 14 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 14)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 30 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 30)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 12 meses',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setFullYear(date.getFullYear() - 1)),
              new Date()
            ]
          }
        },
        {
          label: 'Seleccionar días'
        }
      ]
    },
    formmated_date_value (date) {
      return date.split(/[-/]/).reverse().join('-')
    },
    translateDifficulty (level_difficulty) {
      if (this.academy_filters.level_difficulty && level_difficulty) {
        return this.academy_filters.level_difficulty.find((el) => el.value === level_difficulty).name
      }
      return ''
    },
    async archiveCourse (idCourse) {
      await api.patch(`/campus/${this.active_business}/courses/${idCourse}/`, { status_formation: 'archived' })
        .then(() => {
          this.getCatalog()
          this.addToastMessage({
            title: 'Formación archivada',
            message: 'La formación ha sido archivada correctamente.',
            status: 'success'
          })
        })
        .catch((error) => {
          console.errr(error)
          this.addToastMessage({
            title: 'Error al archivar la formación',
            message: 'Ha ocurrido un error al archivar la formación.',
            status: 'error'
          })
        })
    },
    async deleteCourse (idCourse) {
      await api.delete(`/campus/${this.active_business}/courses/${idCourse}/`)
        .then(() => {
          this.getCatalog()
          this.addToastMessage({
            title: 'Formación eliminada',
            message: 'La formación ha sido eliminada correctamente.',
            status: 'success'
          })
        })
        .catch((error) => {
          console.errr(error)
          this.addToastMessage({
            title: 'Error al borrar la formación',
            message: 'Ha ocurrido un error al borrar la formación.',
            status: 'error'
          })
        })
      this.$refs['user-dialog'].dialog('hide')
    },
    async restoreCourse (idCourse) {
      await api.patch(`/campus/${this.active_business}/courses/${idCourse}/`, { status_formation: 'published' })
        .then(() => {
          this.getCatalog()
          this.addToastMessage({
            title: 'Formación restaurada',
            message: 'La formación ha sido restaurada correctamente.',
            status: 'success'
          })
        })
        .catch((error) => {
          console.error(error)
          this.addToastMessage({
            title: 'Error al restaurar la formación',
            message: 'Ha ocurrido un error al restaurar la formación.',
            status: 'error'
          })
        })
      this.$refs['user-dialog'].dialog('hide')
    },
    editCourse (id) {
      window.open(
        `https://campus.openwebinars.net/formacion/${id}/edicion/datos-del-curso`,
        '_blank'
      )
    },
    async getCategories () {
      await api.get('/campus/ow/courses/categories/')
        .then(({ data }) => {
          this.categories = data.results
        })
        .catch(() => {
          console.error('Problema al cargar las categorias')
        })
    }
  },
  created () {
    this.getCatalog()
    this.getFilters()
    this.getCategories()
  }
}
</script>
<style scoped>
select {
  -webkit-appearance: none;
  cursor: pointer;
}

.pe-none {
  pointer-events: none;
}

.bg-corporative {
  background-color: #8DAA9D;
}
</style>
