<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold  border-b-[1px]">Borrar usuario</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <section class="pt-4">
        <section>
          <article class="px-12 py-3">
            <div class="pb-16">
              <div class="mb-5 border-custom border-grey-lighter rounded-xl p-3 pl-5">
                <UserIdentity large :user="{ first_name: first_name.value, email: email.value, last_name: last_name.value, avatar_url }" dark/>
              </div>
              <div class="mb-5">
                <h2 class="text-xl font-semibold text-grey-dark">Dar de baja usuario</h2>
                <p class="text-grey-dark">Esta persona dejará de tener acceso a la plataforma y se ocultará de los listados, pero seguirá formando parte de las estadísticas.</p>
                <div class="w-56">
                  <button @click.prevent="openDialogBox({
                    $refs, ref: 'user-dialog', dialog: {
                      title: '¿Quieres dar de baja a esta persona?',
                      text: 'Esta persona dejará de tener acceso a la plataforma y se ocultará de los listados, pero seguirá formando parte de las estadísticas.',
                      btn_text: 'Dar de baja',
                      func: () => {
                        disableUserFromBusiness()
                      }
                    }
                  })" class="flex items-center text-sm w-full text-error my-5 border-custom px-3 py-1 rounded mb-1">
                    <DisabledUserIcon :size="{ width: 14, height: 14 }" class="mr-2" color="red"/>
                    Dar de baja al usuario</button>
                </div>
              </div>
              <div>
                <h2 class="text-xl font-semibold text-grey-dark">Eliminar usuario</h2>
                <p class="text-grey-dark">Esta persona dejará de tener acceso a la plataforma y sus datos se eliminarán permanentemente de las bases de datos de la plataforma y estadísticas de la empresa.</p>
                <div class="xl:w-2/6 2xl:w-3/6">
                  <button @click.prevent="openDialogBox({
                    $refs, ref: 'user-dialog', dialog: {
                      title: '¿Seguro que quieres eliminar a este usuario de la organización?',
                      text: 'Esta persona dejará de tener acceso a la plataforma y sus datos se eliminarán permanentemente de las bases de datos de la plataforma y estadísticas de la empresa.',
                      btn_text: 'Eliminar',
                      func: () => {
                        deleteUserFromBusiness()
                      }
                    }
                  })" class="flex items-center text-sm text-error border-custom mt-5 px-3 py-1 rounded">
                    <TrashIcon :size="{width: 14, height: 14}" class="mr-2" color="red"/>
                    Eliminar de la organización</button>
                </div>
              </div>
            </div>
          </article>
        </section>
      </section>

    </div>
  </div>
  </transition>
  <DialogBox ref="user-dialog" />
</template>

<script>

import { api } from '../../api/instances'
import { mapActions, mapMutations, mapState } from 'vuex'
import DisabledUserIcon from '@/components/Icons/DisabledUserIcon.vue'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import DialogBox from '@/components/DialogBox.vue'
import UserIdentity from '../UserIdentity.vue'

export default {
  name: 'DeleteUserModal',
  components: {
    DisabledUserIcon,
    TrashIcon,
    DialogBox,
    UserIdentity
  },
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      open: false,
      is_team: false,
      employee_id: null,
      email: { value: '', error: false, error_message: '' },
      team: { value: '', error: false },
      first_name: { value: '', error: false },
      last_name: { value: '', error: false },
      avatar_url: '',
      cid: '',
      tags: [],
      custom_tags: [],
      role: 'student',
      licenses: null,
      error_licenses: '',
      managed_teams: [],
      has_campus_access: false,
      custom_teams: [],
      loading_button: false,
      has_future_planning: false,
      dataUserView: true,
      deleteUserView: false
    }
  },
  computed: {
    ...mapState(['user', 'active_business'])
  },
  methods: {
    ...mapMutations(['addToastMessage', 'setDisabledUsers']),
    ...mapActions(['openDialogBox', 'getTotalLicenses']),
    changeState (state) {
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action, obj = null) {
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
      }
      if (obj !== null) {
        this.email.value = obj.email
        this.team.value = obj.team
        this.has_future_planning = obj.has_future_planning
        this.cid = (obj.cid) ? obj.cid : ''
        this.first_name.value = obj.first_name
        this.last_name.value = obj.last_name
        this.custom_tags = obj.custom_tags
        this.custom_teams = obj.managed_teams
        this.role = obj.role
        this.avatar_url = obj.avatar_url
        this.has_campus_access = obj.has_campus_access
        this.employee_id = obj.employee_id
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    async disableUserFromBusiness () {
      try {
        await api.patch(`/jarvis/business/${this.active_business}/users/${this.employee_id}/disable/`)
        this.$emit('disable')
        this.setDisabledUsers()
        this.addToastMessage({
          status: 'success',
          title: 'Usuario dado de baja',
          message: 'Ya no tendrá acceso a la plataforma.'
        })
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al dar de baja al usuario.'
        })
        console.error('Error al dar de baja usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
      this.changeState(false)
    },
    async deleteUserFromBusiness () {
      try {
        await api.delete(`/jarvis/business/${this.active_business}/users/${this.employee_id}/`)
        this.$emit('delete')
        this.addToastMessage({
          status: 'success',
          title: 'Usuario eliminado',
          message: 'Eliminado correctamente de la organización.'
        })
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al desactivado al usuario.'
        })
        console.error('Error al desvincular usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
      this.changeState(false)
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-5of12 rounded-lg bg-white
  }
  .border-custom {
    border-width: 1px;
  }
</style>
