<template>
  <nav class="flex justify-between p-4">
    <div class="flex-none w-2/3 flex">
      <div class="w-4/6">
        <SearchField v-model="search" :placeholder="'Introduce tu búsqueda...'" @change="getProgress" />
      </div>

    </div>
    <div class="">
      <DownloadXLS  class="btn btn-secondary"
          :file="`usuario-${this.$route.params.employee_id}-progreso.xlsx`"
          :path="`/jarvis/progress/users/${this.$route.params.employee_id}/courses`"
          formatted='[{"field":"kind","visible":"Tipo"},
                    {"field":"name","visible":"Nombre"},
                    {"field":"progress","visible":"Progreso"},
                    {"field":"stage","visible":"Estado"},
                    {"field":"active_time","visible":"Dedicación", "output":"tiempo"},
                    {"field":"active_time_in_hours","visible":"Dedicación (horas)", "output":"hours"},
                    {"field":"start","visible":"Fecha de Inicio", "output": "date"},
                    {"field":"end","visible":"Fecha de Fin", "output": "date"},
                    {"field":"last_test_end","visible":"Último intento de exámen", "output": "date"},
                    {"field":"score","visible":"Nota"},
                    {"field":"certificate_url","visible":"Certificado"}]'
          :params="{
            limit:1000000000,
            search:this.search,
            ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
            course__kind: this.course__kind,
            stage: this.stage,
            catalog: this.catalog_selected,
            }">
        Descargar reporte
      </DownloadXLS>
    </div>
  </nav>
  <UiMoreFilters>
<div class="px-1">
          <UiCustomSelect class="w-full" @change="getProgress" v-model="course__kind" name="type" :bgClass="'bg-white'">
            <option value="">Tipo</option>
            <option value="curso" selected="">Curso</option>
            <option value="taller">Taller</option>
            <option value="premium">Empresas</option>
            <option value="lab">Laboratorio</option>
          </UiCustomSelect>
        </div>
        <div class="px-1">
          <UiCustomSelect class="w-full" @change="getProgress" v-model="stage" name="state" :bgClass="'bg-white'">
            <option value="">Estado</option>
            <option :value="'Aprobado'">Aprobado</option>
            <option :value="'En curso'">En curso</option>
          </UiCustomSelect>
        </div>
          <Visibility :permission="'can_see_corporative_courses'">
            <div class="col-auto px-1">
              <UiCustomSelect class="w-full" :bgClass="'bg-white'" @change="getProgress" v-model="catalog_selected" name="">
                <option value="">Catálogo</option>
                <option value="ow">OpenWebinars</option>
                <option value="business">Corporativo</option>
              </UiCustomSelect>
            </div>
          </Visibility>
  </UiMoreFilters>
  <UiTable class="text-sm">
      <UiTableHeader>
        <UiTableCell class="w-1/12 pl-4">Tipo</UiTableCell>
        <UiTableCell class="w-4/12"><span>Nombre</span></UiTableCell>
        <UiTableCell class="w-1/12 flex items-center justify-center">Duración</UiTableCell>
        <UiTableCell class="w-2/12 flex items-center justify-center">
              Matriculación
        </UiTableCell>
        <UiTableCell class="w-1/12 flex items-center justify-center">Progreso</UiTableCell>
        <UiTableCell class="w-2/12 flex items-center justify-center ">
          <TableSorter
          class="text-grey-light"
              :loading="loading"
              filter="dedication_time"
              v-model="activeFilter"
              @update="getProgress()"
            >
              Dedicación
          </TableSorter>
        </UiTableCell>
        <UiTableCell class="w-2/12 flex items-center justify-center">Certificado</UiTableCell>
      </UiTableHeader>
      <UiTableSkeleton v-if="first_loading && progress.length == 0"/>
      <UiTableBody class="text-sm">
        <UiTableRow
          :shadow="true"
          :bg="'bg-white'"
          v-for="{
            id,
            kind,
            business,
            name,
            progress,
            active_time,
            stage,
            certificate_url,
            start,
            duration,
            end
          } in progress" :key="id">
          <UiTableCell class="w-1/12 pl-4 my-1 capitalize ">
            {{translate_kind[kind]}}
          </UiTableCell>
          <UiTableCell class="flex w-4/12 items-center relative">
            <span :title="name" class="w-7/12">{{name}}</span>
            <span v-if="business" title="Formación corporativa"
                class="text-2xs bg-corporative absolute rounded-full right-8 px-2 py-1">C</span>
          </UiTableCell>
          <UiTableCell class="flex-auto w-1/12 flex items-center justify-center">
          {{$filters.hoursAndMinutes(duration)}}
          </UiTableCell>
          <UiTableCell class="w-2/12 flex items-center justify-center">
            {{$filters.shortDate(start)}}
          </UiTableCell>
          <UiTableCell class="w-1/12 flex justify-center items-center">
            <Progress :progress="progress" />
          </UiTableCell>
          <UiTableCell class="flex-auto w-2/12 flex items-center justify-center">
          {{$filters.hoursAndMinutes(active_time)}}
          </UiTableCell>
          <UiTableCell class="flex-auto w-2/12 flex justify-center items-center">
            <div v-if="stage == 'Aprobado'">
              <a
                v-if="certificate_url"
                :href="certificate_url"
                class="flex items-center justify-center text-center font-semibold text-primary"
                >
                Descargar <SVGIcons class="svg-12 mx-1" :icon="'download'"/></a>
                <span v-else>No disponible</span>
                <span class="text-[10px] text-left">Aprobado {{ $filters.shortDate(end) }}</span>
            </div>
            <span v-else>{{stage}}</span>
          </UiTableCell>
        </UiTableRow>
         <NotFoundSearch :status="status"/>
      </UiTableBody>
      <TableFooter v-if="count >= paginator.limit && !status" :pages="pages" v-model="paginator" @change="getProgress(this.paginator.page)" v-cloak/>
    </UiTable>
</template>

<script>
import NotFoundSearch from '../../components/NotFoundSearch.vue'
import Progress from '../../components/Progress.vue'
import SearchField from '../../components/SearchField.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import TableSorter from '../../components/TableSorter.vue'
import TableFooter from '../../components/TableFooter.vue'
import UiCustomSelect from '../../components/Layout/UI/UiCustomSelect.vue'
import { api } from '../../api/instances'
import UiMoreFilters from '../../components/Layout/UI/Table/UiMoreFilters.vue'
import UiTable from '../../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableCell from '../../components/Layout/UI/Table/UiTableCell.vue'
import UiTableSkeleton from '../../components/Layout/UI/Table/UiTableSkeleton.vue'
import UiTableBody from '../../components/Layout/UI/Table/UiTableBody.vue'
import UiTableRow from '../../components/Layout/UI/Table/UiTableRow.vue'
import DownloadXLS from '../../components/DownloadXLS.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import Visibility from '@/components/Visibility.vue'
export default {
  name: 'UserProgress',
  components: {
    NotFoundSearch,
    Progress,
    SearchField,
    SVGIcons,
    TableSorter,
    TableFooter,
    UiCustomSelect,
    UiMoreFilters,
    UiTable,
    UiTableHeader,
    UiTableCell,
    UiTableSkeleton,
    UiTableBody,
    UiTableRow,
    DownloadXLS,
    Visibility
  },
  data () {
    return {
      loading: false,
      first_loading: true,
      activeFilter: { filter: 'start', direction: true },
      search: '',
      status: false,
      pages: {},
      count: 0,
      paginator: { limit: 10, page: 1 },
      progress: [],
      course__kind: '',
      stage: '',
      translate_kind: {
        premium: 'Empresas',
        taller: 'Taller',
        curso: 'Curso',
        lab: 'Laboratorio'
      },
      catalog_selected: ''
    }
  },
  computed: {
    ...mapState(['active_business', 'files_downloading'])
  },
  methods: {
    ...mapActions(['updateProgressWait']),
    ...mapMutations(['addToastMessage', 'progressWait']),
    async getProgress (pag = 1) {
      this.loading = true
      await api.get(`/jarvis/progress/users/${this.$route.params.employee_id}/courses`, {
        params: {
          limit: this.paginator.limit,
          search: this.search,
          course__kind: this.course__kind,
          stage: this.stage,
          catalog: this.catalog_selected,
          ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
          page: pag
        }
      })
        .then(({ data }) => {
          if (data.count === 0) {
            this.status = true
          } else {
            this.status = false
          }
          this.progress = data.results
          this.pages = data.paginator
          this.count = data.count
        })
        .catch(console.error)
      this.first_loading = false
      this.loading = false
    }
  },
  created () {
    this.getProgress()
  }
}
</script>

<style scoped>
  .custom-table-body{
  min-height: 25vh;
  }
  .bg-corporative {
  background-color: #8DAA9D;
}
</style>
