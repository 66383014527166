<template>
  {{Math.round(progress)}}%
  <div class="w-1/3 flex items-center rounded h-2 ml-2" :class="(progress !== 0) ? 'color-opacity' : 'color-zero'">
    <div class="h-2 rounded-full" :class="(progress === 100) ? 'bg-success' : 'bg-primary'" :style="`width: ${progress}%`"></div>
  </div>
</template>
<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style scoped>
.color-opacity{
  background-color: #BBC0D3;
;
}
.color-zero{
  background-color: #E5A0A0;
 ;
}
</style>
