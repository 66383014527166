<template>
  <div class="w-[102px]">
  <div class="relative">
    <SpeedometerBG :color="degreeTeamState.color" :id="`speedTeam`" />
    <div class="flex justify-center absolute" style="width: 10%; bottom: 12%; left: 50%; transform: translateX(-50%);">
      <div class="absolute z-10" style="bottom: -14.5%">
        <svg width="100%" height="100%" viewBox="0 0 75 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M37.1,0c20.49,-0.014 37.136,16.61 37.15,37.1c0.014,20.49 -16.61,37.136 -37.1,37.15c-20.49,0.014 -37.136,-16.61 -37.15,-37.1c-0.014,-20.49 16.61,-37.136 37.1,-37.15Z" style="fill:#323232;"/><path d="M37.1,0c20.49,-0.014 37.136,16.61 37.15,37.1c0.014,20.49 -16.61,37.136 -37.1,37.15c-20.49,0.014 -37.136,-16.61 -37.15,-37.1c-0.014,-20.49 16.61,-37.136 37.1,-37.15Zm0.005,7.5l0.005,0c16.346,-0.011 29.629,13.255 29.64,29.605c0.011,16.351 -13.255,29.634 -29.605,29.645c-16.351,0.011 -29.634,-13.255 -29.645,-29.605c-0.011,-16.351 13.255,-29.634 29.605,-29.645Z" style="fill:#fff;"/></svg>
      </div>
      <div class="transition-all" :style="`width: 50%; transform-origin: bottom center; transform: rotate(${props.degree - 90}deg);`">
        <svg width="100%" height="100%" viewBox="0 0 43 322" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M22.366,-0l20.052,321.627l-42.418,-0.152l22.366,-321.475Z" style="fill:#323232;fill-rule:nonzero;"/></svg>
      </div>
    </div>
  </div>
  </div>
      <div class="text-sm text-center">
        <div class="whitespace-nowrap">La calidad del equipo de:</div>
        <div class="whitespace-nowrap flex items-center justify-center">
          <div class="inline-block text-ellipsis overflow-hidden whitespace-nowrap max-w-[150px] font-bold text-secondary-darker pr-1">{{name}} </div> es:</div>
        <div class="font-bold" :style="`color:${degreeTeamState.color}`" >{{ degreeTeamState.text }}</div>
      </div>
</template>
<script setup>
import SpeedometerBG from './SpeedometerBG.vue'
import { computed } from 'vue'
const degreeTeamState = computed(() => {
  if (props.degree < 30) {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  } else if (props.degree < 60) {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  } else if (props.degree < 90) {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  } else if (props.degree < 120) {
    return { color: '#d1d80f', text: 'Aceptable' }
  } else if (props.degree < 150) {
    return { color: '#84bd32', text: 'Buena' }
  } else if (props.degree <= 180) {
    return { color: '#30ad43', text: 'Excelente' }
  } else {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  }
})

const props = defineProps({
  degree: {
    type: Number,
    default: 0
  },
  name: {
    type: String,
    default: ''
  }
})
</script>

<style scoped>
  .transition-all {
    transition: all 0.3s ease-in-out;
  }
</style>
