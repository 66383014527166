<script setup>
const props = defineProps({
  color: {
    type: String,
    default: '#39725A'
  }
})
</script>
<template>
  <svg width="12" height="12" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.59591 6.83333V5.16667M5.59591 3.5H5.60029M9.97381 5.16667C9.97381 7.46785 8.01376 9.33333 5.59591 9.33333C3.17807 9.33333 1.21802 7.46785 1.21802 5.16667C1.21802 2.86548 3.17807 1 5.59591 1C8.01376 1 9.97381 2.86548 9.97381 5.16667Z" :stroke="props.color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
