<template>
  <div class="relative flex items-center mr-2">
    <button class="absolute" @click="setSubmit">
      <SVGIcons class="svg-18 ml-2" icon="search"/>
    </button>
    <form class="w-full" @submit.prevent="setSubmit">
      <input v-model="search" @keyup="update" :class="`w-full ${border} py-2 pl-8 pr-4 outline-none rounded ${bgClass}`" type="text" :placeholder="placeholder">
    </form>
      <button @click.prevent="reset()" v-if="search.length > 0" class="absolute top-4 right-0">
        <svg class="absolute right-4 cursor-pointer" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.56065 6.68199L4.12131 4.12134M4.12131 4.12134L6.68196 1.56068M4.12131 4.12134L1.56065 1.56068M4.12131 4.12134L6.68196 6.68199" stroke="#304178" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
      </button>
  </div>
</template>

<script>
import SVGIcons from './Layout/SVGIcons.vue'

export default {
  name: 'SearchField',
  components: {
    SVGIcons
  },
  props: {
    modelValue: {
      type: String
    },
    bgClass: {
      type: String,
      default: 'border-grey-lighter'
    },
    border: {
      type: String,
      default: 'border-b'
    },
    placeholder: {
      type: String,
      default: 'Buscar por nombre o email'
    }
  },
  emits: ['change', 'update:modelValue'],
  data () {
    return {
      search: ''
    }
  },
  watch: {
    modelValue (val) {
      this.search = val
    }
  },
  methods: {
    update () {
      this.$emit('update:modelValue', this.search)
    },
    setSubmit () {
      this.$emit('change')
    },
    reset () {
      this.search = ''
      this.update()
      this.setSubmit()
    }
  },
  created () {
    this.search = this.modelValue
  }
}
</script>

<style scoped>
input.focus-visible {
  background-color: #f1f2f5;
  box-shadow: 0px 3px 0 -1px #ccc;
}
</style>
