<template>
  <ul class="flex items-start justify-center 2xl:flex-col xl:flex-col">
    <li class="flex flex-col xl:flex-row items-center my-1 mr-2" v-for="({name, count, color}, index) in chartData" :key="index">
        <Tooltip v-if="has_tooltip.length > 0">
          <template v-slot:icon>
            <div class="w-4 h-2" :style="`background-color: ${color}`"></div>
          </template>
          <li>
            <p>{{writeText(name).text}}</p>
            </li>
        </Tooltip>
      <div class="w-4 h-2 mr-3" :style="`background-color: ${color}`" v-else></div>
      <div class=" ml-3 lg:mr-auto md:text-sm md:mr-3">
        {{name}}
      </div>
      <span class="text-primary font-semibold 2xl:ml-3 xl:ml-2 md:text-sm">
        {{count}}
      </span>
    </li>
  </ul>
</template>

<script>
import Tooltip from '../Tooltip.vue'
export default {
  components: { Tooltip },
  name: 'ChartLegend',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    has_tooltip: {
      type: Array
    }
  },
  methods: {
    writeText (name) {
      return this.has_tooltip.find(el => el.name === name)
    }
  }
}
</script>

<style>

</style>
