<template>
  <ul class="chart flex items-end relative justify-between w-full">
    <div class="ruler flex flex-col text-right">
      <span>+{{(slot * 5)}}h</span>
      <span>{{(slot * 4)}}h</span>
      <span>{{(slot * 3)}}h</span>
      <span>{{(slot * 2)}}h</span>
      <span>{{(slot)}}h</span>
      <span>0h</span>
    </div>
    <li class="flex flex-col justify-center relative" v-for="{ month, hours } in calendar" :key="month">
      <div class="relative bg-primary w-8 2xl:w-12 rounded-t-md" :style="'height:'+(hours*(200/max_value))+'px;'">
        <div v-if="hours > 0" class="absolute -top-5 left-0 right-0 mx-auto font-semibold text-primary text-center">{{hours}}</div>
      </div>
      <span class="absolute -bottom-5 left-0 right-0 mx-auto w-full text-center text-xs">{{months[month-1]}}</span>
    </li>
    <svg v-if="loading" class="absolute left-0 right-0 mx-auto bottom-1/3 w-32 h-32 animate-spin" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#e4e4e4" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path></svg>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '../../api/instances'

export default {
  name: 'BarChart',
  props: {
    path: String
  },
  data () {
    return {
      loading: false,
      calendar: [],
      months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    }
  },
  computed: {
    ...mapState(['active_business']),
    max_value () {
      const max = Math.max(...this.calendar.map((el) => el.hours))
      return (max < 10) ? 10 : max
    },
    slot () {
      return parseInt((40 * this.max_value) / 200)
    }
  },
  methods: {
    async getUserProgress () {
      this.loading = true
      try {
        if (this.$route.params.id_team) {
          const { data } = await api.get(`/jarvis/business/${this.active_business}/teams/${this.$route.params.id_team}/graph/`)
          this.calendar = data.dedication_calendar
        }
        if (this.$route.params.employee_id) {
          const { data } = await api.get(`/jarvis/business/${this.active_business}/users/${this.$route.params.employee_id}/graph/`)
          this.calendar = data.dedication_calendar
        }
        this.loading = false
      } catch (err) {
        console.error('No se han podido cargar los datos en la gráfica!')
        this.loading = false
      }
    }
  },
  created () {
    this.getUserProgress()
  }
}
</script>

<style scoped>
  .chart{
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .chart::before {
    content: '';
    position: absolute;
    z-index: 10;
    right: 0;
    display: block;
    width: calc(100% - 25px);
    height: 1px;
    background-color: rgb(218, 218, 218);
  }
  .ruler{
    height: 240px;
    min-width: 25px;
    border-right: 1px solid rgb(218, 218, 218);
  }
  .ruler span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40px;
    font-size: 10px;
    line-height: 10px;
    position: relative;
    bottom: -2px;
    margin-right: 3px;
  }
</style>
