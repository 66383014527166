<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
  <div v-if="open" class="overlay custom-scroll">
    <div class="modal animate__animated animate__slideInUp">
      <header class="px-12 pt-8 pb-3">
        <h2 class="text-2xl text-grey-darkest font-semibold">Alta de usuario</h2>
        <button type="button" @click="changeState(false)" class="absolute right-5 top-7">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14L8.00002 8.00003M8.00002 8.00003L14 2M8.00002 8.00003L2 2M8.00002 8.00003L14 14" stroke="#444444" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </button>
      </header>
      <section class="pt-4">
        <nav class="px-12 border-b">
            <ul class="flex">
              <li :class="{ 'border-primary border-b-2': dataUserView }"  class="flex-none text-xl text-primary font-semibold  border-primary pb-4 pr-1"><button @click="dataUserView = true, emailInvite = false">Por email</button> </li>
              <li :class="{ 'border-primary border-b-2': emailInvite }"  class="flex-none text-xl text-primary font-semibold  ml-20 border-primary pb-4 pr-1"><button @click="emailInvite = true, dataUserView = false">Por enlace</button> </li>
            </ul>
          </nav>
      <form v-if="dataUserView" @submit.prevent="createUser()" id="create-edit-user">
        <article class="px-12 py-3">
            <fieldset>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-1/2 mr-2" for="email">
                  <strong class="font-medium">E-mail*</strong>
                  <input id="email" required autofocus v-model="email.value" :class="{'error': email.error}"
                    class="p-3 mt-2 rounded-lg bg-grey-lightest" placeholder="Introduce un e-mail válido..." type="email">
                  <div v-if="email.error" class="mt-1 text-xs text-danger">
                    <strong>Error:</strong> <span v-html="email.error_message"></span>
                    <button class="text-secondary-darker font-bold" v-if="email.idUser" @click="() => {$router.push(`/gestion/usuarios/detalle/${email.idUser}`) , changeState(false)}">Ver detalle de usuario</button>
                  </div>
                </label>
                <label class="flex flex-col w-1/2 ml-2" for="team">
                  <strong class="font-medium">Equipo*</strong>
                  <Tooltip v-if="has_future_planning" class="ml-1">
                      <template v-slot:icon>
                      <SelectTeam @licenses="(e) => withoutLicenses(e)" :full="true" :filter="false" :bgClass="'bg-grey-lightest'"
                        :disabled="true" :id="'team'" :required="true" v-model="team.value" :class="{'error': team.error}"
                        class="p-1 mt-2 rounded-lg bg-grey-lightest" />
                    </template>
                    <template v-slot:default>
                      <li>
                        <p>Este usuario tiene licencias planificadas.</p>
                      </li>
                      <li>
                        <p>Para modificar su equipo, accede al  <u><router-link to="/planificador">planificador</router-link></u> y elimina sus planificaciones.</p>
                      </li>
                    </template>
                  </Tooltip>
                    <SelectTeam v-else @licenses="(e) => withoutLicenses(e)" :full="true" :filter="false" :bgClass="'bg-grey-lightest'"
                      :disabled="is_team" :id="'team'" :required="true" v-model="team.value" :class="{'error': team.error}"
                      class="p-1 mt-2 rounded-lg bg-grey-lightest" />
                  <div v-if="team.error" class="mt-1 text-xs text-red"><strong>Error:</strong> Este campo es obligatorio.</div>
                </label>
              </div>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-1/2 mr-2" for="first_name">
                  <strong class="font-medium">Nombre*</strong>
                  <input id="first_name" v-model="first_name.value" required :class="{'error': first_name.error}"
                    class="p-3 mt-2 rounded-lg bg-grey-lightest" placeholder="Escribe aquí el nombre del usuario..." type="text">
                  <div v-if="first_name.error" class="mt-1 text-xs text-red"><strong>Error:</strong> Este campo es obligatorio.</div>
                </label>
                <label class="flex flex-col w-1/2 ml-2" for="last_name">
                  <strong class="font-medium">Apellidos*</strong>
                  <input id="last_name" v-model="last_name.value" required :class="{'error': last_name.error}"
                    class="p-3 mt-2 rounded-lg bg-grey-lightest" placeholder="Escribe aquí los apellidos del usuario..." type="text">
                  <div v-if="last_name.error" class="mt-1 text-xs text-red"><strong>Error:</strong> Este campo es obligatorio.</div>
                </label>
              </div>
              <div class="mt-2 flex w-full">
                <label class="flex flex-col w-1/2 mr-2" for="cid">
                  <strong class="font-medium">ID</strong>
                  <input id="cid" v-model="cid"
                    class="p-3 mt-2 rounded-lg bg-grey-lightest" placeholder="Escribe aquí el ID del usuario..." type="text">                </label>
                <label class="flex flex-col w-1/2 ml-2" for="tags">
                  <strong class="font-medium">Etiquetas</strong>
                  <TagFieldToAdd v-model="tags" :custom="custom_tags" class="py-2 mt-2 rounded-lg bg-grey-lightest" />
                </label>
              </div>
                <div class="flex w-full" :class="(role === 'staff') ? 'flex-wrap' : 'flex-col'">
                  <div class="mt-2 flex w-full">
                    <label class="flex flex-col" :class="(role === 'staff') ? 'w-1/2 mr-2' : 'w-1/2 mr-2'" for="role">
                    <Visibility :permission="'can_create_staff'">
                      <strong class="font-medium">Rol</strong>
                      <SelectRole @change="checkRole" :is_restricted="(!user.is_superuser) ? true : false" :filter="false" :label="false" :bgClass="'bg-grey-lightest'" :id="'role'" v-model="role" class="p-1 mt-2 rounded-lg bg-grey-lightest" />
                    </Visibility>
                    </label>
                    <label v-if="role === 'staff'" class="flex flex-col w-1/2 ml-2" for="managed_teams">
                      <strong class="font-medium">Equipos que gestionará*</strong>
                      <MultiSelectTeam v-model="managed_teams" :custom="custom_teams" class="py-1 mt-2 rounded-lg bg-grey-lightest" />
                    </label>
                    <Visibility v-if="role !== 'staff'"  class="w-1/2 mt-5 ml-2" :permission="'can_manage_access_campus'">
                        <div class="flex items-center w-full my-2 mt-5">
                          <UiCheck :class="{ 'disabled pointer-events-none': role === 'student' }" class="mt-1" :state="has_campus_access" @click="has_campus_access = !has_campus_access" ></UiCheck>
                          <label for="has_campus_access" @click="has_campus_access = !has_campus_access" :class="{ 'disabled pointer-events-none text-grey': role === 'student' }" class="ml-2 mt-1 w-full  text-sm font-medium">Acceso a la gestión del campus corporativo</label>
                        </div>
                    </Visibility>
                  </div>
                   <Visibility v-if="role === 'staff'" class="w-full mt-8 ml-2" :permission="'can_manage_access_campus'">
                          <div v-if="role !== 'student'" class="flex items-center w-full my-2">
                            <UiCheck class="mt-1" :state="has_campus_access" @click="has_campus_access = !has_campus_access" ></UiCheck>
                            <label for="has_campus_access" @click="has_campus_access = !has_campus_access" class="ml-2 mt-1 text-base font-medium">Acceso a la gestión del campus corporativo</label>
                          </div>
                    </Visibility>
                </div>
            </fieldset>
          <UiBox class="mt-5" v-if="error_licenses === true && employee_id !== null">
            <div class="flex items-center" >
              <div class="mr-4">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#EFC250"/>
                <path d="M23.5046 23.4291H11.4954C10.3471 23.4291 9.62858 22.1871 10.2009 21.1917L16.2056 10.7489C16.7797 9.75039 18.2203 9.75038 18.7944 10.7489L24.7991 21.1917C25.3714 22.1871 24.6529 23.4291 23.5046 23.4291Z" fill="white"/>
                <path d="M17.5 14.4705V17.4567V14.4705Z" fill="white"/>
                <path d="M17.5 14.4705V17.4567" stroke="#EFC250" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M17.5 20.4506L17.5078 20.4419L17.5 20.4506Z" fill="white"/>
                <path d="M17.5 20.4506L17.5078 20.4419" stroke="#EFC250" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div>
                <h3 class="font-medium text-grey-darker">El equipo elegido no tiene licencias disponibles</h3>
                <p class="text-grey-darker">Para que el usuario pueda formar parte de este equipo se le desactivará la licencia.</p>
              </div>
            </div>
          </UiBox>
        </article>
      <footer class="px-12 pb-8 mt-5 flex justify-end">
        <div class="flex justify-end">
          <button type="button" @click.prevent="changeState(false)" class="btn-outline-default mr-3">Cancelar</button>
          <UiButton :loading="this.loading_button" :type="(this.loading_button) ? 'disabled' : 'secondary'" @click="validateForm">Dar de alta</UiButton>
        </div>
      </footer>
      </form>
      <article v-if="emailInvite" class="px-16 pt-3 pb-16">
        <div>
          <div v-if="!hasDomain" class="alerts-wrapper w-full z-30}">
            <div class="alert-centered">
              <div class="flex items-center p-4 rounded shadow bg-white my-3 text-base font-normal">
                <svg class="w-6 h-6 mr-4 flex-none" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#FDBA74"/>
                <g transform="matrix(2.1119,0,0,2.1119,-885.047,-596.816)">
                  <path d="M428.694,293.925C428.694,293.194 428.094,292.594 427.363,292.594C426.631,292.594 426.032,293.194 426.032,293.925C426.032,294.657 426.631,295.257 427.363,295.257C428.094,295.257 428.694,294.657 428.694,293.925ZM428.802,288.346C428.802,287.458 428.382,286.51 427.363,286.51C426.343,286.51 425.924,287.458 425.924,288.346C425.924,289.09 426.2,289.989 426.535,290.649C426.643,290.877 427.063,291.657 427.363,291.657C427.663,291.657 428.082,290.877 428.19,290.649C428.526,289.989 428.802,289.09 428.802,288.346Z" style="fill:white;fill-rule:nonzero;"/>
                </g>
              </svg>
              <span class="text-left font-bold" style="color:#A16207">
                No se pueden crear invitaciones por enlace si los dominios de correos no están configurados. Para configurar los dominios de correos
                <router-link class="underline text-primary" :to="{name: 'profile-organization'}">haz click aquí</router-link>
              </span>
              </div>
            </div>
          </div>
          <h2 class="text-xl font-semibold mt-3 text-grey-dark">Enlace por equipos</h2>
          <p class="text-grey-dark mb-5">Invita a usuarios a un equipo específico compartiendo este enlace.</p>
          <div class="flex">
            <div class="flex w-full flex-col ">
              <div class="mb-5 flex items-start">
                <UiCustomSelect :border="false" :class="{'disabled pointer-events-none' : !hasDomain}"  :filter="false" class="w-full text-sm text-grey bg-grey-lightest" v-model="teamInvitation">
                  <option value="">Selecciona un equipo</option>
                  <option :value="team" v-for="team in teams.results" :key="team.id">{{team.name}}</option>
                </UiCustomSelect>
              </div>
              <div :class="{ 'disabled pointer-events-none': !hasDomain }" class="bg-grey-lightest w-full flex px-4 py-3 rounded ">
                <div  class="tooltip inline-block relative w-auto">
                  <div class="tooltip" name="title">
                    <button @click="copyToClipboard(teamInvitation.registration_url), this.visibleTooltipTeamUrl = true" :class="(!teamInvitation) ? 'text-grey' : 'text-grey-darkest underline'" :disabled="!teamInvitation" class="text-xs flex items-center">{{(teamInvitation) ? `https://openwebinars.net${teamInvitation.registration_url}` : 'Selecciona un equipo para obtener el enlace de invitación'}} <CopyIcon class="ml-1"/> </button>
                 </div>
                  <ul
                  :class="(visibleTooltipTeamUrl) ? 'block' : 'hidden'"
                  class="z-50 arrow-bottom absolute w-80 bg-white rounded-md border-2 border-primary-light text-xs leading-4 text-grey font-normal">
                    <li>Enlace copiado en portapapeles</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="flex items-end justify-center">
              <div  class="tooltip inline-block relative w-auto">
                <div class="tooltip" name="title">
                  <UiButton :class="{'disabled' : !teamInvitation}" :disabled="!teamInvitation" @click="copyToClipboard(teamInvitation.registration_url),this.visibleTooltipTeamBtn = true" class="ml-4" :type="'secondary'">Copiar</UiButton>
                </div>
                <ul
                :class="(visibleTooltipTeamBtn) ? 'block' : 'hidden'"
                class="z-50 arrow-bottom absolute w-80 bg-white rounded-md border-2 border-primary-light text-xs leading-4 text-grey font-normal">
                  <li>Enlace copiado en portapapeles</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <h2 class="text-xl font-semibold  text-grey-dark">Enlace general</h2>
          <p class="text-grey-dark mb-5">Invita a nuevos usuarios a tu empresa compartiendo este enlace.</p>
          <div class="flex items-center" >
            <div :class="{ 'disabled pointer-events-none': !hasDomain }" class="bg-grey-lightest w-full flex px-4 py-3 rounded" >
                <div  class="tooltip inline-block relative w-auto">
                  <div class="tooltip" name="title">
                    <button @click="copyToClipboard(urlRegistration), this.visibleTooltipUrl = true" class="text-xs flex items-center underline">https://openwebinars.net{{ urlRegistration }} <CopyIcon class="ml-1"/> </button>
                 </div>
                  <ul
                  :class="(visibleTooltipUrl) ? 'block' : 'hidden'"
                  class="z-50 arrow-bottom absolute w-80 bg-white rounded-md border-2 border-primary-light text-xs leading-4 text-grey font-normal">
                    <li>Enlace copiado en portapapeles</li>
                  </ul>
                </div>
            </div>
              <div  class="tooltip inline-block relative w-auto">
                  <div class="tooltip" name="title">
                    <UiButton  :class="{'disabled' : !hasDomain }" :disabled="!hasDomain" @click="copyToClipboard(urlRegistration), this.visibleTooltipBtn = true" class="ml-4" :type="'secondary'">Copiar</UiButton>
                 </div>
                  <ul
                  :class="(visibleTooltipBtn) ? 'block' : 'hidden'"
                  class="z-50 arrow-bottom absolute w-80 bg-white rounded-md border-2 border-primary-light text-xs leading-4 text-grey font-normal">
                    <li>Enlace copiado en portapapeles</li>
                  </ul>
                </div>
          </div>
        </div>
      </article>
      </section>
    </div>
  </div>
  </transition>
  <DialogBox ref="user-dialog" />
</template>

<script>
import { api } from '../../api/instances'
import { mapActions, mapMutations, mapState } from 'vuex'

import SelectTeam from '../SelectTeam.vue'
import SelectRole from '../SelectRole.vue'
import TagFieldToAdd from '../TagFieldToAdd.vue'
import MultiSelectTeam from '../MultiSelectTeam.vue'
import UiButton from '../Layout/UI/UiButton.vue'
import Visibility from '../Visibility.vue'
import UiBox from '../Layout/UI/UiBox.vue'
import UiCheck from '@/components/Layout/UI/UiCheck.vue'
import DialogBox from '@/components/DialogBox.vue'
import Tooltip from '@/components/Tooltip.vue'
import CopyIcon from '@/components/Icons/CopyIcon.vue'
import UiCustomSelect from '../Layout/UI/UiCustomSelect.vue'

export default {
  name: 'AddUserModal',
  components: {
    SelectTeam,
    SelectRole,
    TagFieldToAdd,
    UiButton,
    Visibility,
    MultiSelectTeam,
    UiBox,
    UiCheck,
    DialogBox,
    Tooltip,
    CopyIcon,
    UiCustomSelect
  },
  emits: ['modal:show', 'modal:hide', 'success', 'error'],
  data () {
    return {
      open: false,
      is_team: false,
      employee_id: null,
      email: { value: '', error: false, error_message: '' },
      team: { value: '', error: false },
      first_name: { value: '', error: false },
      last_name: { value: '', error: false },
      cid: '',
      tags: [],
      custom_tags: [],
      role: 'student',
      licenses: null,
      error_licenses: '',
      managed_teams: [],
      has_campus_access: false,
      custom_teams: [],
      loading_button: false,
      has_future_planning: false,
      dataUserView: true,
      emailInvite: false,
      teamInvitation: '',
      visibleTooltipTeamBtn: false,
      visibleTooltipTeamUrl: false,
      visibleTooltipUrl: false,
      visibleTooltipBtn: false
    }
  },
  computed: {
    ...mapState(['user', 'active_business', 'permissions', 'urlRegistration', 'hasDomain', 'teams'])
  },
  methods: {
    ...mapMutations(['addToastMessage', 'setDisabledUsers']),
    ...mapActions(['openDialogBox', 'getTags', 'getTotalLicenses', 'getTeams']),
    changeState (state) {
      console.log('sdf')
      document.querySelector('body').style.overflow = (state) ? 'hidden' : 'auto'
      this.open = state
    },
    modal (action, obj = null) {
      this.resetFileds()
      if (action === 'show') {
        this.$emit('modal:show')
        this.changeState(true)
        this.autoFocus()
        this.getTeams()
        if (obj && obj.is_team) {
          this.is_team = obj.is_team
        }

        if (obj !== null) {
          this.email.value = obj.email
          this.team.value = obj.team
          this.has_future_planning = obj.has_future_planning
          this.cid = (obj.cid) ? obj.cid : ''
          this.first_name.value = obj.first_name
          this.last_name.value = obj.last_name
          this.custom_tags = obj.custom_tags
          this.custom_teams = obj.managed_teams
          this.role = obj.role
          this.has_campus_access = obj.has_campus_access
          this.employee_id = obj.employee_id
        }
      }
      if (action === 'hide') {
        this.$emit('modal:hide')
        this.changeState(false)
      }
    },
    withoutLicenses (e) {
      if (e === 0) {
        this.error_licenses = true
      } else {
        this.error_licenses = false
      }
    },
    async createUser () {
      this.loading_button = true
      try {
        await api.post(`/jarvis/business/${this.active_business}/users/`,
          {
            cid: this.cid,
            email: this.email.value,
            team: this.team.value,
            first_name: this.first_name.value,
            last_name: this.last_name.value,
            custom_tags: this.tags,
            role: this.role,
            departments: this.managed_teams,
            has_campus_access: this.has_campus_access
          })
        this.$emit('success')
        this.loading_button = false
        this.addToastMessage({
          status: 'success',
          title: 'Completado',
          message: '¡El usuario fue añadido con éxito!'
        })
        this.changeState(false)
        this.getTags()
      } catch (err) {
        this.$emit('error')
        this.loading_button = false
        console.error('Error en alta de usuario:', err)
        if (err.response) {
          if (err.response.data.email) {
            this.email.error = true
            this.email.error_message = err.response.data.email.split('&')[0]
            if (err.response.data.email.split('&').length > 1) {
              this.email.idUser = err.response.data.email.split('&')[1]
            }
          }
          let message = ''
          if (err.response.data.non_field_errors) {
            err.response.data.non_field_errors.forEach((error) => {
              message += error + '\n'
            })
          }
          message += (message === '') ? '¡Se ha producido un error!' : ''
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message: message
          })
        } else {
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message: '¡Se ha producido un error!'
          })
        }
        this.loading_button = false
      }
    },
    validateForm () {
      const required_fields = [...document.querySelectorAll('#create-edit-user [required]')]
      required_fields.forEach((el) => {
        if (this[el.id].value === '') {
          this[el.id].error = true
        } else {
          this[el.id].error = false
        }
      })
    },
    goToDetailUser (id) {
      this.changeState(false)
      this.$router.push(`/gestion/usuarios/detalle/${id}`)
    },
    resetFileds () {
      this.email = { value: '', error: false }
      this.team = { value: '', error: false }
      this.first_name = { value: '', error: false }
      this.last_name = { value: '', error: false }
      this.custom_tags = []
      this.tags = []
      this.role = 'student'
      this.employee_id = null
      this.cid = null
      this.has_campus_access = false
      this.dataUserView = true
      this.emailInvite = false
      this.teamInvitation = ''
      this.visibleTooltipTeamBtn = false
      this.visibleTooltipTeamUrl = false
      this.visibleTooltipUrl = false
      this.visibleTooltipBtn = false
    },
    autoFocus () {
      setTimeout(() => {
        document.querySelector('#create-edit-user input[autofocus]').focus()
      }, 100)
    },
    async disableUserFromBusiness () {
      try {
        await api.patch(`/jarvis/business/${this.active_business}/users/${this.employee_id}/disable/`)
        this.$emit('disable')
        this.setDisabledUsers()
        this.addToastMessage({
          status: 'success',
          title: 'Usuario dado de baja',
          message: 'Ya no tendrá acceso a la plataforma.'
        })
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al dar de baja al usuario.'
        })
        console.error('Error al dar de baja usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
      this.changeState(false)
    },
    async deleteUserFromBusiness () {
      try {
        await api.delete(`/jarvis/business/${this.active_business}/users/${this.employee_id}/`)
        this.$emit('delete')
        this.addToastMessage({
          status: 'success',
          title: 'Usuario eliminado',
          message: 'Eliminado correctamente de la organización.'
        })
        this.getTotalLicenses()
      } catch (err) {
        this.addToastMessage({
          status: 'danger',
          title: 'Error!',
          message: 'Error al desactivado al usuario.'
        })
        console.error('Error al desvincular usuario', err)
      }
      this.$refs['user-dialog'].dialog('hide')
      this.changeState(false)
    },
    copyToClipboard (text) {
      const textArea = document.createElement('textarea')
      textArea.value = `https://openwebinars.net${text}`
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      setTimeout(() => {
        this.visibleTooltipTeamBtn = false
        this.visibleTooltipTeamUrl = false
        this.visibleTooltipUrl = false
        this.visibleTooltipBtn = false
      }, 2000)
    },
    checkRole () {
      if (this.role === 'student') {
        this.has_campus_access = false
      }
    }
  }
}
</script>

<style scoped>
  input::-webkit-input-placeholder {
    font-size: 12px;
      line-height: 3;
  }
  .error{
    border: 2px solid red;
  }
  .overlay {
    animation-duration: .4s;
    padding-top: 5vh;
    @apply fixed top-0 left-0 z-40 flex items-baseline pb-10 justify-center bg-black w-screen h-screen overflow-y-scroll bg-opacity-75
  }
  .modal{
    animation-duration: .4s;
    @apply relative lg:w-2of3 2xl:w-6of12 rounded-lg bg-white
  }

  .arrow-bottom {
    max-width: 213px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc( 150% - 12px ) );
    @apply  py-2 px-2;
    text-align: center;
  }
  .arrow-bottom::before {
    content: '';
    position: absolute;
    bottom: 32px;
    left: 50%;
    display: block;
    width: 9px;
    height: 9px;
    background-color: white;
    transform: rotate(224deg) translateX(-50%);
    @apply border-b-2 border-r-2 border-primary-light
  }
  .icon {
    top: 0px;
    left: 0px;
  }
  .tooltip {
    /* padding: 10px; */
  }

</style>
