<template>
<div class="block relative" v-click-outside="hide">

  <div class="flex items-center" >
    <button type="button" @click.prevent="open = !open" class="flex items-center whitespace-nowrap w-full bg-grey-lightest py-2 pl-4 pr-9 rounded">
      <ul v-if="selected_tags.length > 0" class="flex flex-wrap w-full mx-2">
        <li class="pm-1" v-for="tag in selected_tags" :key="tag.id" >
          <button type="button" @click.stop="tag.checked = false">
            <Tag :color="{ bg_color: tag.bg_color, text_color: tag.text_color }" :name="tag.name" :close="true"></Tag>
          </button>
        </li>
      </ul>
      <span v-else class="text-xs text-grey-light">
        Selecciona una o varias etiquetas...
      </span>

      <span class="text-xs w-5 rounded bg-grey-lighter ml-1" :class="{'opacity-0': selected_tags.length <= 0}">{{selected_tags.length}}</span>

    </button>
    <svg class="relative -left-5" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.35355 1.60355L8.70711 1.25L8 0.542893L7.64645 0.896447L8.35355 1.60355ZM4.5 4.75L4.14645 5.10355L4.5 5.45711L4.85355 5.10355L4.5 4.75ZM1.35355 0.896446L1 0.542893L0.292893 1.25L0.646447 1.60355L1.35355 0.896446ZM7.64645 0.896447L4.14645 4.39645L4.85355 5.10355L8.35355 1.60355L7.64645 0.896447ZM4.85355 4.39645L1.35355 0.896446L0.646447 1.60355L4.14645 5.10355L4.85355 4.39645Z" fill="#404040"/>
    </svg>
  </div>

  <div class="absolute z-20 bg-grey-lightest w-full mt-1" :class="{'hidden': !open}">
    <hr class="border-grey-lightest">

    <div class="relative px-2 pb-2">
      <input @keydown.enter.prevent="addNewTag(search), search = ''" v-model="search" class="pl-3 py-1 box-border text-sm outline-none w-full" type="text" placeholder="Filtrar o añadir">
      <button type="button" @click.prevent="addNewTag(search), search = ''" v-if="filtered_tags.length == 0" class="bg-grey-light px-1 py-0.5 rounded absolute right-5 top-2 text-xs">Añadir</button>
    </div>

    <hr class="border-grey-lighter mb-1">
    <ul class="tags-list">
      <li class="pr-1 py-1 flex" v-for="tag in filtered_tags" :key="tag.id">
        <button type="button" @click.prevent="tag.checked = !tag.checked" class="flex cursor-pointer ml-1" :for="tag.slug">
            <Tag :color="{ bg_color: tag.bg_color, text_color: tag.text_color }" :name="tag.name"/>
        </button>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import Tag from './Tag.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TagFieldToAdd',
  components: {
    Tag
  },
  emits: ['change', 'update:modelValue'],
  props: {
    custom: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      field_tags: [],
      search: ''
    }
  },
  watch: {
    list () {
      this.addTags()
    }
  },
  computed: {
    ...mapState(['active_business', 'tags']),
    filtered_tags () {
      return this.field_tags.filter((el) => {
        return el.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) ? el : ''
      })
    },
    selected_tags () {
      return this.field_tags.filter((el) => {
        return el.checked === true
      })
    }
  },
  methods: {
    ...mapActions(['getTags']),
    hide () {
      this.open = false
      this.search = ''
      this.update()
    },
    async customGetTags () {
      this.loading = true
      await this.getTags()
      this.field_tags = [...this.tags]
      this.loading = false
    },
    update () {
      this.$emit('update:modelValue', this.selected_tags.map((el) => el))
      this.$emit('change')
    },
    addNewTag (tag) {
      if (this.field_tags.findIndex((el) => el.name.toLocaleLowerCase() === tag.toLocaleLowerCase()) < 0) {
        this.field_tags.push(
          {
            id: null,
            name: tag,
            slug: 'slug',
            color: null,
            checked: true
          }
        )
      }
      return null
    },
    addTags () {
      this.custom.forEach(tag => {
        const index = this.field_tags.findIndex((el) => el.slug === tag.slug)
        this.field_tags[index].checked = true
      })
    }
  },
  async created () {
    await this.customGetTags()
    this.addTags()
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
  .tags-list {
    max-height: 200px;
    @apply bg-grey-lightest shadow w-full z-10 overflow-hidden overflow-y-scroll flex flex-wrap
  }
</style>
