<template>
  <svg :id="props.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4V8M12 4C6.47701 4 2.00001 8.477 2.00001 14C1.99661 16.1646 2.69872 18.2713 4.00001 20.001H8.50001M12 4C17.523 4 22 8.477 22 14C22 16.252 21.256 18.33 20 20.001L15.5 20M4.00001 8L6.50001 10.5M17.5 10.5L20 8M3.00001 17H6.00001M12 17L13 11M18 17H21"
     :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 23C12.7956 23 13.5587 22.6839 14.1213 22.1213C14.6839 21.5587 15 20.7956 15 20C15 19.2044 14.6839 18.4413 14.1213 17.8787C13.5587 17.3161 12.7956 17 12 17C11.2044 17 10.4413 17.3161 9.87868 17.8787C9.31607 18.4413 9 19.2044 9 20C9 20.7956 9.31607 21.5587 9.87868 22.1213C10.4413 22.6839 11.2044 23 12 23Z"
     :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'speedometer-icon'
  },
  color: {
    type: String,
    default: '#344054'
  }
})
</script>

<style scoped>

</style>
