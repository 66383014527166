<template>
  <div class="flex flex-col items-center">
    <div v-if="!small" class="ia-thinking" :class="{'ia-thinking--small' : small}">
      <svg class="absolute right-9 top-5" width="20%" height="20%" viewBox="0 0 44 44" version="1.1">
      <path class="pulse-star-reverse pusel-delay-1"
        d="M1.498,21.781c14.126,-0 20.283,-5.941 20.283,-20.283c-0,14.342 6.116,20.283 20.282,20.283c-14.166,-0 -20.282,6.116 -20.282,20.282c-0,-14.166 -6.157,-20.282 -20.283,-20.282Z"
        style="fill:#89d762;fill-rule:nonzero;" />
      </svg>
      <svg class="absolute" width="100%" height="100%" viewBox="0 0 44 44" version="1.1">
      <path class="pulse-star pusel-delay-2"
        d="M1.498,21.781c14.126,-0 20.283,-5.941 20.283,-20.283c-0,14.342 6.116,20.283 20.282,20.283c-14.166,-0 -20.282,6.116 -20.282,20.282c-0,-14.166 -6.157,-20.282 -20.283,-20.282Z"
        style="fill:#315faa;fill-rule:nonzero;" />
      </svg>
      <svg class="absolute bottom-9" width="30%" height="30%" viewBox="0 0 44 44" version="1.1">
      <path class="pulse-star-reverse pusel-delay-3"
        d="M1.498,21.781c14.126,-0 20.283,-5.941 20.283,-20.283c-0,14.342 6.116,20.283 20.282,20.283c-14.166,-0 -20.282,6.116 -20.282,20.282c-0,-14.166 -6.157,-20.282 -20.283,-20.282Z"
        style="fill:#f0e51c;fill-rule:nonzero;" />
      </svg>
      <svg class="absolute" width="50%" height="50%" viewBox="0 0 44 44" version="1.1">
      <path class="pulse-star-reverse pusel-delay-4"
        d="M1.498,21.781c14.126,-0 20.283,-5.941 20.283,-20.283c-0,14.342 6.116,20.283 20.282,20.283c-14.166,-0 -20.282,6.116 -20.282,20.282c-0,-14.166 -6.157,-20.282 -20.283,-20.282Z"
        style="fill:#d348b2;fill-rule:nonzero;" />
      </svg>
    </div>
    <div class="flex flex-col pl-4">
      <div class="flex items-center" v-for="message in messages" :key="message.id">{{message.body}}<span v-if="message.beingTyped" class="blinking-square"></span></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
const props = defineProps({
  small: {
    type: Boolean,
    default: false
  },
  messages: {
    type: Array,
    default: () => [
      'Iniciando exploración de mejoras',
      'Analizando la gestión de la organización',
      'Buscando oportunidades para asignar licencias de forma óptima',
      'Analizando la gestión de los equipos',
      'Buscando oportunidades para activar licencias de forma óptima',
      'Realizando las últimas comprobaciones',
      'Optimizando los resultados obtenidos',
      'Depurando elementos duplicados',
      'Generando informe de mejoras',
      'Enviando informe de mejoras al sistema central',
      'Finalizando exploración de mejoras',
      'Exploración de mejoras finalizadas',
      'Integrando todos los resultados',
      'Generando informe final',
      'Obteniendo feedback de la IA secundaria',
      'Integrando feedback de la IA secundaria',
      'Generando informe final'
    ]
  }
})
const messagesLog = ref([...props.messages])

const messages = ref([])
const typeOutResponse = (message) => {
  messages.value.push({ id: new Date().getTime(), role: 'assistant', body: '', beingTyped: true })
  const responseMessage = messages.value[messages.value.length - 1]
  let i = 0
  const interval = setInterval(() => {
    responseMessage.body += message.charAt(i)
    i++
    if (i > message.length - 1) {
      setTimeout(() => {
        deleteText(message)
      }, 3000)
      clearInterval(interval)
    }
  }, 30)
}
const deleteText = (message) => {
  const responseMessage = messages.value[messages.value.length - 1]
  responseMessage.beingTyped = true
  let i = message.length - 1
  const interval = setInterval(() => {
    responseMessage.body = responseMessage.body.slice(0, -1)
    i--
    if (i < 0) {
      responseMessage.beingTyped = false
      clearInterval(interval)
      nextMessege()
    }
  }, 10)
}
const nextMessege = () => {
  if (messagesLog.value.length > 0) {
    typeOutResponse(messagesLog.value.shift())
  }
}
onMounted(() => {
  nextMessege()
})
</script>

<style scoped>
.ia-thinking {
  position: relative;
  width: 180px;
  height: 180px;
}
.ia-thinking--small {
  width: 100px;
  height: 100px;
}
.blinking-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #3f536f;
  opacity: 1;
  margin-top: 4px;
  margin-left: 2px;
  border-radius: 50%;
  transform-position: center;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }
}

.pulse-star {
    width: 100px;
    height: 100px;
    background-color: black;
    transform-origin: center;
    animation: pulse var(--duration, 2s) linear infinite var(--delay, 0s);
}
.pulse-star-reverse {
    width: 100px;
    height: 100px;
    background-color: black;
    transform-origin: center;
    animation: pulse-reverse var(--duration, 2s) linear infinite var(--delay, 0s);
}
.pusel-delay-1 {
    --delay: 0.5s;
}
.pusel-delay-2 {
    --delay: 1s;
}
.pusel-delay-3 {
    --delay: 1.5s;
}
.pusel-delay-4 {
    --delay: 0.8s;
}

@keyframes pulse {
    0% {
      transform: scale(1) rotate(0deg);
    }
    50% {
      transform: scale(0.9) rotate(180deg);
      opacity: 0.5;
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}
@keyframes pulse-reverse {
    0% {
      transform: scale(1) rotate(360deg);
    }
    50% {
      transform: scale(0.9) rotate(180deg);
      opacity: 0.4;
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}
</style>
