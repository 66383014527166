<script setup>
import { ref, defineExpose } from 'vue'
import Overlay from './components/Overlay.vue'
import Modal from './components/Modal.vue'
import ModalHeader from './components/ModalHeader.vue'
import ModalTitle from './components/ModalTitle.vue'
import ModalBody from './components/ModalBody.vue'
import ModalFooter from './components/ModalFooter.vue'
import UiButton from '../Layout/UI/UiButton.vue'
import UserIdentity from '../UserIdentity.vue'
import router from '../../router'

const emit = defineEmits(['change'])
const users_with_license = ref([])
const users_with_planner = ref([])
const users_total = ref(0)
const team = ref(null)
const showUserPlanner = ref(false)
const showUserLicense = ref(false)
const open = ref(false)
const closeModal = () => { open.value = false }
const addUsersGroup = () => {
  emit('change', { action: 'change_team', team_id: team.value.id, ignore_warnings: true })
  closeModal()
}
const modal = (action, obj = null) => {
  if (action === 'show') {
    open.value = true
    users_with_license.value = obj.users_with_license
    users_with_planner.value = obj.users_with_planner
    users_total.value = obj.users_total
    team.value = obj.team
  }
  if (action === 'hide') {
    open.value = false
    users_with_license.value = []
    users_with_planner.value = []
    users_total.value = 0
    team.value = null
  }
}
defineExpose({ modal })
</script>
<template>
  <Overlay :open="open">
    <Modal>
      <ModalHeader @close="closeModal">
          <ModalTitle>
            {{ users_total - users_with_planner.length }} usuarios cambiarán al equipo {{ team.name }}
          </ModalTitle>
      </ModalHeader>
      <ModalBody class="mb-8">
          <span class="text-sm text-[#505050]">Se han detectado las siguientes alertas:</span>
          <div v-if="users_with_planner.length > 0" class="flex flex-col mt-3 bg-[#F2F4F7] rounded px-10 py-6">
              <div class="flex items-start gap-x-6 ">
                <div  class="">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.5" cy="11.5" r="11.5" fill="#F32222"/>
                    <path d="M7.88574 15.7715L11.8286 11.8286M11.8286 11.8286L15.7715 7.88574M11.8286 11.8286L7.88574 7.88574M11.8286 11.8286L15.7715 15.7715" stroke="white" stroke-width="4" stroke-linecap="square" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="text-sm font-bold text-grey-darker">{{ users_with_planner.length }}</span>
                <div class="text-sm max-w-[518px] text-grey-darker">
                   Usuario{{ users_with_planner.length > 1 ? 's' : '' }} no se moverán porque tienen licencias planificadas, accede al <a class="underline" href="/planificador">planificador</a> y elimina sus planificaciones.
                </div>
              </div>
              <div class="w-full flex justify-end">
                  <button @click="() => {showUserPlanner = !showUserPlanner, showUserLicense = false}" class="text-primary underline text-sm">{{ showUserPlanner ? 'Ocultar' : 'Ver' }} listado usuarios</button>
              </div>
              <div  class="py-2 mt-1 border-t border-secondary custom-scroll  max-h-[150px] overflow-y-scroll" v-if="showUserPlanner">
                <div class=" py-4 flex items-center justify-between" v-for="user in users_with_planner" :key="user.id">
                <div class="">
                  <UserIdentity class="" large :user="user" dark />
                </div>
                <div class="">
                  <div class="">
                      <span class="managed-team_list text-xs"  v-for="sprint, index in user.sprints" :key="sprint">
                        {{user.sprints.length > 1 ? (user.sprints.length !== index + 1) ? sprint.concat(', ') : sprint : sprint}}
                      </span>
                  </div>
                </div>
                </div>
              </div>
          </div>
          <div v-if="users_with_license.length > 0" class="flex flex-col mt-3 bg-[#F2F4F7] rounded px-10 py-6">
              <div class="flex items-start gap-x-6 ">
                <div  class="">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#EFC250"/>
                    <g clip-path="url(#clip0_5361_2431)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.22383 16.585L11.2818 4.80198C11.4305 4.55715 11.6396 4.35474 11.8892 4.21426C12.1388 4.07379 12.4204 4 12.7068 4C12.9932 4 13.2748 4.07379 13.5244 4.21426C13.774 4.35474 13.9832 4.55715 14.1318 4.80198L21.1898 16.585C21.3353 16.837 21.4122 17.1227 21.4129 17.4137C21.4137 17.7047 21.3383 17.9908 21.1942 18.2436C21.0501 18.4964 20.8423 18.7071 20.5916 18.8547C20.3408 19.0023 20.0558 19.0817 19.7648 19.085H5.64883C5.35775 19.082 5.07254 19.0028 4.8216 18.8552C4.57067 18.7077 4.36277 18.497 4.21862 18.2441C4.07447 17.9912 3.99909 17.7049 4.00001 17.4138C4.00092 17.1227 4.07809 16.837 4.22383 16.585ZM12.7068 8.58498C12.972 8.58498 13.2264 8.69033 13.4139 8.87787C13.6015 9.06541 13.7068 9.31976 13.7068 9.58498V12.585C13.7068 12.8502 13.6015 13.1045 13.4139 13.2921C13.2264 13.4796 12.972 13.585 12.7068 13.585C12.4416 13.585 12.1873 13.4796 11.9997 13.2921C11.8122 13.1045 11.7068 12.8502 11.7068 12.585V9.58498C11.7068 9.31976 11.8122 9.06541 11.9997 8.87787C12.1873 8.69033 12.4416 8.58498 12.7068 8.58498ZM11.7068 15.585C11.7068 15.3198 11.8122 15.0654 11.9997 14.8779C12.1873 14.6903 12.4416 14.585 12.7068 14.585H12.7148C12.98 14.585 13.2344 14.6903 13.4219 14.8779C13.6095 15.0654 13.7148 15.3198 13.7148 15.585C13.7148 15.8502 13.6095 16.1045 13.4219 16.2921C13.2344 16.4796 12.98 16.585 12.7148 16.585H12.7068C12.4416 16.585 12.1873 16.4796 11.9997 16.2921C11.8122 16.1045 11.7068 15.8502 11.7068 15.585Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_5361_2431">
                    <rect width="18" height="16" fill="white" transform="translate(4 4)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <span class="text-sm font-bold text-grey-darker">{{ users_with_license.length }}</span>
                <div class="text-sm max-w-[518px] text-grey-darker">
                   Usuario{{ users_with_license.length > 1 ? 's' : '' }} tienen la licencia activa, se les desactivará con el cambio de equipo.
                </div>
              </div>
              <div class="w-full flex justify-end">
                  <button @click="() => {showUserLicense = !showUserLicense, showUserPlanner = false}" class="text-primary underline text-sm">{{ showUserLicense ? 'Ocultar' : 'Ver' }} listado usuarios</button>
              </div>
              <div class="py-2 mt-1 border-t border-secondary custom-scroll  max-h-[150px] overflow-y-scroll" v-if="showUserLicense">
                <div class="mt-4" v-for="user in users_with_license" :key="user.id">
                   <UserIdentity large :user="user" dark />
                </div>

              </div>
          </div>
      </ModalBody>
      <ModalFooter>
        <UiButton @click="closeModal" class="mr-2">Cancelar</UiButton>
        <UiButton v-if="users_with_license.length > 0 " @click="addUsersGroup"  :type="'secondary'">Ignorar y cambiar equipo</UiButton>
        <UiButton v-if="users_with_license.length === 0 " @click="router.push({ name: 'planner-index' })"  :type="'secondary'">Ir al planificador</UiButton>
      </ModalFooter>
    </Modal>
  </Overlay>
</template>
<style scoped>

</style>
