<template>
<RouterParent :name="'teams'">
  <UiHeader :title="'Equipos'">
  </UiHeader>
  <UiPageBody class="mt-4">
    <UiTableFilters>
      <div class="flex-none w-1/3 flex">
        <div class="w-full">
          <SearchField placeholder="Buscar por nombre" v-model="search" @change="customGetTeams" />
        </div>
      </div>
      <div class="flex">
        <Visibility :permission="'can_create_teams'">
          <Tooltip v-if="teamsLimitReached" class="ml-1">
            <template v-slot:icon>
              <UiButton :type="'secondary disabled'">
                Crear equipo
                <SVGIcons class="svg-14 ml-2" icon="plus"/>
              </UiButton>
            </template>
            Has alcanzado el límite de equipos de tu plan actual.
        </Tooltip>
          <UiButton v-else @click="$refs['create-edit-team'].modal('show')" :type="'secondary'">
            Crear equipo
            <SVGIcons class="svg-14 ml-2" icon="plus"/>
          </UiButton>

        </Visibility>
      </div>
    </UiTableFilters>
    <UiTable class="2xl:text-base xl:text-sm">
      <UiTableHeader>
        <UiTableCell first class="2xl:w-3/12 xl:w-5/12 w-5/12">
          <TableSorter
            :loading="loading"
            filter="name"
            v-model="activeFilter"
            @update="customGetTeams()"
          >
            Nombre
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="2xl:w-3/12 xl:w-2/12 w-2/12">
          <TableSorter
            :loading="loading"
            filter="students_count"
            v-model="activeFilter"
            @update="customGetTeams()"
          >
            Usuarios
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-3/12">
          <TableSorter
            :loading="loading"
            filter="licenses_count"
            :margin="'xl:ml-1'"
            v-model="activeFilter"
            @update="customGetTeams()"
          >
            Licencia asignadas
          </TableSorter>
        </UiTableCell>
        <UiTableCell center class="w-3/12">
          <TableSorter
            :loading="loading"
            filter="is_filtered"
            v-model="activeFilter"
            @update="customGetTeams()"
          >
          Licencias activas
          </TableSorter>
          </UiTableCell>
        <UiTableCell center class="w-3/12 ">Nº gestores</UiTableCell>
        <UiTableCell last class="w-1/12"></UiTableCell>
      </UiTableHeader>
      <UiTableRow
      :bg="'bg-white'"
      :class="{ 'loading' : loading }"
        v-for="{
          name,
          managers_count,
          students_count,
          id,
          licenses_count,
          active_licenses,
          failsafe
        } in teams.results" :key="id">
          <UiTableCell first class="2xl:w-3/12 xl:w-5/12 w-5/12 truncate">
            <router-link :title="name" class="truncate text-primary font-semibold" :to="{ name: 'team_id', params: { id_team:id } }">
              {{name}}
            </router-link>
          </UiTableCell>
          <UiTableCell center class="2xl:w-3/12 w-2/12 xl:w-2/12 text-sm">{{students_count}}</UiTableCell>
          <UiTableCell center class=" w-3/12">
              <TeamLicenses
                :permission="'can_add_licenses_to_teams'"
                :team="{id, licenses: licenses_count}"
                @change="getTotalLicenses" />
          </UiTableCell>
          <UiTableCell center class="w-3/12 ">
            {{active_licenses}}
          </UiTableCell>
          <UiTableCell center class="w-3/12">
            {{managers_count}}
          </UiTableCell>
          <UiTableCell last class="w-1/12 justify-end">
            <MenuEllipsis
              v-if="!failsafe"
              class="relative -top-1"
              :items="[
                {type: 'default', permission:'can_edit_teams', icon: 'edit', text: 'Editar', border: false, func: () => {
                    $refs['create-edit-team'].modal('show', {id_team:id , name, action: 'edit'})
                  }
                },
                {type: 'default', permission:'all', icon: 'view', text: 'Ver equipo', border: false, func: () => {
                    this.$router.push({name: 'team_id', params:{id_team: id } })
                  }
                },
                {type: 'default', permission:'all', icon: 'unlink-15x15', text: 'Liberar licencias <br>en este equipo', border: false, func: () => {
                    openDialogBox({ $refs, ref: 'team-dialog', dialog: {
                        title: `¿Quieres liberar las licencias de ${name}?`,
                        text: 'Esta acción desactivará las licencias asignadas y planificadas del equipo y no se puede revertir.',
                        btn_text: 'Liberar licencias',
                        func: async () => { await removeAllLicenses(id) }
                      }
                    })
                  }
                },
                {type: 'danger', permission:'can_delete_teams', icon: 'trash', text: 'Eliminar equipo', border: true,  func: () => {
                    openDialogBox({ $refs, ref: 'team-dialog', dialog: {
                        title: '¿Quieres eliminar este equipo?',
                        text: 'Esta acción no se puede revertir.',
                        btn_text: 'Eliminar',
                        func: async () => { await removeTeam(id) }
                      }
                    })
                  }
                }
              ]"
            />
          </UiTableCell>
        </UiTableRow>

      <NotFoundSearch :status="status"/>
    </UiTable>
  </UiPageBody>
  <TableFooter v-if="!status" class="pl-12" :pages="pages" v-model="paginator" @change="customGetTeams(this.paginator.page)" v-cloak/>
  <AddTeamModal ref="create-edit-team" @success="customGetTeams" />
  <DialogBox ref="team-dialog" />
</RouterParent>
</template>

<script>
import AddTeamModal from '../components/Modals/AddTeamModal.vue'
import DialogBox from '../components/DialogBox.vue'
import MenuEllipsis from '../components/MenuEllipsis/Menu.vue'
import RouterParent from '../components/RouterParent.vue'
import SVGIcons from '../components/Layout/SVGIcons.vue'
import SearchField from '../components/SearchField.vue'
import TableSorter from '../components/TableSorter.vue'
import TableFooter from '../components/TableFooter.vue'
import TeamLicenses from '../components/TeamLicenses.vue'
import UiButton from '../components/Layout/UI/UiButton.vue'
import NotFoundSearch from '../components/NotFoundSearch.vue'
import Visibility from '../components/Visibility.vue'

import { mapActions, mapMutations, mapState } from 'vuex'
import { api } from '../api/instances'
import UiHeader from '../components/Layout/UI/UiHeader.vue'
import UiTable from '../components/Layout/UI/Table/UiTable.vue'
import UiTableHeader from '../components/Layout/UI/Table/UiTableHeader.vue'
import UiTableRow from '../components/Layout/UI/Table/UiTableRow.vue'
import UiTableCell from '../components/Layout/UI/Table/UiTableCell.vue'
import UiTableFilters from '../components/Layout/UI/Table/UiTableFilters.vue'
import UiPageBody from '../components/Layout/UI/UiPageBody.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'Teams',
  components: {
    AddTeamModal,
    DialogBox,
    MenuEllipsis,
    RouterParent,
    SVGIcons,
    SearchField,
    TableFooter,
    TableSorter,
    TeamLicenses,
    NotFoundSearch,
    UiButton,
    Visibility,
    UiHeader,
    UiTable,
    UiTableHeader,
    UiTableRow,
    UiTableCell,
    UiTableFilters,
    UiPageBody,
    Tooltip
  },
  data () {
    return {
      activeFilter: { filter: 'name', direction: false },
      first_loading: true,
      loading: false,
      search: '',
      pages: {},
      status: false,
      paginator: {
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapState(['teams', 'total_licenses', 'active_business', 'filters', 'team_create_limit', 'permissions']),
    allTeams () {
      if (this.teams.results) {
        return this.teams.results
      }
      return []
    },
    teamsLimitReached () {
      if (this.permissions.includes('base_limit_on_teams')) {
        return !(this.allTeams.length <= this.team_create_limit)
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    ...mapActions(['getTeams', 'getTotalLicenses', 'openDialogBox']),
    async customGetTeams (pag = 1) {
      this.loading = true
      const params = {
        limit: this.paginator.limit,
        search: this.search,
        ordering: (this.activeFilter.direction === true) ? `-${this.activeFilter.filter}` : this.activeFilter.filter,
        page: pag
      }
      try {
        await this.getTeams(params)
        this.pages = this.teams.paginator
        this.status = (this.teams.count === 0)
        this.loading = false
      } catch (err) {
        console.error('Problama al cargar los equipos')
        this.loading = false
      }
      this.first_loading = false
    },
    async removeTeam (team_id) {
      await api.delete(`/jarvis/business/${this.active_business}/teams/${team_id}/`)
        .then((resp) => {
          this.$refs['team-dialog'].dialog('hide')
          this.addToastMessage({
            status: 'success',
            title: 'Completado',
            message: 'El equipo fue eliminado.'
          })
          this.customGetTeams()
          this.getTotalLicenses()
        })
        .catch(() => {
          this.$refs['team-dialog'].dialog('hide')
          this.addToastMessage({
            status: 'danger',
            title: 'Error',
            message: 'La operación ha fallado.'
          })
          this.customGetTeams()
        })
    },
    async removeAllLicenses (team_id) {
      try {
        const { data } = await api.patch(`/jarvis/business/${this.active_business}/teams/${team_id}/modify-license/`,
          {
            number_licenses: 0,
            free_used_licenses: 'True'
          })
        const { state, text, number_license } = data
        this.licenses = number_license
        this.$refs['team-dialog'].dialog('hide')
        this.addToastMessage({
          status: state,
          title: (state === 'success') ? 'Completado' : 'Error',
          message: text
        })
        this.customGetTeams()
      } catch (err) {
        console.error('Se ha producido un error al añadir o quitar licencias a este equipo')
      }
    }
  },
  updated () {
    this.customGetTeams()
    this.getTotalLicenses()
  },
  created () {
    this.customGetTeams()
    this.getTotalLicenses()
  }
}
</script>

<style scoped>
  .team{
    @apply flex flex-col justify-center text-center w-full bg-grey-lightest p-6 rounded mb-6 shadow
  }
  .team-title{
    @apply font-semibold text-lg text-grey-darker
  }
  .custom-table-head-rank{
    @apply flex my-2 mb-7 rounded py-3 font-medium text-grey bg-grey-lightest;

}
.custom-table-body-rank{
    @apply mb-2 rounded bg-grey-lightest;

}
.custom-table-body-rank .custom-table-row-rank{
    @apply flex w-full rounded py-3.5 bg-grey-lightest;

}
</style>
