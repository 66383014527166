<template>
  <div class="rounded-full flex items-center text-2xs capitalize py-1 px-2" :class="status_formation">
    <span>
      {{ status_formation }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    status_formation: {
      type: String
    }
  }
}
</script>
<style scoped>
.publicado {
  background-color: #8DAA9D;
  color:#2D4138;
}
.borrador {
  background-color:#F9DB6D;
  color:#6F5E1F;
}
.archivado {
  background-color: #F9A1A1;
  color: #6F1F1F;
}
</style>
