<template>
  <div class="flex items-center">
    <button class="toggle-button" :class="{ 'active': isActive }">
      <div class="toppo p-1"></div>
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

/* const emit = defineEmits(['update:modelValue', 'change']) */

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const isActive = ref(props.modelValue)

watch(() => props.modelValue, (newValue) => {
  isActive.value = newValue
})

/* const update = () => {
  isActive.value = !isActive.value
  emit('update:modelValue', isActive.value)
  emit('change')
} */
</script>

<style scoped>
  .toggle-button{
    transition: all .3s ease-in-out;
    @apply bg-grey-lighter h-4 p-1 rounded-full w-7 relative
  }

  .toggle-button.active{
    background-color: #3F536F;
  }
  .toggle-button.active .toppo{
    left: calc(100% - 14px);
  }

  .toppo{
    top: 2px;
    left: 3px;
    transition: all .3s ease-in-out;
    width: 12px;
    height: 12px;
    @apply bg-white rounded-full absolute shadow
  }

  .disable button {
    pointer-events: none;
  }
  .disable .toggle-button.active {
    background-color: #93dca7;
  }
</style>
