<template>
  <UiHeader :title="'Detalle Usuario'" :slotCol="true">
    <div class="pl-12 pb-6">
      <UiButton @click="$router.go(-1)" :size="'btn-sm'" :type="'default'">
        <SVGIcons class="svg-14 mr-4" :icon="'back-arrow'" />
          Volver al listado
      </UiButton>
    </div>
  </UiHeader>
  <UiPageBody class="pr-6">
    <div class="flex w-full items-center text-grey-dark bg-grey-lightest p-5 mb-10 my-6 rounded">
      <div class="flex ml-6 w-1/2">
        <figure class="flex-none flex relative flex-col items-center">
          <div class="bg-grey-lighter rounded-full overflow-hidden w-16 h-16">
            <img class="cover w-16 h-16" :src="user.avatar_url" alt="Avatar">
          </div>
          <figcaption class="disabled-user" v-if="user.is_disabled">
            Baja
          </figcaption>
        </figure>
        <div class="ml-6">
          <h2 class="font-bold text-lg text-primary">{{user.first_name}} {{user.last_name}}</h2>
          <div class="text-sm text-grey-light font-normal underline">{{user.email}}</div>
          <div class="text-sm font-normal"> <strong>Equipo</strong> {{user.team}}</div>
        </div>
      </div>
      <div class="flex w-1/2 justify-center gap-x-20">
        <div>
          <p class="text-sm text-grey">Última conexión <strong>{{$filters.shortDate(user.last_connection)}}</strong></p>
          <p class="text-sm text-grey mt-2 ">Última formación <strong>{{$filters.shortDate(user.last_learning)}}</strong></p>
        </div>
        <div>
          <p class="text-sm text-grey ">Licencia rotada <strong>{{user.total_assigned_licenses}} veces</strong></p>
          <p class="text-sm text-grey mt-2 ">Última activación <strong>{{$filters.shortDate(user.last_connection)}}</strong></p>
        </div>
      </div>
    </div>
    <UiPageTitle>
      Uso de la plataforma
    </UiPageTitle>
    <UiBox>
      <div class="flex flex-row-reverse">
        <div class="w-5/12 ml-24 flex flex-col">
          <UiBox class="w-full flex flex-col justify-between">
            <div class="flex">
              <div class="w-11/12 font-bold text-lg text-grey-dark">
                Horas de dedicación
              </div>
              <div class="w-1/12">
                <SVGIcons class="svg-32" :icon="'time'"></SVGIcons>
              </div>
            </div>
            <div>
              <div class="font-extrabold text-primary text-3xl mb-4">
                {{$filters.hoursAndMinutes(user.dedication_time)}} <small class="font-normal text-xs">totales</small>
              </div>
              <hr class="border-grey-lighter">
              <div class="flex justify-between mt-4">
                <dl class="text-center">
                  <dd class="font-bold text-sm text-primary">{{$filters.hoursAndMinutes(user.course_dedication_time)}}</dd>
                  <dt class="text-sm text-primary">Formaciones</dt>
                </dl>
                <dl class="text-center">
                  <dd class="font-bold text-sm text-primary">{{$filters.hoursAndMinutes(user.resource_dedication_time)}}</dd>
                  <dt class="text-sm text-primary">Recursos</dt>
                </dl>
                <dl class="text-center">
                  <dd class="font-bold text-sm text-primary">{{$filters.hoursAndMinutes(user.live_dedication_time)}}</dd>
                  <dt class="text-sm text-primary">Directos</dt>
                </dl>
              </div>
            </div>
          </UiBox>
          <UiBox class="w-full mt-5 flex flex-col justify-between">
            <div class="flex">
              <div class="w-11/12 font-bold text-lg text-grey-dark">
                Formación en este año
              </div>
              <div class="w-1/12">
                <SVGIcons class="svg-32" :icon="'book'"></SVGIcons>
              </div>
            </div>
            <div>
              <div class="flex flex-col items-center mt-4">
                <div class="w-full flex items-center justify-center px-3">
                  <dl class="text-center w-1/2 mr-8">
                    <dd class="font-bold text-2xl text-primary">{{progress.enrolls}}</dd>
                    <dt class="2xl:text-sm xl:text-xs text-primary">Matrículas</dt>
                  </dl>
                  <dl class="text-center w-1/2 ml-8">
                    <dd class="font-bold text-2xl text-primary">{{progress.certificates}}</dd>
                    <dt class="2xl:text-sm xl:text-xs text-primary ">Certificados Obtenidos</dt>
                  </dl>
                </div>
                <div class="w-full flex items-center mt-5 justify-center px-3">
                  <dl class="text-center w-1/2 mr-8">
                    <dd class="font-bold text-2xl text-primary">{{progress.hours}}</dd>
                    <dt class="2xl:text-sm xl:text-xs text-primary">Horas de formacion</dt>
                  </dl>
                  <dl class="text-center w-1/2 ml-8">
                    <dd class="font-bold text-2xl text-primary">{{progress.connected }}</dd>
                    <dt class="2xl:text-sm xl:text-xs text-primary">Horas de conexión</dt>
                  </dl>
                </div>
              </div>
            </div>
          </UiBox>
        </div>
        <div class="w-7/12 flex">
          <div class="w-full">
            <div class="flex justify-between">
              <div>
                <strong>Hora de dedicación</strong>
                <p class="text-sm text-grey-light">Horas de dedicación del usuario en la plataforma</p>
              </div>
              <UiCustomSelect :filter="false" :padding="'px-2 py-1'" v-model="days">
                <option value="3650">Todo el tiempo</option>
                <option value="7">Últimos 7 días</option>
                <option value="15">Últimos 15 días</option>
                <option value="30">Últimos 30 días</option>
                <option value="90">Últimos 90 días</option>
                <option value="180">Últimos 180 días</option>
                <option value="365">Últimos 365 días</option>
              </UiCustomSelect>
            </div>
            <ChartHoursByDay class="h-36 mt-20" :days="days" />
          </div>
        </div>
      </div>
    <EventLog :user="{ first_name: user.first_name, last_name: user.last_name}" />
    </UiBox>
    <UiPageTitle class="mt-12">
      Evolución de la formación
    </UiPageTitle>
    <p class="text-xs text-grey-light mt-2 mb-6">Consulta al detalle el estado y la evolución de la formación. </p>
    <div class="mt-12 bg-white shadow-lg p-5 pt-10 rounded-lg mb-12">
    <nav class="flex mb-4 pl-4">
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'detail-student-progress'}">Formaciones
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.courses || 0}}</span>
      </router-link>
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'detail-student-paths'}">Rutas corporativas
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.itineraries || 0}}</span>
      </router-link>
      <router-link class="flex mr-2 rounded px-3 py-1" active-class="active" exact-active-class="bg-grey-lightest"
        :to="{ name: 'detail-student-careers'}">Rutas OW
          <span class="flex justify-center items-center text-sm px-2 rounded-xl ml-2 bg-grey-lighter">{{tabs.careers || 0}}</span>
      </router-link>
    </nav>
    <hr class="border-grey-lightest">
    <router-view />
  </div>
  </UiPageBody>
</template>

<script>
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import UiButton from '../../components/Layout/UI/UiButton.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import { api } from '../../api/instances'
import { mapState } from 'vuex'
import UiPageTitle from '../../components/Layout/UI/Page/UiPageTitle.vue'
import UiBox from '../../components/Layout/UI/UiBox.vue'
import UiCustomSelect from '../../components/Layout/UI/UiCustomSelect.vue'
import ChartHoursByDay from '../../components/Charts/ChartHoursByDay.vue'
import EventLog from '../../components/EventLog.vue'

export default {
  components: { UiHeader, UiButton, SVGIcons, UiPageBody, UiPageTitle, UiBox, UiCustomSelect, ChartHoursByDay, EventLog },
  name: 'StudentsDetail',
  data () {
    return {
      days: '7',
      user: {},
      tabs: {},
      progress: {}
    }
  },
  computed: {
    ...mapState(['active_business'])
  },
  methods: {
    getUser () {
      api.get(`/jarvis/business/${this.active_business}/analitics/students/${this.$route.params.employee_id}/details/`)
        .then(({ data }) => {
          this.user = data
        })
    },
    getTabs () {
      api.get(`/jarvis/progress/users/${this.$route.params.employee_id}/counters/`)
        .then(({ data }) => {
          this.tabs = data
        })
    },
    getUserProgress () {
      api.get(`/jarvis/business/${this.active_business}/users/${this.$route.params.employee_id}/counters/`)
        .then(({ data }) => {
          this.progress = data
        })
    }
  },
  created () {
    this.getUser()
    this.getTabs()
    this.getUserProgress()
  }
}
</script>

<style scoped>
.disabled-user {
  position: relative;
  top: -10px;
  border-radius: 5.143px;
  background: #D2DCEC;
  padding: 0 6px;
}

</style>
