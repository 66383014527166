<template>
  <div class="w-screen h-screen fixed left-0 top-0 bg-[#1D2335] bg-opacity-70 z-50 flex justify-center items-center">
      <div class="bg-white p-12 rounded relative flex flex-col items-center w-[473px]">
        <button @click="() => { emit('close')}" class="absolute right-3 top-3 w-3 h-3"><img src="@/assets/images/landing/times.svg"></button>
        <img src="@/assets/images/landing/mail.svg">
        <h2 class="text-primary font-bold text-[22px]">{{props.title}}</h2>
        <p class="text-center">{{props.message}}</p>
      </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['close'])
const props = defineProps({
  title: {
    type: String,
    default: '¡Solicitud enviada con éxito!'
  },
  message: {
    type: String,
    default: 'En pocos minutos, nuestro equipo de Customer Success se pondrá en contacto contigo para una demo gratuita.'
  }
})
</script>

<style lang="scss" scoped>

</style>
