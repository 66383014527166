<template>
  <TinyToolTip :disabled="open" content="Gestionar etiquetas" backgroundColor="#ffffff" textColor="#333">
  <div class="relative" v-click-outside="hide">
    <button class="massive-action-btn" @click="toggleMenu" :class="{'bg-litepie-primary-400 rounded-lg' : open }">
      <TagIcon />
    </button>
    <div v-if="open" class="absolute left-8 z-30 shadow-lg bg-white py-4 rounded-lg">
      <div v-if="step===0" class="flex flex-col">
        <button @click.stop="getUserTags(1)" class="flex items-center py-2 px-4 hover:bg-grey-lighter-2 text-grey-dark">
          <TimesIcon class="mr-2" />
          Añadir etiquetas
        </button>
        <button @click.stop="getUserTags(2, props.filters)" class="flex items-center py-2 px-4 hover:bg-grey-lighter-2 text-grey-dark">
          <TrashIcon class="mr-2" />
          Eliminar etiquetas
        </button>
        <button @click.stop="getUserTags(3)" class="flex items-center py-2 px-4 hover:bg-grey-lighter-2 text-grey-dark">
          <ChangeIcon class="mr-2" />
          Sustituir todas las etiquetas
        </button>
        <RouterLink :to="{ name: 'tags-list' }" class="flex items-center py-2 px-4 hover:bg-grey-lighter-2 text-grey-dark">
          Gestión de etiquetas
        </RouterLink>
      </div>
      <div class="px-4" v-if="step === 1">
        <div class="text-grey-darkmb-2">Añadir etiquetas:</div>
        <div class="border border-grey-lighter rounded mb-4 px-2 pt-2 flex flex-col">
          <button class="mb-2" v-for="tag in selectedTags" :key="tag.id" @click.stop="removeTagFromSelected(tag.id)">
            <Tag :name="tag.name" :close="true" />
          </button>
          <button class="mb-2" v-for="tag in newTags" :key="tag.name" @click.stop="removeNewTag(tag)">
            <Tag :name="tag" :close="true" />
          </button>
          <form @submit.prevent="addNewTag(search)" class="relative">
            <input v-model="search" class="p-1 bg-transparent text-sm" type="text" placeholder="Filtrar o añadir">
            <button v-if="filteredTags.length === 0" class="absolute underline right-4">
              Añadir
            </button>
          </form>
        </div>
        <div class="tags-list custom-scroll-sm">
          <ul>
            <li v-for="tag in filteredTags" :key="tag.id">
              <button @click="addTagToSelected(tag.id)">
                <Tag :name="tag.name" />
              </button>
            </li>
          </ul>
        </div>
        <hr v-if="newTags.length > 0 || [...selectedTagsIds].length > 0" class="w-full absolute left-0 border-grey-lighter">
        <div v-if="newTags.length > 0 || [...selectedTagsIds].length > 0" class="mt-1">
          <button @click.stop="setAction('add-tags', {
              title: '¡Atención!',
              text: 'Con esta acción, añadirá etiquetas a todos los usuarios seleccionados.',
              btn_text: 'Aceptar'
            })" class="py-1">
            Guardar cambios
          </button>
        </div>
      </div>
      <div class="px-4" v-if="step === 2">
        <div class="text-grey-darkmb-2">Eliminar etiquetas:</div>
        <div class="border border-grey-lighter rounded mb-4 p-2 flex flex-col">
          <button class="mb-2" v-for="tag in selectedTags" :key="tag.id" @click.stop="removeTagFromSelected(tag.id)">
            <Tag :name="tag.name" :close="true" />
          </button>
          <button class="mb-2" v-for="tag in newTags" :key="tag.name" @click.stop="removeNewTag(tag)">
            <Tag :name="tag" :close="true" />
          </button>
          <input v-model="search" class="p-1 bg-transparent text-sm" type="text" placeholder="Filtrar">
        </div>
        <div class="tags-list custom-scroll-sm">
          <ul>
            <li v-for="tag in filteredTags" :key="tag.id">
              <button @click="addTagToSelected(tag.id)">
                <Tag :name="tag.name" />
              </button>
            </li>
          </ul>
        </div>
        <div v-if="newTags.length > 0 || [...selectedTagsIds].length > 0" class="mt-2">
          <button @click.stop="setAction('remove-tags', {
            title: '¡Atención!',
            text: 'Con esta acción, eliminará etiquetas a todos los usuarios seleccionados.',
            btn_text: 'Aceptar'
          })" class="py-2">
            Guardar cambios
          </button>
        </div>
      </div>
      <div class="px-4" v-if="step === 3">
          <div class="text-grey-darkmb-2">Sustituir etiquetas por:</div>
          <div class="border border-grey-lighter rounded mb-4 p-2 flex flex-col">
            <button class="mb-2" v-for="tag in selectedTags" :key="tag.id" @click.stop="removeTagFromSelected(tag.id)">
              <Tag :name="tag.name" :close="true" />
            </button>
            <button class="mb-2" v-for="tag in newTags" :key="tag.name" @click.stop="removeNewTag(tag)">
              <Tag :name="tag" :close="true" />
            </button>
            <form @submit.prevent="addNewTag(search)" class="relative">
              <input v-model="search" class="p-1 bg-transparent text-sm focus-visible:outline-none" type="text" placeholder="Filtrar o añadir">
              <button v-if="filteredTags.length === 0" class="absolute underline right-4">
                Añadir
              </button>
            </form>
          </div>
          <div class="tags-list custom-scroll-sm">
            <ul>
              <li v-for="tag in filteredTags" :key="tag.id">
                <button @click="addTagToSelected(tag.id)">
                  <Tag :name="tag.name" />
                </button>
              </li>
            </ul>
          </div>
          <div v-if="newTags.length > 0 || [...selectedTagsIds].length > 0" class="mt-2">
            <button @click.stop="setAction('set-tags', {
              title: '¡Atención!',
              text: 'Con esta acción, se sustituirán todas las etiquetas a todos los usuarios seleccionados.',
              btn_text: 'Aceptar'
            })" class="py-2">
              Guardar cambios
            </button>
          </div>
        </div>
    </div>
  </div>
  </TinyToolTip>
</template>

<script setup>
import ChangeIcon from './Icons/ChangeIcon.vue'
import TimesIcon from './Icons/TimesIcon.vue'
import TrashIcon from './Icons/TrashIcon.vue'
import TagIcon from './Icons/TagIcon.vue'
import Tag from './Tag.vue'
import TinyToolTip from '@/components/TinyToolTip.vue'

import { computed, ref } from 'vue'
import { api } from '../api/instances'

const emit = defineEmits(['action'])

const step = ref(0)
const open = ref(false)
const tags = ref([])

const reset = () => {
  step.value = 0
  search.value = ''
  selectedTagsIds.value.clear()
  newTags.value = []
}

const toggleMenu = () => {
  open.value = !open.value
  reset()
}

const hide = () => {
  open.value = false
  reset()
}

const getUserTags = (st, filters = false) => {
  console.log('getUserTags', step, filters)
  let params = {}
  if (filters) {
    if (props.ids.length === 0 || props.allUsers) {
      params = { ...props.filters }
    } else {
      params.ids = props.ids
    }
  }
  api.get(`/jarvis/business/${props.bussines}/users/tags/`, { params })
    .then(res => {
      tags.value = res.data
      step.value = st
    })
    .catch(err => {
      console.log(err)
    })
}

const props = defineProps({
  bussines: {
    type: [Number, String],
    required: true
  },
  filters: {
    type: Object,
    default: () => ({})
  },
  ids: {
    type: Array,
    default: () => []
  },
  allUsers: {
    type: Boolean,
    default: false
  }
})

const search = ref('')

const newTags = ref([])

const addNewTag = (name) => {
  if (search.value === '') {
    return
  }
  if (tags.value.find(tag => tag.name === name)) {
    console.log('add new tag', tags.value.find(tag => tag.name === name))
    console.log('tag exists')
    addTagToSelected(tags.value.find(tag => tag.name === name).id)
    return
  }
  if (!newTags.value.includes(name)) {
    newTags.value.push(name)
    search.value = ''
  }
}

const listOfSelectdTags = computed(() => {
  const tagsByIds = [...selectedTagsIds.value].map(id => tags.value.find(tag => tag.id === id).name)
  const tagsByName = newTags.value
  return [...tagsByIds, ...tagsByName]
})

const setAction = (action, dialog) => {
  emit('action', {
    action,
    tags: listOfSelectdTags.value,
    dialog
  })
  hide()
}

const removeNewTag = (name) => {
  newTags.value = newTags.value.filter(tag => tag !== name)
}

const selectedTagsIds = ref(new Set())
const selectedTags = computed(() => {
  return tags.value.filter(tag => selectedTagsIds.value.has(tag.id))
})
const addTagToSelected = (tagId) => {
  selectedTagsIds.value.add(tagId)
}

const removeTagFromSelected = (tagId) => {
  selectedTagsIds.value.delete(tagId)
}

const filteredTags = computed(() => {
  return tags.value.filter(tag => tag.name.toLowerCase().includes(search.value.toLowerCase()))
})

const vClickOutside = {
  mounted (el) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        hide()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
</script>

<style scoped>
.massive-action-btn {
  @apply w-8 h-8 flex justify-center items-center mx-4 transition-all duration-300;
}
.tags-list {
  @apply max-h-32 overflow-y-auto;
}
input:focus-visible {
  @apply outline-none;
}
</style>
