<template>
  <svg :id="props.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 11.5V16.5M12 7.51L12.01 7.499M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
      :stroke="props.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: 'infocircle-icon'
  },
  color: {
    type: String,
    default: '#344054'
  }
})
</script>

<style scoped>

</style>
